<nav class="navbar navbar-expand-lg fixed-top pt-0 pb-0" style="background-color: #ffffff">
    <ul class="navbar-nav me-auto sidenav " [ngClass]="{'expanded': isNavExpand, 'collapsed': !isNavExpand}" style="overflow-y:scroll" id="navAccordion">
        <li class="nav-item" *ngIf="indicator.Batch!='Enabled' && hasAccess('Menu_BatchStart')">
            <a class="nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-batch-receipt']">
                <i class="fa fa-tachograph-digital fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuBatchStart')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="hasAccess('Menu_Draft')">
            <a class="nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-draft-list']">
                <i class="fa fa-table-list fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuDraft')}}</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="indicator.Batch=='Enabled' && hasAccess('Menu_BatchProcess')">
            <a class="nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-batch-process']">
                <i class="fa fa-industry fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuBatchProcess')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="hasAccess('Menu_CodesList')">
            <a class="nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-code-list']">
                <i class="fa fa-qrcode fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuCodesList')}}</span>
            </a>
        </li>


        <li class="nav-item" *ngIf="isFileOperationEnabled() && hasAccess('Menu_FileOperation')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-file-operation']">
                <i class="fa fa-file-alt fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuFilesOperation')}}</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="isSerializationEnabled() && hasAccess('Menu_Serialization')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-serialization']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuSerialization')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isBranchSerializationEnabled() && hasAccess('Menu_Serialization')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-branch-serialization']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuSerialization')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isCodeReservationEnabled() && hasAccess('Menu_CodeReservation')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-code-reservation']">
                <i class="fa fa-bookmark fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuCodeReservation')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isBoxAggregationEnabled() && hasAccess('Menu_AggregationBox')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-box-list']">
                <i class="fa fa-object-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationBox')}}</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="isLayerBoxAggregationEnabled() && hasAccess('Menu_AggregationBox')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-box-visual']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationBox')}}</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="isLayerBoxTimestampAggregationEnabled() && hasAccess('Menu_AggregationBox')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-box-layer-timestamp']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationBox')}}</span>
            </a>
        </li>
        <!--Todo: add accesses-->
        <li class="nav-item" *ngIf="isManualAggregationEnabled() && (hasAccess('Menu_AggregationBox') || hasAccess('Menu_AggregationPallet'))">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-box-pallet-list']">
                <i class="fa fa-object-ungroup fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationBoxPallet')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isLayerSerializationEnabled() && hasAccess('Menu_Serialization')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-layer-serialization']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuLayerValidation')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isLayer63313VisualEnabled() && hasAccess('Menu_Serialization')">
            <a class="nav-link nav-link btn btn-sm mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-layer63313-visual']">
                <i class="fa fa-layer-group fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuLayer63313')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isPalletAggregationEnabled() && hasAccess('Menu_AggregationPallet')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-pallet-list']">
                <i class="fa fa-check-to-slot fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationPallet')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isProductToPalletQueueAggregationEnabled() && hasAccess('Menu_AggregationPallet')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-product-to-pallet-queue-list']">
                <i class="fa fa-check-to-slot fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationPallet')}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="isBoxQueueAggregationEnabled() && hasAccess('Menu_AggregationBox')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-aggregation-box-queue-list']">
                <i class="fa fa-check-to-slot fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAggregationBox')}}</span>
            </a>
        </li>


        <!--<li class="nav-item" *ngIf="hasAccess('Menu_Printers')">
      <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
         [routerLinkActive]="['active']"
         [routerLinkActiveOptions]="{ exact: true }"
         [routerLink]="['/aspu-printer-list']">
        <i class="fa fa-print fa-3x fa-fw faa-pulse faa-slow"></i>
        <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'MenuPrinters')}}</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="hasAccess('Menu_Profiles')">
      <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
         [routerLinkActive]="['active']"
         [routerLinkActiveOptions]="{ exact: true }"
         [routerLink]="['/aspu-profile-list']">
        <i class="fas fa-rectangle-list fa-3x fa-fw faa-pulse faa-slow"></i>
        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuProfiles')}}</span>
      </a>
    </li>

    <li class="nav-item" *ngIf="hasAccess('Menu_Receipts')">
      <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
         [routerLinkActive]="['active']"
         [routerLinkActiveOptions]="{ exact: true }"
         [routerLink]="['/aspu-receipt-list']">
        <i class="fa fa-sliders-h fa-3x fa-fw faa-pulse faa-slow"></i>
        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuReceipts')}}</span>
      </a>
    </li>-->

        <li class="nav-item" *ngIf="hasAccess('Menu_StatusCode')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-code-change']">
                <i class="fa fa-barcode fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'MenuStatusCode')}}</span>
            </a>
        </li>
        <!--<li class="nav-item" *ngIf="hasAccess('Menu_Reports')">
        <a class="nav-link nav-link btn btn-sm mt-0 mb-1 me-1  ms-1 " href="#"
           [routerLinkActive]="['active']"
           [routerLinkActiveOptions]="{ exact: true }"
           [routerLink]="['/aspu-report-list']"
           style="text-align:left">
            <span class="nav-link-text">{{getlocalized('common', 'MenuReports')}}</span>
        </a>
    </li>-->
        <li class="nav-item" *ngIf="hasAccess('Menu_BatchHistory')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-batch-history']">
                <i class="fa fa-history fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'MenuBatchHistory')}}</span>
            </a>

        <li class="nav-item" *ngIf="hasPlcCommon && hasAccess('Menu_Plc')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-plc-list']">
                <i class="fa fa-microchip fa-3x fa-fw faa-pulse faa-slow"></i>
                <!--<i class="fa-3x fa-fw">
                <i class="fa-layers fa-fw">
                    <i class="fa fa-microchip"></i>
                    <span class="fa-layers-text fa-inverse " data-fa-transform="shrink-11.0 rotate--90" style="font-weight:900;">{{getlocalized('common', 'Plc')}}</span>
                </i>
            </i>-->
                <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'Plc')}} </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="hasAccess('Menu_Messages')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-message-list']">
                <span class="fa-layers fa-3x fa-fw">
                    <i class="fa fa-envelope"></i>
                    <!--<span *ngIf="hasMessages" class="fa-layers-counter" style="background-color: black">-->
                    <span *ngIf="hasMessages" class="fa-layers-counter bg-warning">
                        <!--<span style="" class="fa fa-exclamation-triangle text-warning"></span>-->
                        <i class="text-dark">!</i>
                        <!--<span style="" class="fa fa-exclamation-triangle faa-flash faa-slow animated text-warning"></span>-->
                    </span>
                </span>
                <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">
                    {{getlocalized('common', 'MenuMessages')}}
                    <!--<span *ngIf="hasMessages" style="animation: color-change 5s infinite;"><i class="fa fa-exclamation-triangle"></i></span>-->
                </span>


                <!--<i class="fa fa-envelope fa-3x fa-fw"></i>
            <span class="nav-link-text  custom-menu-item">
                {{getlocalized('common', 'MenuMessages')}}
                <span *ngIf="hasMessages" style="animation: color-change 5s infinite;"><i class="fa fa-exclamation-triangle"></i></span>
            </span>-->
            </a>
        </li>
        <li class="nav-item" *ngIf="hasAccess('Menu_ErrorMessages')">
            <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
               [routerLinkActive]="['active']"
               [routerLinkActiveOptions]="{ exact: true }"
               [routerLink]="['/aspu-error-message-list']">
                <i class="fa fa-triangle-exclamation fa-3x fa-fw faa-pulse faa-slow"></i>
                <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'ErrorMessages')}} </span>
            </a>
        </li>

        <li class="nav-item" id="Menu_Administration" *ngIf="hasAccess('Menu_Administration')">
            <p class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover"
               href="#"
               id="hasSubItems"
               data-toggle=""
               data-target="#collapseSubItems2"
               aria-controls="collapseSubItems2"
               aria-expanded="false"
               style="margin-bottom: 0px; cursor: pointer"
               (click)="toggle('admin')">
                <i class="fa-solid fa-screwdriver-wrench fa-3x fa-fw faa-pulse faa-slow"></i>
                <span class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuAdministration')}}</span>
            </p>
            <ul *ngIf="isAdminExpanded && isNavExpand" class="nav-second-level collapse2" id="collapseSubItems2" data-parent="#navAccordion">
                <li class="nav-item" *ngIf="hasAccess('Menu_Printers')">
                    <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: true }"
                       [routerLink]="['/aspu-printer-list']">
                        <i class="fa fa-print fa-3x fa-fw faa-pulse faa-slow"></i>
                        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuPrinters')}}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="hasAccess('Menu_Profiles')">
                    <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: true }"
                       [routerLink]="['/aspu-profile-list']">
                        <i class="fas fa-rectangle-list fa-3x fa-fw faa-pulse faa-slow"></i>
                        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuProfiles')}}</span>
                    </a>
                </li>

                <li class="nav-item" *ngIf="hasAccess('Menu_Receipts')">
                    <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: true }"
                       [routerLink]="['/aspu-receipt-list']">
                        <i class="fa fa-sliders-h fa-3x fa-fw faa-pulse faa-slow"></i>
                        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuReceipts')}}</span>
                    </a>
                </li>

                <li class="nav-item" *ngIf="hasAccess('Menu_Settings')">
                    <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: true }"
                       [routerLink]="['/aspu-settings']">
                        <i class="fa fa-cog fa-3x fa-fw faa-pulse faa-slow"></i>
                        <span *ngIf="isNavExpand" class="nav-link-text custom-menu-item">{{getlocalized('common', 'MenuSettings')}}</span>
                    </a>
                </li>

                <li class="nav-item" *ngIf="hasPlcCommon && hasAccess('Menu_PlcSettings')">
                    <a class="nav-link nav-link btn btn-sm btn-menu-item mt-0 mb-1 me-1  ms-1 faa-parent animated-hover" href="#"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{ exact: true }"
                       [routerLink]="['/aspu-plc-edit-tabs']">
                        <i class="fa fa-microchip fa-3x fa-fw faa-pulse faa-slow"></i>
                        <span *ngIf="isNavExpand" class="nav-link-text  custom-menu-item">{{getlocalized('common', 'PlcSettings')}} </span>
                    </a>
                </li>
            </ul>

            <app-debug-component></app-debug-component>
    </ul>
</nav>

<div *ngIf="confirmation.Message!=undefined">
  <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>

<ng-template #contentLoading let-modal>
  <div class="modal-header p-0">
    <div class="col-lg-12">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          <span *ngIf="currentJob!=undefined" class="header-badge p-2">{{currentJob?.Name}}</span>
          <span *ngIf="currentJob!=undefined && currentJob?.Error==undefined || loading" class="ms-3 "><app-spinner [mode]="'burst'"></app-spinner></span>
        </h4>
      </div>
      <div class="modal-body">
        <div *ngIf="currentJob!=undefined && currentJob?.Name!=undefined" class="alert alert-info h1" style="word-wrap: break-word; animation: color-change-info 2s infinite;">{{currentJob.Name}}</div>
        <div *ngIf="currentJob!=undefined && currentJob?.SubStepName!=undefined" class="alert alert-info h2 mb-0" style="word-wrap: break-word; ">{{currentJob.SubStepName}}</div>
        <div *ngIf="currentJob!=undefined && currentJob?.Error!=undefined" class="alert alert-danger h2 mb-0" style="word-wrap: break-word">{{currentJob.Error}}</div>
        <!--<div *ngIf="currentJob!=undefined && currentJob.Error!=undefined" class="alert alert-danger h2 mb-0" style="word-wrap: break-word; animation: color-change 5s infinite;">{{currentJob.Error}}</div>-->
      </div>
      <div class="modal-footer">
        <button *ngIf="currentJob !=undefined && currentJob?.Failed" type="button" class="btn btn-info" aria-label="Retry" (click)="retry()">
          {{getlocalized('common', 'Retry')}}
        </button>
        <button *ngIf="currentJob != undefined && currentJob?.AllowCancellation || currentJob?.AllowTimeoutBreak" type="button" class="btn btn-info" aria-label="Retry" (click)="cancel()">
          {{getlocalized('common', 'Cancel')}}
        </button>

      </div>
    </div>
  </div>
</ng-template>
