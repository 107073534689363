import { Component, HostListener, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuCodeService } from './code.service';
import { AspuCode, AspuCodeFilter } from './code.model';
import { NgbModalRef, NgbModal, NgbPanelChangeEvent, NgbPanel, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-code-list-component',
    templateUrl: './code-list.component.html',
    providers: [AspuCodeService]
})
export class AspuCodeListComponent implements OnInit {
    //rows = new Array<AspuCode>();
    page = new Page<AspuCode>();

    //filter = new AspuCodeFilter();
    modalReference: NgbModalRef;
    productTypes = ["-", "Product", "Block", "Box", "Pallet"];
    printerStatuses = ["-","Unprinted", "Printing", "Printed"];
    entityStatuses = ["-", "None", "Defect", "PartialEntity", "FullEntity"];  //None - КМ без привязки// Entity - агрегирован
    omsUploadedStatuses = ["-", "Uploaded", "NotUploaded"];  //None - КМ без привязки// Entity - агрегирован
    validateStatuses = ["-", "Validated", "NotValidated"];  
    omsUploadedStatus = "-";

    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();
    isCodeReceivedPrevented = false;
    codesWeigth = undefined;

    getUploadStatusByText(caption: string) {
        switch (caption) {
            case "Uploaded": return true;
            case "NotUploaded": return false;
            default:
                return undefined
        }
    }

    getValidateStatusByText(caption: string) {
        switch (caption) {
            case "Validated": return true;
            case "NotValidated": return false;
            default:
                return "undefined";
        }
    }

    counter = 0;

    //currentEntityStatus: string;
    //currentPrinterStatus: string;
    //currentGroupType: string;
    //currentOmsUploadedStatus: string;

    constructor(private serv: AspuCodeService, private lservice: LangService, public modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        this.page.Size = 50; // window["pageSize"];
        this.page.PageNumber = 0;
        //this.gridSort.dir = "desc";
        //this.gridSort.field = "Time";

        this.loadItemsV2();
    }
    private _doTryReload = this.doTryReload.bind(this);
    private _doCatchCode = this.doCatchCode.bind(this);

    ngOnInit() {
        this.preventDoubleCheck();
        window.addEventListener("CodesModified", this._doTryReload, false);
        window.addEventListener("CodeReceived", this._doCatchCode, false);
        window.addEventListener("EnterCodeReceived", this._doCatchCode, false);
    }

    ngOnDestroy() {
        window.removeEventListener("CodesModified", this._doTryReload);
        window.removeEventListener('CodeReceived', this._doCatchCode);
        window.removeEventListener('EnterCodeReceived', this._doCatchCode);
        clearInterval(this.timerId);
    }

    //prevent block
    eventCounter = 0;
    timerId: any;

    doTryReload() {
        this.eventCounter++;
    }

    doCatchCode(code: any) {
        if(!this.isCodeReceivedPrevented)
            this.editCode(new AspuCode(code.detail))
        //this.currentCode = new AspuCode(code.detail, false);
        //console.log(this.currentCode);
        ////this.doSmth(this.currentCode);
        ////this.currentCode = undefined;
        //this.secondModalReference.close();
    }

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.eventCounter > 0) {
                this.eventCounter = 0;
                this.loadItemsV2();
            }
        }, 5000);
    }
    //prevent block end

    updateFilter(event) {
        this.page.PageNumber = 0;
        const val = event.target.value;
        let field = event.target.id;
        this.cleanFilter(field);

        if (val != "undefined" && val != "-" ) {
            let filter = new GridFilter();
            filter.field = field;
            filter.value = val;
            filter.operator = "eq";
            this.gridFilters.push(filter);
        }
        this.loadItemsV2();
    }

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadItemsV2();
    }

    setPage(event) {
        const val = event.newValue;
        this.page.PageNumber = 0;
        this.page.Size = val;

        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                    this.searchChangeObserver = observer;
                }).pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe(() => {
                    this.loadItemsV2();
                });
        }
        this.searchChangeObserver.next(event);

        //const val = event.target.value;

        //this.loadItemsV2();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort.field = sort.prop;
        this.gridSort.dir = sort.dir;
        this.loadItemsV2();
    }


    //public loadItems() {
    //    this.loading = true;

    //    if (this.filter.GroupType == "-")
    //        this.filter.GroupType = undefined;
    //    if (this.filter.EntityStatus == "-")
    //        this.filter.EntityStatus = undefined;
    //    if (this.filter.PrintingStatus == "-")
    //        this.filter.PrintingStatus = undefined;

    //    switch (this.omsUploadedStatus) {
    //        case "-":
    //            this.filter.OmsUploaded = undefined;
    //            break;
    //        case "Uploaded":
    //            this.filter.OmsUploaded = true;
    //            break;
    //        case "NotUploaded":
    //            this.filter.OmsUploaded = false;
    //            break;
    //        default:
    //            break;
    //    }

    //    console.log("filter");
    //    console.log(this.filter);
    //        this.serv.getItems(this.filter).subscribe((data: IResult<any>) => {
    //            if (data.IsSuccess) {
    //                this.rows = data.Value;
    //                this.isReady = true;
    //            } 
    //                this.loading = false;
    //        }, error => console.error(error));
    //}

   

    public loadItemsV2() {
        this.loading = true;
        this.serv.getItemsV2(this.getFilter()).subscribe((data: IResult<Page<AspuCode>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;
                console.log(this.page)
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));

      console.log("getWeight");
      let filter = this.getFilter();
      filter.take = 0;
      filter.skip = 0;
      this.serv.getWeight(filter).subscribe((data: IResult<number>) => {
            if (data.IsSuccess) {                      
                this.codesWeigth = data.Value;
                console.log(this.codesWeigth);
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

  filterContainer: FiltersContainer
    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters;
        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
      }
      this.filterContainer = filter;

      console.log('this.filterContainer')
      console.log(this.filterContainer)
        return filter;
    }



    currentCode : AspuCode;
    customCode: string;

    editCodeFromCode() {
        this.editCode(new AspuCode(this.customCode));
        this.customCode = undefined;
    }

    editCode(row: AspuCode) {
        this.counter++;
        this.currentCode = row;
    }

    aggregate(row: AspuCode) {
        if (row.GroupType == "Product") {

        }
        else if(row.GroupType == "Box"){

        }
    }

    isWeightAvailable() {
        return this.page?.Items?.filter(x => x.Weight!=undefined).length>0
    }

    isServiceCodeAvailable() {
        return this.page?.Items?.filter(x => x.ServiceCode!=undefined).length>0
    }

    isCodeWeightAvailable() {
        return this.codesWeigth != undefined && this.codesWeigth > 0;
    }
    
    copyToClipboard(item) {
        navigator.clipboard.writeText(item).then().catch(e => console.error(e));
    }

    onActivate(event) {
        if (event.type === 'dblclick') {
            console.log('dblclick');
            console.log(event);
            if (event.row.OmsUploaded !== true)
                this.editCode(event.row);
        }
    }

    cleanFilter(field: string) {
        if (field != undefined) {
            this.gridFilters = this.gridFilters.filter(x => x.field != field);
        } else {
            this.gridFilters = new Array<GridFilter>();
        }
  }

    getRowClass = (row: AspuCode) => {
        return {
            //'bg-danger': row.Type == "Error",
            'bg-danger': row.EntityStatus == 'Defect',
            //'bg-warning': row.Type == "Warning",
            //'bg-custom-validated': row.Validated && row.EntityStatus != 'Defect',
            'bg-success': row.Validated && row.EntityStatus != 'Defect',
            'bg-custom-success': row.EntityStatus == 'FullEntity' && row.OmsUploaded == undefined,
            'bg-custom-accepted': row.OmsUploaded,
            'bg-dark text-danger': row.EntityStatus == 'FullEntity' && row.OmsUploaded == false,
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name, localStorage.getItem('industry'));
    }
}
