import { AspuProductType } from "../receipt/receipt-detail.model";

export class AspuDraft  {
    Id: string;
    ProductLineId: string;
    ProductTypeId: string;
    ProductLineName: string;
    BatchNumber: string;
    VeterinaryCertificate: string;
    InternalNumberERP: string;
    Status: string;
    IdentificationCodesSource: string;
    CreatedBy: string;
    CreatedOn: string;
    UpdatedBy: string;
    UpdatedOn: string;
    Count: number;

    ExpirationDate: string;	
    ProductionDate: string;
    ExpirationDatePlan: string;
    ProductionDatePlan: string;

    ProductType: AspuProductType;
}

export class ProductLine { // extends CreateUpdateInfo {
  public Id: string;
  public Name: string;
  public Description: string;
  public ProductLineNumber: string;
  public AspuIp: string;
  public AspuPort: number;
  public AspuProtocol: string;
  public ProductLineModuleId: string;
  //public Prefix: string;
  public CompanyId: string;
  public ProductTypeIds: string[] = [];
  public IsChecked: boolean = false;
  public IsNotSaveProductsToStorage: boolean = false;
  public PerformancePerHour: number;
  public ShiftHours: number;
  public DefectPercent: number;
  public ExternalId: string;
  public ProductLineType: Array<string>;

  public WorkshopId: string;
  public PlantId: string;
  public GoodsCategoryId: string;
}


export class CodesForProductLine   {
    Id: string;
    Status: string;
    Quantity: number;
    BatchId: string;
    ProductTypeId: string;
    GroupType: string;
    ProductTypeName: string;
    //IdentificationCodesSource: string;
    //IdentificationCodesSources: Array<string>;
    ProductType: AspuProductType;


}



export class BatchStartRequest {
    BatchNumber: string;
    AspuMetadata: string;
    ProductLineBatchId: string;
    ProductLineId: string;
    IdentificationCodesSource: string;

    constructor(id: string, batchNumber: string, plId: string, aspuMetadata: string, requesterType :string) {
        this.ProductLineId = plId;
        this.ProductLineBatchId = id;
        this.BatchNumber = batchNumber;
        this.AspuMetadata = aspuMetadata;
        this.IdentificationCodesSource = requesterType;
    }
}
