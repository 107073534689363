import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from './aspu.http.service';
import { AspuJob } from '../core/models/aspu.job.model';

@Injectable()
export class AspuJobService {

    constructor(
        private httpService: AspuHttpService) { }


    getJobStatus(name: string): Observable<IResult<AspuJob>> {
        return this.httpService.get<IResult<AspuJob>>('Jobs', name);
    }


    retryJob(name: string) {
        return this.httpService.post<IResult<any>>(null, 'Jobs/'+name+'/retry');
    }

    cancelJob(name: string) {
        return this.httpService.post<IResult<any>>(null, 'Jobs/' + name +'/cancel');
    }
}