<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h2 ps-1 pe-1 text-uppercase">
                        {{getlocalized('SerializationList', 'Serialization')}}{{codeLevel}}
                    </div>
                    <!--<span class="badge badge-info" *ngIf="viewModel.Products!=undefined">{{getRealCount()}}</span>-->
                </div>
                <span *ngIf="loading" class="ms-3" style="position: relative; top: -5px;"><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
            <div class="btn-group2 d-flex justify-content-end">
                <button *ngIf="currentAlgorithm!=undefined && currentAlgorithm.IsShowPlcQueueClearButton" class="btn btn btn-danger me-2  text-uppercase" (click)="plcQueueClear()">{{getlocalized('common', 'PlcQueueClear')}}</button>
                <button *ngIf="!isDetalized" class="btn btn btn-secondary text-uppercase" (click)="isDetalized=true;loadCodes()"><i class="fa fa-lg fa-toggle-off me-1 "></i>{{getlocalized('common', 'Details')}}</button>
                <button *ngIf="isDetalized" class="btn btn btn-success text-uppercase" (click)="isDetalized=false"><i class="fa fa-lg fa-toggle-on me-1 "></i>{{getlocalized('common', 'Details')}}</button>

                <!--<span [disabled]="false" class="btn-group2 btn-group-toggle float-end" ngbRadioGroup name="radioBasic" [(ngModel)]="mode">
                    <label ngbButtonLabel class="btn-sm me-1 " [ngClass]="{'btn-success': mode=='aggregation', 'btn-info': mode!='aggregation'}">
                        <input id="Serialization_ValidationToggleAction" ngbButton type="radio" value="aggregation">{{getlocalized('SerializationList', 'Validation')}}
                    </label>
                    <label ngbButtonLabel class="btn-sm me-1 " [ngClass]="{'btn-danger': mode=='remove', 'btn-info': mode!='remove'}">
                        <input id="Serialization_DefectToggleAction" ngbButton type="radio" value="remove">{{getlocalized('SerializationList', 'Defect')}}
                    </label>
                    <label ngbButtonLabel class="btn-sm" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                        <input id="Serialization_InfoToggleAction" ngbButton type="radio" value="info">{{getlocalized('SerializationList', 'Information')}}
                    </label>
                </span>-->
            </div>
        </div>

        <div class="card-body form-group mb-3 p-2 pb-0 {{getCodeBgColor()}}">

            <div *ngIf="getOperationStatus()=='codeValidateSuccess'" class="alert alert-success text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ValidationSuccess')}}
            </div>

            <div *ngIf="getOperationStatus()=='codeDefectSuccess' && mode=='remove'" class="alert alert-success text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'DefectSuccess')}}
            </div>

            <div *ngIf="getOperationStatus()=='coreValidateFailed' || getOperationStatus()=='coreDefectFailed'" class="alert alert-danger text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'Error')}}
            </div>

            <div *ngIf="(getOperationStatus()==undefined || getOperationStatus()=='') && mode=='aggregation'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanProductCode')}}
            </div>

            <div *ngIf="(getOperationStatus()==undefined || getOperationStatus()=='') && mode!='weight' && mode!='servicecode' && mode!='aggregation'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanCode')}}
            </div>

            <div *ngIf="(getOperationStatus()==undefined || getOperationStatus()=='') && mode=='weight'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanWeight')}}
            </div>

            <div *ngIf="(getOperationStatus()==undefined || getOperationStatus()=='') && mode=='servicecode'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanServiceCode')}}
            </div>

            <div *ngIf="getOperationStatus()=='weightReadedSuccess'" class="alert alert-success text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'WeightSuccess')}}
            </div>

            <div *ngIf="getOperationStatus()=='weightReadedFailed'" class="alert alert-danger text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'WeightError')}}
            </div>

            <div *ngIf="getOperationStatus()=='serviceCodeReadedSuccess'" class="alert alert-success text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ServiceCodeSuccess')}}
            </div>

            <div *ngIf="getOperationStatus()=='serviceCodeReadedFailed'" class="alert alert-danger text-center text-uppercase pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ServiceCodeError')}}
            </div>

            <!--StartNew-->
            <div *ngIf="!isDetalized" class="m-0">
                <div class="row align-items-center d-flex justify-content-center">
                    <div class="col">
                        <span class="btn-group2 btn-group-toggle btn-group-vertical" ngbRadioGroup name="radioBasic" [(ngModel)]="mode" (change)="modeChanged($event)">
                            <label *ngIf="isWeightEnable" ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg text-uppercase" [ngClass]="{'btn-success': mode=='weight', 'btn-info': mode!='weight'}">
                                <input id="Serialization_WeightToggleAction" class="btn-check" ngbButton type="radio" value="weight">{{getlocalized('SerializationList', 'Weight')}}
                            </label>
                            <label *ngIf="isServiceCodeEnable" ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg text-uppercase" [ngClass]="{'btn-success': mode=='servicecode', 'btn-info': mode!='servicecode'}">
                                <input id="Serialization_ServiceCodeToggleAction" class="btn-check" ngbButton type="radio" value="servicecode">{{getlocalized('SerializationList', 'ServiceCode')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg text-uppercase" [ngClass]="{'btn-success': mode=='aggregation', 'btn-info': mode!='aggregation'}">
                                <input id="Serialization_ValidationToggleAction" class="btn-check" ngbButton type="radio" value="aggregation">{{getlocalized('SerializationList', 'Validation')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg rounded-top text-uppercase" [ngClass]="{'btn-danger': mode=='remove', 'btn-info': mode!='remove'}">
                                <input id="Serialization_DefectToggleAction" class="btn-check" ngbButton type="radio" value="remove">{{getlocalized('SerializationList', 'Defect')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-top btn-agg text-uppercase" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                                <input id="Serialization_InfoToggleAction" class="btn-check" ngbButton type="radio" value="info">{{getlocalized('SerializationList', 'Information')}}
                            </label>
                        </span>
                    </div>
                    <div class="col d-flex justify-content-center" style="">
                        <div>
                            <span class="" style="font-size:170px; ">
                                {{page?.TotalElements}}
                            </span>
                        </div>
                    </div>
                    <div class="col align-self-start d-flex justify-content-center">
                        <div>
                            <span class="display-4" style="">
                                <!--{{getlocalized('common', 'of')}} <span class="display-2">{{viewModel.Capacity}}</span>-->
                            </span>
                        </div>
                    </div>
                    <!--<div *ngIf="viewModel.IsQueueEnabled" class="col align-self-start d-flex justify-content-center">
                <div>
                    <div class="fw-font-weight-bold text-info">{{getlocalized('AggregationList', 'Queue')}}:</div>
                    <div *ngFor="let item of viewModel.Queue">
                        <div [ngClass]="{'text-success': item.IsOk==true, 'text-danger': item.IsOk!=true}" class="mb-1">
                            {{item.Name}}
                            <div class="btn btn-sm btn-danger" (click)="removeFromQueue(item.Id)"><i class="fa fa-trash"></i></div>
                        </div>
                    </div>
                </div>
            </div>-->
                </div>
            </div>

            <div class="row col-lg-12 pl-0 pr-0 pt-2">
                <div *ngIf="isWeightEnable && mode !='weight' && weightKg != undefined" class="mb-3 pr3 col-xl-12 col-xxl-6">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductWeight')}}</span>
                        <label id="codeInput" type="text" class="form-control">{{weightKg}}</label>
                    </div>
                </div>
                <div *ngIf="isServiceCodeEnable && mode !='servicecode' && serviceCode != undefined" class="mb-3 pr3 col-xl-12 col-xxl-6">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ServiceCode')}}</span>
                        <label id="codeInput" type="text" class="form-control">{{serviceCode}}</label>
                    </div>
                </div>
            </div>

            <div class="form-row mt-1">
                <div *ngIf="mode == 'aggregation'" class="mb-1 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductCode')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="code">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(code)" class="input-group-append2 btn btn-success" style="padding-left: 70px; padding-right: 70px;">
                            <i class="fa fa-lg fa-arrow-circle-right me-1 "></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
                <div *ngIf="mode != 'weight' && mode != 'servicecode' && mode != 'aggregation'" class="mb-1 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'Code')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="code">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(code)" class="input-group-append2 btn btn-success" style="padding-left: 70px; padding-right: 70px;">
                            <i class="fa fa-lg fa-arrow-circle-right me-1 "></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
                <div *ngIf="mode == 'weight'" class="mb-1 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductWeight')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new weight" type="text" class="form-control" [(ngModel)]="weight">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doWeight(weight)" class="input-group-append2 btn btn-success" style="padding-left: 70px; padding-right: 70px;">
                            <i class="fa fa-lg fa-arrow-circle-right me-1 "></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
                <div *ngIf="mode == 'servicecode'" class="mb-1 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('SerializationList', 'ServiceCode')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new service code" type="text" class="form-control" [(ngModel)]="serviceCode">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doServiceCode(serviceCode)" class="input-group-append2 btn btn-success" style="padding-left: 70px; padding-right: 70px;">
                            <i class="fa fa-lg fa-arrow-circle-right me-1 "></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
            </div>
            <!--ENDNew-->
            <div>
                <ngx-datatable ngx-resize-watcher *ngIf="isReady && isDetalized" ngx-localizer class="bootstrap ngx-datatable"
                               id="someTable"
                               [rows]="page.Items"
                               [loadingIndicator]=""
                               [externalPaging]="true"
                               [externalSorting]="true"
                               [columnMode]="ColumnMode.force"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               rowHeight="auto"
                               [count]="page.TotalElements"
                               [limit]="page.Size"
                               [offset]="page.PageNumber"
                               (page)="onPage($event)"
                               (sort)="onSort($event)"
                               [rowClass]="getRowClass"
                               [reorderable]="reorderable">
                    <ngx-datatable-column name="{{getlocalized('CodeList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Code'" [fieldType]="'string'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <code style="word-wrap:inherit">{{ value }}</code>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{getlocalized('CodeList', 'GroupType')}}" prop="GroupType" cellClass="custom-row" [minWidth]="110" [maxWidth]="150" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'ProductType.GroupType'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'ProductGroupTypeDictionary'"
                                                          [fieldValues]="productTypes"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('ProductGroupTypeDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('CodeList', 'PrintingStatus')}}" prop="PrintingStatus" cellClass="custom-row" [minWidth]="120" [maxWidth]="190" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'PrintingStatus'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'PrintStatusDictionary'"
                                                          [fieldValues]="printerStatuses"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('PrintStatusDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('CodeList', 'EntityStatus')}}" prop="EntityStatus" cellClass="custom-row" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'EntityStatus'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'AggregationStatusDictionary'"
                                                          [fieldValues]="entityStatuses"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('AggregationStatusDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column *ngIf="isWeightAvailable()" name="{{getlocalized('CodeList', 'Weight')}}" prop="Weight" cellClass="" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Code'" [fieldType]="'string'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{value}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column *ngIf="isServiceCodeAvailable()" name="{{getlocalized('CodeList', 'ServiceCode')}}" prop="ServiceCode" cellClass="" [minWidth]="120" [maxWidth]="190" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Code'" [fieldType]="'string'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{value}}
                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="130" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                {{column.name}}
                            </div>
                            <div class="btn-group2">
                                <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadCodes()" class="btn btn-sm btn-info me-2 "><i class="fa fa-redo"></i></button>
                                <button id="Company_List_ResetAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                            </div>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <div *ngIf="isSquareVisible" class="bg-dark p-4 m-4 float-end" style="height: 2cm; width: 2cm; margin-right:400px!important"></div>
</div>




<div *ngIf="currentCode!=undefined">
    <app-code-edit-component [code]="currentCode" [counter]="counter" [isActionAvailable]="true"></app-code-edit-component>
</div>

<!--<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)"></confirmation-component>
</div>-->

<ng-template #contentPopup let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12 p-0">
            <div *ngIf="popupMessageStatus" class="alert alert-success text-center display-4 m-0" style="word-wrap: break-word" role="alert">
                {{popupMessage}}
            </div>
            <div *ngIf="!popupMessageStatus" class="alert alert-danger text-center display-4 m-0" style="word-wrap:break-word" role="alert">
                {{popupMessage}}
            </div>
        </div>
    </div>
</ng-template>
