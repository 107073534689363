import { Component, Injectable, TemplateRef, OnInit, ViewChild } from '@angular/core';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuConfigSettings, AspuSettings } from './settings.model';
import { AspuSettingsService } from './settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AspuIndicatorService } from '../../business/core/indicator/indicator.service';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuMetaBase } from '../../business/core/common/common.model';

import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'app-settings-component',
    templateUrl: './settings.component.html',
    providers: [AspuSettingsService, AspuIndicatorService]
})
export class AspuSettingsComponent implements OnInit {
    viewModel = new AspuSettings();
    config = new AspuConfigSettings();
    appParams: Array<AspuMetaBase>;
    errors = new Array<any>(new Object());
    isBatch = true;

    currentUserTheme = "";
    userThemes = ['dark-theme', 'light-theme']

    public editorOptions: JsonEditorOptions;
    public data: any;
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

    modes = ['VirtualBox', 'Standart', 'Serialiaziton']

    constructor(private serv: AspuSettingsService, private lservice: LangService,
        private indServ: AspuIndicatorService, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        this.currentUserTheme = localStorage.getItem("userTheme");
        this.reload();
        this.getConfigs();

        this.editorOptions = new JsonEditorOptions()
        this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    }

    ngOnInit() {
        window.addEventListener("AspuIndicatorUpdated", this._doAction, false);
        let receiptRawData = localStorage.getItem('AppConfigVariables');
        if (receiptRawData != undefined) {
            this.appParams = JSON.parse(receiptRawData);
        }
    }

    ngOnDestroy() {
        window.removeEventListener('AspuIndicatorUpdated', this._doAction);
    }

    private _doAction = this.doActionPrevent.bind(this);

    doActionPrevent(data: any) {
        let indicator = data.detail;
        this.isBatch = (indicator.Batch === 'Enabled');
        if (this.isBatch)
            this.nav.select('PacketMode');
    }

    @ViewChild("nav", { static: true, read: NgbNav }) nav: NgbNav;


    private reload(): void {
        this.indServ.getIndicators().subscribe((data) => {
            if (data.IsSuccess) {
                this.isBatch = (data.Value.Batch === 'Enabled');
                if (this.isBatch)
                    this.nav.select('PacketMode');
                this.serv.getSettings().subscribe((data: IResult<any>) => {
                    if (data.IsSuccess) {
                        this.viewModel = data.Value;
                        this.errors = data.ValidationMessages;
                    }
                },
                    error => console.error(error));
            }
        });
    }

    saveItem() {
        this.serv.setSettings(this.viewModel).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Настройки успешно обновлены", "info");
                if (this.viewModel.Fields.WorkMode != undefined) {
                    let c_event = new CustomEvent<any>("WorkModeChanged", { detail: this.viewModel.Fields.WorkMode });
                    window.dispatchEvent(c_event);
                }
            }
            else {
                this.errors = data.ValidationMessages;
            }
        },
            error => {
                console.error(error);
            });
    }

    redirect() {
        this.router.navigate(['/aspu-printer-list']);
    }

    updateAspuConfig() {
        this.serv.setConfig(this.config).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Настройки успешно обновлены", "info");
                this.getConfigs();
            }
        },
            error => {
                console.error(error);
            });
    }

    updateCameraData(event) {
        this.config.CameraConfig = event;
        this.serv.setConfig(this.config).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Настройки успешно обновлены", "info");
                this.getConfigs();
            }
        },
        error => {
            console.error(error);
        });
    }

    updateWeightData(event) {
        console.log("updateWeightData");
        console.log(event);

        this.config.WeightConfig = event;
        this.serv.setConfig(this.config).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Настройки успешно обновлены", "info");
                this.getConfigs();
            }
        },
        error => {
            console.error(error);
        });
    }

    private getConfigs(): void {
        this.serv.getConfig().subscribe((data: IResult<AspuConfigSettings>) => {
            if (data.IsSuccess) {
                this.config = data.Value;

                this.config.CameraConfig.forEach(function (item, i, arr) {
                    arr[i].Id = i + 1;
                });
            }
        },
            error => console.error(error));
    }

    changeTheme(event) {
        let theme = event.target.value;
        this.serv.setUserTheme(theme).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                localStorage.setItem("userTheme", theme);
                this.toastService.getToast("Тема изменена", "info")
            }
        },
            error => console.error(error));;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
