import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuDriver, AspuPrinter, AspuPrinterId, AspuPrintHeadersEnabled } from './printer.model';
import { AspuPrinterService } from './printer.service';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { element } from 'protractor';
import { ToastService } from 'vekas_models/core/toaster/toast-service';

@Component({
  selector: 'app-printer-edit-component',
  templateUrl: './printer-edit.component.html',
  providers: [AspuPrinterService]
})
export class AspuPrinterEditComponent implements OnInit {
  viewModel: AspuPrinter = new AspuPrinter();
  drivers: Array<AspuDriver>;
  isReady = false;
  loading = true;
  ColumnMode = ColumnMode;
  reorderable = true;
  id: string;
  errors = new Array<any>(new Object());
  ar = [true, false];

  encodings = ["UTF8", "Unicode"];

  constructor(private serv: AspuPrinterService, private lservice: LangService, public toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (localStorage.getItem("token") == undefined) {
      this.router.navigate(['/home']);
    }

    this.loadDrivers();

    this.route.params.subscribe(params => {
      if (!params.id) {
        return;
      }
      this.id = params.id;
      this.loadItems();
    });
  }

  isLoaded = false;
  hasErrors() {
    if (this.isLoaded) {
      var elements = document.getElementsByClassName("hasValidationError")
      return elements.length > 0;
    }
    return true;
  }

  ngAfterViewInit() {
    //this.cdr.detectChanges();
    this.isLoaded = true;
    console.log("LOADED")
  }

  ngOnInit() { }

  loadItems() {
    this.loading = true;
    this.serv.getItems().subscribe((data: IResult<Array<AspuPrinter>>) => {
      this.viewModel = data.Value.filter(x => x.Id == this.id)[0];
      this.fillPrintHeadersEnabled();
      this.isReady = true;
      this.loading = false;
    },
      error => console.error(error));
  }

  loadDrivers() {
    this.serv.getDrivers().subscribe(data => {
      if (data.IsSuccess) {
        this.drivers = data.Value
        console.log(this.drivers)
      }
    });
  }

  isNew() {
    return this.viewModel == null ? false : this.viewModel.Id == null;
  }

  saveItem(redirect = false) {
    this.onPrinterHeadUpdate();

    if (this.viewModel.Id == null) {
      //this.viewModel.discriminator = "SocketPrinterDto";
      this.serv.createItem(this.viewModel).subscribe(data => {
        if (data.IsSuccess) {
          this.toastService.getToast("Принтер успешно создан!", 'info');
          if (redirect) this.redirect();
          else
            this.redirectToThis(data.Value.Id);
        } else {
          this.errors = data.ValidationMessages;
        }
      },
        error => {
          console.error(error);
          this.loadItems()
        });
    }
    else {
      this.serv.updateItem(this.viewModel).subscribe(data => {
        if (data.IsSuccess) {
          this.toastService.getToast("Принтер успешно обновлен!", 'info');
          if (redirect) this.redirect();
          else
            this.redirectToThis(this.viewModel.Id);
        } else {
          this.errors = data.ValidationMessages;
        }
      },
        error => {
          console.error(error);
          this.loadItems()
        });
    }
  }

  isRefresh = true;
  refreshTemplates() {
    this.isRefresh = false;
    this.serv.refreshTemplates(new AspuPrinterId(this.viewModel.Id)).subscribe(data => {
      if (data.IsSuccess)
        this.loadItems();
      this.isRefresh = true;
    }, error => {
      this.isRefresh = true;
      console.error(error);
    });
  }

  driverIdChanged(event) {
    const val = event.target.value;
    this.viewModel.DriverName = this.drivers.filter(x => x.DriverId == val)[0].Name;
    this.viewModel.discriminator = this.drivers.filter(x => x.DriverId == val)[0].Prototype.discriminator;
    this.viewModel.LoadCodesTriggerSource = this.drivers.filter(x => x.DriverId == val)[0].Prototype.LoadCodesTriggerSource;

    if (this.viewModel.discriminator == "VideoJetPrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.Encoding = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Encoding;
      this.viewModel.CodeWritingDelay = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CodeWritingDelay;
      this.viewModel.AcceptableWarnings = this.drivers.filter(x => x.DriverId == val)[0].Prototype.AcceptableWarnings;
      this.viewModel.BufferEmptyFinalizeTrigger = this.drivers.filter(x => x.DriverId == val)[0].Prototype.BufferEmptyFinalizeTrigger;
    }
    if (this.viewModel.discriminator == "SocketPrinterDto" ||
      this.viewModel.discriminator == "TemplatedSocketPrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
    }
    if (this.viewModel.discriminator == "GodexPrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.NumberOfPrintedJobPerSecond = this.drivers.filter(x => x.DriverId == val)[0].Prototype.NumberOfPrintedJobPerSecond;
    }
    if (this.viewModel.discriminator == "CabPrinterDto") {
      this.viewModel.Path = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Path;
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.CabAllowableErrors = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CabAllowableErrors;
      this.viewModel.MaxFreeMemoryPercent = this.drivers.filter(x => x.DriverId == val)[0].Prototype.MaxFreeMemoryPercent;
    }
    if (this.viewModel.discriminator == "MarkemImajePrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.Receiver = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Receiver;
      this.viewModel.Sender = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Sender;
    }
    if (this.viewModel.discriminator == "TestPrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.Period = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Period;
    }
    if (this.viewModel.discriminator == "WolkePrinterDto") {
      this.viewModel.Limit = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Limit;
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.PrintHeadersEnabled = this.drivers.filter(x => x.DriverId == val)[0].Prototype.PrintHeadersEnabled;
      this.fillPrintHeadersEnabled();
    }
    if (this.viewModel.discriminator == "BizerbaPrinterDto") {
      this.viewModel.Defect = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Defect;
      this.viewModel.CheckIdleState = this.drivers.filter(x => x.DriverId == val)[0].Prototype.CheckIdleState;
      this.viewModel.FtpTimeout = this.drivers.filter(x => x.DriverId == val)[0].Prototype.FtpTimeout;

      this.viewModel.Brain2LicenseEnable = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Brain2LicenseEnable;
      this.viewModel.Brain2Timeout = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Brain2Timeout;
      this.viewModel.Brain2IterationCount = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Brain2IterationCount;
      this.viewModel.Brain2IterationPause = this.drivers.filter(x => x.DriverId == val)[0].Prototype.Brain2IterationPause;

      this.viewModel.ConnectBrainLicenseEnable = this.drivers.filter(x => x.DriverId == val)[0].Prototype.ConnectBrainLicenseEnable;
      this.viewModel.ConnectBrainTimeout = this.drivers.filter(x => x.DriverId == val)[0].Prototype.ConnectBrainTimeout;
      this.viewModel.ConnectBrainIterationCount = this.drivers.filter(x => x.DriverId == val)[0].Prototype.ConnectBrainIterationCount;
      this.viewModel.ConnectBrainIterationPause = this.drivers.filter(x => x.DriverId == val)[0].Prototype.ConnectBrainIterationPause;
    }
    if (this.viewModel.discriminator == "OfficePrinterDto") {
      this.viewModel.TemplatePath = this.drivers.filter(x => x.DriverId == val)[0].Prototype.TemplatePath;
    }
    console.log(val);
  }

  //isVideoJetPrinter() {
  //    this.viewModel.
  //}

  redirect() {
    this.router.navigate(['/aspu-printer-list']);
  }

  redirectToThis(id: string) {
    this.router.navigate(['/aspu-printer-edit/' + id + '/editCard']);
  }

  getlocalized(type: string, name: string) {
    return LangService.getlocalized(type, name);
  }

  printerHeadersStates = new Array<AspuPrintHeadersEnabled>()

  toggleWithGreeting(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  fillPrintHeadersEnabled() {
    this.printerHeadersStates = [];
    for (var i = 0; i < this.viewModel?.PrintHeadersEnabled?.length; i++) {
      this.printerHeadersStates.push(new AspuPrintHeadersEnabled(this.viewModel?.PrintHeadersEnabled[i]))
    }
  }

  getCurrentPrintHeads() {
    let isActive = this.viewModel?.PrintHeadersEnabled.filter(x => x == true).length;
    return isActive + ' ' + this.getlocalized('common', 'of') + ' ' + this.viewModel?.PrintHeadersEnabled.length;
  }

  onPrinterHeadHidden() {
    this.viewModel.PrintHeadersEnabled = this.printerHeadersStates.map(x => x.IsActive) as Array<boolean>;
  }

  onPrinterHeadUpdate() {
    if (this.printerHeadersStates == undefined) return;
    this.viewModel.PrintHeadersEnabled = this.printerHeadersStates.map(x => x.IsActive) as Array<boolean>;
  }
}
