import { Component, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CodeInfo } from './code-info.model';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { CodeinfoService } from './code-info.service';


@Component({
    selector: 'app-code-info-component',
    templateUrl: './code-info.component.html',
    providers: [CodeinfoService],
    host: {
        '[style.display]': '"flex"',
        '[style.flex-direction]': '"column"',
        '[style.overflow]': '"hidden"'
    }
})
export class CodeInfoComponent implements OnInit {
    viewModel = new CodeInfo();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    modalReference: NgbModalRef;
    secondModalReference: NgbModalRef;
    @Input() code: String;
    @Input() counter: any;
    @Output() onChange = new EventEmitter();

    constructor(
        private serv: CodeinfoService,
        private modalService: NgbModal,
        public toastService: ToastService,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    onSort(event) {

    }

    public loadInfo() {
        this.loading = true;
        this.serv.getInfo(this.code).subscribe(
            (data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.viewModel = data.Value;
                    this.isReady = true;
                    console.log(this.viewModel);
                }
                else {
                    this.viewModel = undefined;
                }
                this.loading = false;
            },
            error => {
                this.viewModel = undefined;
                console.error(error);
            });
    }

    @ViewChild('contentLoads', { static: true }) viewMe?: TemplateRef<any>;

    openLoad() {
        this.modalReference = this.modalService.open(this.viewMe, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            const cur = chng.currentValue;
            const prev = chng.previousValue;
            console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
            if (this.code != undefined && propName == "counter") {
                console.log('ngOnChanges')
                this.loadInfo();
                this.modalService.dismissAll();
                this.openLoad();
            }
        }
    }

    copyToClipboard(item) {        
        navigator.clipboard.writeText(item).then().catch(e => console.error(e));
    }

    redirectToCode(code: String) {
        this.code = code;
        console.log('redirectToCode');
        this.loading = true;
        this.serv.getInfo(code).subscribe(
            (data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.viewModel = data.Value;
                    this.isReady = true;
                    console.log(this.viewModel);
                }
                else {
                    this.viewModel = undefined;
                }
                this.loading = false;
            },
            error => {
                this.viewModel = undefined;
                console.error(error);
            });
    }

    dismiss() {
        console.log('DISMISS');
    }

    close() {
        console.log('CLOSE');
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    private getEntityStatus(code: CodeInfo) {
        if (code == undefined) {
            return null;
        }

        if (code.IsPacked) {
            return 'FullEntity';
        }

        if (code.IsDefected) {
            return 'Defect';
        }

        if (code.Aggregated == 'Full') {
            return 'FullEntity';
        }

        if (code.Aggregated == 'Partial') {
            return 'PartialEntity';
        }

        return 'None';
    }
}
