import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import localeRu from '@angular/common/locales/ru';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeRuExtra from '@angular/common/locales/extra/ru';

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { PageModule } from "./core/page.module";

import { LoginComponent } from "./core/login/login.component";
import { DatepickerRangeComponent } from "vekas_models/core/datepicker-range/datepicker-range.component";

import { NgJsonEditorModule } from 'ang-jsoneditor' 

//common packages
import { CounterComponent } from "vekas_models/core/counter/counter.component";
import { ToastsContainer } from "vekas_models/core/toaster/toast.component";

import { AspuMessageListComponent } from "./business/common/message/message-list.component";
import { AspuBatchReceiptComponent } from "./business/common/batch/batch-receipt.component";
import { AspuReceiptListComponent } from "./business/common/receipt/receipt-list.component";
import { AspuBatchHistoryListComponent } from "./business/common/batch/batch-history/batch-history-list.component";
import { AspuReportListComponent } from "./business/common/report/report-list.component";
import { AspuPrinterListComponent } from "./business/common/printer/printer-list.component";
import { AspuPrinterEditComponent } from "./business/common/printer/printer-edit.component";
import { AspuProfileListComponent } from "./business/common/profile/profile-list.component";
import { AspuProfileEditComponent } from "./business/common/profile/profile-edit.component";
import { AspuBatchEditComponent } from "./business/common/batch/batch-edit/batch-edit.component";
import { AspuBatchProcessComponent } from "./business/common/batch/batch-process/batch-process.component";
import { AspuCodeListComponent } from "./business/common/code/code-list.component";
import { AspuCodeEditComponent } from "./business/common/code/code-edit/code-edit.component";
import { CodeInfoComponent } from "./business/common/code-status/code-info/code-info.component";
import { AspuIndicatorComponent } from "./business/core/indicator/indicator.component";
import { ReceiptProductListComponent } from "./business/common/receipt/receipt-product-list.component";
import { AspuSettingsComponent } from "./core/settings/settings.component";
import { ConfirmationComponent } from "vekas_models/core/confirm/confirmation.component";
import { AspuDebugComponent } from "./business/common/debug-functions/debug.component";
import { NgxBootstrapSliderModule } from 'ngx-bootstrap-slider';
import { OnlynumberDirective } from "vekas_models/models/onlynumber.directive";
import { ComplexFilterComponent } from "vekas_models/core/complex-filter/complex-filter.component";
import { AspuSerializationComponent } from "./business/common/aggregation/serialization/serialization.component";
import { AspuBranchSerializationComponent } from "./business/common/aggregation/branch-serialization/branch-serialization.component";
import { AspuAggregationBoxListComponent } from "./business/common/aggregation/box/aggregation-box-list.component";
import { AspuAggregationPalletListComponent } from "./business/common/aggregation/pallet/aggregation-pallet-list.component";
import { AspuCodeStatusComponent } from "./business/common/code-status/code-status.component";
import { AspuDraftListComponent } from "./business/common/draft/draft-list.component";
import { AspuCustomPlcEditComponent } from "./business/common/plc/plc-edit.component";
import { AspuCustomPlcListComponent } from "./business/common/plc/plc-list.component";

import { AspuCameraConfigComponent } from "./core/settings/camera-config/camera-config.component";
import { SpinnerComponent } from "vekas_models/core/spinner/spinner.component";
import { ValidationComponent } from "vekas_models/core/validation/validation.component";
import { NgxLocalizerDirective } from "vekas_models/core/ngx-table-helpers/ngx-table-localizer.directive";


import { AspuAggregationBoxVisualComponent } from "./business/common/aggregation/box-visual/aggregation-box-visual.component";
import { AspuLayerSerializationComponent } from "./business/common/aggregation/layer-serialization/layer-serialization.component";
import { AspuLayer63313VisualComponent } from "./business/common/aggregation/layer63313-visual/layer63313-visual.component";
import { LinqFilterComponent } from "vekas_models/core/linq-filter/linq-filter.component";
import { AspuReceiptDetailComponent } from "./business/common/receipt/receipt-detail.component";
import { AspuFileComponent } from "./business/common/file/file.component";
import { AngularFileUploaderModule } from "angular-file-uploader";
import { AspuBufferEditComponent } from "./business/common/profile/buffer/buffer-edit.component";
import { AspuMetaComponent } from "./business/core/common/meta.component";
import { NgxResizeWatcherDirective } from "./core/ngx-resize-watcher/ngx-resize-watcher.directive";
import { AspuCodeReservationComponent } from "./business/common/aggregation/code-reservation/code-reservation.component";
import { AspuCountersListComponent } from "./business/common/counters/counters-list.component";
import { AspuErrorMessageListComponent } from "./business/common/message/error-message-list.component";
import { AspuPlcTabsComponent } from "./business/common/plc/plc-edit-tabs.component";
import { AspuConfigurationJsonComponent } from "./core/settings/configurationJson/configurationJson.component";
import { AspuAggregationBoxPalletListComponent } from "./business/common/aggregation/box-pallet/aggregation-box-pallet-list.component";
import { ConfigJsonEditorComponent } from './core/settings/config-json-editor/config-json-editor.component';
import { AspuAggrBoxLayerTimestampComponent } from "./business/common/aggregation/box-layer-timestamp/aggregation-box-layer-timestamp.component";
import { AspuAggregationBoxQueueListComponent } from "./business/common/aggregation/box-queue/aggregation-box-queue-list.component";
import { AspuAggregationProductToPalletQueueListComponent } from "./business/common/aggregation/product-to-pallet-queue/aggregation-product-to-pallet-queue-list.component";
import { AspuWeightConfigComponent } from "./core/settings/weight-config/weight-config.component";

@NgModule({
  declarations: [
        AppComponent,
        LoginComponent,
        NavMenuComponent,
        HomeComponent,
        CounterComponent,
        DatepickerRangeComponent, 
        ToastsContainer,
        SpinnerComponent,
        AspuSettingsComponent,
        AspuMessageListComponent,
        AspuBatchReceiptComponent,
        AspuBatchHistoryListComponent,
        AspuReportListComponent,
        AspuReceiptListComponent,
        AspuPrinterListComponent,
        AspuPrinterEditComponent,
        AspuProfileListComponent,
        AspuProfileEditComponent,
        AspuBatchEditComponent,
        AspuBatchProcessComponent,
        AspuCodeListComponent,
        AspuCodeEditComponent,
        CodeInfoComponent,
        ReceiptProductListComponent,
        AspuDebugComponent,
        AspuAggregationBoxListComponent,
        AspuAggregationPalletListComponent,
        AspuAggregationBoxQueueListComponent,
        AspuAggregationProductToPalletQueueListComponent,
        AspuAggregationBoxPalletListComponent,
        OnlynumberDirective,
        AspuCustomPlcListComponent,
        AspuCustomPlcEditComponent,
        AspuPlcTabsComponent,
        AspuSerializationComponent,
        AspuBranchSerializationComponent,
        AspuCodeStatusComponent,
        AspuDraftListComponent,
        AspuCameraConfigComponent,
        AspuWeightConfigComponent,
        ComplexFilterComponent,
        ConfirmationComponent,
        ValidationComponent,
        AspuAggregationBoxVisualComponent,
        AspuAggrBoxLayerTimestampComponent,
        AspuLayerSerializationComponent,
        AspuLayer63313VisualComponent,
        LinqFilterComponent,
        AspuReceiptDetailComponent,
        AspuFileComponent,
        AspuBufferEditComponent,
        AspuMetaComponent,
        AspuCodeReservationComponent,
        AspuCountersListComponent,
        NgxLocalizerDirective,
        NgxResizeWatcherDirective,
        AspuErrorMessageListComponent,
        AspuConfigurationJsonComponent,
        ConfigJsonEditorComponent
  ],
    imports: [
        AngularFileUploaderModule,
        NgxDatatableModule,
        NgxBootstrapSliderModule,
        NgToggleModule,
        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        PageModule,
        ReactiveFormsModule,
        NgJsonEditorModule
    ],
    exports: [
        BrowserAnimationsModule        
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
