import { NgModule, Component, Injectable, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, EmbeddedViewRef, ViewContainerRef, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgbModalRef, NgbModal, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
//import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { DebugService } from './debug.service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { DebugAggregationsInfo, DebugCreateCommonAggregation, DebugCurrentCodesAvailabilityOperations} from './debug.model';
//////////////////////////////////////Do not delete
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from 'vekas_models/services/authorization.service';
//////////////////////////////////////

@Component({
    selector: 'app-debug-component',
    templateUrl: './debug.component.html',
    providers: [DebugService]
})

export class AspuDebugComponent implements OnInit {
    closeResult = '';
    //messageCenterService = MessageCenterService.getInstance();
    availableTypes = new DebugAggregationsInfo();
    viewModel =new DebugCurrentCodesAvailabilityOperations();

    allowGenerateCodes = true;
    count = 1;

  constructor(
    private serv: DebugService,
    private modalService: NgbModal,
    private lservice: LangService,
    private ngbService: NgbAlertModule,
    public toastService: ToastService,
    private authorizationService: AuthorizationService)
  { }

    ngOnInit(): void {   }

    open(contentDebug) {        
            this.modalService.open(contentDebug, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
            this.getAggregation();
            this.getCurrent();
    }

    getAggregation() {
        this.serv.getAggregations().subscribe((data: IResult<DebugAggregationsInfo>) => {
            if (data.IsSuccess) {
                this.availableTypes = data.Value

            }
            //else {
            //    this.getToast("Ошибка при выполнении  бекапа", "danger")
            //    console.log(data);
            //}
        }, error => console.error(error));
    }
    

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent, contentDebug) {
        if ((e.key === 'A' && e.shiftKey === true && e.altKey === true)) {
            let val = localStorage.getItem("isUserAdmin");
            if (val != undefined && val == "true") {
                this.modalService.dismissAll();
                this.open(contentDebug)
                console.log("action !");
            }
        }
    }

    isTypeAvailable(type: string) {
        if (this.availableTypes.GroupTypes == undefined)
            return false;
        return this.availableTypes.GroupTypes.filter(x => x == type).length > 0;
    }

    backup() {
        this.serv.backup().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Бекап выполнен: " + data.Value, "warning")

            } else {
                this.toastService.getToast("Ошибка при выполнении  бекапа", "danger")
                console.log(data);
            }
        }, error => console.error(error));
    }

    clearBatch() {
        this.serv.clearBatch().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Партия сброшена", "warning")

            } else {
                this.toastService.getToast("Ошибка при выполнении сброса партии", "danger")
                console.log(data);
            }
        }, error => console.error(error));
    }

    addAggregate(val: string) {
        let model = new DebugCreateCommonAggregation();
        model.Count = this.count;
        model.AllowGenerateCodes = this.allowGenerateCodes;
        model.GroupType = val;
        this.serv.createAggregations(model).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Добавлено агрегатов: " + data.Value, "warning");
                this.getCurrent();

            }
        }, error => console.error(error));
    }

    getCurrent() {
        this.serv.getCurrent().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.viewModel = data.Value;
                console.log(this.viewModel)
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    setPrintNew() {
        this.serv.setPrintNew().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    setPrintInProgress() {
        this.serv.setPrintInProgress().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    setPrintFinished() {
        this.serv.setPrintFinished().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    setDefect() {
        this.serv.setDefect().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    unsetDefect() {
        this.serv.unsetDefect().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    createAggr() {
        this.serv.createAggr(this.allowGenerateCodes).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                //this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }


    deleteAggr() {
        this.serv.deleteAggr().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    customBoxCode: string;
    customProductCode: string;

    getBoxCode() {
        this.serv.getCode(true).subscribe((data: IResult<any>) => {
            console.log(data)
            if (data.IsSuccess) {
                this.customBoxCode = data.Value;    
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    getProductCode() {
        this.serv.getCode(false).subscribe((data: IResult<any>) => {
            console.log(data)
            if (data.IsSuccess) {
                this.customProductCode = data.Value;
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    setValidated() {
        this.serv.setValidated().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    unsetValidated() {
        this.serv.unsetValidated().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.getCurrent();
            } else {
                this.toastService.getToast("Ошибка при выполнении запроса", "danger")
            }
        }, error => console.error(error));
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
  }

  hostUrl: any = (<any>window).aspuApiServiceHost;

  uploadCodesConfig = {
    multiple: true,
    formatsAllowed: ".csv, .json, .txt",
    maxSize: "20",
    uploadAPI: {
      url: `${this.hostUrl}/api/debug/codes/`,
      method: "POST",
      headers: {
        "Authorization": this.authorizationService.GetToken() == undefined
          ? ""
          : "Bearer " + this.authorizationService.GetToken(),
        "Lang": LangService.currentLangName,
      },
      responseType: 'application/json; charset=utf-8',
    },
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    fileNameIndex: false,
    replaceTexts: {
      selectFileBtn: this.getlocalized('common', 'SelectFiles'),
      resetBtn: this.getlocalized('common', 'ResetFiles'),
      uploadBtn: this.getlocalized('common', 'UploadFiles'),
      dragNDropBox: 'Drag N Drop',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: this.getlocalized('common', 'FilesUploadedSuccess'),
      afterUploadMsg_error: this.getlocalized('common', 'FilesUploadedFailed'),
      sizeLimit: this.getlocalized('common', 'SizeLimit'),
    }
  };

  CodesUpload(event): void {
    console.log(event.body);
    let json = JSON.parse(event.body.toString());
    console.log(json);
    json.Value.forEach((x: string) => { this.toastService.getToast(x, 'info') });
    json.Messages.forEach((x: string) => { this.toastService.getToast(x, 'error') });
  }
}
