import { Component, OnInit } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from "../../../core/indicator/indicator.service";
import { AspuCode, AspuCodeOnly } from "../../code/code.model";
import { AspuBranchSerializationService } from './branch-serialization.service';
import { Howl } from 'howler';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { AspuCodeService } from '../../code/code.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AggrAlgorithm } from 'src/app/business/core/common/common.model';
import { AspuBranchState } from './branch-serialization.model';

@Component({
    selector: 'app-branch-serialization-component',
    templateUrl: './branch-serialization.component.html',
    providers: [AspuIndicatorService, AspuBranchSerializationService, AspuCodeService],
    styles: ['.codeReadedSuccess { background-color: #A0FFA0; } .codeReadedWarning { background-color: #ffc107; } .coreReadedFailed { background-color: #FF4040;}'],
    styleUrls: ['./branch-serialization.scss']
})
export class AspuBranchSerializationComponent implements OnInit {
    viewModel = new AspuBranchState();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    isDetalized = false;
    CodeAddedSuccess = '';

    page = new Page<AspuCode>();
    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();

    soundScan: Howl;
    soundOk: Howl;
    soundError: Howl;
    indicator = new AspuIndicator();
    mode = "aggregation"; //aggregation, remove, info
    productTypes = ["Product", "Block", "Box", "Pallet"];
    printerStatuses = ["Unprinted", "Printing", "Printed"];
    entityStatuses = ["None", "Defect", "PartialEntity", "FullEntity"];  //None - КМ без привязки// Entity - агрегирован

    currentAlgorithm: AggrAlgorithm;
    currentProductCode: string;

    constructor(private serv: AspuBranchSerializationService, private cserv: AspuCodeService,
        public modalService: NgbModal, public toastService: ToastService, private indServ: AspuIndicatorService
    ) {
        this.page.Size = 50; // window["pageSize"];
        this.page.PageNumber = 0;
        this.gridSort.dir = "desc";
        this.gridSort.field = "PrintedDate";

        this.loadItemsWithStateCheck();

        this.soundScan = new Howl({
            src: ['sound/scanit.wav'],

            onend: function () {
                console.log('SoundScan Finished!');
            },
            onloaderror: function () {
                console.log('SoundScan Error!');
            },
        });

        this.soundOk = new Howl({
            src: ['sound/success.wav'],
            onend: function () {
                console.log('soundOk Finished!');
            },
            onloaderror: function () {
                console.log('soundOk Error!');
            },
        });

        this.soundError = new Howl({
            src: ['sound/error.wav'],
            onend: function () {
                console.log('soundError Finished!');
            },
            onloaderror: function () {
                console.log('soundError Error!');
            },
        });
    }

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReloadEvent.bind(this);
    private _doTryReload = this.doTryReload.bind(this);
    
    doActionPrevent(data: any) {
        console.log("CodeReceived - prevent")
        this.doAction(data.detail)
    }

    doReloadEvent() {
        this.loading = true;
        this.loadItemsWithStateCheck();
        this.loadCodes();
    }
    
    ngOnInit() {
        this.preventDoubleCheck();
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
        window.addEventListener("AspuIndicatorUpdated", this._doReload, false);
        window.addEventListener("CodesModified", this._doTryReload, false);
        window.addEventListener("AggrStateUpdated", this._doReload, false);

        this.gridFilters.push(this.getGridFilter("Validated", "true", "eq", true));
        
        this.InitMode();
    }

    InitMode() {
        var algoritms = localStorage.getItem("AggrAlgorithms");
        if (algoritms == undefined) return;

        var list = JSON.parse(algoritms) as Array<AggrAlgorithm>;
        if (list == undefined) return;

        var param = list.filter(x=>x.Type == "Serialization").find(x=>x.Mode != undefined && x.IsShowPlcQueueClearButton != undefined);
        if (param == undefined) return;

        this.currentAlgorithm = param;
        this.mode = param.Mode.toLowerCase();
        if (param.IsShowOnlyValidateProduct)
            this.gridFilters.push(this.getGridFilter("EntityStatus", "Defect", "neq", true));
    }

    ngOnDestroy() {
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener('EnterCodeReceived', this._doAction);
        window.removeEventListener('AspuIndicatorUpdated', this._doReload);
        window.removeEventListener("CodesModified", this._doTryReload);
        window.removeEventListener("AggrStateUpdated", this._doReload);
        clearInterval(this.timerId);
    }

    eventCounter = 0;
    timerId: any;

    doTryReload() {
        this.eventCounter++;
    }

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.eventCounter > 0) {
                this.eventCounter = 0;
                this.loadCodes();
            }
        }, 5000);
    }

    public loadItems() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<AspuBranchState>) => {
            console.log(data.Value);
            data.Value.Products;
            this.viewModel = data.Value;
            this.isReady = true;
            this.loading = false;
        });
    }

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) =>
        {
            console.log(this.indicator?.Production)
            console.log(data.Value.Production)
            if (this.indicator?.Production != data.Value.Production && data.Value.Production == "Enabled") {
                console.log('soundScan.play')
                this.soundScan.play();
            }

            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled")
            {
                this.loadCodes();
                this.loadItems();
            }
            else
            {
                this.loading = false;
            }
        });
    }

    counter = 0;
    currentCode: AspuCode;
    code: string;

    editCode(row: AspuCode) {
        this.counter++;
        this.currentCode = row;

        this.currentProductCode = undefined;
    }
   
    doAction(code: string) {
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        switch (this.mode) {
            case "aggregation":
                this.serialize(code.trim());
                this.code = undefined;
                break;
            case "remove":
                this.remove(code.trim());
                this.code = undefined;
                break;
            case "info":
                this.editCode(new AspuCode(code.trim()));
                this.code = undefined;
                break;
            default: break;
        }
    }
          
    remove(code: string) {
        this.loading = true;
        this.serv.delete(new AspuCodeOnly(code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadCodes();
                this.toastService.getToast("Код забракован!", 'warning');
                this.soundOk.play();
                this.CodeAddedSuccess = 'codeReadedWarning';
            }
            else {
                this.toastService.getToast("Ошибка при забраковке кода!", 'error');
                this.soundError.play();
                this.CodeAddedSuccess = 'coreReadedFailed';
            }
            this.isReady = true;
            this.loading = false;
            this.setTimetToCleanCodeAddedSuccess();
        },
            error => console.error(error));
    }

    serialize(code: string) {
        this.loading = true;

        this.serv.serialize(new AspuCodeOnly(code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.ShowBlackSquare(250, 1000);
                this.toastService.getToast("Код валидирован!", 'info');
                this.soundOk.play();
                this.loadCodes();
                this.CodeAddedSuccess = 'codeReadedSuccess';
            }
            else {
                this.toastService.getToast("Ошибка при валидировании кода!", 'error');
                this.soundError.play();

                this.CodeAddedSuccess = 'coreReadedFailed';
                this.setTimetToCleanCodeAddedSuccess();
            }

            this.isReady = true;
            this.setTimetToCleanCodeAddedSuccess();
            },
            error => {
                console.error(error)
            });
    }


    public loadCodes() {
        this.loading = true;
        this.cserv.getItemsV2(this.getFilter()).subscribe((data: IResult<Page<AspuCode>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;              
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        return filter;
    }

    updateFilter(event) {
        this.page.PageNumber = 0;
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
                // .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    this.loadCodes();
                });
        }
        this.searchChangeObserver.next(event);
    }

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadCodes();
    }

    setPage(event) {
        const val = event.newValue;
        this.page.PageNumber = 0;
        this.page.Size = val;

        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe(() => {
                    this.loadCodes();
                });
        }
        this.searchChangeObserver.next(event);
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort.field = sort.prop;
        this.gridSort.dir = sort.dir;
        this.loadCodes();
    }

    public resetChild: Object;

    resetFilter() {
        this.resetChild  = JSON.parse(JSON.stringify(this.gridFilters));
    }

    getGridFilter(field: string, val: any, operator: string, isHidden = false) {
        let gridFilter = new GridFilter();
        gridFilter.field = field;
        gridFilter.value = val;
        gridFilter.operator = operator;
        gridFilter.isHidden = isHidden
        return gridFilter;
    }


    getCodeBgColor() {
        return this.CodeAddedSuccess; // ? 'codeReadedSuccess' : 'coreReadedFailed';
    }

    setTimetToCleanCodeAddedSuccess() {
        setTimeout(() => { this.CodeAddedSuccess = ''; }, 1000);
        
    }

    getRowClass = (row: AspuCode) => {
        return {
            'bg-dark text-danger': row.EntityStatus == 'Defect',
            'bg-custom-validated': row.Validated && row.EntityStatus != 'Defect',
            'bg-custom-success': row.EntityStatus == 'FullEntity' && row.OmsUploaded == undefined,
            'bg-custom-accepted': row.OmsUploaded,
            'bg-custom-danger': row.EntityStatus == 'FullEntity' && row.OmsUploaded == false,
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    plcQueueClear() {
        this.loading = true;
        this.serv.plcQueueClear().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
            }
            this.isReady = true;
        },
        error => console.error(error)); 
        this.loading = false;
    }

    timer1:any;
    timer2:any;
    isSquareVisible = false;

    ShowBlackSquare(delay: number, time: number) 
    {
        if (this.currentAlgorithm==undefined || !this.currentAlgorithm.IsShowBlackSquare) return;

        clearTimeout(this.timer1);
        clearTimeout(this.timer2);
        if (!this.isSquareVisible) {
            this.isSquareVisible=true;
            this.timer2 = setTimeout(() => { this.isSquareVisible=false; }, time);
        } else {        
            this.isSquareVisible=false;
            this.timer1 = setTimeout(() => { this.isSquareVisible=true; }, delay);
            this.timer2 = setTimeout(() => { this.isSquareVisible=false; }, time);
        }
    }

    getBorder(color: string) {
        let hasSuccess = this.viewModel.Products.filter(x => x.IsOk).length == this.viewModel.Products.length;
        let hasError = this.viewModel.Products.filter(x => x.IsError).length != 0;
        switch (color) {
            case 'success':
                return hasSuccess;
            case 'danger':
                return hasError;
            default:
                return !(hasError || hasSuccess);
        }
    }

    getCellError() {
        for (var i = 0; i < this.viewModel.Products.length; i++) {
            let layer = this.viewModel.Products[i];
            if (layer.IsError && layer.Message != undefined)
                return layer.Message;
        }
        return undefined;
    }

    clearLayer() {
        console.log("Clear layer");
        this.serv.clearLayer().subscribe((data: IResult<any>) => {
            this.isReady = true;
            this.loading = false;
        },
        error => console.error(error));
    }
}
