import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';

@Injectable()
export class AspuPlcService {

    constructor(
        private httpService: AspuHttpService) {    }
    
    getItems(): Observable<IResult<any>> {
        return this.httpService.get<Observable<IResult<any>>>('Module/plc/state', null);
    }


    getPlcSettings(groupType: string): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('Module/plc/config/' + groupType, null);
    }

    setPlcSettings(item: any, groupType: string): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(item, 'Module/plc/config/' + groupType);
    }

   
}