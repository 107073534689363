import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { AspuProductType } from './receipt-detail.model';
import { AspuProfile } from '../profile/profile.model';
import { AspuProfileService } from '../profile/profile.service';
import { IResult } from 'vekas_models/models/iresult';
import { TimePickerInfo } from 'vekas_models/models/timepicker.model';

@Component({
    selector: 'app-receipt-product-list-component',
    templateUrl: './receipt-product-list.component.html',
    providers: [AspuProfileService]
})
export class ReceiptProductListComponent implements OnInit {
    @Input() productList: Array<AspuProductType>; 
    rows = new Array<AspuProductType>();
    profiles = new Array<AspuProfile>()
    @Input() loading: boolean;

    isReady = false;
    ColumnMode = ColumnMode;
    reorderable = true;

    constructor(private lservice: LangService, private pservice: AspuProfileService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            const cur = chng.currentValue;
            const prev = chng.previousValue;
          //  console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
            if (propName == 'productList') {
                this.rows = this.productList;
                this.getProfiles();
                this.loading = false;
            }
        }
    }

    getProfileNameById(id: string) {
        return this.profiles.filter(x => x.Id == id).map(x => x.Name)[0];
    }

    getProfilesByType(type: string) {
        return this.profiles.filter(x => x.GroupType == type);
    }

    getProfiles() {
        //this.loading = true;
        this.pservice.getItems().subscribe((data: IResult<Array<AspuProfile>>) => {
            //this.loading = false;
            this.profiles = data.Value;
            this.isReady = true;
        },
            error => console.error(error));
    }

    getExpirationTimeNormalized(minutes: number) {
        let expiration = new TimePickerInfo();
        expiration.setTime(minutes);
        let result = expiration.Days.toString() + "д "
            + expiration.Hours.toString() + "ч "
            + expiration.Minutes.toString() + "м"
        return result 
    }
   
    getRowClass = (row: AspuProductType) => {
        return {
            'bg-danger': row.ProfileId == undefined,
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
