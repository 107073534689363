<div *ngIf="viewModel!=undefined" class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{viewModel.Description}}</span>
            <span class="badge badge-danger mt-1" style="float: right">{{getlocalized('common', 'Edition')}}</span>
        </h3>
    </div>
    <div *ngIf="isFlat==false" class="card-body form-group mb-2 pb-0">
        <div *ngFor="let config of viewModel.Children">
            <h4>
                <span class="h3 text-uppercase">{{config.Description}}</span>
            </h4>
            <div class="row">
                <div [ngSwitch]="item.discriminator" *ngFor="let item of config.Children" class="mb-3 pr3 col-xl-12 col-xxl-6">
                    <!-- INT -->
                    <div *ngSwitchCase="'IntVariable'" class="input-group input-group-lg">
                        <span class="input-group-text label-edit-width">{{item.Description}}</span>
                        <input appOnlynumber type="number" class="form-control" [(ngModel)]="item.Value">
                    </div>
                    <!-- BOOL -->
                    <div *ngSwitchCase="'BoolVariable'" class="form-check form-control-lg form-switch" style="margin-left: -50px">
                        <div *ngIf="setId(item.Name) as name" class="form-control-lg">
                            <input class="form-check-input big-switch" style="margin-left: -10px; margin-right: 10px;" type="checkbox" [(ngModel)]="item.Value" id="{{name}}">
                            <label class="form-check-label ps-2" for="{{name}}">{{item.Description}}</label>
                        </div>
                    </div>
                    <!-- STRING -->
                    <div *ngSwitchDefault class="input-group input-group-lg">
                        <span class="input-group-text label-edit-width">{{item.Description}}</span>
                        <input type="text" class="form-control" [(ngModel)]="item.Value">
                    </div>
                    <!--EnumVariable-->
                    <div *ngSwitchCase="'EnumVariable'" class="input-group input-group-lg">
                        <span class="input-group-text">{{item.Description}}</span>
                        <select name="{{item.Name}}"
                                [(ngModel)]="item.Value"
                                (ngModelChange)="changeData($event)"
                                class="form-select form-control form-control-sm">
                            <option *ngFor="let opt of item.Items" ng-selected="item.Value===opt.Name" [value]="opt.Name">{{ opt.Description}}</option>
                        </select>
                    </div>

                    <!--<input *ngSwitchDefault type="text" class="form-control" [(ngModel)]="item.Value">-->
                </div>
            </div>
        </div>
        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveComplexItem()" class="btn btn-lg btn-success float-end"><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
    </div>


    <div *ngIf="isFlat==true" class="card-body form-group mb-2 pb-0">
        <div class="form-row">
            <!--<h4>
                <span class="badge badge-secondary">{{config.Name}}</span>
            </h4>-->
            <div *ngFor="let config of viewModel.Children" [ngSwitch]="config.discriminator" class="mb-3 pr3 col-xl-12 col-xxl-6">
                <!-- INT -->                
                <div *ngSwitchCase="'IntVariable'" class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{config.Description}}</span>
                    <input appOnlynumber type="number" class="form-control" [(ngModel)]="config.Value">
                </div>
                <!-- BOOL -->
                <div *ngSwitchCase="'BoolVariable'" class="form-check form-control-lg form-switch form-control-lg" style="margin-left: -50px">
                    <input class="form-check-input big-switch" style="margin-left: -10px; margin-right: 10px;" type="checkbox" [(ngModel)]="config.Value" id="{{config.Name}}">
                    <label class="form-check-label" for="{{config.Name}}">{{config.Description}}</label>
                </div>
                <!-- STRING -->
                <div *ngSwitchDefault class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{config.Description}}</span>
                    <input type="text" class="form-control" [(ngModel)]="config.Value">
                </div>
                <!--EnumVariable-->
                <div *ngSwitchCase="'EnumVariable'" class="input-group input-group-lg">
                    <span class="input-group-text">{{item.Description}}</span>
                    <select name="{{item.Name}}"
                            [(ngModel)]="item.Value"
                            (ngModelChange)="changeData($event)"
                            class="form-select form-control form-control-sm ">
                        <option *ngFor="let opt of item.Items" ng-selected="item.Value===opt.Name" [value]="opt.Name">{{ opt.Description}}</option>
                    </select>
                </div>
            </div>
        </div>
        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveFlatItem()" class="btn btn-lg btn-success float-end"><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
    </div>


</div>
