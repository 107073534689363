<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'Receipts')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
        <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                       #someTable
                       [rows]="page.Items"
                       [loadingIndicator]=""
                       [externalPaging]="true"
                       [externalSorting]="true"
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       (activate)="onActivate($event)"
                       [count]="page.TotalElements"
                       [limit]="page.Size"
                       [offset]="page.PageNumber"
                       (page)="onPage($event)"
                       (sort)="onSort($event)"
                       [rowClass]="getRowClass"
                       [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Name')}}" prop="Name" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">
                            {{column.name}}
                        </span>
                    </div>
                    <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Name'" [fieldType]="'string'"
                                              (onClick)="gridFilters = $event"
                                              (onClose)="updateFilter($event)"
                                              [reset]="resetChild"
                                              (onSort)="onSort($event)"
                                              [isLarge]=true>
                    </complex-filter-component>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'HasBlock')}}" prop="HasBlock" [sortable]="false" cellClass="" [minWidth]="90" [maxWidth]="110" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter">
                            {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="value"><i class="fa fa-check fa-2x text-success"></i></div>
                    <!--<div *ngIf="value!=true"><i class="fa fa-square-o"></i></div>-->
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'HasBox')}}" prop="HasBox" [sortable]="false" cellClass="" [minWidth]="90" [maxWidth]="110" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter">
                            {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="value"><i class="fa fa-check fa-2x text-success"></i></div>
                    <!--<div *ngIf="value!=true"><i class="fa fa-square-o"></i></div>-->
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'HasPallet')}}" prop="HasPallet" [sortable]="false" cellClass="" [minWidth]="110" [maxWidth]="110" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter">
                            {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <div *ngIf="value"><i class="fa fa-check fa-2x text-success"></i></div>
                    <!--<div *ngIf="value!=true"><i class="fa fa-square-o"></i></div>-->
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="140" [maxWidth]="140" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label">
                        <span>
                            {{column.name}}
                        </span>
                    </div>
                    <div class="btn-group2">
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info me-3"><i class="fa fa-redo"></i></button>
                        <button id="Receipt_List_ResetAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div class="btn-group2 action-buttons">
                        <button id="Receipt_List_EditAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editItem(row['Id'])" class="btn btn btn-info"><i class="fa fa-edit"></i></button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

