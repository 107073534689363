 <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" (navChange)="changeTab($event)" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="i+1" [destroyOnHide]="true" *ngFor="let counter of counters.Branches; let i = index">
        <a ngbNavLink style="font-size: x-large;" *ngIf="counter.IsTotal == false">{{getlocalized('common', 'BranchCounters') + ' ' + counter.Name}}</a>
        <a ngbNavLink  style="font-size: x-large;" *ngIf="counter.IsTotal == true">{{getlocalized('common', 'TotalCounters')}}</a>
        <ng-template ngbNavContent> 
            <div class="row pb-2">
                <div class="col-lg-6">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3>
                                <span class="text-uppercase header-badge">{{getlocalized('common', 'PlcCounters')}}</span>
                            </h3>
                        </div>
                        <div class="card-body form-group mb-0">
                            <div class="h3" *ngFor="let item of counter.Values">
                                <span class="badge bg-secondary display-4" *ngIf="item.Description=='plc'">
                                    {{getlocalized('common', item.Id)}}: <span class="badge bg-success">{{item.Value}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3>
                                <span class="text-uppercase header-badge">{{getlocalized('common', 'AspuCounters')}} </span>
                            </h3>
                        </div>
                        <div class="card-body form-group mb-0">
                            <div class="h3" *ngFor="let item of counter.Values">
                                <span class="badge bg-secondary display-4" *ngIf="item.Description=='aspu'">
                                    {{getlocalized('common', item.Id)}}: <span class="badge bg-success">{{item.Value}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6" *ngIf="counter.IsTotal == false">
                    <div class="card mt-2">
                        <div class="card-header">
                            <h3>
                                <span class="badge badge-secondary">{{getlocalized('common', 'DiffCounters')}}</span>
                            </h3>
                        </div>
                        <div class="card-body form-group mb-0 pb-2">
                            <div class="h3" *ngFor="let item of counter.Values">
                                <span class="badge badge-secondary display-4" *ngIf="item.Description=='diff'">
                                    {{getlocalized('common', item.Id)}}: <span class="badge badge-success">{{item.Value}}</span>
                                </span>
                            </div>
                        </div>
                        <div class="btn-group2 mb-4" style="padding-left: 20px;">
                            <button id="Order_Plc_Counters_Save_Action" [disabled]="false" *ngIf="true" (click)="setCounters(counter.Name)" [openDelay]="1500" [closeDelay]="400" class="btn btn-success mr-1">{{getlocalized('common', 'DiffCountersSave')}}</button>
                            <button id="Order_Plc_Counters_Clear_Action" [disabled]="false" *ngIf="true" (click)="clearCounters(counter.Name)" [openDelay]="1500" [closeDelay]="400" class="btn btn-warning ml-1 mr-1">{{getlocalized('common', 'DiffCountersClear')}}</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
