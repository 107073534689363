import { AspuProfile } from "../profile/profile.model";

export class AspuReceiptBase {
    Id: string;
    Name: string;
    Nomenclature: string;
    NomenclatureCode: string;
    ProductTypes: Array<AspuProductType>;
    IsValid: boolean;
    Discriminator: string;  //TODO: приходит с малой буквы
}

export class AspuProductType {
    Id: string;
    GroupType: string;
    QuantityKm: number;
    Name: string;
    Gtin: string;
    GroupCapacity: number;
    ExpirationDate: number;
    ProfileId: string;
    Profiles: Array<AspuProfile>;
    RequesterType: string;
    IdentificationCodesSources: Array<string>;
}

export class AspuReceipt {
    Id: string;
    Name: string;
    ProductTypes: Array<AspuProductType>;
    Fields: any;
    IsValid: boolean;

    constructor() {
        this.Fields = new Object();
    }
}