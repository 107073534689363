import { AspuCode, AspuCodeOnly } from "../code.model";



export class AspuCodeInfo {
    Current: AspuSingleCodeInfoItem;
    Parent: AspuSingleCodeInfoItem; 
    Children: AspuMultiCodeInfoItem;
    AvailableOperations: AspuAvailableOperations;

    constructor() {
        this.Current = new AspuSingleCodeInfoItem();
    }
}

export class AspuSingleCodeInfoItem {
    PrintingStatus: string; // Unprinted,    Printing,    Printed
    EntityStatus: string; //  None, //КМ без привязки     Defect,     Entity //агрегирован
    GroupType: string; //Product,    Block,    Box,    Pallet
    Code: string;
    Weight: number;
    GroupCapacity: number;
    OmsUploaded: boolean; //undefined если неизвестно
    ServiceCode: string;
    constructor() { }
}

export class AspuMultiCodeInfoItem {
    GroupType: string; ////Product,    Block,    Box,    Pallet
    Codes: string[] 

    constructor() { }
}

export class AspuCodeReplace {
    Code: string;
    NewCode: string;
    constructor(code: string, newCode: string) {
        this.Code = code;
        this.NewCode = newCode;
    }
}

export class AspuAvailableOperations {
    Defect: boolean;
    Undefect: boolean;
    Validate: boolean;
    Destroy: boolean;
    ReplaceCode: boolean;
    ReplaceChild: boolean;
    IndividualOmsReportingReload: boolean;
    constructor() { }
}
