import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

import { AuthorizationService } from "vekas_models/services/authorization.service";
import { LangService } from 'vekas_models/services/lang.service';

//import { IResult } from "../../../models/iresult";
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { MessageCenterService } from "vekas_models/core/message-center/message-center.service";
import { ConfigurationService } from "../../services/configuration.service";
import { LoginService } from "../../core/login/login.service";

const webApiPrefix: string = "api";

@Injectable({
    providedIn: "root"
})

export class AspuHttpService {
    private hostUrl: string = "http://localhost:3428";
    private isRefreshToken: boolean = false;
    private refreshTokenTimerId: any;
    private configuration: ConfigurationService;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authorizationService: AuthorizationService,
        //public loginService: LoginService,
        public toastService: ToastService
    ) {
        this.hostUrl = (<any>window).aspuApiServiceHost;
        window.addEventListener("Login", this._doRefreshToken, false);
    }

    private _doRefreshToken = this.doRefreshTokenEvent.bind(this);

    isImidiatelyRefreshToken = false;
    doRefreshTokenEvent() {
        this.isImidiatelyRefreshToken = true;
        this.isRefreshToken = true;
        this.refreshToken()        
    }

    get<TOut>(urlSuffix: string, value?: any, options?: any, skipLoader: boolean = false): Observable<any> {
        let url = `${this.hostUrl}/${webApiPrefix}/${urlSuffix}/${value || ""}`;
        options = options || this.getOptions();
        let httpResult = this.http.get<TOut>(url, options);
        return this.subsribeToMessageService<TOut>(httpResult, this.toastService);
    }

    post<TOut>(args: any, urlSuffix?: any, options?: any, skipLoader: boolean = false): Observable<any> {
        const url = `${this.hostUrl}/${webApiPrefix}/${urlSuffix || ""}`;
        options = options || this.getOptions();
        let httpResult = this.http.post<TOut>(url, args, options);

        return this.subsribeToMessageService<TOut>(httpResult, this.toastService);
    }

    put<TOut>(args: any, urlSuffix?: any, options?: any, skipLoader: boolean = false): Observable<any> {
        const url = `${this.hostUrl}/${webApiPrefix}/${urlSuffix || ""}`;
        options = options || this.getOptions();
        let httpResult = this.http.put<TOut>(url, args, options);

        return this.subsribeToMessageService<TOut>(httpResult, this.toastService);
    }

    delete<TOut>(data: any, urlSuffix?: any, options?: any, skipLoader: boolean = false): Observable<any> {
        let url = `${this.hostUrl}/${webApiPrefix}/${urlSuffix}/${data}`;
        if (data == null)
            url = `${this.hostUrl}/${webApiPrefix}/${urlSuffix}`;

        options = options || this.getOptions();
        let httpResult = this.http.delete<TOut>(url, options);
        return this.subsribeToMessageService<TOut>(httpResult, this.toastService);
    }

    getOptions() {
        let options = this.getCommonOptions();
        return options;
    }

    private getCommonOptions(): any {
        return {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Accept": "application/json",
                //"Accept": "*/*",
                "Authorization": this.authorizationService.GetToken() == undefined
                    ? ""
                    : "Bearer " + this.authorizationService.GetToken(),
                "Lang": LangService.currentLangName
            })
        };
    }


    isRequiredResfreshToken() {
        let now = Date.now();
        let exp = this.authorizationService.GetTokenExpirationDateTime();
        return exp != null && ((exp.getTime() - now) < 1000 * 60 * 5 || now > exp.getTime())
    }

    public refreshToken() {
        // if (!this.isRequiredResfreshToken()) return;
        let teDT = this.authorizationService.GetTokenUpdateDate();
        //console.log(this.authorizationService.GetTokenAliveMinutes())
        let now = Date.now();
        if (!this.isImidiatelyRefreshToken && (this.isRefreshToken || (now - teDT) < 1000 * 60 * 2)) return;
        this.isRefreshToken = true;

        const url = `${this.hostUrl}/${webApiPrefix}/users/refreshtoken`;
        let httpResult = this.http.get<IResult<any>>(url, this.getCommonOptions());
        httpResult
            .subscribe((token: any) => {
                /*console.log(token)*/
                if (token) {
                    if (token.IsSuccess) {
                        this.authorizationService.SetUserInfo(token.Value.UserName, token.Value.Token, token.Value.TokenExpirationDateTime, Date.now(), token.Value.TokenLifeTime);
                    }
                    else {
                        let loginService = new LoginService(this, this.authorizationService);
                        loginService.Logout();
                        this.router.navigateByUrl("/login");
                    }
                }
            },
                error => {
                    if (error.status == 401) {
                        console.log(error);
                        this.router.navigateByUrl("/login");
                    }
                },
                () => {
                    console.log('this.isRefreshToken = false;')
                    this.isRefreshToken = false;
                    this.isImidiatelyRefreshToken = false;
                }
            );
    }
   

    private getMessage(value: string) {
        if (value.indexOf(" - ") >= 0)
            return value.split(" - ").slice(1).join(" - ");
        else
            return value;
    }

    private subsribeToMessageService<T>(httpEvent: any, toastService: ToastService) {
        //console.log(this.authorizationService.GetTokenAliveMinutes());
        let messageCenter = MessageCenterService.getInstance();
        clearTimeout(this.refreshTokenTimerId);
        let getMessage = this.getMessage;
        if (this.authorizationService.GetToken() == undefined || this.authorizationService.GetToken() == null) {
            console.log('Unauthorized')
            this.router.navigateByUrl("/login");
        }

        //console.log(this.authorizationService.GetTokenAliveMinutes());

        return new Observable<HttpEvent<T>>(x => {
            let expMinutes = this.authorizationService.GetTokenAliveMinutes();
            httpEvent
                .subscribe(httpResult => {
                    if (!httpResult.IsSuccess) {
                        // ToDo Change it to Toast sevice
                        if (httpResult.Messages != null) {
                            httpResult.Messages.forEach(function (value) {
                                let message = getMessage(value);
                                messageCenter.addMessage("error", message);
                                toastService.show(message, { classname: 'bg-danger text-light', delay: 10000 });
                            });
                        }
                    }
                    else {
                        if (httpResult.Messages != null) {
                            httpResult.Messages.forEach(function (value) {
                                let message = getMessage(value);
                                messageCenter.addMessage("info", message);
                                toastService.show(message, { classname: 'bg-success text-light', delay: 1000 });
                            });
                        }
                    }

                    x.next(httpResult);
                },
                    error => {
                        console.log("subscribe error");
                        console.log(error);
                        if (error.status == 401) {
                            console.log('401')
                            if (this.isRequiredResfreshToken()) this.router.navigateByUrl("/login");
                        }
                        else {
                            messageCenter.addMessage("error", error.message);
                            toastService.show(error.message, { classname: 'bg-danger text-light', delay: 10000 });
                        }
                    },
                    () => {
                        if (this.isRequiredResfreshToken()) this.refreshToken();
                    }
                );
        });
    }
}
