<!--<div id="aspuStatus" style="position: absolute; bottom: 12px; right: 25px; z-index:999;">-->
<!--<div id="aspuStatus" style="z-index: 999;" class="justify-content-center">-->
    <!--<div class="row">-->
    <div *ngIf="viewModel!=undefined" class="btn-group2">
        <div class="me-1" triggers="click:blur" ngbTooltip="{{getlocalized('IndicatorList', 'Batch')}}: {{getlocalized('IndicatorList', viewModel.Batch)}}" [ngClass]="getStatusClass(viewModel.Batch)"><i class="fa fa-cube fa-2x"></i></div>
        <div class="me-1" triggers="click:blur" ngbTooltip="{{getlocalized('IndicatorList', 'Accounting')}}: {{getlocalized('IndicatorList', viewModel.Production)}}" [ngClass]="getStatusClass(viewModel.Production)"><i class="fa fa-power-off fa-2x"></i></div>
        <div class="me-1" triggers="click:blur" ngbTooltip="{{getlocalized('IndicatorList', 'Print')}}: {{getlocalized('IndicatorList', viewModel.Printing)}}" [ngClass]="getStatusClass(viewModel.Printing)"><i class="fa fa-print fa-2x"></i></div>
        <div class="me-1" triggers="click:blur" ngbTooltip="{{getlocalized('IndicatorList', 'Mqtt')}}: {{getlocalized('IndicatorList', viewModel.Mqtt)}}" [ngClass]="getStatusClass(viewModel.Mqtt)"><i class="fa fa-network-wired fa-2x"></i></div>
        <div class="me-1" triggers="click:blur" ngbTooltip="{{getlocalized('IndicatorList', 'OMS')}}: {{getlocalized('IndicatorList', viewModel.OmsService)}}" [ngClass]="getStatusClass(viewModel.OmsService)"><i class="fa fa-database fa-2x"></i></div>
        <div [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('IndicatorList', 'Refresh')}}" class="btn btn-info" style="background-color: #56CCF2!important" (click)="reload()"><i class="fa fa-sync-alt fa-3x"></i></div>

        <!--<div [ngClass]="getStatusClass(viewModel.Batch)"><i class="fa fa-cube"></i></div>-->
    </div>
    <!--</div>-->
<!--</div>-->