import { Injectable } from '@angular/core';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from "../../services/aspu.http.service";
import { AspuCodeStatus } from "./code-status.model";

@Injectable()
export class AspuCodeStatusService {

    constructor(
        private httpService: AspuHttpService) { }

    changeStatus(item: AspuCodeStatus) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'oms/Storage/changeStatus');
    }
}
