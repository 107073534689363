import { OnInit, Input, Component, Output, EventEmitter } from "@angular/core";
import { AspuIndicatorService } from './indicator.service';
import { AspuIndicator } from './indicator.model';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModule, NgbAlertModule, NgbAlert, NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-indicator-component',
    templateUrl: './indicator.component.html',
    providers: [AspuIndicatorService]
})
export class AspuIndicatorComponent implements OnInit {

    viewModel : AspuIndicator;
    //@Output() onChange = new EventEmitter();
    status: string[] = [ "None", "Disabled", "Switching" , "Enabled" ];


    constructor(private lservice: LangService, private serv: AspuIndicatorService) {
        this.reload();
        
    }

    ngOnInit() {
        window.addEventListener("IndicatorsUpdated", this._doReload, false);
        window.addEventListener("Login", this._doReload, false);
    }

    ngOnDestroy() {
        window.removeEventListener('IndicatorsUpdated', this._doReload);
        window.removeEventListener('Login', this._doReload);
    }

    private _doReload = this.reload.bind(this);

    //addListener(val: string) {
    //    window.addEventListener(val, () => {
    //        this.reload();
    //    }, false);
    //}

    public reload(): void {
        this.serv.getIndicators().subscribe((data: IResult<AspuIndicator>) => {
            if (data.IsSuccess) {
                this.viewModel = data.Value;
                let c_event = new CustomEvent<any>("AspuIndicatorUpdated", { detail: this.viewModel });
                window.dispatchEvent(c_event);
                //this.onChange.emit(this.viewModel);
            }
        },
            error => console.error(error));
    }

    getStatusClass(status: any) {
        switch (status) {
            case "Switching":
                return "btn btn-warning";
            case "Enabled":
                return "btn btn-success";
            case "Disabled":
                return "btn btn-danger";
            default:
                return "btn btn-dark";
        }
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
