<div class="btn-group2">
  <!--<button id="Printer_Edit_BackAction" class="btn  btn-lg btn-info me-1 " [routerLink]="['/aspu-printer-list']"><i class="fa fa-reply-all me-1 "></i>{{getlocalized('common', 'ToPrinters')}}</button>
  <button id="Printer_Edit_RefreshTemplateAction" [disabled]="!isRefresh" (click)="refreshTemplates()" *ngIf="viewModel.discriminator!='SocketPrinterDto' && viewModel.discriminator!='CabPrinterDto' && viewModel.discriminator!=undefined" class="btn btn-lg btn-info me-1 "><i class="fa fa-redo me-1 "></i>{{getlocalized('PrinterList', 'RefreshTemplates')}}</button>
  <button id="Printer_Edit_SaveAction" [disabled]="false" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()" class="btn btn-lg btn-success me-1 "><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
  <button id="Printer_Edit_SaveAndCloseAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save&Close')}}" (click)="saveItem(true);" class="btn btn-lg btn-success me-1 "><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save&Close')}}</button>-->

  <button id="Printer_Edit_BackAction" class="btn btn-info btn-icon me-1 " [routerLink]="['/aspu-printer-list']" style="width: 186px">
    <span><i class="fa-solid fa-2x fa-pull-left fa-reply-all mt-0 me-0"></i></span>
    <span class="btn-icon-label mt-0" style="">{{getlocalized('common', 'ToPrinters')}}</span>
  </button>

  <button id="Printer_Edit_RefreshTemplateAction" [disabled]="!isRefresh" (click)="refreshTemplates()" *ngIf="viewModel.discriminator!='SocketPrinterDto' && viewModel.discriminator!='CabPrinterDto' && viewModel.discriminator!=undefined"
          class="btn btn-info btn-icon me-1 " style="width: 176px">
    <span><i class="fa-solid fa-2x fa-pull-left fa-redo mt-2 me-0"></i></span>
    <span class="btn-icon-label" style="">{{getlocalized('PrinterList', 'RefreshTemplates')}}</span>
  </button>

  <button id="Printer_Edit_SaveAction" [disabled]="false" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()"
          class="btn btn-success btn-icon me-1 " style="width: 166px;">
    <span><i class="fa-solid fa-2x fa-save fa-pull-left mt-0 me-0"></i></span>
    <span class="btn-icon-label mt-0">{{getlocalized('common', 'Save')}}</span>
  </button>

  <button id="Printer_Edit_SaveAndCloseAction" class="btn btn-success btn-icon me-1 " (click)="saveItem(true);" style="width: 176px">
    <span><i class="fa-solid fa-2x fa-pull-left fa-save mt-2 me-0"></i></span>
    <span class="btn-icon-label" style="">{{getlocalized('common', 'Save&Close')}}</span>
  </button>
</div>

<div class="card mt-2">
  <div class="card-header">
    <h3>
      <span class="header-badge text-uppercase">
        {{getlocalized('common', 'Printer')}}:
        <span *ngIf="isNew()">{{getlocalized('common', 'NewM')}}</span>
        <span *ngIf="!isNew()">{{viewModel.Name}}</span>
      </span>
      <!--<span class="badge badge-danger mt-1" style="float: right">{{getlocalized('common', 'Edition')}}</span>-->
      <!--<span class="ms-3 me-3"><app-spinner></app-spinner></span>-->
    </h3>
  </div>
  <div class="card-body container2 m-0">
    <div class="row">
      <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-prepend input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Name')}}</span>
          <input id="Printer_Edit_NameInput" type="text" class="form-control" [(ngModel)]="viewModel.Name">
          <app-validation [val]="viewModel.Name" [name]="'Name'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div class=" mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'DriverName')}}</span>
          <select id="Printer_Edit_DriverIdSelect"
                  [(ngModel)]="viewModel.DriverId"
                  [disabled]="!isNew()"
                  (change)="driverIdChanged($event)"
                  class="form-select form-control form-control-lg ">
            <option *ngFor="let driver of drivers" [value]="driver.DriverId">{{driver.Name}}</option>
          </select>
          <app-validation [val]="viewModel.DriverId" [name]="'DriverId'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='OfficePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'TemplatePath')}}</span>
          <input id="Printer_Edit_TemplatePathInput" type="text" class="form-control" [(ngModel)]="viewModel.TemplatePath">
          <app-validation [val]="viewModel.TemplatePath" [name]="'TemplatePath'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator!='BizerbaPrinterDto' && viewModel.discriminator!='OfficePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Host')}}</span>
          <input id="Printer_Edit_HostInput" type="text" class="form-control" [(ngModel)]="viewModel.Host">
          <app-validation [val]="viewModel.Host" [name]="'Host'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator!='BizerbaPrinterDto' && viewModel.discriminator!='OfficePrinterDto'" class="mb-3 pe-3  col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Port')}}</span>
          <input id="Printer_Edit_PortInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Port">
          <app-validation [val]="viewModel.Port" [name]="'Port'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'MinBufferSize')}}</span>
          <input id="Printer_Edit_MinBufInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.MinBufferSize">
          <app-validation [val]="viewModel.MinBufferSize" [name]="'MinBufferSize'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div class="mb-3 pe-3  col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'MaxBufferSize')}}</span>
          <input id="Printer_Edit_MaxBufInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.MaxBufferSize">
          <app-validation [val]="viewModel.MaxBufferSize" [name]="'MaxBufferSize'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'LoadCodesTriggerSource')}}</span>
          <select id="Printer_Edit_LoadCodesTriggerSourceInput"
                  [(ngModel)]="viewModel.LoadCodesTriggerSource"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='0'>{{getlocalized('PrinterList', 'PrinterApiTriggerSource')}}</option>
            <option [ngValue]='1'>{{getlocalized('PrinterList', 'ValidatedCountTriggerSource')}}</option>
          </select>
          <app-validation [val]="viewModel.LoadCodesTriggerSource" [name]="'LoadCodesTriggerSource'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator!='OfficePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Limit')}}</span>
          <input id="Printer_Edit_Limit" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Limit">
          <app-validation [val]="viewModel.Limit" [name]="'Limit'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='VideoJetPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'CodeWritingDelay')}}</span>
          <input id="Printer_Edit_CodeWritingDelayInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.CodeWritingDelay">
          <app-validation [val]="viewModel.CodeWritingDelay" [name]="'CodeWritingDelay'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='VideoJetPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Encoding')}}</span>
          <select id="Printer_Edit_EncodingInput"
                  [(ngModel)]="viewModel.Encoding"
                  class="form-select form-control form-control-lg ">
            <option *ngFor="let encoding of encodings">{{encoding}}</option>
          </select>
          <app-validation [val]="viewModel.Encoding" [name]="'Encoding'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='VideoJetPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'AcceptableWarnings')}}</span>
          <input id="Printer_Edit_AcceptableWarningsInput" type="text" class="form-control" [(ngModel)]="viewModel.AcceptableWarnings">
          <app-validation [val]="viewModel.AcceptableWarnings" [name]="'AcceptableWarnings'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator!='OfficePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Defect')}}</span>
          <select id="Printer_Edit_DefectInput"
                  [(ngModel)]="viewModel.Defect"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='true'>{{getlocalized('PrinterList', 'DefectOn')}}</option>
            <option [ngValue]='false'>{{getlocalized('PrinterList', 'DefectOff')}}</option>
          </select>
          <app-validation [val]="viewModel.Defect" [name]="'Defect'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator!='OfficePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'IdleState')}}</span>
          <select id="Printer_Edit_IdleState"
                  [(ngModel)]="viewModel.CheckIdleState"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='true'>{{getlocalized('PrinterList', 'IdleStateOn')}}</option>
            <option [ngValue]='false'>{{getlocalized('PrinterList', 'IdleStateOff')}}</option>
          </select>
          <app-validation [val]="viewModel.CheckIdleState" [name]="'IdleState'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='WolkePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'PrintHeads')}}</span>
          <input id="Printer_Edit_SomeInput" type="text" class="form-control" disabled value="{{getCurrentPrintHeads()}}">
          <!--<app-validation [val]="viewModel.Port" [name]="'Port'" [errors]="errors"></app-validation>-->
          <div class="input-group-append">
            <button *ngIf="true"
                    [openDelay]="500" [closeDelay]="400" placement="left"
                    [ngbTooltip]="ShowAll"
                    [ngbPopover]="statusButtons"
                    popoverClass="customPopover2"
                    [autoClose]="'outside'"
                    (hidden)="onPrinterHeadHidden()"
                    triggers="manual"
                    #p2="ngbPopover"
                    (click)="toggleWithGreeting(p2)"
                    class="btn btn-lg btn-info ">
              <i class="fa fa-edit"></i>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='CabPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Path')}}</span>
          <select id="Printer_Edit_PathInput"
                  [(ngModel)]="viewModel.Path"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='"card"'>{{getlocalized('PrinterList', 'PathCard')}}</option>
            <option [ngValue]='"sdcard"'>{{getlocalized('PrinterList', 'PathSdcard')}}</option>
            <option [ngValue]='"iffs"'>{{getlocalized('PrinterList', 'PathIffs')}}</option>
            <option [ngValue]='"usbmem"'>{{getlocalized('PrinterList', 'PathUsbmem')}}</option>
            <option [ngValue]='"webdal"'>{{getlocalized('PrinterList', 'PathWebdav')}}</option>
          </select>
          <app-validation [val]="viewModel.Path" [name]="'Path'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='MarkemImajePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Receiver')}}</span>
          <input id="Printer_Edit_ReceiverInput" type="text" class="form-control" [(ngModel)]="viewModel.Receiver">
          <app-validation [val]="viewModel.Receiver" [name]="'Receiver'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='MarkemImajePrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Sender')}}</span>
          <input id="Printer_Edit_SenderInput" type="text" class="form-control" [(ngModel)]="viewModel.Sender">
          <app-validation [val]="viewModel.Sender" [name]="'Sender'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='TestPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'RequestUri')}}</span>
          <input id="Printer_Edit_RequestUriInput" type="text" class="form-control" [(ngModel)]="viewModel.RequestUri">
          <app-validation [val]="viewModel.RequestUri" [name]="'RequestUri'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='TestPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Period')}}</span>
          <input id="Printer_Edit_PeriodInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Period">
          <app-validation [val]="viewModel.Period" [name]="'Period'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='VideoJetPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'FinalizePrinting')}}</span>
          <select id="Printer_Edit_FinalizePrinting"
                  [(ngModel)]="viewModel.BufferEmptyFinalizeTrigger"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='true'>{{getlocalized('PrinterList', 'EmptyBuffer')}}</option>
            <option [ngValue]='false'>{{getlocalized('PrinterList', 'MinLimit')}}</option>
          </select>
          <app-validation [val]="viewModel.BufferEmptyFinalizeTrigger" [name]="'BufferEmptyFinalizeTrigger'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='CabPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'MaxFreeMemoryPercent')}}</span>
          <input id="Printer_Edit_MaxFreeMemoryPercentInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.MaxFreeMemoryPercent">
          <app-validation [val]="viewModel.MaxFreeMemoryPercent" [name]="'MaxFreeMemoryPercent'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='CabPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'AcceptableWarnings')}}</span>
          <input id="Printer_Edit_CabAllowableErrorsInput" type="text" class="form-control" [(ngModel)]="viewModel.CabAllowableErrors">
          <app-validation [val]="viewModel.CabAllowableErrors" [name]="'CabAllowableErrors'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'FtpPath')}}</span>
          <input id="Printer_Edit_FtpPathInput" type="text" class="form-control" [(ngModel)]="viewModel.FtpPath">
          <app-validation [val]="viewModel.FtpPath" [name]="'FtpPath'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'FtpLogin')}}</span>
          <input id="Printer_Edit_FtpLoginInput" type="text" class="form-control" [(ngModel)]="viewModel.FtpLogin">
          <app-validation [val]="viewModel.FtpLogin" [name]="'FtpLogin'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'FtpPassword')}}</span>
          <input id="Printer_Edit_FtpPasswordInput" type="text" class="form-control" [(ngModel)]="viewModel.FtpPassword">
          <app-validation [val]="viewModel.FtpPassword" [name]="'FtpPassword'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'FtpTimeout')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.FtpTimeout">
          <app-validation [val]="viewModel.FtpTimeout" [name]="'FtpTimeout'" [errors]="errors"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2License')}}</span>
          <select id="Printer_Edit_Brain2LicenseEnable"
                  [(ngModel)]="viewModel.Brain2LicenseEnable"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='true'>{{getlocalized('PrinterList', 'LicenseEnable')}}</option>
            <option [ngValue]='false'>{{getlocalized('PrinterList', 'LicenseDisable')}}</option>
          </select>
          <app-validation [val]="viewModel.Brain2LicenseEnable" [name]="'Brain2LicenseEnable'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg ">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2ServiceHost')}}</span>
          <input id="Printer_Edit_Brain2ServiceHostInput" type="text" class="form-control" [(ngModel)]="viewModel.Brain2ServiceHost">
          <app-validation [val]="viewModel.Brain2ServiceHost" [name]="'Brain2ServiceHost'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2ServiceLogin')}}</span>
          <input id="Printer_Edit_Brain2ServiceLoginInput" type="text" class="form-control" [(ngModel)]="viewModel.Brain2ServiceLogin">
          <app-validation [val]="viewModel.Brain2ServiceLogin" [name]="'Brain2ServiceLogin'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2ServicePassword')}}</span>
          <input id="Printer_Edit_Brain2ServicePasswordInput" type="text" class="form-control" [(ngModel)]="viewModel.Brain2ServicePassword">
          <app-validation [val]="viewModel.Brain2ServicePassword" [name]="'Brain2ServicePassword'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2Timeout')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Brain2Timeout">
          <app-validation [val]="viewModel.Brain2Timeout" [name]="'Brain2Timeout'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2IterationCount')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Brain2IterationCount">
          <app-validation [val]="viewModel.Brain2IterationCount" [name]="'Brain2IterationCount'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Brain2IterationPause')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.Brain2IterationPause">
          <app-validation [val]="viewModel.Brain2IterationPause" [name]="'Brain2IterationPause'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainLicense')}}</span>
          <select id="Printer_Edit_ConnectBrainLicenseEnable"
                  [(ngModel)]="viewModel.ConnectBrainLicenseEnable"
                  class="form-select form-control form-control-lg ">
            <option [ngValue]='true'>{{getlocalized('PrinterList', 'LicenseEnable')}}</option>
            <option [ngValue]='false'>{{getlocalized('PrinterList', 'LicenseDisable')}}</option>
          </select>
          <app-validation [val]="viewModel.ConnectBrainLicenseEnable" [name]="'ConnectBrainLicenseEnable'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainHost')}}</span>
          <input id="Printer_Edit_ConnectBrainInput" type="text" class="form-control" [(ngModel)]="viewModel.ConnectBrainHost">
          <app-validation [val]="viewModel.ConnectBrainHost" [name]="'ConnectBrainHost'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainDeviceName')}}</span>
          <input id="Printer_Edit_ConnectBrainDeviceNameInput" type="text" class="form-control" [(ngModel)]="viewModel.ConnectBrainDeviceName">
          <app-validation [val]="viewModel.ConnectBrainDeviceName" [name]="'ConnectBrainDeviceName'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.discriminator=='GodexPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'NumberOfPrintedJobPerSecond')}}</span>
          <input id="Printer_Edit_NumberOfPrintedJobPerSecondInput" type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.NumberOfPrintedJobPerSecond">
          <app-validation [val]="viewModel.NumberOfPrintedJobPerSecond" [name]="'NumberOfPrintedJobPerSecond'" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainTimeout')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.ConnectBrainTimeout">
          <app-validation [val]="viewModel.ConnectBrainTimeout" [name]="'ConnectBrainTimeout'" [errors]="errors"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainIterationCount')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.ConnectBrainIterationCount">
          <app-validation [val]="viewModel.ConnectBrainIterationCount" [name]="'ConnectBrainIterationCount'" [errors]="errors"></app-validation>
        </div>
      </div>
      <div *ngIf="viewModel.discriminator=='BizerbaPrinterDto'" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'ConnectBrainIterationPause')}}</span>
          <input type="number" appOnlynumber class="form-control" [(ngModel)]="viewModel.ConnectBrainIterationPause">
          <app-validation [val]="viewModel.ConnectBrainIterationPause" [name]="'ConnectBrainIterationPause'" [errors]="errors"></app-validation>
        </div>
      </div>

      <div *ngIf="viewModel.Templates!=undefined" class="mb-3 pe-3 col-xl-12 col-xxl-6">
        <div class="input-group input-group-lg">
          <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('PrinterList', 'Templates')}}</span>
          <input disabled="disabled" type="text" class="form-control" value="{{viewModel.Templates}}">
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #statusButtons let-row="row">
  <div class="list-group">
    <div *ngFor="let val of printerHeadersStates; index as i">
      <div class="mb-3 pe-3 col-lg-12">
        <div class="input-group input-group-lg ms-1 form-control-lg">
          <input id="PrinterEditSome{{i}}"
                 class="form-check-input big-checkbox" type="checkbox" [(ngModel)]="val.IsActive">
          <label class="form-check-label label-edit-width text-uppercase ms-3" style="margin-top: -3px;" for="PrinterEditSome{{i}}">{{getlocalized('PrinterList', 'PrintHead')}} #{{i+1}}</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
