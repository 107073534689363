<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<!-- <div *ngIf="true" class="p-0 m-0"> -->
<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h4 ps-1 pe-1 text-uppercase">
                        {{getlocalized('common', 'CodeReservation')}}
                    </div>
                </div>
                <span *ngIf="loading" class="ms-3 "><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
        </div>

        <div class="card-body form-group mb-3 p-2 pb-0 {{getCodeBgColor()}}">

            <div *ngIf="getCodeBgColor()=='codeReadedSuccess' && mode=='aggregation'" class="alert alert-success text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ValidationSuccess')}}
            </div>

            <div *ngIf="getCodeBgColor()=='codeReadedSuccess' && mode=='remove'" class="alert alert-success text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'DefectSuccess')}}
            </div>

            <div *ngIf="getCodeBgColor()=='coreReadedFailed'" class="alert alert-danger text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'Error')}}
            </div>

            <!--<div *ngIf="getCodeBgColor()!='coreReadedFailed' && getCodeBgColor()!='codeReadedSuccess'" class="alert alert-info text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanCode')}}
            </div>-->

            <div class="form-row">
                <div class="mb-3 pe-3 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductCode')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="code">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(code)" class="input-group-append btn btn- ms-3 btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right"></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
            </div>
            <!--ENDNew-->
            <div>
                <ngx-datatable *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                               #someTable
                               [rows]="page"
                               [loadingIndicator]=""
                               [columnMode]="ColumnMode.force"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               rowHeight="auto"
                               [rowClass]="getRowClass"
                               [reorderable]="reorderable">
                    <ngx-datatable-column name="{{getlocalized('BatchList', 'BatchNumber')}}" prop="BatchNumber" [sortable]="false" cellClass="custom-row" [resizeable]="false" headerClass="hide-icon">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                <span id="Len{{column.prop}}" class="{{column.prop}}">
                                    <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                                    {{column.name}}
                                </span>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('BatchList', 'Quantity')}}" prop="Count" [sortable]="false" cellClass="custom-row" [resizeable]="false" headerClass="hide-icon">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    <span id="Len{{column.prop}}" class="{{column.prop}}">
                                        <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                                        {{column.name}}
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" [minWidth]="170" [maxWidth]="170" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                <span>
                                    {{column.name}}
                                </span>
                            </div>
                            <div class="btn-group2">
                                <!--<button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info me-1 "><i class="fa fa-redo"></i></button>
                                <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>-->
                            </div>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                            <div class="btn-group2 action-buttons">
                                <button (click)="reserveCodes(row['Id'])" class="btn btn-sm btn-success action-buttons me-1 "><i class="fa fa-play"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
