<div class="btn-group2 mt-2">
    <button id="ConfigurationJson_Edit_SaveAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()" class="btn btn-lg btn-success me-1"><i class="fa fa-save me-1"></i>{{getlocalized('common', 'Save')}}</button>
</div>

<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge">
                {{getlocalized('ReferenceList', 'Value')}}
            </span>
            <span class="badge bg-danger mt-1" style="float: right">JSON</span>
        </h3>
        <div class="row col-lg-12 pl-0 pr-0 pt-2">
            <!--INT-->
            <div [ngSwitch]="getType(item)" *ngFor="let item of props" [ngStyle]="{'display': getType(item)=='object' ? 'none' : null }" class="mb-3 pr3 col-xl-12 col-xxl-6">
                <div *ngSwitchCase="'string'" class="input-group input-group-lg">
                    <span class="input-group-text">{{item}}</span>
                    <input *ngSwitchCase="'string'" type="text" class="form-control" (ngModelChange)="changeData($event)" [(ngModel)]="obj[item]">
                </div>

                <!--INT-->
                <div *ngSwitchCase="'number'" class="input-group input-group-lg">
                    <span class="input-group-text">{{item}}</span>
                    <input *ngSwitchCase="'number'" appOnlynumber type="number" class="form-control" (ngModelChange)="changeData($event)" [(ngModel)]="obj[item]">
                </div>

                <!-- BOOL -->
                <div *ngSwitchCase="'boolean'" class="form-check form-control-lg form-switch">
                    <input class="form-check-input big-switch" type="checkbox" [(ngModel)]="obj[item]" id="{{item}}" (ngModelChange)="changeData($event)">
                    <label class="form-check-label ms-3" for="{{item}}">{{item}}</label>
                </div>

                <!--OBJECT-->
                <div *ngSwitchDefault class="input-group input-group-lg">
                    <span class="input-group-text">{{item}}</span>
                    <input *ngSwitchDefault type="text" class="form-control" [(ngModel)]="obj[item]" (ngModelChange)="changeData($event)">
                </div>
            </div>
        </div>

        <div *ngFor="let item of props">
            <div *ngIf="getType(item)=='object'">
                <h3 class="pb-2">
                    <span class="header-badge">
                        {{item}}
                    </span>
                </h3>
                <div class="row">
                    <!--STRING-->
                  <div [ngSwitch]="getSubType(item, subItem)" *ngFor="let subItem of getParams(item)" class="mb-3 pr3 col-lg-12 col-xl-6">
                    <div *ngSwitchCase="'string'" class="input-group input-group-lg">
                      <span class="input-group-text">{{subItem}}</span>
                      <input *ngSwitchCase="'string'" type="text" class="form-control" (ngModelChange)="changeData($event)" [(ngModel)]="obj[item][subItem]">
                    </div>

                    <!--INT-->
                    <div *ngSwitchCase="'number'" class="input-group input-group-lg">
                      <span class="input-group-text">{{subItem}}</span>
                      <input *ngSwitchCase="'number'" appOnlynumber type="number" class="form-control" (ngModelChange)="changeData($event)" [(ngModel)]="obj[item][subItem]">
                    </div>

                    <!-- BOOL -->
                    <div *ngSwitchCase="'boolean'" class="form-check form-control-lg form-switch">
                      <input class="form-check-input big-switch" type="checkbox" [(ngModel)]="obj[item][subItem]" id="{{subItem}}" (ngModelChange)="changeData($event)">
                      <label class="form-check-label ms-3" for="{{subItem}}">{{subItem}}</label>
                    </div>

                    <!-- ENUM -->
                    <div *ngSwitchCase="'enum'" class="input-group input-group-lg">
                      <span class="input-group-text">{{subItem}}</span>
                      <select *ngSwitchCase="'enum'" type="text" name="{{subItem.Name}}"
                              [(ngModel)]="obj[item][subItem].Selected"
                              (ngModelChange)="changeData($event)"
                              class="form-select form-control form-control-lg">
                        <option *ngFor="let opt of getVariants(item, subItem)" ng-selected="obj[item][subItem].Selected===opt" [value]="opt">{{ opt }}</option>
                      </select>
                    </div>

                    <!--OBJECT-->
                    <div *ngSwitchDefault class="input-group input-group-lg">
                      <span class="input-group-text">{{subItem}}</span>
                      <input *ngSwitchDefault type="text" class="form-control" [(ngModel)]="obj[item][subItem]" (ngModelChange)="changeData($event)">
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="btn-group2 mt-2">
  <button [openDelay]="1500"  [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()" class="btn btn-lg btn-success me-1 mb-1"><i class="fa fa-save me-1"></i>{{getlocalized('common', 'Save')}}</button>
</div>
