<form class="mt-4">
    <div class="row">
        <div class="col-lg-9 col-xl-7 col-md-9 mx-auto" style="max-width: 640px!important">
            <div class="card mt-4 rounded" style="border-radius: 5px!important;">
                <div id="login-container" class="card-header text-white" style="border-top-left-radius: 5px !important; border-top-right-radius: 5px !important; background-color: #36367A ">
                    <h3 class="d-flex justify-content-center text-uppercase">
                        <!--<i class="fa fa-user me-1 "></i>-->
                        <i class="fa fa-user" data-fa-transform="shrink-5.5" data-fa-mask="fa fa-circle" style=""></i>
                        <span class="ms-1">{{getlocalized('common', 'MenuAuthorization')}}</span>
                        <!--<span class="bg-secondary rounded-circle" style="position: absolute; top: -30px; font-size: 20px;">
                            <i class="fa fa-user m-2 fa-2x"></i>
                        </span>-->
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="mb-4 mt-3 pe-3 col-md-12">
                            <div class="input-group input-group-lg">
                                <label class="input-group-text col-form-label-lg label-edit-width text-uppercase" style="color: #36367A" for="Login">{{getlocalized('common', 'Login')}}</label>
                                <input type="text" class="form-control form-control-lg" placeholder="{{getlocalized('common', 'Login')}}" [(ngModel)]="loginModel.Login" name="Login" autofocus="autofocus">
                            </div>
                        </div>

                        <div class="mb-4 pe-3 col-md-12">
                            <div class="input-group input-group-lg">
                                <label class="input-group-text label-edit-width text-uppercase" style="color: #36367A" for="Password">{{getlocalized('common', 'Password')}}</label>
                                <input type="password" class="form-control form-control-lg" placeholder="{{getlocalized('common', 'Password')}}" [(ngModel)]="loginModel.Password" name="Password">
                            </div>
                        </div>


                        <!--<div class="form-group form-row">
                            <label for="UserName" class="col-md-4 col-form-label col-form-label-lg" style="position:relative">{{getlocalized('common', 'Login')}}</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control form-control-lg" placeholder="{{getlocalized('common', 'Login')}}" [(ngModel)]="loginModel.Login" name="Login" autofocus="autofocus">
                            </div>
                        </div>

                        <div class="form-group form-row">
                            <label for="Password" class="col-md-4 col-form-label col-form-label-lg">{{getlocalized('common', 'Password')}}</label>
                            <div class="col-md-8">
                                <input type="password" class="form-control form-control-lg" placeholder="{{getlocalized('common', 'Password')}}" [(ngModel)]="loginModel.Password" name="Password">
                            </div>
                        </div>-->
                        <!--</div>

                        <div class="card-footer text-right">-->
                    </div>
                    <div class="">
                        <span *ngIf="loginError!=''" class="alert alert-danger" style="position:relative!important; top:15px;">{{loginError}}&nbsp;</span>
                        <button class="btn btn-lg btn-success float-end" (click)="onLoginClick($event)">{{getlocalized('common', 'MenuLogOn')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
