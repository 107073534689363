<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'ErrorMessages')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
      <div *ngIf="rows!=undefined" class="form-row">
        <div class="mb-0 pe-3 col-xl-10 col-lg-12">
          <table class="table table-striped">
            <tbody>
              <tr *ngFor="let val of rows">
                <td class="text-uppercase" style="font-size: 24px; font-weight: 500;">
                  {{val.Text}}
                  <!--<span class="ms-1 badge" [ngClass]="{'bg-success': val.Count==0, 'bg-danger': val.Count>0}">{{val.Count}}</span></td>-->
                  <span *ngIf="val.Count>0" class="ms-1 badge" [ngClass]="{'bg-success': val.Count==0, 'bg-danger': val.Count>0}">{{val.Count}}</span>
                </td>
                <td>
                  <!--<div *ngIf="val.Count==0" class="plc-indicator bg-success "></div>
  <div *ngIf="val.Count>0" class="plc-indicator bg-danger "></div>-->
                  <!--<div *ngIf="val.Count==0" class="text-success "><i class="fa fa-2x fa-circle-dot"></i></div>
  <div *ngIf="val.Count>0" class="text-danger "><i class="fa fa-2x fa-circle-dot"></i></div>-->

                  <div *ngIf="val.Count==0" class="btn-circle btn btn-sm btn-success"><i class="fa fa-2x fa-record-vinyl"></i></div>
                  <div [openDelay]="1500" (click)="sendConfirmationByType(val.PlcMessageId)" [closeDelay]="400" ngbTooltip="{{getlocalized('MessageList', 'Confirm')}}" *ngIf="val.Count>0" class="btn-circle btn btn-sm btn-danger "><i class="fa fa-2x fa-record-vinyl"></i></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <!--<ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                     #someTable
                     [rows]="rows"
                     [loadingIndicator]=""
                     [externalSorting]="true"
                     [columnMode]="ColumnMode.force"
                     (sort)="onSort($event)"
                     [headerHeight]="50"
                     [footerHeight]="50"
                     rowHeight="auto"
                     [rowClass]="getRowClass"
                     [reorderable]="reorderable">
        <ngx-datatable-column name="{{getlocalized('MessageList', 'Text')}}" prop="Text" cellClass="custom-row" headerClass="hide-icon" [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Text'" [fieldType]="'string'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('common', 'Quantity')}}" prop="Count" cellClass="custom-row" headerClass="hide-icon" [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Count'" [fieldType]="'number'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn hide-icon" cellClass="central-btn" [minWidth]="130" [maxWidth]="140" [canAutoResize]="true" [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <div class="btn-group2">
              <button id="Message_List_Update" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItemsV2()" class="btn btn-sm btn-info me-1 "><i class="fa fa-redo"></i></button>
              <button id="Message_List_ConfirmAll" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('MessageList', 'ConfirmAll')}}" (click)="sendConfirmation()" class="btn btn-sm btn-info me-1 "><i class="fa fa-paper-plane"></i></button>
              <button id="Message_List_ResetFilter" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
            </div>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div class="btn-group2 action-buttons">
              <button id="Message_List_Confirm" *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('MessageList', 'Confirm')}}" (click)="sendConfirmationByType(row.PlcMessageId)" class="btn btn-sm btn-info"><i class="fa fa-paper-plane"></i></button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>-->
    </div>
</div>
