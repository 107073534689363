import { Component, ViewChild, TemplateRef, SimpleChanges } from "@angular/core";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { AuthorizationService } from "vekas_models/services/authorization.service";
import { LangService } from 'vekas_models/services/lang.service';
import { AspuIndicator } from "../business/core/indicator/indicator.model";
import { AspuJob } from "../business/core/models/aspu.job.model";
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AspuJobService } from "../business/services/aspu.job.service";
import { IResult } from "vekas_models/models/iresult";
import { MessageService } from "../business/common/message/message.service";
import { ToastService } from "vekas_models/core/toaster/toast-service";
import { MessageCenterService } from "vekas_models/core/message-center/message-center.service";
import { AspuCommonAlgorithms } from "../business/core/common/common.model";
import { AspuCommonService } from "../business/core/common/common.service";
import { Payload } from "../business/core/models/payload";
import { ConfirmationModel } from "vekas_models/core/confirm/confirmation.model";
import { parse } from "path";


@Component({
    selector: "app-nav-menu",
    templateUrl: "./nav-menu.component.html",
    styleUrls: ["./nav-menu.component.css"],
    providers: [AspuJobService, MessageService, AspuCommonService]
})

export class NavMenuComponent {
    modalReference: NgbModalRef;
    currentJob: AspuJob;
    hasMessages: boolean;
    indicator = new AspuIndicator();
    algorithms: AspuCommonAlgorithms;
    jobs = new Array<string>();
    loading = false;
    isNavExpand = true;

    constructor(
        private route: ActivatedRoute,
        private lservice: LangService,
        private jservice: AspuJobService,
        private cservice: AspuCommonService,
        private mservice: MessageService,
        public toastService: ToastService,
        private router: Router,
        private authorizationService: AuthorizationService,
        private modalService: NgbModal) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.router.url.search("/login") === -1 && this.router.url.search("/home") === -1) {
                    window["lastUrl"] = this.router.url;
                }
            }
        });

        this.isNavExpand = localStorage.getItem("isNavExpand") == 'false' ? false : true;

        if (authorizationService.IsAuthorized()) {
            this.jobs = [
                "BatchStartJob",
                "BatchFinalizeJob",
                "OmsSyncJob",
                "RequestKmJob",
                "UpdateKmJob",
                "ReserveCodesJob"
            ];
            this.checkJobs();
        }

    }

    timerId: any;

    ngOnInit() {

        this.getUnconfirmedMessages();
        this.getAlgorithms();

        window.addEventListener("AspuIndicatorUpdated", this._doAspuIndicatorUpdated, false);
        window.addEventListener("UnconfirmedMsgChanged", this._doUnconfirmedMsgChanged, false);
        window.addEventListener("WorkModeChanged", this._doWorkModeChanged, false);
        window.addEventListener("SomeMessagePath", this._doSomeMessagePath, false)

        window.addEventListener("Login", this._doLogin, false);
        window.addEventListener("JobStateChanged", this._doJobStateChanged, false);

        window.addEventListener("ShowError", this._doShowMessage, false);
        window.addEventListener("ShowInfo", this._doShowMessage, false);
        window.addEventListener("ShowSuccess", this._doShowMessage, false);

        window.addEventListener("ChangeNavSize", this._doChangeNavSize, false);

        this.timerId = setInterval(() => {
            this.checkJobs();
        }, 5000);
    }

    ngOnDestroy() {
        window.removeEventListener("AspuIndicatorUpdated", this._doAspuIndicatorUpdated);
        window.removeEventListener("UnconfirmedMsgChanged", this._doUnconfirmedMsgChanged);
        window.removeEventListener("WorkModeChanged", this._doWorkModeChanged);
        window.removeEventListener("ShowError", this._doShowMessage);
        window.removeEventListener("ShowInfo", this._doShowMessage);
        window.removeEventListener("ShowSuccess", this._doShowMessage);
        window.removeEventListener("ChangeNavSize", this._doChangeNavSize);
        window.removeEventListener("SomeMessagePath", this._doSomeMessagePath)

        window.removeEventListener("Login", this._doLogin);
        window.removeEventListener("JobStateChanged", this._doJobStateChanged);
        clearInterval(this.timerId);
    }


    private _doAspuIndicatorUpdated = this.doAspuIndicatorUpdated.bind(this);
    private _doUnconfirmedMsgChanged = this.doUnconfirmedMsgChanged.bind(this);
    private _doWorkModeChanged = this.doWorkModeChanged.bind(this);
    private _doShowMessage = this.doShowMessage.bind(this);
    private _doSomeMessagePath = this.doSomeMessagePath.bind(this);
    private _doChangeNavSize = this.doChangeNavSize.bind(this);



    doAspuIndicatorUpdated(data: any) {
        this.indicator = data.detail;
    }

    doUnconfirmedMsgChanged(data: any) {
        this.hasMessages = data.detail;
    }

    doWorkModeChanged(data: any) {
        this.getAlgorithms();
    }


    doShowMessage(data: any) {
        let type = "";
        switch (data.type) {
            case "ShowSuccess":
                type = "info";
                break;
            case "ShowError":
                type = "error";
                break;
            default:
                type = "warning";
                break;
        }

        let message = data.detail;  //header
        //let messageCenter = MessageCenterService.getInstance();
        //if (type == 'error')
        //    messageCenter.addMessage(type, message);
        this.toastService.getToast(message, type);
    }

    doSomeMessagePath(data: any) {
        let message = "SomeMessagePath recieved by SIgnalR";
        let messageCenter = MessageCenterService.getInstance();
        messageCenter.addMessage("info", message);
        this.toastService.show(message, { classname: 'bg-success text-light', delay: 10000 });
    }

    doChangeNavSize(data: any) {
        let val = data.detail;
        //console.log("ChangeNav");
        //console.log(val);
        this.isNavExpand = JSON.parse(val);
    }


    private _doLogin = this.doLogin.bind(this);
    doLogin() {
        this.router.navigate([this.router.url])
        this.getUnconfirmedMessages();
        this.getAlgorithms();
    }

    private _doJobStateChanged = this.doJob.bind(this);



    getUnconfirmedMessages() {
        this.mservice.getUnconfirmed().subscribe((data: IResult<any>) => {
            this.hasMessages = data.Value;
        })
    }
    //
    getAlgorithms() {
        this.cservice.getAlgorithms().subscribe((data: IResult<AspuCommonAlgorithms>) => {
            if (data.IsSuccess) {
                this.algorithms = data.Value;
              console.log(this.algorithms);
              console.log('this.algorithms')
              console.log(this.algorithms)
                if (this.algorithms != undefined) {
                    if (this.algorithms.PlcStateMetadata != undefined || this.algorithms.PlcConfigPages != undefined)
                        this.hasPlcCommon = true;
                    localStorage.setItem("allowValidatePrintedOnFinalize", this.algorithms.AllowValidatePrintedOnFinalize.toString());
                    localStorage.setItem("AggrAlgorithms", JSON.stringify(this.algorithms.AggrAlgorithms));
                    localStorage.setItem("FileOperations", JSON.stringify(this.algorithms.FileOperations));
                    localStorage.setItem("plcMeta", JSON.stringify(this.algorithms.PlcStateMetadata));
                    localStorage.setItem("plcPageMeta", JSON.stringify(this.algorithms.PlcConfigPages));
                    localStorage.setItem("plcPrintingMeta", JSON.stringify(this.algorithms.PrintingFlowVariables));
                    localStorage.setItem("plcBatchMeta", JSON.stringify(this.algorithms.BatchVariables));
                    localStorage.setItem("StartBatchByKm", JSON.stringify(this.algorithms.StartBatchByKm));

                    localStorage.setItem("AppConfigVariables", JSON.stringify(this.algorithms.AppConfigVariables));
                    localStorage.setItem("ReceiptsVariables", JSON.stringify(this.algorithms.ReceiptsVariables));
                    localStorage.setItem("GroupTypes", JSON.stringify(this.algorithms.GroupTypes));
                }
            }
        },
            error => console.error(error));
    }

    doJob(detail) {
        console.log(detail)
        //console.log("JOBLIST before")
        //console.log(this.jobs)
        let data = detail.detail as AspuJob;
        if (data.Active) {
            this.currentJob = data;
            if (this.jobs.find(x => x === data.Id) == undefined) {
                this.jobs.push(data.Id);
            }

            //if (this.currentJob.SubStepId == "ReportsUploadingStep" && this.currentJob.Failed==true)
            //    this.router.navigate(['/aspu-report-list']);
        } else {
            if (this.jobs.find(x => x === data.Id) != undefined) {
                this.jobs = JSON.parse(JSON.stringify(this.jobs.filter(x => x !== data.Id)));
                if (data.Id == 'BatchFinalizeJob') {
                    //this.router.navigate(['/aspu-receipt-list']);
                }

                if (data.Id == 'OmsSyncJob' && data.SubStepId == undefined) {
                    console.log('OmsSyncJob')
                    let c_event = new CustomEvent<any>('OmsSyncJobFinished');
                    window.dispatchEvent(c_event);
                    //this.router.navigate(['/aspu-receipt-list']);
                }
            }
        }

        if (this.jobs.length === 0) {
            if (this.modalService.hasOpenModals())
                this.modalService.dismissAll();
        } else {
            if (!this.modalService.hasOpenModals()) {
                this.openLoading();
            }
        }
        //console.log("JOBLIST after")
        //console.log(this.jobs)

    }

    checkJobs() {
        //console.log("checkJob")
        if (this.jobs != undefined && this.jobs.length !== 0) {
            for (var i = 0; i < this.jobs.length; i++) {
                this.jservice.getJobStatus(this.jobs[i]).subscribe((data: IResult<AspuJob>) => {
                    let obj = new Payload();
                    obj.detail = data.Value;
                    this.doJob(obj)
                });
            }
        }
    }

    retry() {
        console.log("RETRY");
        this.loading = true;
        this.jservice.retryJob(this.currentJob?.Id).subscribe((data: IResult<AspuJob>) => {
            console.log(data);
            if (data.IsSuccess) {
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    cancel() {
        console.log("CANCEL");
        if (this.currentJob?.AllowTimeoutBreak) {
            this.confirmation.Action = 'cancelWithoutConfirm';
            this.confirmation.Counter++;
            this.confirmation.Message = 'Возможно задаче требуется дополнительное время для обработки. Вы действительно хотите отменить ее?';
            this.confirmation.ClassType = "warning"

            //TODO: add confirmation modal
            //this.cancelWithoutConfirm(); //REMOVE
        } else {
            this.cancelWithoutConfirm();
        }


    }

    cancelWithoutConfirm() {
        this.loading = true;
        this.jservice.cancelJob(this.currentJob?.Id).subscribe((data: IResult<AspuJob>) => {
            console.log(data)
            if (data.IsSuccess) {
                this.loading = false;
            }
        },
            error => console.error(error));
    }

    isAspuExpanded = false;
    isAdminExpanded = false;

    collapse(panel: string) {
        this.isAspuExpanded = false;
    }

    toggle(panel: string) {
        //  this.isAspuExpanded = !this.isAspuExpanded;
        /*isNavExpand = true*/
        if (!this.isNavExpand) {
            let c_event = new CustomEvent<any>('ExpandBarEvent');
            window.dispatchEvent(c_event);
            setTimeout(() => { this.isAdminExpanded = true; }, 100);
        }
        else
            this.isAdminExpanded = !this.isAdminExpanded;
    }

    isFileOperationEnabled() {
        return this.algorithms != undefined && this.algorithms.FileOperations != undefined && this.algorithms.FileOperations.length > 0;
    }
    //TODO: refact!
    isSerializationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'Serialization') != undefined;
    }

    isBranchSerializationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'BranchSerialization') != undefined;
    }

    isCodeReservationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'CodesReservation') != undefined;
    }

    isBoxAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'Simple' || x.Type == 'Box4602') != undefined;
    }

    isManualAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'Manual') != undefined;
    }

    isLayerBoxAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'LayerBox') != undefined;
    }

    isLayerBoxTimestampAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'LayerBoxTimestamp') != undefined;
    }

    isLayerSerializationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'LayerSerialization') != undefined;
    }

    isLayer63313VisualEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'Layer63313') != undefined;
    }

    isPalletAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'PalletQueue') != undefined;
    }

    isBoxQueueAggregationEnabled() {
      return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'BoxQueue') != undefined;
    }

    isProductToPalletQueueAggregationEnabled() {
        return this.algorithms != undefined && this.algorithms.AggrAlgorithms.find(x => x.Type == 'ProductToPalletQueue') != undefined;
    }

    getModuleRoute(segment: string) {
        return '/module-' + localStorage.getItem("modulePath") + segment;
    }

    hasPlcCommon = false;

    @ViewChild('content', { static: true }) viewMe?: TemplateRef<any>; //ElementRef<HTMLElement>;

    open() {
        this.modalReference = this.modalService.open(this.viewMe, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
    }

    @ViewChild('contentLoading', { static: true }) viewMeLoading?: TemplateRef<any>; //ElementRef<HTMLElement>;
    openLoading() {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'custom-modal-class',
            size: 'lg'
        };
        this.modalService.open(this.viewMeLoading, ngbModalOptions);
    }

    confirmation = new ConfirmationModel();

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'cancelWithoutConfirm') {
                this.cancelWithoutConfirm();
            }
        }
        //this.modalService.dismissAll();
        this.confirmation.Clear();
    }


    ActiveRoute() {
        var ar = this.router.url;
        var segments = ar.split("/")

        if (segments.length > 1) {
            if (segments[1] === "product-type") {
                if (segments[3] === "editCard") { return this.getlocalized("common", "MenuCreateUpdateProduct") }

            }
            else if (segments[1] === "company") {
                if (segments[3] === "editCard") { return this.getlocalized("common", "MenuCreateUpdateCompany") }

            }
            else if (segments[1] === "order") {
                if (segments[3] === "editCard") { return this.getlocalized("common", "MenuCreateUpdateOrder") }

            }
            else if (segments[1] === "product-line") {
                if (segments[3] === "editCard") { return this.getlocalized("common", "MenuCreateUpdateProductLine") }
            }

        }
        return;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    hasAccess(menuItem: string) {
        let userUIAccess = JSON.parse(this.authorizationService.GetUserUIAccess());
        if (userUIAccess == null) return false;
        let isMenuItemExist = userUIAccess.includes('ASPU_UI!!Menu!!' + menuItem);
        return isMenuItemExist;
    }
}
