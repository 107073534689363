export class AggrAlgorithm {
    AllowCreateNotFull: boolean;
    ScanOrder: string;
    Type: string;
    DisplayName: string;
    ShowParentInfo: boolean;
    Mode:string;
    IsShowPlcQueueClearButton:boolean;
    IsShowOnlyValidateProduct:boolean;
    IsShowBlackSquare: boolean;
    ParentBindPolicy: string; //Scan, ConfirmUI, Auto
}

export class FileOperation {
    Type: string;
    DisplayName: string;
}

export class AspuMetaBase {
    discriminator: string;
    Description: string;
    Name: string;
    Required: boolean;
    IsReadOnly: boolean;
    Value: any;
}

export class AspuPlcStateMetadata extends AspuMetaBase {
}

export class AspuPlcConfigPage extends AspuMetaBase {
    Children: Array<AspuPlcConfigPage>
}

export class AspuInitialFields {
    SubjectType: string;
    ReceiptId: string; //for batch start
    Batch: any;
    PriningFlowId: number; //for print menu
    Values: any;

    constructor(subject: string) {
        this.SubjectType = subject;
    }
}


export class AspuCommonAlgorithms {
    AllowValidatePrintedOnFinalize: boolean;
    StartBatchByKm: boolean;
    AggrAlgorithms: Array<AggrAlgorithm>;
    FileOperations: Array<FileOperation>;
    PlcStateMetadata: Array<AspuPlcStateMetadata>;
    PlcConfigPages: Array<AspuPlcConfigPage>;
    PrintingFlowVariables: Array<AspuMetaBase>;
    BatchVariables: Array<AspuMetaBase>;
    AppConfigVariables: Array<AspuMetaBase>;
    ReceiptsVariables: Array<AspuMetaBase>;
    GroupTypes: Array<string>;
}

export class AspuDiagnosticInfo{
    AspuVersion: string;
    AspuModule: string;
    OmsLineName: string;
    OmsLineNumber: string;
  OmsLineUrl: string;


  constructor() {
    this.AspuVersion = "-";
    this.AspuModule = "-";
  }
}
