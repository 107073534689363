import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AspuDraftService } from './draft.service';
import { AspuDraft, CodesForProductLine, BatchStartRequest, ProductLine } from './draft.model';
import { LangService } from 'vekas_models/services/lang.service';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { AspuSettingsService } from '../../../core/settings/settings.service';
import { AspuSettings } from '../../../core/settings/settings.model';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuBatchEditService } from '../batch/batch-edit/batch-edit.service';
import { AspuBatchState } from '../batch/batch-state.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuMetaBase } from '../../core/common/common.model';
import { BatchIdDto } from '../batch/batch-process/batch-process.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter2, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from 'vekas_models/core/datepicker-adapter/datepicker-adapter';
import { LinqFilterItem } from 'vekas_models/models/filtersContainer';
import { AspuCommonService } from '../../core/common/common.service';
import { AspuIndicatorService } from '../../core/indicator/indicator.service';
import { AspuIndicator } from '../../core/indicator/indicator.model';
import { AspuReceiptService } from '../receipt/receipt.service';
import { ProductTypeImage } from '../batch/batch-receipt.model';

@Component({
    selector: 'app-draft-component',
    templateUrl: './draft-list.component.html',
    providers: [AspuDraftService, AspuIndicatorService, AspuSettingsService, AspuBatchEditService, AspuCommonService, AspuReceiptService,
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateAdapter, useClass: CustomAdapter2 },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})

export class AspuDraftListComponent implements OnInit {
    loading = true;
    page = new Page<AspuDraft>();
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();
    draftFindFilter: GridFilter;
    ColumnMode = ColumnMode;
    modalReference: NgbModalRef;
    reorderable = true;
    searchChangeObserver;
    exceptions = new Array<string>();
    substatus: string;
    isApplyEnabled = true;
    indicator = new AspuIndicator();
    eanCode: string;

    settings = new AspuSettings();
    productLine = new ProductLine();
    batchParams: Array<AspuMetaBase>;
    defaultRow: AspuDraft;
    statuses = ["Draft", "Stopped"];

    isReady = false;

    linqGTINFilter = this.getLinqFilter('ProductType.GTIN');
    linqNameFilter = this.getLinqFilter('ProductType.Name');

    constructor(
        private serv: AspuDraftService,
        private indServ: AspuIndicatorService,
        private cserv: AspuSettingsService,
        private bserv: AspuBatchEditService,
        private dservice: AspuCommonService,
        private rservice: AspuReceiptService,
        private lservice: LangService,
        public toastService: ToastService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {

        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        window.addEventListener("JobStateChanged", this._doReload, false);
        window.addEventListener("BatchStartJob", this._doCheckState, false);
        window.addEventListener("AspuIndicatorUpdated", this._doCheckIndicator, false);
        window.addEventListener("OmsSyncJobFinished", this._doLoadItems, false);
        window.addEventListener('EnterCodeReceived', this._getDrafts, false);


        //this.loadStatuses();

        this.defaultRow = undefined;
        let batchRawData = localStorage.getItem('plcBatchMeta');
        if (batchRawData != undefined) {
            this.batchParams = JSON.parse(batchRawData);
        }

        this.getIndicator();

        this.cserv.getSettings().subscribe((data: IResult<AspuSettings>) => {
            if (data.IsSuccess) {
                this.settings = data.Value;

                this.serv.getProductLineById(this.settings.ProductionLineId).subscribe((result: IResult<ProductLine>) => {
                    if (data.IsSuccess) {
                        this.productLine = result.Value;
                        this.page.Size = window["pageSize"];
                        this.page.PageNumber = 0;
                        this.gridSort.dir = "desc";
                        this.gridSort.field = "CreatedOn";

                        let statusFilter = new GridFilter();
                        statusFilter.logic = 'or',
                        statusFilter.isHidden = true;
                        statusFilter.filters = [];
                        statusFilter.filters.push(this.getGridFilter('Status', 'Draft', 'eq', true), this.getGridFilter('Status', 'Stopped', 'eq', true));

                        let plFilter = new GridFilter();
                        plFilter.logic = 'or',
                        plFilter.isHidden = true;
                        plFilter.filters = [];

                        plFilter.filters.push(this.getGridFilter('ProductLineId', this.productLine.Id, 'eq', true), this.getPlantWorkshopFilter())

                        this.gridFilters.push(statusFilter);
                        this.gridFilters.push(plFilter);
                        //this.gridFilters.push(statusFilter2);
                        // this.loadStatuses();
                        this.loadItems();
                    }
                })
            }
            else {
                this.loading = false;
            }
        });
    }

    getLinqFilter(field: string) {
        return [new LinqFilterItem('startswith', 'CodesForProductLines.Any(x=>x.' + field + '.StartsWith("{0}"))'),
        new LinqFilterItem('endswith', 'CodesForProductLines.Any(x=>x.' + field + '.EndsWith("{0}"))'),
        new LinqFilterItem('contains', 'CodesForProductLines.Any(x=>x.' + field + '.Contains("{0}"))'),
        new LinqFilterItem('eq', 'CodesForProductLines.Any(x=>x.' + field + '=="{0}")')];
    }

    getPlantWorkshopFilter() {
        if (this.productLine.WorkshopId == undefined && this.productLine.PlantId == undefined) {
            return this.getGridFilter('ProductLineId == null && WorkshopId == null && PlantId == null', '', 'linq', true)
        }
        else if (this.productLine.WorkshopId != undefined && this.productLine.PlantId == undefined) {
            return this.getGridFilter('(ProductLineId == null && WorkshopId=="' + this.productLine.WorkshopId + '" && PlantId == null)'
                + ' || (ProductLineId == null && WorkshopId == null && PlantId == null)', '', 'linq', true)
        }
        else if (this.productLine.WorkshopId == undefined && this.productLine.PlantId != undefined) {
            return this.getGridFilter(
                '(ProductLineId == null && WorkshopId == null && PlantId == "' + this.productLine.PlantId + '" ) '
                + ' || (ProductLineId == null && WorkshopId == null && PlantId == null)', '', 'linq', true);
        }
        else if (this.productLine.WorkshopId != undefined && this.productLine.PlantId != undefined)
            return this.getGridFilter('(WorkshopId=="' + this.productLine.WorkshopId + '" && ProductLineId == null)'
                + ' || (PlantId == "' + this.productLine.PlantId + '" && WorkshopId == null && ProductLineId == null) '
                + ' || (ProductLineId == null && WorkshopId == null && PlantId == null)', '', 'linq', true)
                ;
    }

    ngOnDestroy() {
        window.removeEventListener('JobStateChanged', this._doReload);
        window.removeEventListener("BatchStartJob", this._doCheckState);
        window.removeEventListener('AspuIndicatorUpdated', this._doCheckIndicator);
        window.removeEventListener("OmsSyncJobFinished", this._doLoadItems, false);
        window.removeEventListener('EnterCodeReceived', this._getDrafts);
    }

    getIndicator() {
        this.indServ.getIndicators().subscribe((data) => {
            this.indicator = data.Value;
        });
    }

    isBatchDisabled() {
        return this.indicator.Batch == 'Disabled';
    }

    private _doReload = this.doReloadEvent.bind(this);
    private _doCheckState = this.doCheckStateStatuses.bind(this);
    private _doCheckIndicator = this.getIndicator.bind(this);
    private _doLoadItems = this.loadItems.bind(this);
    private _getDrafts = this.getDraftsPrevent.bind(this);


    doReloadEvent() {
        this.loading = true;
        this.bserv.getBatchStatuses().subscribe((data: IResult<AspuBatchState>) => {
            if (data.IsSuccess) {
                if (data.Value.IsBatch) {
                    this.router.navigate(['/aspu-batch-process']);
                }
            }
            this.isReady = true;
            this.loading = false;
        })
    }

  updateFilter(event) {
        this.page.PageNumber = 0;
        console.log('updateFilter start')
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
              this.searchChangeObserver = observer;
            }).pipe(debounceTime(300)) // wait some ms after the last event before emitting last event
                //.pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    console.log('updateFilter start finally')
                    this.loadItems();
                });
        }
        this.searchChangeObserver.next(event);
    }

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadItems();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort = new GridSort();
        if (sort.prop == "ProductLineName") {
            this.gridSort.field = "ProductLine.Name";
        }
        else {
            this.gridSort.field = sort.prop;
        }
        this.gridSort.dir = sort.dir;
        this.loadItems();
    }

    private loadItems() {
        let filter = this.getFilter();
      if (filter.take == undefined) return;

        this.loading = true;
        this.serv.getItems(filter).subscribe((data: IResult<Page<AspuDraft>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;

                for (var i = 0; i < this.page.Items.length; i++) {
                    if (this.page.Items[i].ProductTypeId == undefined) {
                        let ptId = this.page.Items[i]?.ProductType?.Id;
                        if (ptId != undefined) {
                            this.page.Items[i].ProductTypeId = ptId;
                        }
                    }
                }
                let res = Array.from(new Set(this.page.Items.map((item: any) => item.ProductTypeId)))
                this.loadImages(res);
            }
            this.isReady = true;
            this.loading = false;

        }, error => console.error(error));
    }


    getFilter() {
        //console.log('GETFILTER')
        let filter = new FiltersContainer();
        filter.includes = ["CodesForProductLines.ProductType", "ProductLine"];
        //filter.includes.push("CodesForProductLines.ProductType");
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        console.log('***********')
        for (var i = 0; i < filter.filter.filters.length; i++) {
            console.log(i)
            console.log(filter.filter.filters[i])
            
            let additionFilters = new Array<GridFilter>();
            if (filter.filter.filters[i].fieldCaption == 'ProductType.Name' || filter.filter.filters[i].fieldCaption == 'ProductType.GTIN') {
                filter.filter.filters[i].filters = filter.filter.filters[i].filters.filter(f => !f.isCombinedFilter);
                

                for (var j = 0; j < filter.filter.filters[i].filters.length; j++) {

                    //filter.filter.filters[i].filters[j]
                    console.log('!')
                    console.log(filter.filter.filters[i].filters[j])

                    if (filter.filter.filters[i].filters[j].isCombinedFilter) {
                        console.log('combined')
                        //filter.filter.filters[i].filters[j]
                        filter.filter.filters.slice(j, 1)
                        break;
                    }

                    let f1 = new GridFilter();
                    f1.field = filter.filter.filters[i].fieldCaption;
                    f1.logic = "or";
                    f1.operator = "contains";
                    f1.value = filter.filter.filters[i].filters[j].value;
                    f1.isCombinedFilter = true;
                    additionFilters.push(f1)
                }

                for (var m = 0; m < additionFilters.length; m++) {
                    filter.filter.filters[i].logic = 'or';
                    filter.filter.filters[i].filters.push(additionFilters[m]);
                }
                


                
            }
            //let f1
        }

        console.log('***********')
        console.log(filter)

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        if (this.draftFindFilter != undefined) {
          filter.filter.filters.push(this.draftFindFilter);
        }
        console.log(filter)
        return filter;
    }

    getCodesFilter(batchId: string) {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = [];

        let batchFilter = new GridFilter();
        batchFilter.field = "ProductLineBatchId";
        batchFilter.operator = "eq";
        batchFilter.value = batchId;
        filter.filter.filters.push(batchFilter);
        return filter;
    }

    isStarted = false;
    currentBatch: AspuDraft

    batchStart(row: AspuDraft) {
        console.log("startDraftBatch");
        //this.defaultRow = undefined;
        let metadata: string;
        if (this.aspuBatchMetadata != undefined)
            metadata = JSON.stringify(this.aspuBatchMetadata);

        this.loading = true;
        this.isStarted = true;

        //this.modalService.dismissAll();
        //this.confirmation.Clear();
        console.log('Check codes available start')
        this.serv.getCodesItems(this.getCodesFilter(row.Id)).subscribe((data: IResult<Page<CodesForProductLine>>) => {
            if (data.IsSuccess) {
                console.log('Check codes available end: Success')
                console.log(data);
                if (data.Value.Items.length == 0) {
                    this.isStarted = false;
                    this.toastService.getToast('Черновик пуст!', 'error');
                    console.log("ERROR ON START");
                    return;
                }
                let result = data.Value.Items.find(x => x.GroupType == 'Product').ProductTypeId
                    || data.Value.Items[0].ProductTypeId;

                console.log('Check receipt is valid start')
                this.serv.isReceiptValidForType(result).subscribe((receiptValidData: IResult<any>) => {
                    console.log(receiptValidData);
                    if (receiptValidData.IsSuccess) {
                        console.log('Check receipt is valid end: Success')
                        this.loading = true;
                        console.log('Start batch from draft')
                        this.serv.startBatchFromDraft(new BatchStartRequest(row.Id, row.BatchNumber, this.settings.ProductionLineId, metadata, row.IdentificationCodesSource)).subscribe((batchStartData: IResult<any>) => {
                            if (batchStartData.IsSuccess) {
                                console.log('Start batch from draft: Success')
                                this.defaultRow = undefined;
                                if (this.modalReference != undefined) {
                                    console.log('Closing modal reference')
                                    this.modalReference.close();
                                }

                                localStorage.setItem('industry', batchStartData.Value.Industry);

                                this.loading = true;
                                console.log('Start sync job')
                                this.serv.startOmsSyncJob().subscribe((omsSyncData: IResult<any>) => {
                                    if (omsSyncData.IsSuccess) {
                                        console.log('End sync job: Success')
                                        console.log(omsSyncData)
                                        this.toastService.getToast('Синхронизация с OMS-сервером запущена', 'info');
                                    }
                                });

                            }
                            else {
                                this.cancelBatchStarting();
                            }
                        })
                        //POST /api/jobs/OmsSync (без параметров)
                        //this.serv.startBatchFromDraft(new BatchStartRequest(row.BatchNumber));
                        //job start
                        //let result = data.Value.Items[0].ProductTypeId;
                        //this.serv.isReceiptValidForType(result).subscribe();
                        //console.log(data.Value);
                        //this.currentCodes = data.Value;
                    }
                    else {
                        this.cancelBatchStarting();
                    }

                    this.isReady = true;
                    this.loading = false;

                }, error => console.error(error));
                //console.log(data.Value);
                //this.currentCodes = data.Value;
            }
            this.isReady = true;
            this.loading = false;

        }, error => console.error(error));
    }

    cancelBatchStarting() {
        if (this.modalReference != undefined)
        this.modalReference.close();
        this.isStarted = false;
    }

    batchContinue(id: string) {
        this.loading = true;
        this.isStarted = true;

        this.serv.getCodesItems(this.getCodesFilter(id)).subscribe((data: IResult<Page<CodesForProductLine>>) => {
            if (data.IsSuccess) {
                console.log('Check codes available end: Success')
                console.log(data);
                if (data.Value.Items.length == 0) {
                    this.isStarted = false;
                    this.toastService.getToast('Черновик пуст!', 'error');
                    console.log("ERROR ON START");
                    return;
                }
                let result = data.Value.Items.find(x => x.GroupType == 'Product').ProductTypeId
                    || data.Value.Items[0].ProductTypeId;

                console.log('Check receipt is valid start')
                this.serv.isReceiptValidForType(result).subscribe((receiptValidData: IResult<any>) => {
                    console.log(receiptValidData);
                    if (receiptValidData.IsSuccess) {
                        console.log('Check receipt is valid end: Success')
                        this.loading = true;

                        this.serv.batchContinue(new BatchIdDto(id)).subscribe((data: IResult<any>) => {
                            if (data.IsSuccess) {
                                this.loading = true;
                                this.serv.startOmsSyncJob().subscribe((omsSyncData: IResult<any>) => {
                                    if (omsSyncData.IsSuccess) {
                                        console.log(omsSyncData)
                                        this.toastService.getToast('Синхронизация с OMS-сервером запущена', 'info');
                                    }
                                });

                                localStorage.setItem('industry', data.Value.Industry);

                                this.isReady = true;
                                this.loading = false;
                            }
                            else {
                                this.cancelBatchStarting();
                            }

                        })
                    }
                    else {
                        this.cancelBatchStarting();
                        console.log('Check receipt is valid end: FAILURE')
                    };

                });
            }
            else {
                console.log('Не удалось получить информацию по кодам')
                this.cancelBatchStarting();
            }

        });
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning";
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.deleteItem(this.confirmation.Parameter);
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    deleteItem(id: string) {
        this.loading = true;
        this.serv.deleteItem(id).subscribe(data => {
            if (data.IsSuccess) {
                if (this.page.PageNumber != 0 && this.page.Items.length == 1)
                    this.page.PageNumber--;
                this.loadItems();
            }
            this.loading = false;
        });
    }

    currentCodes = new Page<CodesForProductLine>();
    summaryProductTypeForBatch = new CodesForProductLine()

    productForBatch = new CodesForProductLine();
    boxForBatch = new CodesForProductLine();
    palletForBatch = new CodesForProductLine();

    private getCodesByBatch(batchId: string) {
        this.summaryProductTypeForBatch = undefined;
        this.productForBatch = undefined;
        this.boxForBatch = undefined;
        this.palletForBatch = undefined;
        this.loading = true;
        this.serv.getCodesItems(this.getCodesFilter(batchId)).subscribe((data: IResult<Page<CodesForProductLine>>) => {
            if (data.IsSuccess) {
                console.log(data.Value);
                this.currentCodes = data.Value;

                this.productForBatch = data.Value.Items.filter(item => item.GroupType == 'Product')[0];
                if (this.productForBatch)
                    this.productForBatch.Quantity = data.Value.Items.filter(item => item.GroupType == 'Product').map(m => m.Quantity).reduce((ty, u) => ty + u, 0);

                this.boxForBatch = data.Value.Items.filter(item => item.GroupType == 'Box')[0];
                if (this.boxForBatch)
                    this.boxForBatch.Quantity = data.Value.Items.filter(item => item.GroupType == 'Box').map(m => m.Quantity).reduce((ty, u) => ty + u, 0);

                this.palletForBatch = data.Value.Items.filter(item => item.GroupType == 'Pallet')[0];
                if (this.palletForBatch)
                    this.palletForBatch.Quantity = data.Value.Items.filter(item => item.GroupType == 'Pallet').map(m => m.Quantity).reduce((ty, u) => ty + u, 0);

                this.summaryProductTypeForBatch = this.isOnlyOne();
            }
            this.isReady = true;
            this.loading = false;

        }, error => console.error(error));
    }

    isOnlyOne() {
      let useProduct = this.productForBatch != undefined;
      let useBox = this.boxForBatch != undefined;
      let usePallet = this.palletForBatch != undefined;

      if (useProduct && !useBox && !usePallet)
        return this.productForBatch;

      if (!useProduct && useBox && !usePallet)
        return this.boxForBatch;

      if (!useProduct && !useBox && usePallet)
        return this.palletForBatch;
    }

    //editItem(row: AspuDraft, contentChange: any) {
    //    this.currentBatch = row;
    //    this.getCodesByBatch(row.Id);
    //    this.open(contentChange);
    //}

    open(contentChange) {
        console.log('OPEN MODAL contentChange')
        this.modalReference = this.modalService.open(contentChange, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
    }

    isBatchStarted = false;
    doCheckStateStatuses(detail: any) {
        let data = detail.detail;
        if (data.IsSuccess) {
            if (data.Value.Active && this.isBatchStarted != true) {
                this.isBatchStarted = true;
            }
            else if (data.Value.Active != true && this.isBatchStarted) {
                this.isBatchStarted = false;
            }

            console.log("data.Value.SubStepName")
            console.log(data.Value.SubStepName)
            console.log("this.substatus")
            console.log(this.substatus)

            if (data.Value.SubStepName != undefined && data.Value.SubStepName != this.substatus) {
                this.toastService.getToast(data.Value.Name + ": " + data.Value.SubStepName, 'info');
                this.substatus = data.Value.SubStepName;
            }
            if (data.Value.Active == false) {
                this.router.navigate(['/aspu-batch-process']);
                // this.doGetBatchStatuses();
            }
        }
    }

    //getDate(d: string) {
    //    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    //    var dt = new Date(d.replace(pattern, '$3-$2-$1'));
    //    return dt.toLocaleDateString('ru-RU');
    //}

    getDate(d: string) {
        if (d == undefined || d == "") {
            return undefined;
        }
        let date = new Date(d);
        return date;
    }

    public resetChild: Object;

  resetFilter() {
    this.draftFindFilter = undefined;
    this.resetChild = JSON.parse(JSON.stringify(this.gridFilters));
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    startBatchWithParameters(row: AspuDraft, contentBatch: any) {
        console.log('startBatchWithParameters')
        this.serv.getCodesItems(this.getCodesFilter(row.Id)).subscribe((data: IResult<Page<CodesForProductLine>>) => {
            if (data.IsSuccess) {
                console.log(data.Value);
              this.currentCodes = data.Value;
              let product = this.currentCodes.Items.find(x => x.ProductType.GroupType == 'Product');

              if (product == undefined) {
                this.toastService.getToast('В черновике не указан продукт', 'error');
                return;
              }

              let pt = product.ProductType;

                this.defaultRow = row;
                if (this.defaultRow.IdentificationCodesSource == undefined) {
                    this.defaultRow.IdentificationCodesSource = this.currentCodes.Items.find(x => x.ProductType.GroupType == 'Product').ProductType.RequesterType;
                }

                //checko identification source
                if (this.batchParams.length == 0 && pt.IdentificationCodesSources?.length <= 1) {
                    this.batchStart(row);
                    return;
                }

                this.exceptions = [];
                if (row.ProductionDatePlan != undefined) {
                    this.exceptions.push('ProductionDate')
                }
                console.log('OPEN MODAL startBatchWithParameters')
                this.modalReference = this.modalService.open(contentBatch, { scrollable: false, windowClass: 'custom-modal-class' });
            }
        }, error => console.error(error));
    }

    aspuBatchMetadata: any; //Array<AspuMetaBase>;

    saveBatchParameters() {
        if (this.defaultRow != undefined)
            this.batchStart(this.defaultRow);
    }

    getGridFilter(field: string, val: any, operator: string, isHidden = false) {
        let gridFilter = new GridFilter();
        gridFilter.field = field;
        gridFilter.value = val;
        gridFilter.operator = operator;
        gridFilter.isHidden = isHidden
        return gridFilter;
    }

    toggleWithGreeting(popover, row: AspuDraft) {
        if (popover.isOpen()) {
            popover.close();
        } else {
            popover.open({ row });
        }
    }

    getRowClass = (row: AspuDraft) => {
        return {
            'bg-dark text-danger': row.BatchNumber == undefined,
        };
    }

    omsSync() {
        this.serv.startOmsSyncJob().subscribe((omsSyncData: IResult<any>) => {
            if (omsSyncData.IsSuccess) {
                console.log(omsSyncData)
                this.toastService.getToast('Синхронизация с OMS-сервером запущена', 'info');
            }
        });
    }

    //refreshRequesterType() {
    //    let sources = this.productTypes.find(x => x.GroupType == 'Product').IdentificationCodesSources;
    //    let defaultSource = this.productTypes.find(x => x.GroupType == 'Product').RequesterType;
    //    console.log('defaultSource')
    //    console.log(defaultSource)
    //    if (defaultSource != undefined)
    //        this.viewModel.IdentificationCodesSource = defaultSource;

    //    if (defaultSource == undefined && sources.length == 1) {
    //        this.viewModel.IdentificationCodesSource = sources[0];
    //    }
    //}

    getRequesterTypesEnabled() {
        return this.currentCodes.Items.find(x => x.ProductType.GroupType == 'Product')?.ProductType?.IdentificationCodesSources;
    }

    getRequesterSourcesSplitted(s: Array<string>) {
        let newAr = [];
        s.forEach(val => {
            newAr.push(this.getlocalized('RequesterTypeDictionary', val));
        });
        return newAr.join(", ");
    }


    images = new Array<ProductTypeImage>();

    //image: any;
    //getImage() {
    //    this.rservice.getImage(this.summaryProductTypeForBatch.ProductTypeId).subscribe((data: IResult<any>) => {
    //        if (data.IsSuccess) {
    //            this.image = data.Value.Image;
    //        }
    //        else {
    //            this.toastService.getToast("Не удалось получить изображение", "error")
    //        }
    //    });
    //}

    getImageById(id: string) {
        return this.images.find(x => x.ProductTypeId == id).Image;
    }

    hasImage(id: string) {
        return this.images.filter(x => x.ProductTypeId == id).length > 0;
    }

    loadImages(ids: Array<string>) {
        this.images = [];
        for (var i = 0; i < ids.length; i++) {
            if (ids[i] == undefined) continue;

            this.rservice.getImage(ids[i]).subscribe((data: IResult<ProductTypeImage>) => {
                if (data.IsSuccess && data.Value.Image != undefined) {
                    this.images.push(data.Value);
                }
            }, error => console.error(error));
        }
    }

  getDraftsPrevent(data: any) {
    if (this.modalReference != undefined && this.modalService.hasOpenModals()) {
        this.getDraftsByEan(data.detail)
      }
      else if (data.detail == 'ShowModalEanWindow') {
        document.getElementById('FindDraftsBtn').click();
      }
    }

    showModalEan(parameters: any) {
      this.eanCode = undefined;
      this.modalReference = this.modalService.open(parameters, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
    }

    closeModal() {
      this.modalReference.dismiss('Cross click');
      this.eanCode = undefined;
    }

    getDraftsByEan(ean: string) {
      this.closeModal();
      let gtin = ean.trim().padStart(14, '0');

      this.draftFindFilter = new GridFilter();
      this.draftFindFilter.filters = new Array<GridFilter>();
      this.draftFindFilter.logic = 'or';
      this.draftFindFilter.operator = 'linq';
      this.draftFindFilter.filters.push(this.getGridFilter('ProductType.GTIN', gtin, 'eq', true));
      this.draftFindFilter.filters.push(this.getGridFilter('CodesForProductLines.Any(x=>x.ProductType.GTIN =="{0}")', gtin, 'linq', true));

      let filter = this.getFilter();
      if (filter.take == undefined) return;

      this.serv.getItems(filter).subscribe((data: IResult<Page<AspuDraft>>) => {
        if (data.IsSuccess) {

          if (data.Value.TotalElements == 0) {
            this.toastService.getToast('По указанному GTIN [' + ean + '] ничего не найдено', 'error');
            this.draftFindFilter = undefined;
            this.loadItems();
          }
          else {
            this.page = data.Value
          }

          this.isReady = true;
        }
      },
        error => console.error(error));
    }


}
