import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuSettingsService } from '../settings.service';
import {AspuCameraConfig } from '../settings.model';

@Component({
    selector: 'app-camera-config-component',
    templateUrl: './camera-config.component.html',
    providers: [AspuSettingsService]
})
export class AspuCameraConfigComponent implements OnInit {
    @Input() items: Array<AspuCameraConfig>
    //buffers: Array<AspuPacketPrinter>;
    destinations = ['Serialize', 'AggrBox', 'AggrPallet'];

    isReady = true;
    loading = false;
    ColumnMode = ColumnMode;
    reorderable = true;
    id: string;

    @Output() onUpdateData = new EventEmitter<Array<AspuCameraConfig>>();

    constructor(private serv: AspuSettingsService, private lservice: LangService, public modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        //this.loadBuffers();
        
    }

    ngOnInit() {}

    //loadBuffers() {
    //    this.serv.getPacketPrinters().subscribe(data => {
    //        if (data.IsSuccess) {
    //            this.buffers = data.Value;
    //            this.isReady = true;
    //            this.loading = false;
    //        }
    //    });
    //}

    saveProfile() {
        this.onUpdateData.emit(this.items);
        this.currentId = undefined;
        this.editState = false;
    }

    editState: boolean = false;
    printerFlow = new AspuCameraConfig;
    printerFlowOrigin: AspuCameraConfig;

    saveItem(printerFlow: AspuCameraConfig) {
        this.printerFlow = printerFlow;
        this.printerFlowOrigin = JSON.parse(JSON.stringify(printerFlow)); 
        if (this.printerFlow.Id == undefined) {
            this.currentId = undefined;
            this.editState = false;
            }
            else {
                this.currentId = undefined;
                this.editState = false;
            }
        this.saveProfile();
    }

    cancel(row: AspuCameraConfig) {
        if (this.currentId != undefined) {
            this.currentId = undefined;
        }
        else {
            this.items = this.items.filter(x => x.Id!=undefined);
        }
        this.editState = false;
        //this.loadItems();
    }

    addItem() {
        if (this.editState) {
            return;
        }

        this.editState = true;
        let newPrinterFlow = new AspuCameraConfig();
        newPrinterFlow.Id = undefined;
        newPrinterFlow.Destination = 'Serialize';

        let rows = [...this.items];
        rows.splice(0, 0, newPrinterFlow);
        this.items = [...rows];
        this.printerFlow = newPrinterFlow;
        this.editItem(newPrinterFlow);
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning"
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.items = this.items.filter(x => x.Id != this.confirmation.Parameter)
                this.saveProfile();
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    currentId: number;

    editItem(printingFlow: AspuCameraConfig) {
        this.currentId = printingFlow.Id;
        this.printerFlowOrigin = JSON.parse(JSON.stringify(printingFlow));
        this.printerFlow = printingFlow;
    }

    isEdit(id: number) {
        return (id == this.currentId);
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
