import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuEntity, AspuSetEntityResult } from '../box/aggregation-box.model';
import { PackZoneClearRequest, PackZoneStateDto } from './aggregation-product-to-pallet-queue.model';

@Injectable()
export class AspuAggregationProductToPalletQueueService {


    url = "AggrAlgorithms/ProductToPalletQueue";

    constructor(private httpService: AspuHttpService) { }

    getState(): Observable<IResult<PackZoneStateDto>> {
        return this.httpService.get<IResult<PackZoneStateDto>>(this.url);
    }

    getStatus(): Observable<IResult<PackZoneStateDto>> {
        return this.httpService.get<IResult<PackZoneStateDto>>(this.url + '/status');
    }

    getPalletPack(filter: FiltersContainer): Observable<IResult<Page<AspuEntity>>> {
        return this.httpService.post<IResult<Page<AspuEntity>>>(filter, this.url + '/pack');
    }

    getPalletQueue(filter: FiltersContainer): Observable<IResult<Page<AspuEntity>>> {
        return this.httpService.post<IResult<Page<AspuEntity>>>(filter, this.url + '/queue');
    }

    clearPallet(item: PackZoneClearRequest): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), this.url + '/clear');
    }

    setProductCodeToQueue(item: AspuCodeOnly) {
        return this.httpService.put<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url);
    }

    setProductCode(item: AspuCodeOnly) {
        return this.httpService.post<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url + '/addProduct');
    }

    removeProductCode(item: AspuCodeOnly) {
        return this.httpService.post<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url + '/removeProduct');
    }

    replaceProductCode(item: AspuCodeOnly) {
        return this.httpService.post<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url + '/replaceProduct');
    }

    closePallet(item: AspuCodeOnly = undefined) {
        let request = new AspuCodeOnly(undefined)
        if (item != undefined) {
            request = item
        } else {
            request.Code = null;
            request.ShowParentInfo = undefined;
        }
        return this.httpService.post<IResult<any>>(JSON.stringify(request), this.url);
    }

    moveStorageToPack() {
        return this.httpService.post<IResult<AspuSetEntityResult>>(null, this.url + '/moveStorageToPack');
    }
}
