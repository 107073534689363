import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';
import { AggrAlgorithm, AspuCommonAlgorithms, AspuDiagnosticInfo, AspuInitialFields } from './common.model';

@Injectable()
export class AspuCommonService {

    constructor(
        private httpService: AspuHttpService) { }

    //getAlgorithms(): Observable<any> {
    //    return this.httpService.get<any>('Module/meta', null);
    //}

    getAlgorithms(): Observable<IResult<AspuCommonAlgorithms>> {
        return this.httpService.get<IResult<AspuCommonAlgorithms>>('Module/meta', null);
    }

    getDiagnosticInfo(): Observable<IResult<AspuDiagnosticInfo>>{
        return this.httpService.get<IResult<AspuDiagnosticInfo>>('Module/diagnostic', null);
    }


    getInitialValues(param: AspuInitialFields): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(param, 'Module/ExtraFields/getInitialValues');
    }
}