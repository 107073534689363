export class CodeInfo {
    public Code: String;

    public IsValidated: Boolean;
    public IsDefected: Boolean;
    public Aggregated: String;
    public IsPacked: Boolean;

    public Status: String;

    public PrintedOn: Date;

    public BatchNumber: String;
    public StartDate: Date;
    public FinalizationDate: Date;

    public ProductLineName: String;

    public ParentCode: String;
    public ChildrenCodes: String[];
}