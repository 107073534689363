<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h2 ps-1 pe-1 text-uppercase">
                        {{getlocalized('SerializationList', 'Serialization')}}{{codeLevel}}
                    </div>
                </div>
                <span *ngIf="loading" class="ms-3 "><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
            <div class="btn-group2 d-flex justify-content-end">
                <button *ngIf="currentAlgorithm!=undefined && currentAlgorithm.IsShowPlcQueueClearButton" class="btn btn-lg btn-danger me-1  text-uppercase" (click)="plcQueueClear()">{{getlocalized('common', 'PlcQueueClear')}}</button>
                <button *ngIf="!isDetalized" class="btn btn-lg btn-secondary me-1  text-uppercase" (click)="isDetalized=true;loadCodes()"><i class="fa fa-toggle-off me-1 "></i>{{getlocalized('common', 'Details')}}</button>
                <button *ngIf="isDetalized" class="btn btn-lg btn-success me-1  text-uppercase" (click)="isDetalized=false"><i class="fa fa-toggle-on me-1 "></i>{{getlocalized('common', 'Details')}}</button>
            </div>
        </div>

        <div class="card-body form-group mb-3 p-2 pb-0 {{getCodeBgColor()}}">

            <div *ngIf="getCodeBgColor()=='codeReadedSuccess' && mode=='aggregation'" class="alert alert-success text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ValidationSuccess')}}
            </div>

            <div *ngIf="getCodeBgColor()=='codeReadedSuccess' && mode=='remove'" class="alert alert-success text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'DefectSuccess')}}
            </div>

            <div *ngIf="getCodeBgColor()=='coreReadedFailed'" class="alert alert-danger text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'Error')}}
            </div>

            <div *ngIf="getCodeBgColor()!='coreReadedFailed' && getCodeBgColor()!='codeReadedSuccess'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanCode')}}
            </div>

            <!--StartNew-->
            <div *ngIf="!isDetalized" class="m-0">
                <div class="row align-items-center d-flex justify-content-center">
                    <div class="col">
                        <span class="btn-group2 btn-group-toggle btn-group-vertical" ngbRadioGroup name="radioBasic" [(ngModel)]="mode">
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 text-uppercase btn-agg rounded-bottom" [ngClass]="{'btn-success': mode=='aggregation', 'btn-info': mode!='aggregation'}">
                                <input id="Serialization_ValidationToggleAction" class="btn-check" ngbButton type="radio" value="aggregation">{{getlocalized('SerializationList', 'Validation')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 text-uppercase btn-agg rounded-bottom rounded-top" [ngClass]="{'btn-danger': mode=='remove', 'btn-info': mode!='remove'}">
                                <input id="Serialization_DefectToggleAction" class="btn-check" ngbButton type="radio" value="remove">{{getlocalized('SerializationList', 'Defect')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 text-uppercase btn-agg rounded-top" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                                <input id="Serialization_InfoToggleAction" class="btn-check" ngbButton type="radio" value="info">{{getlocalized('SerializationList', 'Information')}}
                            </label>
                        </span>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <div>
                            <span class="" style="font-size:170px; ">
                                {{page?.TotalElements}}
                            </span>
                        </div>
                    </div>
                    <div class="col align-self-start d-flex justify-content-center">
                        <div>
                            <span class="display-4">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="mb-3 pe-3 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductCode')}}</span>
                        <input id="codeInput" [disabled]="false" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="code">
                        <button id="codeButton"
                                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(code)" class="input-group-append btn btn- ms-3 btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right me-1 "></i><!--{{getlocalized('common', 'Save')}}-->
                        </button>
                    </div>
                </div>
            </div>
            <!--ENDNew-->
            <div>
                <ngx-datatable *ngIf="isReady && isDetalized" ngx-localizer class="bootstrap ngx-datatable"
                               id="someTable"
                               [rows]="page.Items"
                               [loadingIndicator]=""
                               [externalPaging]="true"
                               [externalSorting]="true"
                               [columnMode]="ColumnMode.force"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               rowHeight="auto"
                               [count]="page.TotalElements"
                               [limit]="page.Size"
                               [offset]="page.PageNumber"
                               (page)="onPage($event)"
                               (sort)="onSort($event)"
                               [rowClass]="getRowClass"
                               [reorderable]="reorderable">
                    <ngx-datatable-column name="{{getlocalized('CodeList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Code'" [fieldType]="'string'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            <code style="word-wrap:inherit">{{ value }}</code>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="{{getlocalized('CodeList', 'GroupType')}}" prop="GroupType" cellClass="custom-row" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'ProductType.GroupType'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'ProductGroupTypeDictionary'"
                                                          [fieldValues]="productTypes"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('ProductGroupTypeDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('CodeList', 'PrintingStatus')}}" prop="PrintingStatus" cellClass="custom-row" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'PrintingStatus'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'PrintStatusDictionary'"
                                                          [fieldValues]="printerStatuses"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('PrintStatusDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="{{getlocalized('CodeList', 'EntityStatus')}}" prop="EntityStatus" cellClass="custom-row" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'EntityStatus'" [fieldType]="'list'"
                                                          [fieldValuesLocaleKey]="'AggregationStatusDictionary'"
                                                          [fieldValues]="entityStatuses"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </div>
                        </ng-template>
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{getlocalized('AggregationStatusDictionary', value)}}
                        </ng-template>
                    </ngx-datatable-column>


                    <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                        <ng-template let-column="column" ngx-datatable-header-template>
                            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                <span>
                                    {{column.name}}
                                </span>
                            </div>
                            <div class="btn-group2">
                                <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadCodes()" class="btn btn-sm btn-info me-1 "><i class="fa fa-redo"></i></button>
                                <button id="Company_List_ResetAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                            </div>
                        </ng-template>
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <div *ngIf="isSquareVisible" class="bg-dark p-4 m-4 float-start" style="height: 2cm; width: 2cm;"></div>
    <div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
        <div class="card mt-0">
            <div class="card-body form-group mb-2 p-2 pb-0">
                <div class="row">
                    <div class="col-md-5 p-0">
                        <div class="container">
                            <div class="mb-3 border border-light rounded">
                                <div class="grid p-2 rounded border" style="border-width:4px!important;"
                                     [class.bg-secondary]="false"
                                     [class.border-success]="getBorder('success')"
                                     [class.border-danger]="getBorder('danger')"
                                     [class.border-dark]="getBorder('default')"
                                     [ngStyle]="{'grid-template-columns': 'repeat(' + viewModel.Capacity + ', 1fr)', 'grid-template-rows': 'repeat('+ 1 + ', 1fr)'}">
                                    <div *ngFor="let item of viewModel.Products"
                                         [class.bg-success]="item.IsOk"
                                         [class.bg-danger]="item.IsError"
                                         [class.bg-secondary]="item.IsEmpty"
                                         [class.active-cell]="item.IsActive">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-7 ps-0">
                        <div class="card-body form-group p-0 pb-0">
                            <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerComplete'" class="alert alert-success text-uppercase text-center pb-3 pt-3 h3" role="alert">
                                {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                            </div>
                            <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerNotComplete'" class="alert alert-warning text-uppercase text-center pb-3 pt-3 h3" role="alert">
                                {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                            </div>
                            <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerError'" class="alert alert-danger text-uppercase text-center pb-3 pt-3 h3" role="alert">
                                {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                            </div>
                            <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerEmpty'" class="alert alert-info text-uppercase text-center pb-3 pt-3 h3" role="alert">
                                {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                            </div>
                            <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='Unknown'" class="alert alert-danger text-uppercase text-center pb-3 pt-3 h3" role="alert">
                                {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                            </div>
                        </div>
                        <div class="input-group input-group-lg mb-2">
                            <button id="BranchSerialization_ClearLayerAction" class="btn btn-lg text-uppercase mt-1 btn-danger btn-block" (click)="clearLayer()">
                                {{getlocalized('common', 'ClearLayer')}}
                            </button>
                        </div>
                        <div *ngIf="getCellError()" class="mt-1 p-2 alert-danger h3 rounded">
                            {{getCellError()}}
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentCode!=undefined">
    <app-code-edit-component [code]="currentCode" [counter]="counter" [isActionAvailable]="true"></app-code-edit-component>
</div>
