import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';

@Injectable()
export class AspuFileService {

    constructor(private httpService: AspuHttpService) { }

    getCodesToJsonFile(): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('files/UploadProductCodes/json');
    } 

    getCodesToCsvFile(): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('files/UploadProductCodes/csv');
    }
    
    getCodesToTxtFile(): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('files/UploadProductCodes/txt');
    }
}
