import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuCode, AspuCodeFilter } from './code.model';
//import { IResult } from '../../../../models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';
import { AspuMessage } from "../message/message.model";
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';

@Injectable()
export class AspuCodeService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(filter: AspuCodeFilter): Observable<IResult<Array<AspuCode>>> {
        return this.httpService.post<IResult<Array<AspuCode>>>(filter, 'Codes');
    }

    getItemsV2(filter: FiltersContainer): Observable<IResult<Page<AspuCode>>> {
        return this.httpService.post<IResult<Page<AspuCode>>>(filter, 'Codes/v2');
    }

    getWeight(filter: FiltersContainer): Observable<IResult<number>> {
        return this.httpService.post<IResult<number>>(filter, 'Codes/v2/weight');
    }
}