<!--<button (click)="omsSync()" class="btn btn-sm btn-success"><i class="fa fa-redo me-1 "></i>{{getlocalized('common', 'SyncToOms')}}</button>-->

<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="text-uppercase header-badge ">{{getlocalized('common', 'BatchStart')}}</span>

            <!--<span class="faa-parent" style="animation: color-change-info 1.8s infinite">
                <i class="fas fa-dot-circle faa-burst animated"></i>
            </span>-->

            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            <button (click)="omsSync()" class="btn btn-lg btn-success float-end text-uppercase"><i class="fa fa-sync-alt me-1 "></i>{{getlocalized('common', 'SyncToOms')}}</button>
            <button id="FindReceiptsBtn" (click)="showModalEan(contentBatch)" class="btn btn-lg btn-info float-end text-uppercase me-2"><i class="fa-solid fa-magnifying-glass me-1"></i>{{getlocalized('common', 'FindReceipts')}}</button>
        </h3>
    </div>
    <div *ngIf="!hasBarcode()" class="card-body form-group mb-3 pb-0">
        <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                       id="someTable"
                       [rows]="page.Items"
                       [loadingIndicator]=""
                       [externalPaging]="true"
                       [externalSorting]="true"
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       (activate)="onActivate($event)"
                       [count]="page.TotalElements"
                       [limit]="page.Size"
                       [offset]="page.PageNumber"
                       (page)="onPage($event)"
                       (sort)="onSort($event)"
                       [rowClass]="getRowClass"
                       [selectionType]="SelectionType.single"
                       [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Name')}}" prop="Name" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">
                            {{column.name}}
                        </span>
                    </div>
                    <complex-filter-component [gridSort]="gridSort"
                                              [viewModel]="gridFilters"
                                              [fieldName]="'Name'"
                                              [fieldType]="'string'"
                                              (onClick)="gridFilters = $event"
                                              (onClose)="updateFilter($event)"
                                              [reset]="resetChild"
                                              (onSort)="onSort($event)"
                                              [isLarge]=true>
                    </complex-filter-component>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="150" [maxWidth]="150" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label">
                        <span>
                            <!--{{column.name}}-->
                        </span>
                    </div>
                    <div class="btn-group2 mt-2">
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="reload()" class="btn btn-sm btn-info me-1 "><i class="fa fa-redo"></i></button>
                        <button id="Receipt_List_ResetAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <img 
                         [ngbPopover]="currentImage" closePopoverOnOutsideClick
                                triggers="manual"
                                #p2="ngbPopover"
                                popoverClass="customPopover2 text-uppercase"
                                (click)="toggleWithGreeting(p2, row)"
                         style="max-height: 64px !important; width: auto !important;" *ngIf="hasImage(row.Id)" src="data:image/jpg;base64,{{getImageById(row.Id)}}" class="img-thumbnail rounded mr-1" />
                    <!--<div class="btn-group2 action-buttons">
        <button id="Receipt_List_EditAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editItem(row['Id'])" class="btn btn-sm btn-info"><i class="fa fa-edit"></i></button>-->
                    <!--<button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn-sm btn-danger"><i class="fa fa-trash-o"></i></button>-->
                    <!--</div>-->
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<div [ngSwitch]="selectedReceipt.Id">
    <div *ngSwitchCase="undefined">
    </div>
    <div *ngSwitchDefault>
        <app-batch-edit-component [receiptId]="selectedReceipt.Id" [initialBatchNumber]="batchNumber" [productTypes]="selectedReceipt.ProductTypes"></app-batch-edit-component>
    </div>
</div>
<div *ngIf="hasBarcode()">
    <app-batch-edit-component></app-batch-edit-component>
</div>


<ng-template #currentImage let-row="row">
    <div *ngIf="row!=undefined">
        <img style="max-height: 200px !important; width: auto !important;" src="data:image/jpg;base64,{{getImageById(row.Id)}}" class="img-thumbnail mx-auto d-block" />
    </div>
</ng-template>

<ng-template #contentBatch let-modal>
  <div class="modal-header p-0">
    <div class="col-lg-12">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          <span class="header-badge" style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common', 'FindReceipts')}}</span>
        </h4>
        <button type="button" class="btn btn-lg btn-info" aria-label="Close" (click)="closeModal()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="mb-3 col-lg-12 col-md-12">
            <div class="input-group input-group-lg">
              <span class="input-group-text text-uppercase">{{getlocalized('common', 'Code')}}</span>
              <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="eanCode">
              <button id="codeButton" *ngIf="eanCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="getReceiptsByEan(eanCode)" class="ms-2 input-group-append2 btn btn btn-success">
                <i class="fa fa-lg fa-arrow-circle-right me-1"></i>{{getlocalized('common', 'Save')}}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer pe-0 btn-group2 me-0">
            <button type="button" class="btn btn-lg btn-info me-2" aria-label="Close" (click)="closeModal()">{{getlocalized('common', 'Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!--<i class="fa fa-bars fa-3x"></i>
<i class="fa fa-bezier-curve fa-3x"></i>
<i class="fa fa-binoculars fa-3x"></i>
<i class="fa fa-boxes fa-3x"></i>
<i class="fa fa-box fa-3x"></i>
<i class="fa fa-box-open fa-3x"></i>
<i class="fa fa-check-double fa-3x"></i>
<i class="fa fa-circle-notch fa-3x"></i>
<i class="fa fa-city fa-3x"></i>
<i class="fa fa-coins fa-3x"></i>
<i class="fa fa-compress fa-3x"></i>
<i class="fa fa-compress-arrows-alt  fa-3x"></i>
<i class="fa fa-crosshairs fa-3x"></i>
<i class="fa fa-cubes fa-3x"></i>
<i class="fa fa-diagnoses fa-3x"></i>
<i class="fa fa-digital-tachograph fa-3x"></i>
<i class="fa fa-dolly fa-3x"></i>
<i class="fa fa-dolly-flatbed fa-3x"></i>
<i class="fa fa-expand fa-3x"></i>
<i class="fa fa-hockey-puck fa-3x"></i>
<i class="fa fa-industry fa-3x"></i>
<i class="fa fa-layer-group fa-3x"></i>
<i class="fa fa-link fa-3x"></i>
<i class="fa fa-memory fa-3x"></i>
<i class="fa fa-microchip fa-3x"></i>
<i class="fa fa-network-wired fa-3x"></i>
<i class="fa fa-object-group fa-3x"></i>
<i class="fa fa-outdent fa-3x"></i>
<i class="fa fa-pallet fa-3x"></i>
<i class="fa fa-people-carry fa-3x"></i>
<i class="fa fa-plug fa-3x"></i>
<i class="fa fa-project-diagram fa-3x"></i>
<i class="fa fa-quidditch fa-3x"></i>
<i class="fa fa-qrcode fa-3x"></i>
<i class="fa fa-random fa-3x"></i>
<i class="fa fa-route fa-3x"></i>
<i class="fa fa-screwdriver fa-3x"></i>
<i class="fa fa-server fa-3x"></i>
<i class="fa fa-shapes fa-3x"></i>
<i class="fa fa-shipping-fast fa-3x"></i>
<i class="fa fa-signal fa-3x"></i>
<i class="fa fa-sitemap fa-3x"></i>
<i class="fa fa-sliders-h fa-3x"></i>
<i class="fa fa-solar-panel fa-3x"></i>
<i class="fa fa-tachometer-alt fa-3x"></i>
<i class="fa fa-truck-loading fa-3x"></i>
<i class="fa fa-tv fa-3x"></i>
<i class="fa fa-user-cog fa-3x"></i>
<i class="fa fa-vote-yea fa-3x"></i>
<i class="fa-3x">
    <i class="fa-layers fa-fw">
        <i class="fa fa-microchip"></i>
        <span class="fa-layers-text fa-inverse " data-fa-transform="shrink-11.0 rotate--90" style="font-weight:900;">ПЛК</span>
    </i>
</i>
<i class="fa fa-wrench fa-3x"></i>


<span class="fa-stack ">
    <i class="fa fa-expand fa-stack-2x"></i>
    <i class="fa fa-list fa-stack-1x"></i>
</span>

<br />
<i class="fa-2x far fa-circle faa-burst animated"></i>
<br />

<span class="faa-parent" style="animation: color-change-info 1.8s infinite">
    <i class="fa-2x far fa-circle faa-burst animated"></i>
</span>

<div class="row">
    <div class="col-lg-1">
        <br /><i class="fa fa-bars-progress fa-3x"></i>
        <br /><i class="fa fa-bars-staggered fa-3x"></i>
        <br /><i class="fa fa-blender fa-3x"></i>
        <br /><i class="fa fa-border-none fa-3x"></i>
        <br /><i class="fa fa-border-all fa-3x"></i>
        <br /><i class="fa fa-boxes-stacked fa-3x"></i>
        <br /><i class="fa fa-box-tissue fa-3x"></i>
        <br /><i class="fa fa-cart-flatbed fa-3x"></i>
        <br /><i class="fa fa-cart-flatbed-suitcase fa-3x"></i>
        <br /><i class="fa fa-cash-register fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-chalkboard fa-3x"></i>
        <br /><i class="fa fa-chalkboard-user fa-3x"></i>
        <br /><i class="fa fa-check-to-slot fa-3x"></i>
        <br /><i class="fa fa-city fa-3x"></i>
        <br /><i class="fa fa-clipboard fa-3x"></i>
        <br /><i class="fa fa-clipboard-check fa-3x"></i>
        <br /><i class="fa fa-clipboard-list fa-3x"></i>
        <br /><i class="fa fa-code-fork fa-3x"></i>
        <br /><i class="fa fa-code-pull-request fa-3x"></i>
        <br /><i class="fa fa-coins fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-cube fa-3x"></i>
        <br /><i class="fa fa-cubes fa-3x"></i>
        <br /><i class="fa fa-database fa-3x"></i>
        <br /><i class="fa fa-desktop fa-3x"></i>
        <br /><i class="fa fa-diagram-next fa-3x"></i>
        <br /><i class="fa fa-diagram-predecessor fa-3x"></i>
        <br /><i class="fa fa-diagram-project fa-3x"></i>
        <br /><i class="fa fa-diagram-successor fa-3x"></i>
        <br /><i class="fa fa-dolly fa-3x"></i>
        <br /><i class="fa fa-elementor fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-eraser fa-3x"></i>
        <br /><i class="fa fa-ethernet fa-3x"></i>
        <br /><i class="fa fa-expand fa-3x"></i>
        <br /><i class="fa fa-file fa-3x"></i>
        <br /><i class="fa fa-gears fa-3x"></i>
        <br /><i class="fa fa-grip fa-3x"></i>
        <br /><i class="fa fa-grip-vertical fa-3x"></i>
        <br /><i class="fa fa-gripfire fa-3x"></i>
        <br /><i class="fa fa-hard-drive fa-3x"></i>
        <br /><i class="fa fa-hotjar fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-id-badge fa-3x"></i>
        <br /><i class="fa fa-id-card fa-3x"></i>
        <br /><i class="fa fa-image-portrait fa-3x"></i>
        <br /><i class="fa fa-industry fa-3x"></i>
        <br /><i class="fa fa-indent fa-3x"></i>
        <br /><i class="fa fa-laptop fa-3x"></i>
        <br /><i class="fa fa-laptop-code fa-3x"></i>
        <br /><i class="fa fa-layer-group fa-3x"></i>
        <br /><i class="fa fa-memory fa-3x"></i>
        <br /><i class="fa fa-microchip fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-minimize fa-3x"></i>
        <br /><i class="fa fa-mobile fa-3x"></i>
        <br /><i class="fa fa-mobile-button fa-3x"></i>
        <br /><i class="fa fa-network-wired fa-3x"></i>
        <br /><i class="fa fa-note-sticky fa-3x"></i>
        <br /><i class="fa fa-object-group fa-3x"></i>
        <br /><i class="fa fa-object-ungroup fa-3x"></i>
        <br /><i class="fa fa-outdend fa-3x"></i>
        <br /><i class="fa fa-pager fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-pallet fa-3x"></i>
        <br /><i class="fa fa-paste fa-3x"></i>
        <br /><i class="fa fa-people-carry-box fa-3x"></i>
        <br /><i class="fa fa-person-dots-from-line fa-3x"></i>
        <br /><i class="fa fa-power-off fa-3x"></i>
        <br /><i class="fa fa-print fa-3x"></i>
        <br /><i class="fa fa-qrcode fa-3x"></i>
        <br /><i class="fa fa-readme fa-3x"></i>
        <br /><i class="fa fa-rectangle-list fa-3x"></i>
        <br /><i class="fa fa-screwdriver fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-server fa-3x"></i>
        <br /><i class="fa fa-share-nodes fa-3x"></i>
        <br /><i class="fa fa-sitemap fa-3x"></i>
        <br /><i class="fa fa-sliders fa-3x"></i>
        <br /><i class="fa fa-table-cells fa-3x"></i>
        <br /><i class="fa fa-table-cells-large fa-3x"></i>
        <br /><i class="fa fa-table-list fa-3x"></i>
        <br /><i class="fa fa-tablet fa-3x"></i>
        <br /><i class="fa fa-tablet-button fa-3x"></i>
        <br /><i class="fa fa-tablet-android fa-3x"></i>
    </div>
    <div class="col-lg-1">
        <br /><i class="fa fa-tachograph-digital fa-3x"></i>
        <br /><i class="fa fa-timeline fa-3x"></i>
        <br /><i class="fa fa-window-restore fa-3x"></i>
        <br /><i class="fa fa- fa-3x"></i>
    </div>
</div>-->
