import { Component, OnInit} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { AspuBatchService } from './batch.service';
import { AspuBatchReceipt, ProductTypeImage } from './batch-receipt.model';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuReceiptInfo } from '../receipt/receipt-info.model';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { AspuReceiptService } from '../receipt/receipt.service';
import { debounceTime, distinct } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AspuIndicatorService } from '../../core/indicator/indicator.service';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-batch-receipt-component',
    templateUrl: './batch-receipt.component.html',
    providers: [AspuBatchService, AspuReceiptService, AspuIndicatorService]
})
export class AspuBatchReceiptComponent implements OnInit {
    //rows = new Array<AspuBatchReceipt>();
    page = new Page<AspuReceiptInfo>();
    batchNumber: string;
    eanCode: string;
    eanCodeWaitng: boolean;
    isReady = false;
    loading = true;
    searchChangeObserver;
    ColumnMode = ColumnMode;
    SelectionType = SelectionType;
    reorderable = true;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();
    selectedReceipt = new AspuReceiptInfo();
    modalReference: NgbModalRef;

    constructor(
        private serv: AspuBatchService,
        private b2serv: AspuReceiptService,
        private indServ: AspuIndicatorService,
        public toastService: ToastService,
        private modalService: NgbModal,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        //console.log('AspuBatchReceiptComponent initilizing')
        //this.router.events.subscribe((evt) => {
        //    window.scrollTo(0, 0)
        //});
        
        this.indServ.getIndicators().subscribe(
            (data) => {
                if (data.Value.Batch == "Disabled") {
                  this.page.Size = window["pageSize"];
                    this.page.PageNumber = 0;

                    let gridFilter = new GridFilter();
                    gridFilter.field = 'IsValid';
                    gridFilter.value = 'true';
                    gridFilter.operator = 'eq';
                    gridFilter.isHidden = true
                    this.gridFilters.push(gridFilter);
                    this.reload();
                    this.getBatchNumber();
                }
                else {
                    this.router.navigate(['/aspu-batch-process']);
                }
            },
            (error) => {
                console.error(error);
            });
    }

    ngOnInit() {
        window.addEventListener("DayChanged", this._doAction, false);
        window.addEventListener("OmsSyncJobFinished", this._doLoadItems, false);
      window.addEventListener('EnterCodeReceived', this._getReceipts, false);
    }

    ngOnDestroy() {
        window.removeEventListener('DayChanged', this._doAction);
        window.removeEventListener("OmsSyncJobFinished", this._doLoadItems, false);
      window.removeEventListener('EnterCodeReceived', this._getReceipts);
    }

    private _doAction = this.getBatchNumber.bind(this);
    private _doLoadItems = this.reload.bind(this);//this.loading.bind(this);
    private _getReceipts = this.getReceiptsPrevent.bind(this);

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.reload();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort = new GridSort();
        this.gridSort.dir = sort.dir;
        this.gridSort.field = sort.prop;
        this.reload();
    }

    updateFilter(event) {
        this.page.PageNumber = 0;
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
                //  .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    this.reload();
                });
        }
        this.searchChangeObserver.next(event);
    }


    private reload(): void {
        this.loading = true;
        this.b2serv.getItemsV2(this.getFilter()).subscribe((data: IResult<any>) => {
          if (data.IsSuccess) {
               // this.rows = data.Value.filter(x => x.IsValid);
                this.page = data.Value
                this.getImages()
                //this.page.Items = this.page.Items.filter(x => x.IsValid);
                
                this.isReady = true;
            }

                this.loading = false;
            },
            error => console.error(error));

        //this.serv.getReceipts().subscribe((data: IResult<any>) => {
        //    if (data.IsSuccess) {
        //        //this.rows = data.Value.filter(x => x.IsValid);
        //        this.page = data.Value
        //    }

        //    this.isReady = true;
        //    this.loading = false;
        //    },
        //    error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        return filter;
    }

    getBatchNumber() {
        this.serv.getBatchNumber().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.batchNumber = data.Value;
            }

            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    omsSync() {
        this.serv.startOmsSyncJob().subscribe((omsSyncData: IResult<any>) => {
            if (omsSyncData.IsSuccess) {
                console.log(omsSyncData)
                this.toastService.getToast('Синхронизация с OMS-сервером запущена', 'info');
            }
        });
    }
    
    getRowClass = (row: AspuBatchReceipt) => {
        return {
            'bg-danger': !row.IsValid,
            //'bg-dark text-danger': row.Type == "Critical" && row.ConfirmStatus === "NotConfirmed",
            //'bg-warning': row.Type == "Warning",
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus === "Confirmed",
        };
    }

    selected = [];
    onSelect({ selected }) {
        console.log('Select Event', selected, this.selected);
    }

    onActivate(event) {
        if (event.type == 'click') {
            console.log(event.row)
          this.selectedReceipt = event.row;
        }
    }

    public resetChild: Object;

    resetFilter() {
        this.resetChild = JSON.parse(JSON.stringify(this.gridFilters));
    }

    hasBarcode() {
        return localStorage.getItem("StartBatchByKm")=='true';
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    toggleWithGreeting(popover, row: AspuReceiptInfo) {
        if (popover.isOpen()) {
            popover.close();
        } else {
            popover.open({ row });
        }
    }

    images = new Array<ProductTypeImage>();
    getImages() {
        let allpts = this.page.Items.reduce((pn, u) => [...pn, ...u.ProductTypes], []);
        let res = Array.from(new Set(allpts.filter(f => f.GroupType == 'Product').map((item: any) => item.Id)))

        this.images = [];
        for (var i = 0; i < res.length; i++) {
            this.b2serv.getImage(res[i]).subscribe((data: IResult<ProductTypeImage>) => {
                if (data.IsSuccess && data.Value.Image != undefined) {
                    this.images.push(data.Value);
                }
            }, error => console.error(error));
        }
    }

    hasImage(receiptId: string) {
        let ptId = this.getPtId(receiptId);
        if (this.images.find(f => f.ProductTypeId == ptId))
            return true;
        return false;
    }

    getImageById(receiptId: string) {
        let ptId = this.getPtId(receiptId);
        return this.images.find(f => f.ProductTypeId == ptId).Image
    }

    getPtId(receiptId: string) {
        let allpts = this.page.Items.find(f => f.Id == receiptId);
        return allpts.ProductTypes.find(f => f.GroupType == 'Product').Id;
  }

  getReceiptsPrevent(data: any) {
    if (this.modalReference != undefined && this.modalService.hasOpenModals()) {
      this.getReceiptsByEan(data.detail)
    }
    else if (data.detail == 'ShowModalEanWindow') {
      document.getElementById('FindReceiptsBtn').click();
      }
  }

  showModalEan(parameters: any) {
    this.eanCode = undefined;
    this.modalReference = this.modalService.open(parameters, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
  }

  getReceiptsByEan(ean: string) {
    this.closeModal();
    this.serv.getReceiptsByEan(this.getFilter(), ean.trim()).subscribe((data: IResult<any>) => {
      if (data.IsSuccess) {
        this.page = data.Value
        this.getImages()
        this.isReady = true;

        if (this.page.Items.length == 1) {
          this.selectedReceipt = this.page.Items[0];
        }
      }
    },
      error => console.error(error));
  }

  closeModal() {
    this.modalReference.dismiss('Cross click');
    this.eanCode = undefined;
  }

}
