import { Component, OnInit } from '@angular/core';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
import { AspuConfigurationJson } from '../settings.model';
import { AspuSettingsService } from '../settings.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-configurationJson-component',
    templateUrl: './configurationJson.component.html',
    providers: [AspuSettingsService]
})

export class AspuConfigurationJsonComponent implements OnInit {
    viewModel: AspuConfigurationJson = new AspuConfigurationJson();
    loading = true;
    obj: any;

    constructor(private serv: AspuSettingsService, private location: Location) {
        this.reload();
    }  
    
    ngOnInit() {}

    locationBack() {
        this.location.back();
    }

    private reload(): void {
        this.loading = true;
        this.obj = null;
        this.props = [];

        this.serv.getConfigurationJson().subscribe((data: IResult<AspuConfigurationJson>) => {
        this.viewModel = data.Value;
        console.log(this.viewModel.Data)
        this.obj = this.getObj(JSON.parse(this.viewModel.Data));
            console.log(this.obj)
        this.loading = false;
        },
        error => console.error(error));
    }

    hasJsonStructure(str) {
        if (typeof str !== 'string') return false;
        try {
            const result = JSON.parse(str);
            const type = Object.prototype.toString.call(result);
            return type === '[object Object]'
                || type === '[object Array]';
        } catch (err) {
            return false;
        }
    }

    props = new Array<string>();
    getObj(param: any) {
        var obj = {};
        let val = param;
        for (var key in val) {
        if (val.hasOwnProperty(key)) {
            this.props.push(key);

            let value = val[key]
            let tp = typeof (value)
            console.log(key + ': ' + value + ' ==> ' + tp)
            if (tp == 'object' && value == null) {
            obj[key] = '';
            }
            else {
            obj[key] = value;
            }
        }
        }
        console.log('props after initialize');
        console.log(this.props);
        return obj;
    }

    getParams(val: any) {
        let items = [];

        for (var key in this.obj[val]) {
        items.push(key)
      }

        return items
    }

    getVariants(item: any, subItem: any) {
      let items = [];

      for (var key in this.obj[item][subItem]) {
        if (key == 'Variants') {
          for (var variant in this.obj[item][subItem][key]) {
            items.push(this.obj[item][subItem][key][variant]);
          }
          return items;
        }
      }
      return items;
    }

    getSubItemValue(item: any, subItem: any){
        return this.obj[item][subItem]
    }

    changeData(event) { }

    getType(s: string) {
        return typeof (this.obj[s]);
    }

    getSubType(item: any, subItem: any) {
        for (var key in this.obj[item][subItem]) {
          if (key == 'Selected') return 'enum';
        }

        return typeof(this.obj[item][subItem]);
    }

    isNew() {
        return this.viewModel == null ? false : this.viewModel.Id == null;
    }

    saveItem() {
        this.loading = true;
        this.viewModel.Data = JSON.stringify(this.obj);
        console.log('this.viewModel.Value')
        console.log(this.viewModel.Data)

        this.serv.setConfigurationJson(this.viewModel).subscribe(data => {
            if (data.IsSuccess) {
                this.reload();
            }
            this.loading = false;
        });
        this.reload();
    }
    
    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
