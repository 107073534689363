<!--<body>-->
<div *ngIf="configuration.isLoaded" class="{{getTheme()}}">
    <!--<app-messages></app-messages>-->
    <page-header></page-header>

    <app-nav-menu></app-nav-menu>
    <main class="content-wrapper" [ngClass]="{'content-wrapper-expanded': isNavExpand, 'content-wrapper-collapsed': !isNavExpand}" >
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </main>

    <page-footer></page-footer>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
