import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuBatchReceipt } from './batch-receipt.model';
import { AspuBatchHistory } from './batch-history/batch-history.model';
import { AspuBatchState } from './batch-state.model';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';

import { AspuHttpService } from '../../services/aspu.http.service';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
import { Page } from 'vekas_models/models/page';
import { AspuInitialFields } from '../../core/common/common.model';

@Injectable()
export class AspuBatchService {

    constructor(
        private httpService: AspuHttpService) { }

    getReceipts(): Observable<IResult<AspuBatchReceipt>> {
        return this.httpService.get<IResult<AspuBatchReceipt>>('Receipts/batch', null);
    }

    getBatchHistory() {
        return this.httpService.get<IResult<Array<AspuBatchHistory>>>('Management/BatchHistory');
    }

    getBatchHistoryV2(filter: FiltersContainer): Observable<IResult<Page<AspuBatchHistory>>> {
        return this.httpService.post<IResult<Page<AspuBatchHistory>>>(filter, 'Management/V2/BatchHistory');
    }

    getBatchStatuses(): Observable<IResult<AspuBatchState>> {
        return this.httpService.get<IResult<AspuBatchState>>('Management/batch');
    }

    getBatchNumber() {
        return this.httpService.get<IResult<string>>('Management/InitialBatchName');
    }

    startOmsSyncJob(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'Jobs/OmsSync');
    }

  getReceiptsByEan(filter: FiltersContainer, ean: string): Observable<IResult<Array<AspuBatchHistory>>> {
    return this.httpService.post<IResult<Array<AspuBatchHistory>>>(filter, 'Receipts/GetReceiptsByGtin/' + ean);

  }
}
