export class AspuPrinter {
  Host: string;
  Port: number;
  Id: string;
  Name: string;
  DriverId: string;
  DriverName: string;
  MaxBufferSize: number;
  MinBufferSize: number;
  Limit: number;
  discriminator: string;
  Templates: Array<string>;
  CodeWritingDelay: number;
  Encoding: string;
  AcceptableWarnings: string;
  CabAllowableErrors: string;
  Path: string;
  Defect: boolean;
  CheckIdleState: boolean;
  PrintHeadersEnabled: Array<boolean>;
  Receiver: string;
  Sender: string;
  Period: number;
  RequestUri: string;
  BufferEmptyFinalizeTrigger: boolean;
  MaxFreeMemoryPercent: number;
  FtpPath: string;
  FtpLogin: string;
  FtpPassword: string;
  FtpTimeout: number;
  LoadCodesTriggerSource: number;

  Brain2LicenseEnable: boolean;
  Brain2ServiceHost: string;
  Brain2ServiceLogin: string;
  Brain2ServicePassword: string;
  Brain2Timeout: number;
  Brain2IterationCount: number;
  Brain2IterationPause: number;

  ConnectBrainLicenseEnable: boolean;
  ConnectBrainHost: string;
  ConnectBrainDeviceName: string;
  ConnectBrainTimeout: number;
  ConnectBrainIterationCount: number;
  ConnectBrainIterationPause: number;

  NumberOfPrintedJobPerSecond: number;
  TemplatePath: string;
}

export class AspuDriver {
  DriverId: string;
  Name: string;
  Prototype: AspuPrinter;
}

export class AspuPrinterId {
  PrinterId: string;

  constructor(id: string) {
    this.PrinterId = id;
  }
}

export class AspuPrintHeadersEnabled {
  IsActive: boolean;

  constructor(isActive: boolean) {
    this.IsActive = isActive;
  }
}
