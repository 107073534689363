import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuCountersDto, AspuCounterRequestObj } from './counters.model';
import { AspuCountersService } from './counters.service';

@Component({
    selector: 'app-counters-list-component',
    templateUrl: './counters-list.component.html',
    providers: [AspuCountersService]
})
export class AspuCountersListComponent implements OnInit {
    id: string;

    constructor(private serv: AspuCountersService,
        private lservice: LangService,
        public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        window.addEventListener("CountersUpdated", this._doUpdateCounters, false);
        this.route.params.subscribe(params => {
            if (!params.id) {
                this.id = params.id;
                return;
            }
            this.id = params.id;
            this.loadItems();
        });
    }

    ngOnDestroy() {
        window.removeEventListener("CountersUpdated", this._doUpdateCounters);
    }

    private _doUpdateCounters = this.doUpdateCounters.bind(this);

    doUpdateCounters(data: any) {
        console.log("doUpdateCounters");
        console.log(data.detail);
        this.temp = data.detail;
        if (this.id == undefined || this.id == this.temp.BatchId) {
            let index = this.active - 1;
            let branch = this.temp.Branches[index];
            console.log("branch");
            console.log(branch);

            //this.counters.Branches[index] = branch;

            this.counters.Branches[index].Values.forEach((obj, i) => {
                obj.Value = branch.Values[i].Value;
            })

            //for (var i = 0; i < branch.Values.length; i++) {

            //}

            //this.active
            //this.counters = this.temp;
        }
    }

    active = 1;
    temp = new AspuCountersDto();
    counters = new AspuCountersDto();

    public loadItems() {
        this.serv.getCounters(this.id).subscribe((data: IResult<AspuCountersDto>) => {
            if (data.IsSuccess) {
                console.log("counters");
                console.log(data.Value);
                this.counters = data.Value;
            }
        }, error => console.error(error));
    }

  /*  setCounters(name: number) {
        let request = new AspuCounterRequestObj();
        request.Id = this.id;
        request.Name = name;
        this.serv.setCounters(request).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
        },
            error => console.error(error));
    }

    clearCounters(name: number) {
        let request = new AspuCounterRequestObj();
        request.Id = this.id;
        request.Name = name;
        this.serv.clearCounters(request).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
        },
            error => console.error(error));
    }
    */

    changeTab(event) {
        this.active = event.nextId;
        console.log(this.active)
        this.loadItems();
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
