import { AspuEntity } from "../box/aggregation-box.model";

export class BoxPalletStateDto {
    Pallet: AspuEntity;
    Boxes: Array<AspuEntity>;
    Products: Array<AspuEntity>;
    Status: string;
    IsClearAllAvailable: boolean;
    IsClearBoxAvailable: boolean;
    IsClearPalletAvailable: boolean;
    IsAllowCloseBoxNotFull: boolean;
    IsAllowClosePalletNotFull: boolean;
    IsCancelAvailable: boolean;
    BoxCapacity: number;
    PalletCapacity: number;
}