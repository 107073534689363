<div class="card mt-2">
    <div class="card-header">
      <h3>
        <span class="header-badge text-uppercase">{{getlocalized('common', 'Batches')}}</span>
        <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        <button (click)="omsSync()" class="btn btn-lg btn-success float-end text-uppercase"><i class="fa fa-sync-alt me-1 "></i>{{getlocalized('common', 'SyncToOms')}}</button>
        <button id="FindDraftsBtn" (click)="showModalEan(contentDraft)" class="btn btn-lg btn-info float-end text-uppercase me-2"><i class="fa-solid fa-magnifying-glass me-1"></i>{{getlocalized('common', 'FindDrafts')}}</button>
      </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
        <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                       #someTable
                       [rows]="page.Items"
                       [loadingIndicator]=""
                       [scrollbarH]="true"
                       [externalPaging]="true"
                       [externalSorting]="true"
                       [columnMode]="ColumnMode.force"
                       [count]="page.TotalElements"
                       [limit]="page.Size"
                       [offset]="page.PageNumber"
                       (page)="onPage($event)"
                       (sort)="onSort($event)"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       [rowClass]="getRowClass"
                       [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('common', 'ProductLine')}}" prop="ProductLineName" [sortable]="true" [minWidth]="150" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div>
                        <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            <span id="Len{{column.prop}}" class="{{column.prop}}">
                                <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                                {{column.name}}
                            </span>
                        </div>
                        <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'ProductLine.Name'" [fieldType]="'string'"
                                                  (onClick)="gridFilters = $event"
                                                  (onClose)="updateFilter($event)"
                                                  [reset]="resetChild"
                                                  (onSort)="onSort($event)"
                                                  [isLarge]=true>
                        </complex-filter-component>
                    </div>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span [ngSwitch]="row.Status">
                        <span *ngSwitchCase="'Draft'">
                            <i class="fa fa-play-circle fa-2x text-success me-2 "></i>
                        </span>
                        <span *ngSwitchCase="'Stopped'">
                            <i class="fa fa-stop-circle fa-2x text-warning fa me-2 "></i>
                        </span>
                        <span *ngSwitchDefault></span>
                    </span>
                    <span class="" style="position: relative; top:-5px;">{{ value }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('BatchList', 'BatchNumber')}}" prop="BatchNumber" [minWidth]="150" [sortable]="true" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div>
                        <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            <span id="Len{{column.prop}}" class="{{column.prop}}">

                                <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                                {{column.name}}
                            </span>
                        </div>
                        <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'BatchNumber'" [fieldType]="'string'"
                                                  (onClick)="gridFilters = $event"
                                                  (onClose)="updateFilter($event)"
                                                  [reset]="resetChild"
                                                  (onSort)="onSort($event)"
                                                  [isLarge]=true>
                        </complex-filter-component>
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('BatchList', 'InternalNumberERP')}}" prop="InternalNumberERP" [minWidth]="150" [sortable]="true" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">

                            <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                            {{column.name}}
                        </span>
                    </div>
                    <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'InternalNumberERP'" [fieldType]="'string'"
                                              (onClick)="gridFilters = $event"
                                              (onClose)="updateFilter($event)"
                                              [reset]="resetChild"
                                              (onSort)="onSort($event)"
                                              [isLarge]=true>
                    </complex-filter-component>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('BatchList', 'IdentificationCodesSource')}}" prop="IdentificationCodesSource" [sortable]="true" [minWidth]="140" [maxWidth]="190" cellClass="custom-row text-uppercase" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">

                            <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                            {{column.name}}
                        </span>
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{getlocalized('RequesterTypeDictionary', value)}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Name')}}" prop="ProductType.Name" resizeable="true" cellClass="custom-row text-uppercase" [minWidth]="180" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>

                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">

                            <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                            {{column.name}}
                        </span>
                    </div>
                    <linq-filter-component [viewModel]="gridFilters" [fieldName]="column.prop" [fieldType]="'string'"
                                           (onClick)="gridFilters = $event"
                                           (onClose)="updateFilter($event)"
                                           [reset]="resetChild"
                                           [linqOperators]="linqNameFilter"
                                           [caption]="column.prop"
                                           [isLarge]=true>
                    </linq-filter-component>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="{{getlocalized('common', 'Image')}}" resizeable="true" cellClass="text-uppercase" [minWidth]="180" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">

                            <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                            {{column.name}}
                        </span>
                    </div>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <!--{{ value }}-->
                    <img style="max-height: 64px !important; width: auto !important;" *ngIf="hasImage(row.ProductTypeId)" src="data:image/jpg;base64,{{getImageById(row.ProductTypeId)}}" class="img-thumbnail rounded mr-1" />
                </ng-template>
            </ngx-datatable-column>

            n>

            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'GTIN')}}" prop="ProductType.GTIN" resizeable="true" [minWidth]="180" [maxWidth]="180" cellClass="custom-row" [resizeable]="false" headerClass="hide-icon">
                <ng-template let-column="column" ngx-datatable-header-template>

                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        <span id="Len{{column.prop}}" class="{{column.prop}}">

                            <!--{{header.getColumnNameNormalized(column.prop, column.name)}}-->
                            {{column.name}}
                        </span>
                    </div>
                    <linq-filter-component [viewModel]="gridFilters" [fieldName]="column.prop" [fieldType]="'string'"
                                           (onClick)="gridFilters = $event"
                                           (onClose)="updateFilter($event)"
                                           [reset]="resetChild"
                                           [linqOperators]="linqGTINFilter"
                                           [caption]="column.prop"
                                           [isLarge]=true>
                    </linq-filter-component>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" [minWidth]="210" [maxWidth]="210" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label">
                        <span>
                            {{column.name}}
                        </span>
                    </div>
                    <div class="btn-group2">
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info me-2 "><i class="fa fa-redo"></i></button>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div class="btn-group2 action-buttons">
                        <button *ngIf="row['Status']==='Draft' && row['BatchNumber']!=undefined && isBatchDisabled()" [disabled]="isStarted" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'BatchStart')}}" (click)="startBatchWithParameters(row,contentBatch)" class="btn btn btn-success action-buttons me-1"><i class="fa fa-play"></i></button>
                        <button *ngIf="row['Status']==='Stopped' && row['BatchNumber']!=undefined && isBatchDisabled()" [disabled]="isStarted" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Continue')}}" (click)="batchContinue(row['Id'])" class="btn btn btn-warning action-buttons me-1"><i class="fa fa-play"></i></button>
                        <button container="body" [openDelay]="500" [closeDelay]="400"
                                class="btn btn btn-info me-1 "
                                [ngbPopover]="currentDraft" closePopoverOnOutsideClick
                                triggers="manual"
                                autoClose="outside"
                                #p2="ngbPopover"
                                popoverClass="customPopover2 text-uppercase"
                                (click)="getCodesByBatch(row.Id); toggleWithGreeting(p2, row)">
                            <i class="fa fa-info-circle"></i>
                        </button>
                        <button id="Draft_Edit_RemoveAction" [disabled]="isStarted" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn btn-danger action-buttons"><i class="fa fa-trash-alt"></i></button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>

    </div>
</div>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>

<ng-template #contentChange let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                    <span class="header-badge">{{getlocalized('common', 'ProductTypes')}}</span>
                    <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
                </h4>
                <button id="Draft_Edit_CloseDialogAction" type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-footer">
                    ss
                    <div class="btn-group">
                        <button type="button" class="btn btn-lg btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentBatch let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                    <span class="header-badge" style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common', 'BatchParameters')}}</span>
                    <span *ngIf="isStarted" class="ml-3"><app-spinner></app-spinner></span>
                </h4>
                <button type="button" class="btn btn-lg btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <app-meta-component [undisabledFields]="exceptions" [metaType]="'draft'" [batch]="defaultRow" [isAutoReloadEnabled]="true" (onDataEntered)="isApplyEnabled = $event;" (onUpdateData)="aspuBatchMetadata = $event"></app-meta-component>

                <div *ngIf="defaultRow!=undefined" class="form-row">
                    <div class="input-group input-group-lg mb-3 col-lg-12 col-md-12">
                        <span class="input-group-text label-edit-width">{{getlocalized('BatchList', 'IdentificationCodesSource')}}</span>
                        <select name="erpId"
                                id="erpId"
                                disabled="{{getRequesterTypesEnabled()?.length==1}}"
                                class="form-select form-control form-control-lg"
                                [(ngModel)]="defaultRow.IdentificationCodesSource">
                            <option *ngFor="let req of getRequesterTypesEnabled()" ng-selected="defaultRow.IdentificationCodesSource === req" [value]="req">{{getlocalized('RequesterTypeDictionary', req)}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer p-0 pt-2">
                    <div class="btn-group2">
                        <button type="button" class="btn btn-lg btn-info me-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                        <button [disabled]="isApplyEnabled || loading || isStarted" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveBatchParameters()" class="btn btn-lg btn-success float-end"><i class="fa fa-save mr-1"></i>{{getlocalized('common', 'Save')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #currentDraft let-row="row">
    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion">
        <ngb-panel title="{{getlocalized('common', 'Batch')}}">
            <ng-template ngbPanelContent>
                <p class="m-0"><b>{{getlocalized('BatchList', 'BatchNumber')}}:</b> {{row['BatchNumber']}}</p>
                <p class="m-0"><b>{{getlocalized('common', 'Status')}}:</b> {{getlocalized('BatchStatus', row['Status'])}} </p>
                <p class="m-0" *ngIf="row['VeterinaryCertificate']!=undefined"><b>{{getlocalized('BatchList', 'VeterinaryCertificate')}}:</b> {{row['VeterinaryCertificate']}} </p>
                <p class="m-0" *ngIf="row['IdentificationCodesSource']!=undefined"><b>{{getlocalized('BatchList', 'IdentificationCodesSource')}}:</b> {{getlocalized('RequesterTypeDictionary', row['IdentificationCodesSource'])}} </p>
                <p class="m-0" *ngIf="row['ProductionDatePlan']!=undefined"><b>{{getlocalized('BatchList', 'ProductionDatePlan')}}:</b> {{getDate(row['ProductionDatePlan']) | date: 'dd.MM.yyyy HH:mm'}}</p>
                <p class="m-0" *ngIf="row['ExpirationDatePlan']!=undefined"><b>{{getlocalized('BatchList', 'ExpirationDatePlan')}}:</b> {{getDate(row['ExpirationDatePlan']) | date: 'dd.MM.yyyy HH:mm'}}</p>
            </ng-template>
        </ngb-panel>
        <ngb-panel  title="{{getlocalized('common', 'ProductType')}}">
            <ng-template ngbPanelContent>
                <div *ngIf="summaryProductTypeForBatch != undefined">
                    <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'GroupType')}}:</b> {{getlocalized('ProductGroupTypeDictionary', summaryProductTypeForBatch.GroupType)}}</p>
                    <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'ProductTypeName')}}:</b> {{summaryProductTypeForBatch.ProductTypeName}}</p>
                    <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'Quantity')}}:</b> {{summaryProductTypeForBatch.Quantity}}</p>
                    <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'RequesterType')}}:</b> {{getlocalized('RequesterTypeDictionary', summaryProductTypeForBatch.ProductType.RequesterType)}}</p>
                    <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'IdentificationCodesSources')}}:</b> {{getRequesterSourcesSplitted(summaryProductTypeForBatch.ProductType.IdentificationCodesSources)}}</p>
                </div>
                <ngb-accordion *ngIf="summaryProductTypeForBatch == undefined" [closeOthers]="true" #acc="ngbAccordion">
                    <ngb-panel *ngIf="productForBatch != undefined" title="{{getlocalized('common', 'Product')}}">
                        <ng-template ngbPanelContent>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'GroupType')}}:</b> {{getlocalized('ProductGroupTypeDictionary', productForBatch.GroupType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'ProductTypeName')}}:</b> {{productForBatch.ProductTypeName}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'Quantity')}}:</b> {{productForBatch.Quantity}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'RequesterType')}}:</b> {{getlocalized('RequesterTypeDictionary', productForBatch.ProductType.RequesterType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'IdentificationCodesSources')}}:</b> {{getRequesterSourcesSplitted(productForBatch.ProductType.IdentificationCodesSources)}}</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngIf="boxForBatch != undefined" title="{{getlocalized('common', 'Box')}}">
                        <ng-template ngbPanelContent>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'GroupType')}}:</b> {{getlocalized('ProductGroupTypeDictionary', boxForBatch.GroupType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'ProductTypeName')}}:</b> {{boxForBatch.ProductTypeName}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'Quantity')}}:</b> {{boxForBatch.Quantity}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'RequesterType')}}:</b> {{getlocalized('RequesterTypeDictionary', boxForBatch.ProductType.RequesterType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'IdentificationCodesSources')}}:</b> {{getRequesterSourcesSplitted(boxForBatch.ProductType.IdentificationCodesSources)}}</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel *ngIf="palletForBatch != undefined" title="{{getlocalized('common', 'Pallet')}}">
                        <ng-template ngbPanelContent>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'GroupType')}}:</b> {{getlocalized('ProductGroupTypeDictionary', palletForBatch.GroupType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'ProductTypeName')}}:</b> {{palletForBatch.ProductTypeName}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'Quantity')}}:</b> {{palletForBatch.Quantity}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'RequesterType')}}:</b> {{getlocalized('RequesterTypeDictionary', palletForBatch.ProductType.RequesterType)}}</p>
                            <p class="m-0" *ngIf="true"><b>{{getlocalized('BatchList', 'IdentificationCodesSources')}}:</b> {{getRequesterSourcesSplitted(palletForBatch.ProductType.IdentificationCodesSources)}}</p>
                        </ng-template>
                    </ngb-panel>
                  </ngb-accordion>

                  <img style="max-height: 100px !important; width: auto !important;" *ngIf="image!=undefined" src="data:image/jpg;base64,{{image}}" class="img-thumbnail mx-auto d-block" />
              </ng-template>
        </ngb-panel>
    </ngb-accordion>
</ng-template>

<ng-template #contentDraft let-modal>
  <div class="modal-header p-0">
    <div class="col-lg-12">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          <span class="header-badge" style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common', 'FindDrafts')}}</span>
        </h4>
        <button type="button" class="btn btn-lg btn-info" aria-label="Close" (click)="closeModal()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="mb-3 col-lg-12 col-md-12">
            <div class="input-group input-group-lg">
              <span class="input-group-text text-uppercase">{{getlocalized('common', 'Code')}}</span>
              <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="eanCode">
              <button id="codeButton" *ngIf="eanCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="getDraftsByEan(eanCode)" class="ms-2 input-group-append2 btn btn btn-success">
                <i class="fa fa-lg fa-arrow-circle-right me-1"></i>{{getlocalized('common', 'Save')}}
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer pe-0 btn-group2 me-0">
          <button type="button" class="btn btn-lg btn-info me-2" aria-label="Close" (click)="closeModal()">{{getlocalized('common', 'Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

