<ng-template #contentDebug let-modal>
<div class="modal-header p-0">
    <div class="col-lg-12">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                <span class="header-badge">Дебаг функции</span>
            </h4>
            <button type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="card mt-2">
                <!--<div class="card-header">
                    <h3>
                        <span class="header-badge">{{getlocalized('CodeList', 'Current')}}</span>
                    </h3>
                </div>-->
                <div class="card-body form-group mb-3 pb-0">
                    <div class="">
                      <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>Общие</a>
                          <ng-template ngbNavContent>
                            <div class="card">
                              <div class="card-body form-group mb-0 p-2">
                                <!--<div class="row">-->
                                <div class="btn-group btn-group-lg">
                                  <button (click)="backup()" class="btn btn btn-info">Бекап</button>
                                  <button (click)="clearBatch()" class="btn btn btn-info">Сброс данных партии</button>
                                </div>

                                <!--</div>-->
                              </div>
                            </div>
                          </ng-template>
                        </li>

                        <li [ngbNavItem]="2">
                          <a ngbNavLink>Текущая выборка</a>
                          <ng-template ngbNavContent>
                            <div class="card">
                              <div class="card-header p-2">
                                <h3><span class="badge bg-danger">Изменить статус печати</span></h3>
                              </div>
                              <div class="card-body form-group mb-0 p-2">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="btn-group btn-group-lg">
                                      <button [disabled]="!viewModel.SetPrintNew" (click)="setPrintNew()" class="btn btn btn-info">Не напечатан</button>
                                      <button [disabled]="!viewModel.SetPrintInProgress" (click)="setPrintInProgress()" class="btn btn btn-info">В печати</button>
                                      <button [disabled]="!viewModel.SetPrintFinished" (click)="setPrintFinished()" class="btn btn btn-info">Напечатан</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header p-2">
                                <h3><span class="badge bg-danger">Забраковка</span></h3>
                              </div>
                              <div class="card-body form-group mb-0 p-2">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="btn-group btn-group-lg">
                                      <button [disabled]="!viewModel.SetDefect" (click)="setDefect()" class="btn btn btn-info">Установить забраковку</button>
                                      <button [disabled]="!viewModel.UnsetDefect" (click)="unsetDefect()" class="btn btn btn-info">Снять забраковку</button>
                                      <button (click)="setValidated()" class="btn btn btn-info">Валидировать</button>
                                      <button (click)="unsetValidated()" class="btn btn btn-info">Отменить валидацию</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="card">
                              <div class="card-header p-2">
                                <h3><span class="badge bg-danger">Агрегация</span></h3>
                              </div>
                              <div class="card-body form-group mb-0 p-2">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="btn-group btn-group-lg">
                                      <button [disabled]="!viewModel.DeleteAggr" (click)="deleteAggr()" class="btn btn btn-info">Расформировать</button>
                                      <button [disabled]="!viewModel.CreateAggr" (click)="createAggr()" class="btn btn btn-info">Сформировать</button>
                                    </div>
                                    <div class="form-check form-control-lg form-switch ms-0">
                                      <input class="form-check-input big-switch mt-0 me-2" type="checkbox" id="defaultCheck1" [(ngModel)]="allowGenerateCodes">
                                      <label class="form-check-label" for="defaultCheck1">Автогенерация кодов</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </li>

                        <li [ngbNavItem]="3">
                          <a ngbNavLink>Коды</a>
                          <ng-template ngbNavContent>
                            <div class="card">
                              <div class="card-header p-2">
                                <h3><span class="badge bg-danger">Добавить агрегаты</span></h3>
                              </div>
                              <div class="card-body form-group mb-0 p-2">
                                <div class="form-row">
                                  <div class="mb-3 col-lg-6 col-md-6">
                                    <div class="input-group input-group-lg">
                                      <span class="input-group-text">Количество</span>
                                      <input type="number" class="form-control" [(ngModel)]="count">
                                    </div>
                                  </div>
                                  <div class="form-check form-control-lg form-switch ms-0">
                                    <input class="form-check-input big-switch me-2" type="checkbox" id="defaultCheck1" [(ngModel)]="allowGenerateCodes">
                                    <label class="form-check-label" for="defaultCheck1">Автогенерация кодов</label>
                                  </div>
                                  <div class="btn-group btn-group-lg">
                                    <button (click)="addAggregate('Block')" [disabled]="!isTypeAvailable('Block')" class="btn btn btn-info">Блок</button>
                                    <button (click)="addAggregate('Box')" [disabled]="!isTypeAvailable('Box')" class="btn btn btn-info">Коробка</button>
                                    <button (click)="addAggregate('Pallet')" [disabled]="!isTypeAvailable('Pallet')" class="btn btn btn-info">Палета</button>
                                  </div>
                                </div>
                                <div class="form-row mt-2">
                                  <div class="input-group input-group-lg mt-2">
                                    <button class="btn btn-sm btn-secondary" (click)="getBoxCode()">Генерировать код короба</button>
                                    <input type="text" class="form-control" placeholder="" [(ngModel)]="customBoxCode">
                                  </div>
                                  <div class="input-group input-group-lg mt-2">
                                    <button class="btn btn-sm btn-secondary" (click)="getProductCode()">Генерировать код продукта</button>
                                    <input type="text" class="form-control" placeholder="" [(ngModel)]="customProductCode">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </li>

                        <li [ngbNavItem]="4">
                          <a ngbNavLink>Файлы</a>
                          <ng-template ngbNavContent>
                            <div class="card">
                              <div class="card-header p-2">
                                <h3><span class="badge bg-danger">Загрузить коды</span></h3>
                              </div>
                              <div class="card-body form-group mb-3 pb-0">
                                <angular-file-uploader class="custom-file-uploader p-0" style="margin:0!important"
                                                       id="FileCodesUploadForm"
                                                       [config]="uploadCodesConfig"
                                                       (ApiResponse)="CodesUpload($event)">
                                </angular-file-uploader>
                              </div>
                            </div>
                          </ng-template>
                        </li>
                      </ul>

                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <div class="btn-group btn-group-lg">
                    <button type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        {{getlocalized('common', 'Close')}}
                    </button>
                </div>
            </div>
        </div>
            </div>
        </div>
</ng-template>

<li class="nav-item" (window:keydown)="onKeyDown($event, contentDebug)"><!--<a class="nav-link a-click" (click)="open(contentDebug)" title="Debug"><i class="fa fa-adjust"></i></a>--></li>
