import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuBatchService } from "../batch.service";
import { AspuBatchHistory } from "./batch-history.model";
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-batch-history-list-component',
    templateUrl: './batch-history-list.component.html',
    providers: [AspuBatchService]
})
export class AspuBatchHistoryListComponent implements OnInit {
    page = new Page<AspuBatchHistory>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    stopReasonList: Array<string>;

    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();

    constructor(private serv: AspuBatchService, private lservice: LangService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        this.page.Size = 50; // window["pageSize"];
        this.page.PageNumber = 0;
        this.gridSort.dir = "desc";
        this.gridSort.field = "ProductionStopDate";
        this.loadItemsV2();
        this.stopReasonList = ["Stopped", "Finalized"];
    }

    //private loadItems() {
    //    this.loading = true;

    //    this.serv.getBatchHistory().subscribe((data: IResult<Page<AspuBatchHistory>>) => {
    //        if (data.IsSuccess) {
    //            this.page = data.Value;
    //            this.isReady = true;
    //        }
    //        this.loading = false;
    //    }, error => console.error(error));
    //}


  updateFilter(event) {
        this.page.PageNumber = 0;
        this.loadItemsV2();
    }

  onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadItemsV2();
    }

  setPage(event) {
      const val = event.newValue;
      this.page.PageNumber = 0;
      this.page.Size = val;

      if (!this.searchChangeObserver) {
          Observable.create(observer => {
              this.searchChangeObserver = observer;
          }).pipe(debounceTime(300))
              .pipe(distinctUntilChanged())
            .subscribe(() => {
                  this.loadItemsV2();
              });
      }
      this.searchChangeObserver.next(event);
  }

  onSort(event) {
        const sort = event.sorts[0];
        this.gridSort = new GridSort();
        this.gridSort.field = sort.prop;
        this.gridSort.dir = sort.dir;
        this.loadItemsV2();
  }

  public loadItemsV2() {
        this.loading = true;
        this.serv.getBatchHistoryV2(this.getFilter()).subscribe((data: IResult<Page<AspuBatchHistory>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;
                console.log(this.page)
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        return filter;
    }

    cleanFilter(field: string) {
        if (field != undefined) {
            this.gridFilters = this.gridFilters.filter(x => x.field != field);
        } else {
            this.gridFilters = new Array<GridFilter>();
        }
    }

    public resetChild: Object;

    resetFilter() {
      this.cleanFilter(undefined);
      this.resetChild = JSON.parse(JSON.stringify(this.gridFilters));
      }

    editItem(id: string): void {
        
        this.router.navigate(['../aspu-counters/' + id + '/editCard'], { relativeTo: this.route });
    }

    isWeightAvailable() {
        return this.page.Items.filter(x => x.Weight != undefined && x.Weight > 0).length > 0;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
