
<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-0">
        <!--<div class="card-header">
            <span id="codeInputSpan" style="display:none"></span>
            <div class="display-4">
                {{getlocalized('LayerBoxStatus', this.viewModel.Status)}}
            </div>
        </div>-->
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h4 ps-1 pe-1 text-uppercase">
                        {{getlocalized('common', 'AggregationBox')}}
                    </div>
                    <!--<span class="badge badge-info" *ngIf="viewModel.Products!=undefined">{{getRealCount()}}</span>-->
                </div>
                <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
        </div>
        <div class="card-body form-group mb-3 p-2 pb-0">
            <div *ngIf="true" class="alert alert-info text-center pb-3 pt-3 h3 text-uppercase" role="alert">
                {{getlocalized('LayerBoxStatus', this.viewModel.Status)}}
            </div>

            <div class="row">
                <div class="col-4 pe-0 text-uppercase">
                    <!-- <button id="LayerAggBox_ClearLayerAction" [disabled]="!viewModel.AllowClearLayer" class="btn btn-lg mt-4 btn-danger btn-block" (click)="clearLayer()">
                        {{getlocalized('common', 'ClearLayer')}}
                    </button> -->
                    <button id="LayerAggBox_ClearAllAction" [disabled]="!viewModel.AllowClearAll" class="btn btn-lg mt-4 btn-danger btn-block text-uppercase" (click)="clearAll()">
                        {{getlocalized('common', 'ClearAll')}}
                    </button>
                </div>

                <div class="col-3 p-0">
                    <div class="container">
                        <div *ngFor="let layer of viewModel.Layers; let i = index" id="{{ 'object-' + i }}" class="mb-1 border border-light rounded">
                            <div class="grid p-2 rounded border" style="border-width:4px!important;"
                                 [class.bg-secondary]="layer.IsActive"
                                 [class.border-success]="getBorder(layer, 'success')"
                                 [class.border-danger]="getBorder(layer, 'danger')"
                                 [class.border-dark]="getBorder(layer, 'default')"
                                 [ngStyle]="{'grid-template-columns': 'repeat(' + viewModel.LayerColsCount + ', 1fr)', 'grid-template-rows': 'repeat('+ viewModel.LayerRowsCount + ', 1fr)'}">
                                <div *ngFor="let item of layer.Items"
                                     [class.bg-success]="item.IsOk"
                                     [class.bg-danger]="item.IsError && !item.IsNoRead"
                                     [class.bg-warning]="item.IsNoRead"
                                     [class.active-cell]="item.IsActive">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5 ps-0">
                    <div class="mb-5">
                        <div class="input-group input-group-lg text-uppercase">
                            <span class="input-group-text">{{getlocalized('AggregationList', 'Code')}}</span>
                            <input id="codeInput" placeholder="Введите код" type="text" class="form-control" [(ngModel)]="currentCode">
                            <button id="codeButton" *ngIf="currentCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(currentCode)" class="input-group-append ms-3  btn btn btn-success">
                                <i class="fa fa-2x fa-arrow-circle-right"></i><!--{{getlocalized('common', 'Save')}}-->
                            </button>
                        </div>
                    </div>
                    <div *ngIf="getCellError()" class="mt-1 p-2 alert-danger h3 rounded">
                        {{getCellError()}}
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</div>
