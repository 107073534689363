
export class AspuBuffer {
    Id: string;
    ProfileId: string;
    PrinterId: string;
    PrinterName: string;
    Template: string;
    Templates: Array<any>;
    discriminator: string;
    IsPreInitialize: boolean;
    ClientId: string;
    ClientIp: string;
}


export class AspuClientIp {
    Id: string;
    Ip: string;

    constructor(id: string, ip: string) {
        this.Id = id;
        this.Ip = ip;
    }
}
