import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from "../../../core/indicator/indicator.service";
import { AspuCode, AspuCodeOnly } from "../../code/code.model";
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { Howl, Howler } from 'howler';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { AspuCodeService } from '../../code/code.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AspuCodeReservationService } from './code-reservation.service';
import { AspuDraft } from '../../draft/draft.model';
import { AspuBatch, AspuReserveCodeRequest } from './code-reservation.model';

@Component({
    selector: 'app-code-reservation-component',
    templateUrl: './code-reservation.component.html',
    providers: [AspuIndicatorService, AspuCodeReservationService, AspuCodeService],
    styles: ['.codeReadedSuccess { background-color: #A0FFA0; } .coreReadedFailed { background-color: #FF4040;}']
})
export class AspuCodeReservationComponent implements OnInit {
    isReady = true;
    loading = false;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    CodeAddedSuccess = '';

    page = new Array<AspuBatch>();
    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();

    soundScan: Howl;
    soundOk: Howl;
    soundError: Howl;
    indicator = new AspuIndicator();

    currentProductCode: string;

    constructor(private serv: AspuCodeReservationService, private cserv: AspuCodeService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {   
        this.loadItemsWithStateCheck();
      //  this.loadCodes();

        this.soundScan = new Howl({
            src: ['sound/scanit.wav'],

            onend: function () {
                console.log('SoundScan Finished!');
            },
            onloaderror: function () {
                console.log('SoundScan Error!');
            },
        });

        this.soundOk = new Howl({
            src: ['sound/success.wav'],
            onend: function () {
                console.log('soundOk Finished!');
            },
            onloaderror: function () {
                console.log('soundOk Error!');
            },
        });

        this.soundError = new Howl({
            src: ['sound/error.wav'],
            onend: function () {
                console.log('soundError Finished!');
            },
            onloaderror: function () {
                console.log('soundError Error!');
            },
        });

       // this.soundScan.play();
    }

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReloadEvent.bind(this);
    //private _doTryReload = this.doTryReload.bind(this);
    //private _doCodesReload = this.loadCodes.bind(this);
    
    doActionPrevent(data: any) {
        console.log("CodeReceived - prevent")
        this.doAction(data.detail)
    }

    doReloadEvent() {
        this.loading = true;
        this.loadItemsWithStateCheck();
    }
    
    ngOnInit() {
        this.preventDoubleCheck();
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
        window.addEventListener("AspuIndicatorUpdated", this._doReload, false);
        /*window.addEventListener("CodesModified", this._doTryReload, false);*/

        /*this.gridFilters.push(this.getGridFilter("Validated", "true", "eq", true));*/
        //if (this.indicator.Production == "Enabled")
        //        this.soundScan.play();
    }

    ngOnDestroy() {
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener('EnterCodeReceived', this._doAction);
        window.removeEventListener('AspuIndicatorUpdated', this._doReload);
        /*window.removeEventListener("CodesModified", this._doTryReload);*/
        clearInterval(this.timerId);
    }

    eventCounter = 0;
    timerId: any;

    doTryReload() {
        //console.log('doTryReload: ' + this.eventCounter);
        this.eventCounter++;
    }

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.eventCounter > 0) {
                this.eventCounter = 0;
            }
        }, 5000);
    }


    public reserveCodes(id: string) {
        let items = new AspuReserveCodeRequest();
        items.BatchIds = [];
        items.BatchIds.push(id);

        this.serv.reserveCodes(items).subscribe((data) => {
            if (data.IsSuccess) {
                this.page = [];
                 //this.toastService.getToast("Успешно перерезервировано!", 'info');
             }
        });
    }

    //public loadItems() {
    //    this.loading = false;
    //}

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) =>
        {
            console.log(this.indicator?.Production)
            console.log(data.Value.Production)
            if (this.indicator?.Production != data.Value.Production && data.Value.Production == "Enabled") {
                console.log('soundScan.play')
                this.soundScan.play();

            }

            this.indicator = data.Value;
            //if (data.IsSuccess && data.Value.Production == "Enabled")
            //{
            //}
            //else
            //{
            //}
                this.loading = false;
        });
    }

    code: string;
   
    doAction(code: string) {
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

                this.serialize(code.trim());
                this.code = undefined;
    }
          
    serialize(code: string) {
        this.loading = true;

        this.serv.findBatchesByCode(new AspuCodeOnly(code)).subscribe((data: IResult<Array<AspuBatch>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;
                if (data.Value.length > 0) {
                    this.soundOk.play();
                    this.CodeAddedSuccess = 'codeReadedSuccess';
                    this.toastService.getToast("Партия найдена", 'info');
                }
                else {
                    this.soundError.play();
                    this.CodeAddedSuccess = 'coreReadedFailed';
                    this.setTimetToCleanCodeAddedSuccess();
                    this.toastService.getToast("Партия не найдена (возможно в процессе финализации)", 'error');
                }
               // this.getToast("Код валидирован", 'info');
                //this.successAlert = true;
                //setTimeout(() => { this.successAlert = false}, 2000);
            }
            else {
                //this.toastService.getToast("Ошибка", 'error');
                /*this.failAlert = true;*/
                this.soundError.play();
                /*setTimeout(() => { this.failAlert = false }, 2000);*/

                this.CodeAddedSuccess = 'coreReadedFailed';
                this.setTimetToCleanCodeAddedSuccess();
            }
            this.loading = false;
            this.isReady = true;
            this.setTimetToCleanCodeAddedSuccess();
            },
            error => {
                console.error(error)
            });
    }

    //updateFilter(event) {
    //    this.page.PageNumber = 0;
    //    if (!this.searchChangeObserver) {
    //        Observable.create(observer => {
    //            this.searchChangeObserver = observer;
    //        }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
    //            // .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //            .subscribe(() => {
    //                this.loadCodes();
    //            });
    //    }
    //    this.searchChangeObserver.next(event);
    //}

    //onPage(event) {
    //    this.page.PageNumber = event.offset;
    //}

    //setPage(event) {
    //    const val = event.newValue;
    //    this.page.PageNumber = 0;
    //    this.page.Size = val;

    //    //if (!this.searchChangeObserver) {
    //    //    Observable.create(observer => {
    //    //        this.searchChangeObserver = observer;
    //    //    }).pipe(debounceTime(300))
    //    //        .pipe(distinctUntilChanged())
    //    //        .subscribe(() => {
    //    //            this.loadCodes();
    //    //        });
    //    //}
    //    this.searchChangeObserver.next(event);

    //    //const val = event.target.value;

    //    //this.loadItemsV2();
    //}

    //onSort(event) {
    //    const sort = event.sorts[0];
    //    this.gridSort.field = sort.prop;
    //    this.gridSort.dir = sort.dir;
    //}

    //public resetChild: Object;

    //resetFilter() {
    //    this.resetChild  = JSON.parse(JSON.stringify(this.gridFilters));
    //}

    //getGridFilter(field: string, val: any, operator: string, isHidden = false) {
    //    let gridFilter = new GridFilter();
    //    gridFilter.field = field;
    //    gridFilter.value = val;
    //    gridFilter.operator = operator;
    //    gridFilter.isHidden = isHidden
    //    return gridFilter;
    //}


    getCodeBgColor() {
        return this.CodeAddedSuccess; // ? 'codeReadedSuccess' : 'coreReadedFailed';
    }

    setTimetToCleanCodeAddedSuccess() {
        setTimeout(() => { this.CodeAddedSuccess = ''; }, 1000);

    }

    getRowClass = (row: AspuCode) => {
        return {
            //'bg-danger': row.Type == "Error",
            'bg-dark text-danger': row.EntityStatus == 'Defect',
            //'bg-warning': row.Type == "Warning",
            'bg-custom-validated': row.Validated && row.EntityStatus != 'Defect',
            'bg-custom-success': row.EntityStatus == 'FullEntity' && row.OmsUploaded == undefined,
            'bg-custom-accepted': row.OmsUploaded,
            'bg-custom-danger': row.EntityStatus == 'FullEntity' && row.OmsUploaded == false,
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
