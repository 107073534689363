import { Injectable } from '@angular/core';
import { AspuBatchStart, AspuBatchStartFromBarcode } from './batch-edit.model';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { Observable } from 'rxjs';
import { AspuBatchState } from '../batch-state.model';

@Injectable()
export class AspuBatchEditService {

    constructor(
        private httpService: AspuHttpService) { }

    startBatch(item: AspuBatchStart) {
        return this.httpService.put<IResult<any>>(JSON.stringify(item), 'Management/batch');
    }

    startBatchFromBarcode(item: AspuBatchStartFromBarcode) {
        return this.httpService.put<IResult<any>>(JSON.stringify(item), 'Management/batch');
    }

    getBatchStatuses(): Observable<IResult<AspuBatchState>> {
        return this.httpService.get<IResult<AspuBatchState>>('Management/batch');
    }
}