import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuProductType } from '../../common/receipt/receipt-detail.model';
import { AspuInitialFields, AspuMetaBase } from '../../core/common/common.model';
import { AspuCommonService } from './common.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { type } from 'os';

@Component({
    selector: 'app-meta-component',
    templateUrl: './meta.component.html',
    providers: [AspuCommonService]
})
export class AspuMetaComponent implements OnInit {
    params: Array<AspuMetaBase>;
    loading = true;
    //errors = new Array<any>(new Object());

    @Input() isAutoReloadEnabled: boolean;
    @Input() isValidationEnabled: boolean;
    @Input() id: any;
    @Input() batch: any;
    @Input() metaType: string;
    @Input() startValues: any;
    @Input() undisabledFields = new Array<string>();
    @Input() errors = new Array<any>(new Object());

    @Output() onUpdateData = new EventEmitter<any>() //EventEmitter();
    @Output() onUpdateDataObject = new EventEmitter<any>() //EventEmitter();
    @Output() onDataEntered =  new EventEmitter<boolean>() 

    isLoaded = false;
    constructor(private serv: AspuCommonService, private lservice: LangService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
    }
    
    ngOnInit() {
        console.log('metComponent')
        let rawDataType = undefined;
        switch (this.metaType) {
            case "draft":
            case "batch":
                rawDataType = 'plcBatchMeta'
                break;
            case "receipt":
                rawDataType = 'ReceiptsVariables'
                break;
            case "printingFlow":
                rawDataType = 'plcPrintingMeta'
                break;
            case "appconfig":
                rawDataType = 'AppConfigVariables'
                break;
            default:
                rawDataType = '';
                break;
        }
                let raw = localStorage.getItem(rawDataType);
        if (raw != undefined) {
            console.log('this.raw')
            console.log(raw)

                this.params = JSON.parse(raw);
                console.log('this.params')
                console.log(this.params)
                //if (this.isAutoReloadEnabled)
            this.getMetadata(undefined);
        }
    }

    isFirstInitial = true;

    ngOnDestroy() { }

    searchChangeObserver;

    getParameters() {
        if (this.startValues != undefined && !this.isLoaded) {
            this.isLoaded = true;
            return this.startValues;
        }
        var obj = {};
        let val = this.params;

        for (var key in val) {
            if (val.hasOwnProperty(key)) {

                let some = val[key]
                for (var key2 in some) {
                    if (some.hasOwnProperty(key2)) {
                        if (key2 == 'Name') {
                            obj[some[key2]] = some['Value'];
                            if (this.params.filter(x => x.discriminator == 'DoubleVariable' && x.Name == some[key2])) {
                                if (some['Value'] != undefined) {
                                    let str = some['Value']?.toString();
                                    str = str.replace(',', '.');
                                    //console.log(str)
                                    if (str.slice(-1) == '.') {
                                        str = str + '0';
                                    }
                                    obj[some[key2]] = str;
                                }
                            }
                            else if (this.params.filter(x => x.discriminator == 'DateVariable' && x.Name == some[key2])) {
                                let str = some['Value']?.toString();
                                console.log('str')
                                console.log(str)
                                obj[some[key2]] = str;
                            }
                            //console.log(obj[some[key2]])
                        }
                    }
                }
            }
        }
        //console.log(obj);
        //console.log('params');
        //console.log(this.params);
        console.log('meta');
        console.log(obj);
        return obj;
    }

    isReady = false;

    getMetadata(event) {
        if (event == null && this.isReady)
            return;
        //console.log('getMetadataevent')
        //console.log(event)
        let delay = 500; //(this.metaType != "draft" && this.metaType != "batch") ? 500 : this.isReady ? 500 : 500;

        this.onDataEntered.emit(true);
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(delay)) // wait some ms after the last event before emitting last event
                // .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    this.isReady = true;
                    let meta = this.getParameters();
                    let payLoad = new AspuInitialFields('');
                    switch (this.metaType) {
                        case 'draft':
                            payLoad.SubjectType = 'Batch';
                            payLoad.Batch = this.batch;
                            break;
                        case 'batch':
                            payLoad.SubjectType = 'Batch';
                            payLoad.ReceiptId = this.id;
                            break;
                        case 'receipt':
                            payLoad.SubjectType = 'Receipt';
                            //TODO:
                            break;
                        case 'printingFlow':
                            payLoad.SubjectType = 'PrintingFlow';
                            payLoad.PriningFlowId = this.id;
                            break;
                        case 'settings':
                            //TODO:
                            payLoad.SubjectType = 'ClientConfig';
                            break;
                        case 'appconfig':
                            payLoad.SubjectType = 'AppConfig';
                            break;
                        default:
                            console.error('Не найден тип динамического поля')
                    }
                    payLoad.Values = meta;
                    this.serv.getInitialValues(payLoad).subscribe((data: IResult<Array<any>>) => {
                        if (data.IsSuccess) {
                            console.log("isFirstInitial")
                            console.log(this.isFirstInitial)
                            console.log('CATCHED')
                            console.log(this.params)
                            let result = data.Value;
                            for (var i = 0; i < this.params.length; i++) {
                                let item = this.params[i];
                                if (this.isFirstInitial || item.Name == 'ProductionDate' || item.Name == 'ExpirationDate') {
                                    let some = result[item.Name];
                                    console.log('typeof some')
                                    console.log(this.params[i].discriminator)
                                    //if (this.params[i].discriminator == 'DateVariable') {
                                    //    console.log('!!!')
                                    //    let dt = new Date(result[item.Name]);
                                    //    console.log(dt)

                                    //    this.params[i].Value = new Date(); //.toLocaleDateString('En-en');
                                    //}
                                    //else
                                    //if (this.isValidDate(some)) {
                                    //    this.params[i].Value = new Date(result[item.Name])
                                    //    console.log('Catched!');
                                    //    console.log(this.params[i].Value);
                                    //}
                                    //else
                                    //if (this.params)
                                    this.params[i].Value = result[item.Name]
                                }
                            }
                            this.isFirstInitial = false;

                            console.log(this.params);
                            this.onUpdateData.emit(result);
                            this.onUpdateDataObject.emit(this.params);
                            this.onDataEntered.emit(false);
                        }
                    });
                });
        }
        this.searchChangeObserver.next(event);
    }

    getVal(val) {
        console.log('val')
        console.log(val)
    }

    isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    getDate(d: string) {
        if (d == undefined || d == "") {
            return undefined;
        }
        let date = new Date(d);
        return date;
    }

    changeData(event) {
        if (event == null)
            return;
        console.log('event');
        console.log(event);
        this.onDataEntered.emit(true);
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
                // .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    let meta = this.getParameters();                    
                    //console.log(this.params);
                    this.onUpdateData.emit(meta);
                    this.onUpdateDataObject.emit(this.params);
                    this.onDataEntered.emit(false);
                  
                });
        }
        this.searchChangeObserver.next(event);

    }

    isFieldDisabled(item: any) {
        return this.undisabledFields.filter(x => x == item.Name)[0] != undefined;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
