import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from "../../../services/aspu.http.service";
import { AspuCode, AspuCodeOnly, AspuServiceCodeOnly, AspuWeightOnly } from "../../code/code.model";

@Injectable()
export class AspuSerializationService {

    url = "AggrAlgorithms/Serialization";

    constructor(private httpService: AspuHttpService) { }

    serialize(item: AspuCode) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), this.url);
    }

    delete(item: AspuCodeOnly) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), this.url + '/delete');
    }

    plcQueueClear() {
        return this.httpService.post<IResult<any>>(null, this.url + '/plc/queue/clear');
    }

    getWeightEnable() {
        return this.httpService.get<IResult<number>>('Weighting/enable');
    }

    weight(item: AspuWeightOnly) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Weighting');
    }

    getServiceCodeEnable() {
        return this.httpService.get<IResult<boolean>>(this.url + '/servicecode/enable');
    }

    serviceCode(item: AspuServiceCodeOnly) {
        return this.httpService.post<IResult<string>>(JSON.stringify(item), this.url + '/servicecode');
    }

    getIndustry(): Observable<IResult<string>> {
        return this.httpService.get<IResult<string>>('Management/Industry');
    }
}
