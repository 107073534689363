import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./core/login/login.component";

import { AspuMessageListComponent } from "./business/common/message/message-list.component";
import { AspuBatchReceiptComponent } from "./business/common/batch/batch-receipt.component";
import { AspuBatchHistoryListComponent } from "./business/common/batch/batch-history/batch-history-list.component";
import { AspuReportListComponent } from "./business/common/report/report-list.component";
import { AspuReceiptListComponent } from "./business/common/receipt/receipt-list.component";
import { AspuPrinterListComponent } from "./business/common/printer/printer-list.component";
import { AspuPrinterEditComponent } from "./business/common/printer/printer-edit.component";
import { AspuProfileEditComponent } from "./business/common/profile/profile-edit.component";
import { AspuProfileListComponent } from "./business/common/profile/profile-list.component";
import { AspuBatchEditComponent } from "./business/common/batch/batch-edit/batch-edit.component";
import { AspuBatchProcessComponent } from "./business/common/batch/batch-process/batch-process.component";
import { AspuCodeListComponent } from "./business/common/code/code-list.component";
import { AspuCodeEditComponent } from "./business/common/code/code-edit/code-edit.component";
import { CodeInfoComponent } from "./business/common/code-status/code-info/code-info.component";
import { AspuIndicatorComponent } from "./business/core/indicator/indicator.component";
import { AspuSettingsComponent } from "./core/settings/settings.component";
import { AspuDebugComponent } from "./business/common/debug-functions/debug.component";
import { AspuAggregationBoxListComponent } from "./business/common/aggregation/box/aggregation-box-list.component";
import { AspuAggregationPalletListComponent } from "./business/common/aggregation/pallet/aggregation-pallet-list.component";
import { AspuSerializationComponent } from "./business/common/aggregation/serialization/serialization.component";
import { AspuBranchSerializationComponent } from "./business/common/aggregation/branch-serialization/branch-serialization.component";
import { AspuCodeStatusComponent } from "./business/common/code-status/code-status.component";
import { AspuDraftListComponent } from "./business/common/draft/draft-list.component";
import { AspuCustomPlcEditComponent } from "./business/common/plc/plc-edit.component";
import { AspuCustomPlcListComponent } from "./business/common/plc/plc-list.component";
import { HomeComponent } from "./home/home.component";
import { AspuAggregationBoxVisualComponent } from "./business/common/aggregation/box-visual/aggregation-box-visual.component";
import { AspuLayerSerializationComponent } from "./business/common/aggregation/layer-serialization/layer-serialization.component";
import { AspuLayer63313VisualComponent } from "./business/common/aggregation/layer63313-visual/layer63313-visual.component";
import { AspuReceiptDetailComponent } from "./business/common/receipt/receipt-detail.component";
import { AspuFileComponent } from "./business/common/file/file.component";
import { AspuCodeReservationComponent } from "./business/common/aggregation/code-reservation/code-reservation.component";
import { AspuCountersListComponent } from "./business/common/counters/counters-list.component";
import { AspuErrorMessageListComponent } from "./business/common/message/error-message-list.component";
import { AspuPlcTabsComponent } from "./business/common/plc/plc-edit-tabs.component";
import { AspuAggregationBoxPalletListComponent } from "./business/common/aggregation/box-pallet/aggregation-box-pallet-list.component";
import { AspuAggrBoxLayerTimestampComponent } from "./business/common/aggregation/box-layer-timestamp/aggregation-box-layer-timestamp.component";
import { AspuAggregationBoxQueueListComponent } from "./business/common/aggregation/box-queue/aggregation-box-queue-list.component";
import { AspuAggregationProductToPalletQueueListComponent } from "./business/common/aggregation/product-to-pallet-queue/aggregation-product-to-pallet-queue-list.component";

const defaultRoutePath = "aspu-receipt-list";
const rootRoutes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "login", component: LoginComponent },

  { path: "aspu-settings", component: AspuSettingsComponent },
  { path: "aspu-message-list", component: AspuMessageListComponent },
  { path: "aspu-error-message-list", component: AspuErrorMessageListComponent },
  { path: "aspu-batch-receipt", component: AspuBatchReceiptComponent },
  { path: "aspu-batch-history", component: AspuBatchHistoryListComponent },
  { path: "aspu-report-list", component: AspuReportListComponent },
  { path: "aspu-receipt-list", component: AspuReceiptListComponent },
  { path: "aspu-printer-list", component: AspuPrinterListComponent },
  { path: "aspu-printer-edit/new", component: AspuPrinterEditComponent },
  { path: "aspu-printer-edit/:id/editCard", component: AspuPrinterEditComponent },
  { path: "aspu-profile-edit/new", component: AspuProfileEditComponent },
  { path: "aspu-profile-edit/:id/editCard", component: AspuProfileEditComponent },
  { path: "aspu-profile-list", component: AspuProfileListComponent },
  { path: "aspu-batch-edit", component: AspuBatchEditComponent },
  { path: "aspu-batch-process", component: AspuBatchProcessComponent },
  { path: "aspu-code-list", component: AspuCodeListComponent },
  { path: "aspu-code-edit", component: AspuCodeEditComponent },
  { path: "aspu-code-info", component: CodeInfoComponent },
  { path: "aspu-plc-custom-edit", component: AspuCustomPlcEditComponent },
  { path: "aspu-plc-list", component: AspuCustomPlcListComponent },
  { path: "aspu-plc-edit-tabs", component: AspuPlcTabsComponent },
  { path: "aspu-counters/:id/editCard", component: AspuCountersListComponent },

  { path: "aspu-receipt-detail/:id/editCard", component: AspuReceiptDetailComponent },

  { path: "aspu-serialization", component: AspuSerializationComponent },
  { path: "aspu-branch-serialization", component: AspuBranchSerializationComponent },
  { path: "aspu-code-reservation", component: AspuCodeReservationComponent },
  { path: "aspu-code-change", component: AspuCodeStatusComponent },

  { path: "aspu-aggregation-box-list", component: AspuAggregationBoxListComponent },
  { path: "aspu-aggregation-box-visual", component: AspuAggregationBoxVisualComponent },
  { path: "aspu-aggregation-box-pallet-list", component: AspuAggregationBoxPalletListComponent },
  { path: "aspu-aggregation-box-queue-list", component: AspuAggregationBoxQueueListComponent },
  { path: "aspu-aggregation-pallet-list", component: AspuAggregationPalletListComponent },
  { path: "aspu-aggregation-product-to-pallet-queue-list", component: AspuAggregationProductToPalletQueueListComponent },
  { path: "aspu-aggregation-box-layer-timestamp", component: AspuAggrBoxLayerTimestampComponent },

  

  { path: "aspu-layer-serialization", component: AspuLayerSerializationComponent },
  { path: "aspu-layer63313-visual", component: AspuLayer63313VisualComponent },
  { path: "aspu-file-operation", component: AspuFileComponent },

  { path: "aspu-draft-list", component: AspuDraftListComponent },

  { path: "aspu-indicator", component: AspuIndicatorComponent },

  { path: "**", redirectTo: "/aspu-receipt-list" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
        rootRoutes,      
        { enableTracing: true, relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled'  }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  getDefaultRoutePath() {
    return defaultRoutePath;
  }
}
