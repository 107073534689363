import { Component, OnInit, ViewChild } from '@angular/core';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
import { AspuConfigurationJson } from '../settings.model';
import { AspuSettingsService } from '../settings.service';
import { Location } from '@angular/common';

import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'config-json-editor',
  templateUrl: './config-json-editor.component.html',
  styleUrls: ['./config-json-editor.component.css']
})
export class ConfigJsonEditorComponent implements OnInit {
  viewModel: AspuConfigurationJson = new AspuConfigurationJson();
  loading = true;
  obj: any;

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  constructor(private serv: AspuSettingsService, private location: Location) {
    this.reload();

    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }

  ngOnInit(): void {
  }

  private reload(): void {
    this.loading = true;
    this.obj = null;
    // this.props = [];

    this.serv.getConfigurationJson().subscribe((data: IResult<AspuConfigurationJson>) => {
      this.viewModel = data.Value;
      console.log(this.viewModel.Data)
      this.obj = this.getObj(JSON.parse(this.viewModel.Data));
      console.log(this.obj)
      this.loading = false;
    },
      error => console.error(error));
  }

  props = new Array<string>();
  getObj(param: any) {
    var obj = {};
    let val = param;
    for (var key in val) {
      if (val.hasOwnProperty(key)) {
        this.props.push(key);

        let value = val[key]
        let tp = typeof (value)
        console.log(key + ': ' + value + ' ==> ' + tp)
        if (tp == 'object' && value == null) {
          obj[key] = '';
        }
        else {
          obj[key] = value;
        }
      }
    }
    console.log('props after initialize');
    console.log(this.props);
    return obj;
  }

  saveItem() {
    this.loading = true;
    this.viewModel.Data = this.editor.getText();
    console.log('this.viewModel.Data')
    console.log(this.viewModel.Data)

    this.serv.setConfigurationJson(this.viewModel).subscribe(data => {
      if (data.IsSuccess) {
        this.reload();
      }
      this.loading = false;
    });
    this.reload();
  }

  getlocalized(type: string, name: string) {
    return LangService.getlocalized(type, name);
  }

}
