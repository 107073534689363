<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">
                <span *ngIf="bufferType=='PrintingFlow'">{{getlocalized('common', 'BufferGroupPrintContours')}}</span>
                <span *ngIf="bufferType=='PacketPrintingFlow'">{{getlocalized('common', 'BufferSinglePrintContours')}}</span>
            </span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            <!--<span class="badge badge-danger mt-1" style="float: right">{{getlocalized('common', 'Edition')}}</span>-->
        </h3>
    </div>
    <div class="">

        <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                       id="someTable{{profileId}}"
                       [rows]="buffers.Items"
                       [loadingIndicator]=""
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('PrinterList', 'Name')}}" prop="PrinterName" [sortable]="false" cellClass="custom-row text-uppercase" canAutoResize="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <div [ngSwitch]="isEdit(row['Id'])">
                        <div *ngSwitchCase="true">
                            <div class="">
                                <select name="bufferId"
                                        id="bufferId"
                                        class="form-select form-control form-control-lg"
                                        [(ngModel)]="printerFlow.PrinterId">
                                    <option *ngFor="let buffer of printers" [value]="buffer.Id">{{ buffer.Name}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngSwitchDefault>{{value}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="bufferType=='PacketPrintingFlow'" name="{{getlocalized('PrinterList', 'IsPreInitialize')}}" prop="IsPreInitialize" [sortable]="false" cellClass="" canAutoResize="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template cellClass="custom-row" let-rowIndex="rowIndex" let-row="row">
                    <div [ngSwitch]="isEdit(row['Id'])">
                        <div *ngSwitchCase="true">
                            <div class="input-group-prepend">
                                <input class="big-checkbox" type="checkbox" [(ngModel)]="printerFlow.IsPreInitialize" id="IsPreInitialize">
                            </div>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="text-success fa fa-2x fa-check" *ngIf="value"></i>
                            <i class="text-danger fa fa-2x fa-times" *ngIf="!value"></i>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf="bufferType=='PacketPrintingFlow'" name="{{getlocalized('PrinterList', 'ClientIp')}}" prop="ClientIp" [sortable]="false" cellClass="custom-row" canAutoResize="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <div [ngSwitch]="isEdit(row['Id'])">
                        <div *ngSwitchCase="true">
                            <div class="">
                                <select name="bufferId"
                                        id="bufferId"
                                        class="form-select form-control form-control-lg "
                                        [(ngModel)]="printerFlow.ClientId">
                                    <option [value]="undefined"></option>
                                    <option *ngFor="let client of clients" [value]="client.Id">{{client.Ip}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngSwitchDefault>{{value}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('PrinterList', 'Template')}}" prop="Template" [sortable]="false" cellClass="custom-row text-uppercase" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <div [ngSwitch]="isEdit(row['Id'])">
                        <div *ngSwitchCase="true">
                            <div *ngIf="!hasTemplate(row)" class="input-group ">
                                <input type="text" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="printerFlow.Template">
                                <app-validation [val]="printerFlow.Template" [name]="'Template'" [errors]="errors" [size]="'lg'"></app-validation>
                            </div>
                            <div *ngIf="hasTemplate(row)" class="">
                                <!--<input type="text" class="form-control form-control-sm" style="text-align:center;" [(ngModel)]="printerFlow.Template">-->

                                <select name="flowId"
                                        id="flowId"
                                        class="form-select form-control form-control-lg "
                                        [(ngModel)]="printerFlow.Template">
                                    <option *ngFor="let template of getTemplates(row)" [value]="template">{{ template}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngSwitchDefault>{{value}}</div>
                    </div>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="160" [maxWidth]="160" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                    <div class="btn-group2">
                        <button id="Profile_Edit_AddAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Create')}}" (click)="addItem()" class="btn btn-sm btn-info me-3"><i class="fa fa-plus"></i></button>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                    <div [ngSwitch]="isEdit(row['Id'])">
                        <div *ngSwitchCase="true">
                            <div class="btn-group2 action-buttons ">
                                <button id="Profile_Edit_SaveCurrentAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem(row)" class="btn btn btn-info me-2"><i class="fa fa-save"></i></button>
                                <button id="Profile_Edit_CancelCurrentAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Cancel')}}" (click)="cancel()" class="btn btn btn-danger"><i class="fa fa-ban"></i></button>
                            </div>
                        </div>
                        <div *ngSwitchDefault>
                            <div class="btn-group2 action-buttons">
                                <button id="Profile_Edit_EditCurrentAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editItem(row)" class="btn btn btn-info me-1 "><i class="fa fa-edit"></i></button>
                                <button id="Profile_Edit_RemoveCurrentAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn btn-danger me-1 "><i class="fa fa-trash-alt"></i></button>
                                <button *ngIf="bufferType=='PacketPrintingFlow'" id="Profile_Edit_PreInitializeAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'PreInitialize')}}" (click)="preInit(row.Id)" class="btn btn btn-info"><i class="fa fa-magic"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>
