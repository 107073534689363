import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuCodeOnly, AspuCodeParentChild } from '../../code/code.model';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import { AspuAggrBoxLayerTimestampService } from './aggregation-box-layer-timestamp.service';
import { AspuBoxAggrState, AspuBoxLayer } from './aggregation-box-layer-timestamp.model';
import { AspuCodeReplace } from '../../code/code-edit/code-edit.model';

@Component({
    selector: 'app-aggregation-box-layer-timestamp-component',
    templateUrl: './aggregation-box-layer-timestamp.component.html',
    styleUrls: ['./aggregation-box-layer-timestamp.scss'],
    providers: [AspuAggrBoxLayerTimestampService, AspuIndicatorService]
})
export class AspuAggrBoxLayerTimestampComponent implements OnInit {
    viewModel = new AspuBoxAggrState();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    currentCode: string;

    isHandleModeAvailable = false;
    isDetalized = false;

    currentProductCode: string;

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReload.bind(this);

    constructor(
        private serv: AspuAggrBoxLayerTimestampService, 
        public modalService: NgbModal, 
        public toastService: ToastService,
        private indServ: AspuIndicatorService
    ) {
        this.loadItemsWithStateCheck();
    }

    ngOnInit() {
        window.addEventListener("AggrStateUpdated", this._doReload, false);
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
        window.addEventListener("ShowPopupMessage", this._doShowPopup, false);
    }

    ngOnDestroy() {
        window.removeEventListener("AggrStateUpdated", this._doReload);
        window.removeEventListener("CodeReceived", this._doAction);
        window.removeEventListener("EnterCodeReceived", this._doAction);        
        window.removeEventListener("ShowPopupMessage", this._doShowPopup);
    }
    
    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    doAction(code: string) {
        console.log("doAction")
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }
        this.serv.pushCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                let result = data.Value;
                if (!result.IsOk) {
                    this.toastService.getToast(result.Message, 'error');
                }
            }
            this.isReady = true;
            this.loading = false;
            this.currentCode = undefined;
        },
        error => console.error(error));
    }

    doReload(event) {
        if (event.detail == "LayerBox") {
            this.loadItems();
        }
    }

    clearLayer() {
        console.log("Clear layer"); 
        this.serv.clearLayer().subscribe(() => {
            this.isReady = true;
            this.loading = false;
        },
        error => console.error(error));
    }

    clearAll() {
        this.loading = true;
        console.log("Clear layer"); 
        this.serv.clearAll().subscribe(() => {
            this.isReady = true;
            this.loading = false;
        },
        error => console.error(error));
    }

    public loadItems() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<AspuBoxAggrState>) => {
            console.log(data.Value);
            data.Value.Layers.reverse();
            this.viewModel = data.Value;
            this.isReady = true;
            this.loading = false;
        });
    }

    indicator = new AspuIndicator();

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) => {
            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled") {
                this.loadItems();
            }
            else {
                this.loading = false;
            }
        });
    }

    getBorder(layer: AspuBoxLayer, color: string) {
        let hasSuccess = layer.Items.filter(x => x.IsOk).length == layer.Items.length;
        let hasError = layer.Items.filter(x => x.IsError).length != 0;
        switch (color) {
            case 'success':
                return hasSuccess;
            case 'danger':
                return hasError;
            default:
                return !(hasError || hasSuccess);
        }
    }

    getCellError() {
        for (var i = 0; i < this.viewModel.Layers.length; i++) {
            let layer = this.viewModel.Layers[i];
            if (layer.CellError != undefined)
                return layer.CellError;
        }
        return undefined;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    modalReference: any;
    popupMessage: string;
    popupMessageStatus: boolean;

    private _doShowPopup = this.doShowPopup.bind(this);

    doShowPopup(data: any) {
        console.log(data);
        let result = data.detail;
        this.openPopup();
        this.popupMessage = result.topic;
        this.popupMessageStatus = result.data;
        setTimeout(() => { this.modalService.dismissAll() }, 1000);
    }

    @ViewChild('contentPopup', { static: true }) viewMePopup?: TemplateRef<any>; //ElementRef<HTMLElement>;
    openPopup() {
        this.modalReference = this.modalService.open(this.viewMePopup, { centered: true, scrollable: false, windowClass: 'custom-modal-class', size: 'xl' });
    }


    actionCode: string;
    currentAction: string; // Add, Remove, ReplaceChild, ReplaceCode
    secondModal: Promise<void>;
    secondModalReference: NgbModalRef;    
    replaceItem = new AspuCodeReplace(undefined, undefined);

    @Output() onChange = new EventEmitter();
    @ViewChild('contentSecondModal', { static: true }) viewMe2?: TemplateRef<any>;

    setAction(mode: string) {
        this.currentAction = mode;
        this.openSecondModal();
        this.actionCode = undefined;
        this.onChange.emit(true);
    }

    openSecondModal() {
        this.secondModalReference = this.modalService.open(this.viewMe2, { scrollable: false, windowClass: 'custom-modal-class' });
        this.secondModalReference
            .result.then(() => {}, () => {
                this.currentAction = undefined;
                this.replaceItem = new AspuCodeReplace(undefined, undefined);
                this.onChange.emit(false);
            });
        this.actionCode = undefined;
    }

    doSmth(code: string) {
        if (this.viewModel.BoxCode == undefined) return

        switch (this.currentAction) {
            case "Add":
                 this.addChild(code);
                 break;
            case "Remove":
                this.deleteChild(code);
                break;
            case "ReplaceCode":
                this.replaceBoxCode(code)
                break;
            case "ReplaceChild":
                this.replaceChild(this.replaceItem.Code, this.replaceItem.NewCode)
                break;
            default:
                break;
        }
        this.currentAction = undefined;
        this.replaceItem = new AspuCodeReplace(undefined, undefined);
        this.onChange.emit(false);
    }

    addChild(code: string) {
        this.loading = true;
        this.serv.addToAggregate(new AspuCodeParentChild(this.viewModel.BoxCode.Code, code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.updateState(this.viewModel.BoxCode.Code);
                this.toastService.getToast("Код успешно добавлен", "info");
            }
            this.loading = false;
        }, error => console.error(error));
    }

    deleteChild(code: string) {
        this.loading = false;
        this.serv.removeFromAggregate(new AspuCodeOnly(code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.updateState(this.viewModel.BoxCode.Code);
                this.toastService.getToast("Код успешно удален", "info");
            }
            this.loading = false;
        }, error => console.error(error));
    }

    replaceChild(oldCode:string, newCode: string) {
        let boxCode = this.viewModel.BoxCode.Code;
        let body = new AspuCodeReplace(oldCode, newCode);

        this.loading = true;
        this.serv.clearAll().subscribe(() => {
            this.serv.replaceChildAggregate(body).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.toastService.getToast("Код успешно изменен", "info");
                } else{
                    this.toastService.getToast("Ошибка замены кода", "error");
                }
                this.updateState(boxCode);
                this.loading = false;
            }, error => console.error(error));
        })
    }

    replaceBoxCode(code: string) {
        let boxCode = this.viewModel.BoxCode.Code;
        let body = new AspuCodeReplace(boxCode, code);

        this.loading = true;
        this.serv.clearAll().subscribe(() => {
            this.serv.replaceCodeAggregate(body).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.toastService.getToast("Код успешно изменен", "info");
                    this.updateState(code);
                } else{
                    this.toastService.getToast("Ошибка замены кода", "error");
                    this.updateState(boxCode);
                }                
                this.loading = false;
            }, error => console.error(error));
        }, error => console.error(error));
    }  

    destroyBox() {
        let boxCode = this.viewModel.BoxCode.Code;

        this.loading = true;
        this.serv.clearAll().subscribe(() => {
            this.serv.destroyAggregate(new AspuCodeOnly(boxCode)).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.toastService.getToast("Короб успешно расформирован", "info");
                } else {
                    this.toastService.getToast("Ошибка расформирования кода короба", "error");
                    this.updateState(boxCode);
                }
                this.loading = false;
            }, error => console.error(error));
        }, error => console.error(error));
    }
    
    showErrorToast(content: string, messages: string[]) {
        this.toastService.getToast(content, "error");
        messages.forEach((value: string) => {
            this.toastService.getToast(value, 'error');
        });
    }

    updateState(code: string) {
        this.loading = true;
        this.serv.updateStateByBoxCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Обновление данных выполнено успешно", 'info');
            }
            this.loading = false;
        }, error => console.error(error));
    }
}