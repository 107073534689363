<div *ngIf="!hasBarcode()" class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">
                {{getlocalized('common', 'Batch')}} <!--: {{viewModel.Name}}-->
                <button id="Batch_Edit_SaveAction" [disabled]="viewModel.Name=='' || isStarted || settings.UseOnlyDraftBatchEnable == true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'BatchStart')}}" (click)="checkPerformance(contentBatch)" class="btn btn-lg btn-success"><i class="fa fa-play me-1 "></i>{{getlocalized('common', 'BatchStart')}}</button>
            </span>
            <span *ngIf="loading || isBatchStarted" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            <!--<span>{{substatus}}</span>-->
            <!--<span class="badge badge-danger mt-2 float-end">
                {{getlocalized('common', 'Edition')}}
            </span>-->
        </h3>
    </div>

    <div class="card-body form-group mb-0 pb-0">
        <div class="row">
            <div class="mb-3 pe-3 col-lg-12 col-xl-12 col-xxl-6">
                <div class="input-group input-group-lg">
                    <span class="input-group-text text-uppercase">{{getlocalized('BatchList', 'BatchNumber')}}</span>
                    <input id="Batch_Edit_BatchNumberInput" [disabled]="settings.UseOnlyDraftBatchEnable == true" type="text" class="form-control" [(ngModel)]="viewModel.Name" name="Name" #Name="ngModel" required>
                    <button [hidden]="Name.valid || Name.untouched" class="btn btn-lg btn-danger" ngbPopover="{{getlocalized('common', 'FillField')}}" popoverClass="popup-error"><i class="fa fa-exclamation-triangle"></i></button>
                </div>
            </div>
            <!--<div class="mb-3 pe-3 col-lg-12 col-xl-12 col-xxl-6">
                <div *ngIf="image!=undefined" class="photo mb-2">
                    <img src="data:image/jpg;base64,{{image}}"
                         [ngClass]="{'custom-img-collapsed' : !isImageExpanded, 'custom-img-expanded' : isImageExpanded}"
                         class="img-fluid rounded mx-auto d-block"
                         (click)="isImageExpanded=!isImageExpanded" />
                </div>
            </div>-->
        </div>

        <div class="form-group pb-0">
            <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                           id="someTable"
                           [rows]="rows"
                           [loadingIndicator]=""
                           [scrollbarH]="true"
                           [columnMode]="ColumnMode.force"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           rowHeight="auto"
                           [reorderable]="reorderable">
                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Name')}}" prop="Name" [sortable]="false" cellClass="custom-row text-uppercase" [minWidth]="160" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'QuantityKm')}}" prop="QuantityKm" [sortable]="false" cellClass="custom-row" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'VendorCode')}}" prop="VendorCode" [sortable]="false" cellClass="custom-row ps-1 pe-1" [minWidth]="155" [maxWidth]="155" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'SelectedQuantityKm')}}" prop="SelectedQuantityKm" [sortable]="false" cellClass="custom-row" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <input *ngIf="true" type="number" class="form-control form-control-lg" [(ngModel)]="row.SelectedQuantityKm">
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'GroupType')}}" prop="GroupType" [sortable]="false" cellClass="custom-row text-uppercase" [minWidth]="90" [maxWidth]="110" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{getlocalized('ProductGroupTypeDictionary', value)}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'ProductTypeCodes')}}" prop="ProductTypeCodes" [sortable]="false" cellClass="custom-row" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'SelectedProductTypeCode')}}" prop="SelectedProductTypeCode" [sortable]="false" cellClass="custom-row" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div class="">
                            <select name="erpId"
                                    id="erpId"
                                    class="form-select form-control form-control-lg"
                                    [(ngModel)]="row.SelectedProductTypeCode">
                                <option *ngFor="let erpId of row.ProductTypeCodes" ng-selected="row.SelectedProductTypeCode===erpId" [value]="erpId">{{ erpId}}</option>
                            </select>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('ReceiptList', 'GTIN')}}" prop="Gtin" [sortable]="false" cellClass="custom-row ps-1 pe-1" [minWidth]="155" [maxWidth]="155" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                            {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
        <button id="Batch_Edit_BatchStartAction" [disabled]="viewModel.Name=='' || isStarted || settings.UseOnlyDraftBatchEnable == true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'BatchStart')}}" (click)="checkPerformance(contentBatch)" class="btn btn-lg btn-success mt-2 mb-2 float-end"><i class="fa fa-play me-1 "></i>{{getlocalized('common', 'BatchStart')}}</button>
    </div>
</div>

<div *ngIf="hasBarcode()" class="card mt-2">
    <div class="input-group input-group-lg mb-3 mt-3 ps-3 pe-3 col-xl-6 col-xxl-6">
        <span id="codeInputSpan" style="display:none"></span>
            <span class="input-group-text label-edit-width">{{getlocalized('AggregationList', 'ProductCode')}}</span>
        <input id="'codeInput'}}" placeholder="Введите код" type="text" class="form-control" [(ngModel)]="barcode">
        <button id="codeButton"
                *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="startBatchWithParameters(contentBatch)" class="input-group-append btn btn btn-success">
            <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
        </button>
    </div>
</div>

<!--<div class="input-group input-group-lg">
    <span class="input-group-text label-edit-width">{{getlocalized('ProfileList', 'Name')}}</span>
    <input id="Profile_Edit_NameInput" type="text" name="Name" class="form-control" [(ngModel)]="viewModel.Name">
    <app-validation [val]="viewModel.Name" [name]="'Name'" [errors]="errors" [size]="'lg'"></app-validation>
</div>-->

<ng-template #contentBatch let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                    <span class="header-badge" style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common', 'BatchParameters')}}</span>
                </h4>
                <button type="button" class="btn btn-lg btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <app-meta-component [metaType]="'batch'" [id]="viewModel.ReceiptId" [isAutoReloadEnabled]="true" (onDataEntered)="isApplyEnabled = $event"
                                    (onUpdateData)="aspuBatchMetadata = $event"></app-meta-component>

                <div class="form-row">
                    <div class="mb-3 col-lg-12 col-md-12">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text label-edit-width">{{getlocalized('BatchList', 'IdentificationCodesSource')}}</span>
                            <select name="erpId"
                                    id="erpId"
                                    disabled="{{getRequesterTypesEnabled().length==1}}"
                                    class="form-select form-control form-control-sm"
                                    [(ngModel)]="viewModel.IdentificationCodesSource">
                                <option *ngFor="let req of getRequesterTypesEnabled()" ng-selected="viewModel.IdentificationCodesSource === req" [value]="req">{{getlocalized('RequesterTypeDictionary', req)}}</option>
                            </select>
                        </div>
                        <!--<input id="Printer_Edit_NameInput" type="text" class="form-control" [(ngModel)]="viewModel.IdentificationCodesSource">-->
                        <!--<app-validation [val]="viewModel.IdentificationCodesSource" [name]="'IdentificationCodesSource'" [errors]="errors"></app-validation>-->
                    </div>
                </div>
                <!---->
                <div class="modal-footer pe-0">
                    <div class="btn-group2 me-0">
                        <button type="button" class="btn btn-lg btn-info me-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                        <button [disabled]="isApplyEnabled" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveBatchParameters()" class="btn btn-lg btn-success float-end"><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
                        <!--<button [openDelay]="1500" [closeDelay]="400" ngbTooltip="Заказать" (click)="additionalLoading()" class="btn btn-sm btn-success"><i class="fa fa-save me-1 "></i>Заказать</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [isDoubleConfirm]=true [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>