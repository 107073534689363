import * as signalR from "@microsoft/signalr";
import { AuthorizationService } from 'vekas_models/services/authorization.service';
import { Injectable } from "@angular/core";
import { IResult } from "vekas_models/models/iresult";
import { AspuJob } from "../business/core/models/aspu.job.model";

@Injectable({
    providedIn: 'root',
})

export class SignalRService {
    private static Connection: any;
    private static instance: SignalRService;
    public isLoaded: boolean = false;

    private constructor() {
        console.log("Start SignalRService");
    }

    public static getInstance(): SignalRService {
        if (!SignalRService.instance) {
            SignalRService.instance = new SignalRService();
            this.Connect();
            SignalRService.instance.isLoaded = true;
        }
        return SignalRService.instance;
    }

    private static Connect() {
        //window["aspuApiServiceHost"]
        let url = (<any>window).aspuApiServiceHost;
        console.log("Connect to url = " + url);
        const options: signalR.IHttpConnectionOptions = {
            transport: signalR.HttpTransportType.WebSockets,
            //accessTokenFactory: () => {
            //    return 
            //}
        };
        SignalRService.Connection = new signalR.HubConnectionBuilder()
            .withUrl(url + "/client", options)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 50000, 80000, 100000, 120000])
            .build();

        this.Connection.on("JobStateChanged", (data) => {
            console.log("JobStateChanged");
            this.addEvent("JobStateChanged", data);
        });

        this.Connection.on("BatchStateUpdated", (topic: any, data: any) => {
            console.log("BatchStateUpdated");
            this.addEvent("BatchStateUpdated", {topic, data});
        });

        this.Connection.on("IndicatorsUpdated", () => {
            this.addEvent("IndicatorsUpdated", undefined);
        });

        this.Connection.on("PackZoneStateUpdated", () => {
            this.addEvent("PackZoneStateUpdated", undefined);
        });

        this.Connection.on("AppConfigChanged", () => {
            this.addEvent("AppConfigChanged", undefined);
        });

        this.Connection.on("CodesModified", () => {

            this.addEvent("CodesModified", undefined);
        });

        this.Connection.on("AggrStateUpdated", (data) => {
            this.addEvent("AggrStateUpdated", data);
        });

        this.Connection.on("PackZoneStateUpdated", () => {
            this.addEvent("PackZoneStateUpdated", undefined);
        });
             

        this.Connection.on("ReceiptsUpdated", () => {
            this.addEvent("ReceiptsUpdated", undefined);
        });

        this.Connection.on("PrintingUpdated", () => {
            this.addEvent("PrintingUpdated", undefined);
        });


        this.Connection.on("BatchStartJob", (data: IResult<AspuJob>) => {
            this.addEvent("BatchStartJob", data);
        });

        this.Connection.on("OmsSyncJob", (data: IResult<AspuJob>) => {
            this.addEvent("OmsSyncJob", data);
        });

        this.Connection.on("RequestKmJob", (data: IResult<AspuJob>) => {
            this.addEvent("RequestKmJob", data);
        });    

        this.Connection.on("UpdateKmJob", (data: IResult<AspuJob>) => {
            this.addEvent("UpdateKmJob", data);
        }); 

        this.Connection.on("OmsSync", (data: IResult<AspuJob>) => {
            this.addEvent("OmsSync", data);
        });  
        
        this.Connection.on("ShowPopupMessage", (topic: any, data: any) => {
            this.addEvent("ShowPopupMessage", {topic, data});
        });

        //obsolete
        //this.Connection.on("SendCustom", (topic: any, data: AspuPlcStatus) => {
        //    //console.log("DATA");
        //    //console.log(topic);
        //    //console.log(data);
        //    this.addEvent("SendCustom", { data });
        //    console.log("SendCustom");
        //    console.log(data);
        //    //let c_event = new CustomEvent("BatchStartJob", { detail: data });
        //    //window.dispatchEvent(c_event);
        //});

        this.Connection.on("PlcStatusReceived", (data: any) => {
            this.addEvent("PlcStatusReceived", { data });
            //let c_event = new CustomEvent("BatchStartJob", { detail: data });
            //window.dispatchEvent(c_event);
        });        

        this.Connection.on("UnconfirmedMsgChanged", (data: any) => {
            this.addEvent("UnconfirmedMsgChanged", data);
        });

        this.Connection.on("SomeMessagePath", () => {
            this.addEvent("SomeMessagePath", undefined);
        });


        this.Connection.on("ShowError", (topic: any, data: any) => {
            this.addEvent("ShowError", data);
        }); 

        this.Connection.on("ShowInfo", (topic: any, data: any) => {
            this.addEvent("ShowInfo", data);
        }); 

        this.Connection.on("ShowSuccess", (topic: any, data: any) => {
            this.addEvent("ShowSuccess", data);
        }); 

        
        this.Connection.on("CodeReceived", (data: any) => {
            localStorage.setItem("isUserActive", 'true');
            this.addEvent("CodeReceived", data);
            this.addEvent("CodeReceivedForModal", data);
        });

        this.Connection.on("CountersUpdated", (topic: any, data: any) => {
            console.log("CountersUpdated");  
            console.log(topic);
            console.log(data);            
            this.addEvent("CountersUpdated", topic);
        }); 

        this.Connection.on("DayChanged", () => {
            this.addEvent("DayChanged", undefined);
        });

      this.Connection.on("EnterCodeReceived", (data: any) => {
        this.addEvent("EnterCodeReceived", data);
      });

      this.Connection.on("ProductionButtonOff", () => {
        this.addEvent("ProductionButtonOff", undefined);
      });

        this.Connection.start().catch(err => console.error(err))
            .finally(() => {
            });
    }

    private static addEvent(val: string, detail: any) {
        console.log(val);
        let c_event = new CustomEvent<any>(val, { detail: detail });
        window.dispatchEvent(c_event);
    }
}
