import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuCodeOnly, AspuCodeParentChild } from '../code.model';
import { AspuCodeInfo, AspuCodeReplace, AspuSingleCodeInfoItem } from './code-edit.model';
import { AspuHttpService } from '../../../services/aspu.http.service';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';

@Injectable()
export class AspuCodeEditService {

    constructor(
        private httpService: AspuHttpService) { }

    //getItems(): Observable<IResult<Array<AspuCode>>> {
    //    return this.httpService.post<IResult<Array<AspuCode>>>(null, 'Codes');
    //}
    //new AspuCodeOnly(code)
    getCodeInfo(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<AspuCodeInfo>>(JSON.stringify(item), 'Codes/getInfo');
    }

    setDefect(item: string): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/defect');
    }

    removeDefect(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/undefect');
    }

    setValidate(item: string): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/validate');
    }

    addToAggregate(item: AspuCodeParentChild): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/bind');
    }
    
    removeFromAggregate(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/unbind');
    }

    disbandStorage(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/destroy');
    }

    sendToOms(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/OmsSaveStorage');
    }

    replaceCode(item: AspuCodeReplace): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/replace/code');
    }

    replaceChild(item: AspuCodeReplace): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/replace/child');
    }

    changeServiceCode(item: AspuSingleCodeInfoItem): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/ServiceCode');
    }
}
