import { Component, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, EmbeddedViewRef, ViewContainerRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuCodeEditService } from './code-edit.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuCode, AspuCodeOnly, AspuCodeParentChild } from '../code.model';
import { AspuCodeInfo, AspuCodeReplace, AspuAvailableOperations, AspuSingleCodeInfoItem } from './code-edit.model';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';


@Component({
    selector: 'app-code-edit-component',
    templateUrl: './code-edit.component.html',
    providers: [AspuCodeEditService],
    host: {
        '[style.display]': '"flex"',
        '[style.flex-direction]': '"column"',
        '[style.overflow]': '"hidden"'
    }
})
export class AspuCodeEditComponent implements OnInit {
    //rows = new Array<AspuCode>();
    viewModel = new AspuCodeInfo();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    //@Input() modalReference: NgbModalRef;
     modalReference: NgbModalRef;
    secondModalReference: NgbModalRef;
    //@Input() modalService: NgbModal;
    @Input() code: AspuCode;
    @Input() counter: any;
    @Input() showParentInfo: boolean;
    @Output() onChange = new EventEmitter();

    //@Output() onClick = new EventEmitter();
    //@Input() contentLoads: any
    //productTypes = ["-", "Product", "Block", "Box", "Pallet"];
    //printerStatuses = ["NotPrinted", "Printing", "Printed"];
    //entityStatuses = ["None", "Defect", "Entity"];  //None - КМ без привязки// Entity - агрегирован


   // filter = new AspuMessageFilter();
    replaceItem = new AspuCodeReplace(undefined, undefined);

    constructor(private serv: AspuCodeEditService, private lservice: LangService,
        private modalService: NgbModal, private viewContainerRef: ViewContainerRef,
        public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.viewModel.AvailableOperations = new AspuAvailableOperations();
      //  this.filter.Limit = 50;
       // this.loadItems();
    }

    private _doReload = this.doReloadEvent.bind(this);
    private _doCatchCode = this.doCatchCode.bind(this);

    ngOnInit() {
        window.addEventListener("CodesModified", this._doReload, false);
        window.addEventListener("EnterCodeReceived", this._doCatchCode, false);
        window.addEventListener("CodeReceivedForModal", this._doCatchCode, false);
    }

    ngOnDestroy() {
        window.removeEventListener('CodesModified', this._doReload);
        window.removeEventListener("EnterCodeReceived", this._doCatchCode);
        window.removeEventListener('CodeReceivedForModal', this._doCatchCode);

    }

    doReloadEvent() {
        console.log('CodesModified');
         this.loadItems();
    }


    doCatchCode(val: any) {
        let code = val.detail;
        if (this.currentMode == 'ReplaceCode' || this.currentMode == 'ReplaceChild') {
            if (this.replaceItem.Code == undefined) {
                this.replaceItem.Code = code;
            } 
            else {
                this.replaceItem.NewCode = code;
                this.doSmth(undefined);
                if (this.secondModalReference != undefined)
                    this.secondModalReference.close();
            }
            return;
        }

        this.currentCode = code;

        //console.log('CODE');
        //console.log(code);
        //console.log(this.currentMode);

        this.doSmth(this.currentCode);
        this.currentCode = undefined;
        if (this.secondModalReference!=undefined)
            this.secondModalReference.close();
    }


    onSort(event) {}

    public loadItems() {
        this.loading = true;
        this.serv.getCodeInfo(new AspuCodeOnly(this.code.Code, this.showParentInfo)).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.viewModel = data.Value;
                    this.isReady = true;
                    console.log(this.viewModel);
                }
                    this.loading = false;
            }, error => console.error(error));

    }


    @ViewChild('contentLoads', { static: true }) viewMe?: TemplateRef<any>; //ElementRef<HTMLElement>;
    @ViewChild('contentSecondModal', { static: true }) viewMe2?: TemplateRef<any>;

    openLoad() {
        //this.newCodeToggle = false;
        //this.newCodeValue = undefined;
        this.modalReference = this.modalService.open(this.viewMe, { scrollable: false, windowClass: 'custom-modal-class', size: 'xl' });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            const cur = chng.currentValue;
            const prev = chng.previousValue;
            console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
            if (this.code != undefined && propName == "counter" && this.currentMode != 'ReplaceCode' && this.currentMode != 'ReplaceChild') {
                console.log('ngOnChanges')
                this.loadItems();
                this.modalService.dismissAll();
                this.openLoad();
            }
        }
    }

    setDefect() {
        this.loading = true;
        this.modalReference.close();  
        this.serv.setDefect(this.viewModel.Current.Code).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Метка брака установлена", "warning");
                this.loadItems();

            } else {
                this.toastService.getToast("Ошибка при установке метки брака", "danger");
                console.log(data);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    removeDefect() {
        this.loading = true;
        this.modalReference.close();  
        this.serv.removeDefect(new AspuCodeOnly(this.viewModel.Current.Code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Метка брака снята", "warning");
                this.loadItems();
            } else {
                this.toastService.getToast("Ошибка при снятии метки брака", "danger");
                console.log(data);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    setValidate() {
        this.loading = true;
        this.modalReference.close();  
        this.serv.setValidate(this.viewModel.Current.Code).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Признак валидности установлен", "info");
                this.loadItems();

            } else {
                this.toastService.getToast("Ошибка при установке признака валидности", "danger");
                console.log(data);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    disbandStorage() {
        this.loading = true;
        this.modalReference.close();  
        this.serv.disbandStorage(new AspuCodeOnly(this.viewModel.Current.Code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
                this.toastService.getToast(`${this.viewModel.Current.GroupType == "Box" ? "Короб успешно расформирован" : "Палета успешно расформирована"}`, "info");
                console.log(data);

            } else {
                data.Messages.forEach((val: string) => {
                    this.toastService.getToast(val, 'error');
                });
            }
            this.loading = false;
        }, error => console.error(error));
    }

    sendToOms() {
        this.loading = true;
        this.modalReference.close();  
        this.serv.sendToOms(new AspuCodeOnly(this.viewModel.Current.Code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.toastService.getToast("Запрос успешно отправлен на OMS-сервер", "info");
                this.loadItems();

            } else {
                console.log(data);
                this.toastService.getToast("Ошибка OMS-сервера", "danger");
            }
            this.loading = false;
        }, error => console.error(error));
    }

    //newCodeToggle = false;
    //newCodeValue: string;

    //replaceCode(code: string, newCode: string) {
    //    if (newCode != undefined) {
        
    //        this.replace(code, newCode);
    //    }
    //    else {
    //        if (this.newCodeToggle) {
    //            this.replace(code, newCode);
    //            this.newCodeToggle = false;
    //        }
    //        else {
    //            this.newCodeToggle = true;
    //        }
    //    }
    //}

    replaceCode() {
        this.loading = true;
        //if (newCode == undefined)
        //    newCode = null;
        // let dto = new AspuCodeReplace(code, newCode);
        this.serv.replaceCode(this.replaceItem).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();

            } else {
                console.log(data);
            }
            this.loading = false;
            //this.newCodeToggle = false;
            //this.newCodeValue = undefined;
        }, error => console.error(error));

        this.replaceItem = new AspuCodeReplace(undefined, undefined);
        this.onChange.emit(false);
    }

    replaceChild() {
        this.loading = true;
        this.serv.replaceChild(this.replaceItem).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            console.log(data);
            this.loading = false;
        }, error => console.error(error));
        this.replaceItem = new AspuCodeReplace(undefined, undefined);
        this.onChange.emit(false);
    }

    CheckServiceCode() {
      this.loading = true;

      if (this.currentCode == this.viewModel?.Current?.ServiceCode) {
        this.toastService.getToast("Сервисный код " + this.currentCode + " соответствует коду продукта " + this.viewModel?.Current?.Code, "info");
      }
      else {
        this.toastService.getToast("Сервисный код " + this.currentCode + " не соответствует коду продукта " + this.viewModel?.Current?.Code, 'error');
      }

      this.loading = false;
      this.onChange.emit(false);
    }

    ChangeServiceCode() {
        if (this.viewModel?.Current == undefined || this.currentCode == undefined) return;

        this.loading = true;
        var temp = new AspuSingleCodeInfoItem();
        temp.Code = this.viewModel.Current.Code;
        temp.GroupType = this.viewModel.Current.GroupType;
        temp.ServiceCode = this.currentCode;
        this.serv.changeServiceCode(temp).subscribe(data => {
            if (data.IsSuccess) {
                this.viewModel.Current.ServiceCode = this.currentCode;
                this.code.Code = this.currentCode;
                this.loadItems();
            }
        });
        this.loading = false;
        this.onChange.emit(false);
    }

    copyToClipboard(item) {
        navigator.clipboard.writeText(item).then().catch(e => console.error(e));
    }

    redirectToCode(item) {
        console.log('redirectToCode');
        this.loading = true;
        this.serv.getCodeInfo(new AspuCodeOnly(item, false)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.viewModel = data.Value;
                this.isReady = true;
                console.log(this.viewModel);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    removeItem(val) {
        let item = val == undefined ? this.viewModel.Current.Code : val;
            this.serv.removeFromAggregate(new AspuCodeOnly(item)).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.loadItems();

                } else {
                    console.log(data);
                }
                this.loading = false;
                //this.newCodeToggle = false;
                //this.newCodeValue = undefined;
            }, error => console.error(error));
    }

    openModal() {
        this.openSecondModal();
        this.currentCode = undefined;
        this.onChange.emit(true);
    }

    addItemDialog() {
        console.log('addItemDialog');
        this.currentMode = 'Add';
        this.openModal();
    }

    removeItemDialog() {
        console.log('removeItemDialog');
        this.currentMode = 'Remove';
        this.openModal();
    }

    replaceItemDialog(isChangeAgg = false) {
        console.log('replaceItemDialog');
        this.currentMode = isChangeAgg ? 'ReplaceChild' : 'ReplaceCode';
        this.openModal();
    }

    checkServiceCodeDialog() {
      console.log('checkServiceCodeDialog');
      this.currentMode = 'CheckServiceCode';
        this.openModal();
    }

    changeServiceCodeDialog() {
        console.log('changeServiceCodeDialog');
        this.currentMode = 'ChangeServiceCode';
        this.openModal();
    }

    dismiss() {
        console.log('DISMISS')
    }

    close() {
        console.log('CLOSE')

    }

    secondModal: Promise<void>;
    currentMode: string;
    currentCode: string;
    openSecondModal() {
        //this.modalService.open('contentSecondModal', { static: true });
        this.secondModalReference = this.modalService.open(this.viewMe2, { scrollable: false, windowClass: 'custom-modal-class', centered: true, size: 'lg' });
        this.secondModalReference
            .result.then((result) => {
                /*console.log('CLOSED with: ' + result);*/
            }, (reason) => {
                this.currentMode = undefined;
                this.replaceItem = new AspuCodeReplace(undefined, undefined);
                /*console.log('Dissmissed!')*/
                this.onChange.emit(false);
            });
        this.currentCode = undefined;
    }

    doSmth(code: string) {
        console.log('code');
        console.log(code);
        console.log(this.currentMode);
        switch (this.currentMode) {
            case "Add":
                this.serv.addToAggregate(new AspuCodeParentChild(this.viewModel.Current.Code, code)).subscribe((data: IResult<any>) => {
                    if (data.IsSuccess) {
                        this.loadItems();
                    } else {
                        console.log(data);
                    }
                    this.loading = false;
                }, error => console.error(error));
                break;
            case "Remove":
                this.removeItem(code);
                break;
            case "ReplaceCode":
                this.replaceCode()
                break;
            case "ReplaceChild":
                this.replaceChild()
                break;
            case "CheckServiceCode":
                this.CheckServiceCode()
                break;
            case "ChangeServiceCode":
                this.ChangeServiceCode()
                break;
            default:
                break;
        }
                 this.currentMode = undefined;

        //if (this.currentMode == 'Add') {
        //    this.serv.addToAggregate(new AspuCodeParentChild(this.viewModel.Current.Code, code)).subscribe((data: IResult<any>) => {
        //        if (data.IsSuccess) {
        //            this.loadItems();

        //        } else {
        //            console.log(data);
        //        }
        //        this.loading = false;
        //    }, error => console.error(error));
        //}
        //else if (this.currentMode == 'Remove') {
        //    this.removeItem(code);
        //}
        
    }


    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name, localStorage.getItem('industry'));
  }

  isActionAvailable() {
    return !(this.viewModel?.Current?.OmsUploaded == true);
  }
}
