import { Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuBuffer, AspuClientIp } from './buffer.model';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuBufferService } from './buffer.service';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters } from 'vekas_models/models/filtersContainer';
import { AspuPrinterService } from '../../printer/printer.service';
import { AspuPrinter } from '../../printer/printer.model';

@Component({
    selector: 'app-buffer-edit-component',
    templateUrl: './buffer-edit.component.html',
    providers: [AspuBufferService, AspuPrinterService]
})
export class AspuBufferEditComponent implements OnInit {
    @Input() profileId: string;
    @Input() bufferType: string; 
    @Input() groupType: string;

    buffers: Page<AspuBuffer>;
    printers: Array<AspuPrinter>;
    clients: Array<AspuClientIp>;

    gridFilters = new Array<GridFilter>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    id: string;

    groupTypes: string[];

    constructor(private serv: AspuBufferService, private pserv: AspuPrinterService, private lservice: LangService, public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        if (localStorage.getItem("token") != undefined) {
            this.groupTypes = JSON.parse(localStorage.getItem('GroupTypes'));
        }
    }

    ngOnInit() {
        this.gridFilters.push(this.getGridFilter('ProfileId', this.profileId, 'eq', true));
        this.gridFilters.push(this.getGridFilter('discriminator', this.bufferType, 'eq', true));

        this.loadPrinters();
        this.loadItems();

     }

    loadItems() {
        this.loading = true;
        this.serv.getItems(this.getFilter()).subscribe((data: IResult<Page<AspuBuffer>>) => {
            this.buffers = data.Value;
            this.isReady = true;
            this.loading = false;

            if (this.bufferType == 'PacketPrintingFlow') {
                this.loadClients();
            }
        },
            error => console.error(error));
    }

    loadClients(){
        this.loading = true;
        this.serv.getClients().subscribe((data: IResult<Array<AspuClientIp>>) => {
            if (data.IsSuccess)
                this.clients = data.Value;
            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));
        return filter;
    }

    loadPrinters() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = []; 
        //filter.includes = [];
       // filter.includes.push("PrintingFlows.ProductTypeProfiles");


        filter.filter.filters.push(this.getGridFilter('Modes', this.bufferType == 'PrintingFlow' ? 'Buffer' : 'Packet', 'contains', true));
       // filter.filter.filters.push(this.getGridFilter('GroupType', this.groupType, 'eq', true));

        this.pserv.getItemsV2(filter).subscribe(data => {
            if (data.IsSuccess) {
                this.printers = data.Value.Items;
                console.log('printers')
                console.log(this.printers)
            }
        });
    }

    errors: any;

    editState: boolean = false;
    printerFlow: AspuBuffer;
    printerFlowOrigin: AspuBuffer;

    saveItem(printerFlow: AspuBuffer) {

        if (printerFlow.ClientId == "undefined")
            printerFlow.ClientId = undefined;

        if (printerFlow.Template == undefined || printerFlow.Template == "") {
            this.toastService.getToast('Поле Шаблон должно быть заполнено', 'error')
            return;
        }

        if (printerFlow.PrinterId != null) {
            printerFlow.PrinterName = this.printers.find(p => p.Id == printerFlow.PrinterId)?.Name;
        }

        this.printerFlow = printerFlow;
        this.printerFlowOrigin = printerFlow; 

        if (this.printerFlow.Id == undefined) {
            this.serv.createItem(this.printerFlow).subscribe(data => {
                if (data.IsSuccess) {
                    this.currentId = "-1";
                    this.editState = false;

                    this.toastService.getToast("Контур печати успешно создан!", 'info');
                    this.loadItems();
                } else {
                    this.errors = data.ValidationMessages
                }
            })
        }
        else {
            this.serv.updateItem(this.printerFlow).subscribe(data => {
                if (data.IsSuccess) {
                    this.currentId = null;
                    this.editState = false;

                    this.toastService.getToast("Контур печати успешно обновлен!", 'info');
                    this.loadItems();
                } else {
                    this.errors = data.ValidationMessages
                }
            })
        }
    }

    cancel() {
        if (this.currentId != null) {
            this.printerFlow = this.printerFlowOrigin;
            this.currentId = null;
            this.printerFlow = null;
        }
        else {
            this.buffers.Items.shift();
        }
        this.editState = false;
        this.loadItems();
    }

    addItem() {
        if (this.printers.length==0) {
            this.toastService.getToast("Отсутствуют доступные принтеры!", 'danger');
            return;
        }

        if (this.editState) {
            return;
        }
        else {
            this.editState = true;
        }
        let newPrinterFlow = new AspuBuffer();
        newPrinterFlow.PrinterId = this.printers[0].Id;
        newPrinterFlow.Template = "";
        newPrinterFlow.discriminator = this.bufferType;  
        newPrinterFlow.ProfileId = this.profileId;
        let rows = [...this.buffers.Items];
        rows.splice(0, 0, newPrinterFlow);
        this.buffers.Items = [...rows];
        console.log('this.buffers.Items')
        console.log(this.buffers.Items)
        this.printerFlow = newPrinterFlow;
        this.editItem(newPrinterFlow);
    }

  hasTemplate(row: AspuBuffer) {
    let printer = this.printers.find(f => f.Id == row.PrinterId);
    return printer.discriminator != 'SocketPrinterDto' && printer.discriminator != 'CabPrinterDto' && printer.discriminator != undefined && printer.Templates != undefined;
    }

    getTemplates(row: AspuBuffer) {
        let result = this.printers.filter(x => x.Id == row.PrinterId)[0]?.Templates;
        return result;
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning"
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.buffers.Items = this.buffers.Items.filter(x => x.Id != this.confirmation.Parameter)
                this.deleteItem(this.confirmation.Parameter);
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    deleteItem(id: string) {
        this.loading = true;
        this.serv.deleteItem(id).subscribe(data => {
            if (data.IsSuccess) {
                this.toastService.getToast("Контур печати успешно удален!", 'info');
                this.editState = false;
                this.loadItems();
            }
            this.loading = false;
        });
    }

    currentId: string;

    editItem(printingFlow: AspuBuffer) {
        this.currentId = printingFlow.Id;
        this.printerFlowOrigin = printingFlow;
        this.printerFlow = printingFlow;
    }

    isEdit(id: string) {
        return (id == this.currentId);
    }

    preInit(id: string) {
        this.loading = true;
        this.serv.preInit(id).subscribe((data: IResult<any>) => {
            this.isReady = true;
            this.loading = false;
            this.toastService.getToast("Предынициализация успешно выполнена!", 'info');
        },
            error => console.error(error));
    }


    getGridFilter(field: string, val: any, operator: string, isHidden = false) {
        let gridFilter = new GridFilter();
        gridFilter.field = field;
        gridFilter.value = val;
        gridFilter.operator = operator;
        gridFilter.isHidden = isHidden
        return gridFilter;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
