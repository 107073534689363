import { Injectable } from '@angular/core';
import { PrintingAction, AspuBatchProcessState, StopBatchDto, BatchIdDto, AspuPrintingState } from './batch-process.model';
import { AspuBatchStart, AspuAdditionLoading } from '../batch-edit/batch-edit.model';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { Observable } from 'rxjs';
import { AspuBatchState } from '../batch-state.model';

@Injectable()
export class AspuBatchProcessService {

    constructor(
        private httpService: AspuHttpService) { }

    getBatchProcessState() {
        //return this.httpService.get<IResult<Array<AspuBatchProcessState>>>('Management');
        return this.httpService.get<IResult<AspuBatchProcessState>>('Management');
    }


    getPrintersState() {
        return this.httpService.get<IResult<AspuPrintingState>>('Management/printing');
    }


    startProduction() {
        return this.httpService.put<IResult<any>>(null, 'Management/production');
    }

    stopProduction() {
        return this.httpService.delete<IResult<any>>(null, 'Management/production');
    }

    updateKmCounts(){
        return this.httpService.post<IResult<any>>(null, 'Management/ProductTypesCapacity');
    }

    startPacketPrint(id: number) {
        return this.httpService.put<IResult<any>>(id, 'Management/printing/packet/start');
    }

    stopPacketPrint(id: number) {
        return this.httpService.put<IResult<any>>(id, 'Management/printing/packet/stop');
    }

    startPrint(state: PrintingAction) {
        return this.httpService.put<IResult<any>>(state, 'Management/printing');
    }

    stopPrint(id: number) {
        return this.httpService.delete<IResult<any>>(id, 'Management/printing');
    }

    stopBatch(data: StopBatchDto) {
        return this.httpService.post<IResult<any>>(data, 'Management/StopBatch');
    }

    // suspendBatch(data: BatchIdDto) {
    //     return this.httpService.post<IResult<any>>(data, 'oms/v2Async/ProductLineBatch/batchStop');
    // }
    
    //additionOrder(item: AspuBatchStart) {
    //    return this.httpService.put<IResult<any>>(JSON.stringify(item), 'Management/codes');
    //}

    additionOrder(item: AspuAdditionLoading) {
        return this.httpService.put<IResult<any>>(JSON.stringify(item), 'Management/codes');
    }

    getBatchStatuses(): Observable<IResult<AspuBatchState>> {
        return this.httpService.get<IResult<AspuBatchState>>('Management/batch');
    }

    //getBatchNumber() {
    //    return this.httpService.get<IResult<string>>('Management/InitialBatchName');
    //}
}