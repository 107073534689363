import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { AspuHttpService } from '../../services/aspu.http.service';
import { BatchIdDto } from '../batch/batch-process/batch-process.model';
import { AspuDraft, CodesForProductLine, BatchStartRequest, ProductLine } from "./draft.model";

@Injectable()
export class AspuDraftService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(filter: FiltersContainer): Observable<IResult<Page<AspuDraft>>> {
        return this.httpService.post<IResult<Page<AspuDraft>>>(filter, 'oms/Batch/list');
    }

    getCodesItems(filter: FiltersContainer): Observable<IResult<Page<CodesForProductLine>>> {
        return this.httpService.post<IResult<Page<CodesForProductLine>>>(filter, 'oms/codesforproductline/list');
    }

    isReceiptValidForType(id: string): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('receipts/ValidateProductTypeForBatch',id); 
    }

    startBatchFromDraft(item: BatchStartRequest): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'oms/ProductLine/batchstart');
    }

    getProductLineById(id: string): Observable<IResult<ProductLine>> {
    return this.httpService.get<IResult<any>>('oms/ProductLine/load', id);
    }

    deleteItem(id: string): Observable<IResult<any>> {
        return this.httpService.delete<IResult<any>>(id, 'oms/Batch/delete');
        //return this.httpService.delete<IResult<any>>(null, 'oms/Batch/delete/?id=' + id);
    }

    startOmsSyncJob(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'Jobs/OmsSync');
    }

    batchContinue(data: BatchIdDto) {
        return this.httpService.post<IResult<any>>(data, 'oms/v2Async/ProductLineBatch/batchContinue');
    }

    getStatuses(): Observable<IResult<Array<string>>> {
        return this.httpService.get<IResult<Array<string>>>('oms/batch/getstatuses');
    }
}
