import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { stat } from 'fs';
import { AspuPlcService } from './plc.service';
import { PipeTransform, Pipe } from '@angular/core';
import { Key } from "protractor";
import { AspuPlcConfigPage, AspuPlcStateMetadata } from '../../core/common/common.model';

@Component({
    selector: 'app-plc-list-component',
    templateUrl: './plc-list.component.html',
    providers: [AspuPlcService]
})
export class AspuCustomPlcListComponent implements OnInit {
    raw: object;
    viewModel: Array<AspuPlcStateMetadata>;

    configs: Array<AspuPlcConfigPage>;

    constructor(private serv: AspuPlcService, private lservice: LangService, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }
  
    ngOnInit() {
        window.addEventListener("PlcStatusReceived", this._doAction, false);
        this.loadItems();
    }

    ngOnDestroy() {
        window.removeEventListener('PlcStatusReceived', this._doAction);
    }

    private _doAction = this.doActionPrevent.bind(this);
    doActionPrevent(data: any) {
        this.raw = JSON.parse(data.detail.data);
        let raw = localStorage.getItem('plcMeta');
        this.viewModel = JSON.parse(raw);;
        this.getIndicator(this.raw);
    }


    public loadItems() {
        let raw = localStorage.getItem('plcMeta');
        if (raw != undefined) {
            this.viewModel = JSON.parse(raw);
        }
        else {
            this.viewModel = undefined
        }
            this.serv.getItems().subscribe((data: IResult<any>) => {
            //console.log(data);
                this.raw = data.Value;
                this.getIndicator(this.raw);
                let rawConfigs = localStorage.getItem('plcPageMeta');
                if (rawConfigs!=undefined)
                    this.configs = (JSON.parse(rawConfigs) as Array<AspuPlcConfigPage>);
                //console.log(this.confzigs);

        }, error => {
            console.error(error);
        });
    }
    getIndicator(val: any) {
        for (var key in val) {
            if (val.hasOwnProperty(key)) {
                //console.log(key + " -> " + val[key]);

                let item = this.viewModel.find(x => x.Name == key);
                let index = this.viewModel.indexOf(item);
                item.Value = val[key];

                this.viewModel[index] = item;
            }
        }
        //console.log(this.viewModel);
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
