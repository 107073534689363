import { Injectable } from '@angular/core';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from "../../../services/aspu.http.service";
import { CodeInfo } from "./code-info.model";

@Injectable()
export class CodeinfoService {

    constructor(
        private httpService: AspuHttpService) { }

    getInfo(code: String) {
        let obj: any = { IdentificationCode: code };
        return this.httpService.post<IResult<CodeInfo>>(obj, 'oms/Storage/getInfo/');
    }
}
