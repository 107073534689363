export class AspuProductItem {
    IsError: boolean;
    IsEmpty: boolean;
    IsOk: boolean;
    IsNoRead: boolean;
    IsActive: boolean;
    Code: string;

    constructor() { }
}

export class AspuBoxLayer {
    Items: AspuProductItem[];
    IsActive: boolean;
    CellError: string;

    constructor() {
        this.Items = new Array<AspuProductItem>();
    }
}

export class AspuBoxAggrState {
    BoxCode: AspuProductItem;
    Status: string;

    Layers: AspuBoxLayer[];
    LayerRowCount: number;
    LayerColsCount: number;

    Capacity: number;
    Count: number;

    AllowClearLayer: boolean;
    AllowClearAll: boolean;
    
    IsAggregate: boolean;
    IsFullAggregate: boolean;
    IsNotFullAggregate: boolean;
    IsTemporaryAggregate: boolean;

    constructor() {
        this.Layers = new Array<AspuBoxLayer>();
    }
}
