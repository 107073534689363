import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuBuffer, AspuClientIp } from './buffer.model';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
import { Page } from 'vekas_models/models/page';

@Injectable()
export class AspuBufferService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(filter: FiltersContainer): Observable<IResult<Page<AspuBuffer>>> {
        return this.httpService.post<IResult<Page<AspuBuffer>>>(filter, 'printers/flows/list');
    }

    createItem(item: AspuBuffer): Observable<IResult<AspuBuffer>> {
        return this.httpService.post<IResult<AspuBuffer>>(item, 'printers/flows/add');
    }

    updateItem(item: AspuBuffer): Observable<IResult<AspuBuffer>> {
        return this.httpService.post<IResult<AspuBuffer>>(item, 'printers/flows/update');
    }

    deleteItem(id: string) {
        return this.httpService.delete(id, "printers/flows");
    }

    preInit(id: string): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'printers/flows/preInit/' + id);
    }


    getClients(): Observable<IResult<Array<AspuClientIp>>> {
        return this.httpService.get<IResult<Array<AspuClientIp>>>('status/clients'); 
    }

    //getItemById(id: string): Observable<IResult<AspuProfile>> {
    //    return this.httpService.get<IResult<Array<AspuProfile>>>('Receipts/profiles', id);
    //}

    //createItem(item: AspuProfile): Observable<IResult<any>> {
    //    return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Receipts/profiles');
    //}

    //updateItem(item: AspuProfile) {
    //    return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Receipts/profiles/' + item.Id);
    //}
    //deleteItem(id: string) {
    //    return this.httpService.delete(id, "Receipts/profiles");
    //}

    //getBuffers() {
    //    return this.httpService.get<IResult<Array<AspuBuffer>>>('Printers/buffer');
    //}

}