

<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'Printers')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            <!--<span style="float: left;position: relative; left: 50%;">
                <span class="" style="float: left;position: relative; left: -50%;"><app-spinner></app-spinner></span>
            </span>-->
            <!--<span class="d-flex justify-content-center"><app-spinner></app-spinner></span>-->
            <!--<i class="fa fa-redo fa-spin fa-fw"></i>
            <span class="sr-only">Загрузка...</span>

            <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>-->
        </h3>

    </div>
    <div class="card-body form-group mb-3 pb-0">
        <ngx-datatable ngx-resize-watcher ngx-localizer *ngIf="isReady" class="bootstrap ngx-datatable"
                       id="someTable"
                       [rows]="rows"
                       [loadingIndicator]=""
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       (sort)="onSort($event)"
                       [rowClass]="getRowClass"
                       [reorderable]="reorderable"
                       (activate)="onActivate($event)">
            <ngx-datatable-column name="{{getlocalized('PrinterList', 'Name')}}" prop="Name" cellClass="custom-row text-uppercase" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('PrinterList', 'DriverName')}}" prop="DriverName" cellClass="custom-row text-uppercase" [minWidth]="130" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('PrinterList', 'Host')}}" prop="Host" cellClass="custom-row" [minWidth]="130" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('PrinterList', 'Port')}}" prop="Port" cellClass="custom-row" [minWidth]="90" [maxWidth]="100" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="130" [maxWidth]="140" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                    <div class="btn-group2">
                        <button id="Printer_List_AddAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Add')}}" (click)="addItem()" class="btn btn-sm btn-info me-3"><i class="fa fa-plus"></i></button>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div class="btn-group2 action-buttons">
                        <button id="Printer_List_EditAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editItem(row['Id'])" class="btn btn btn-info me-2"><i class="fa fa-edit"></i></button>
                        <button id="Printer_List_RemoveAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn btn-danger"><i class="fa fa-trash-alt"></i></button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [isDoubleConfirm]=true [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>