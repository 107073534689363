<div class="btn-group2 mb-3">
    <button id="Batch_Proccess_AccountingOnAction" [disabled]="isPrinting" *ngIf="!viewModel.IsProduction"
        [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'AccountingOn')}}"
        (click)="productionOn()" class="btn btn-warning btn-icon me-2 " style="width: 176px">
        <span><i class="fa-solid fa-toggle-off fa-2x fa-pull-left mt-2 me-0"></i></span>
        <span class="btn-icon-label" style="">{{getlocalized('common', 'AccountingOn')}}</span>
    </button>

    <button id="Batch_Proccess_AccountingOffAction" [disabled]="isPrinting" *ngIf="viewModel.IsProduction"
        [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'AccountingOff')}}"
        (click)="productionOff()" class="btn btn-success btn-icon me-2 " style="width: 176px;">
        <span><i class="fa-solid fa-2x fa-toggle-on fa-pull-left mt-2 me-0"></i></span>
        <span class="btn-icon-label">{{getlocalized('common', 'AccountingOff')}}</span>
    </button>

    <button *ngIf="!allowValidatePrintedOnFinalize" id="Batch_Proccess_FinalizeAction"
        [disabled]="viewModel.IsProduction || !viewModel.IsBatch || loading" [openDelay]="1500" [closeDelay]="400"
        ngbTooltip="{{getlocalized('common', 'BatchFinish')}}" (click)="actionRow(undefined, 'stopBatch')"
        class="btn btn-danger btn-icon me-2">
        <span><i class="fa-solid fa-2x fa-times fa-pull-left mt-2 me-0"></i></span>
        <span class="btn-icon-label">{{getlocalized('common', 'BatchFinish')}}</span>
    </button>

    <div *ngIf="allowValidatePrintedOnFinalize" ngbDropdown class="d-inline-block hide-dropdown">
        <button class="btn btn-danger btn-icon me-2 " style="width: 166px;" id="Batch_Proccess_FinalizeAction"
            ngbDropdownToggle ngbTooltip="{{getlocalized('common', 'BatchFinish')}}"
            [disabled]="viewModel.IsProduction || !viewModel.IsBatch || loading" [openDelay]="1500" [closeDelay]="400">
            <span><i class="fa-solid fa-2x fa-chevron-down fa-pull-right mt-2 me-0"></i></span>
            <span class="btn-icon-label">{{getlocalized('common', 'BatchFinish')}}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="Batch_Proccess_FinalizeAction">
            <button class="dropdown-button-text-x-large" ngbDropdownItem
                (click)="actionRow(undefined, 'stopBatch')">{{getlocalized('common', 'SimpleBatchFinish')}}</button>
            <button class="dropdown-button-text-x-large" ngbDropdownItem
                (click)="actionRow(undefined, 'stopBatchWithParameter')">{{getlocalized('common',
                'ValidatePrintedBatchFinish')}}</button>
        </div>
    </div>

    <button *ngIf="!isHideBatchStopBtn" id="Batch_Proccess_StopAction" [disabled]="viewModel.IsProduction || loading"
        [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Suspend')}}"
        (click)="actionRow(undefined, 'suspend')" class="btn btn-warning btn-icon me-2" style="width: 176px;">
        <span><i class="fa-solid fa-2x fa-pause-circle fa-pull-left mt-0 me-0"></i></span>
        <span class="btn-icon-label mt-0">{{getlocalized('common', 'Stop')}}</span>
    </button>

    <div *ngIf="true" ngbDropdown class="d-inline-block hide-dropdown">
        <button class="btn btn-primary btn-icon me-1  " style="width: 166px;" id="Batch_Proccess_FinalizeAction"
            ngbDropdownToggle ngbTooltip="{{getlocalized('common', 'OMS')}}" [disabled]="!viewModel.IsBatch || loading"
            [openDelay]="1500" [closeDelay]="400">
            <span><i class="fa-solid fa-2x fa-chevron-down fa-pull-right mt-0 me-0"
                    style="margin-top: -5px!important;"></i></span>
            <span class="btn-icon-label mt-0">{{getlocalized('common', 'OMS')}}</span>
        </button>
        <div ngbDropdownMenu class="hide-dropdown" aria-labelledby="Batch_Proccess_FinalizeAction">
            <button ngbDropdownItem (click)="updateKmCounts()">{{getlocalized('common', 'UpdateKmCounts')}}</button>
            <a ngbDropdownItem href="{{this.omsBatchUrl}}" target="_blank">{{getlocalized('common', 'ToBatch')}}</a>
        </div>
    </div>
</div>

<div class="card mt-1">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'BatchProcess')}}</span>
            <span *ngIf="loading" class="ms-3">
                <app-spinner [mode]="'burst'"></app-spinner>
            </span>
        </h3>
    </div>

    <div class="card-body form-group mb-3 pb-0">
        <div class="row">
            <div class="col-lg-10 col-xl-10 col-xxl-10"
                [ngClass]="{'col-lg-10 col-xl-10 col-xxl-10' : image!=undefined, 'col-lg-12 col-xl-12 col-xxl-12' : image==undefined}">
                <div class="row">
                    <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                        <div class="input-group input-group-lg">
                            <span
                                class="input-group-text label-edit-width-255 text-uppercase">{{getlocalized('BatchList',
                                'BatchNumber')}}</span>
                            <input type="text" class="form-control" disabled [(ngModel)]="viewModel.BatchName">
                        </div>
                    </div>
                    <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                        <div class="input-group input-group-lg">
                            <span
                                class="input-group-text label-edit-width-255 text-uppercase">{{getlocalized('BatchList',
                                'ReceiptName')}}</span>
                            <input type="text" class="form-control" disabled [(ngModel)]="viewModel.ReceiptName">
                        </div>
                    </div>
                    <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                        <div class="input-group input-group-lg">
                            <span
                                class="input-group-text label-edit-width-255 text-uppercase">{{getlocalized('BatchList',
                                'StartDate')}}</span>
                            <input type="text" class="form-control" disabled [(ngModel)]="viewModel.StartDate">
                        </div>
                    </div>
                    <div *ngIf="viewModel.ProductionDate != undefined" class="mb-3 pe-3 col-xl-12 col-xxl-6">
                        <div class="input-group input-group-lg">
                            <span
                                class="input-group-text label-edit-width-255 text-uppercase">{{getlocalized('ReceiptList',
                                'ProductionDate')}}</span>
                            <input type="text" class="form-control" disabled [(ngModel)]="viewModel.ProductionDate">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="image!=undefined" class="col-lg-2 col-xl-2 col-xxl-2">
                <div class="photo mb-2">
                    <img src="data:image/jpg;base64,{{image}}"
                        [ngClass]="{'custom-img-collapsed' : !isImageExpanded, 'custom-img-expanded' : isImageExpanded}"
                        class="img-fluid rounded mx-auto d-block" (click)="isImageExpanded=!isImageExpanded" />
                </div>
            </div>

        </div>
        <!--data-container="body" display="dynamic"-->
        <ngx-datatable ngx-resize-watcher *ngIf="true" ngx-localizer class="bootstrap ngx-datatable" id="someTable"
            [rows]="rows" [loadingIndicator]="" [scrollbarH]="true" [columnMode]="ColumnMode.force" [headerHeight]="50"
            [footerHeight]="50" rowHeight="auto" [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('BatchList', 'ProductTypeName')}}"
                [minWidth]="fieldSettings.FProductWidth" [maxWidth]="fieldSettings.FProductWidth" [canAutoResize]="true"
                cellClass="custom-row text-uppercase width100" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body"
                        placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template class="">
                    <div><span class="text-secondary">{{getlocalized('ReceiptList', 'GroupType')}}:</span><span
                            class="text-dark fw-bolder"> {{getlocalized('ProductGroupTypeDictionary', row['GroupType']
                            )}}</span></div>
                    <div><span class="text-secondary">{{getlocalized('ReceiptList', 'Name')}}:</span><span
                            class="fw-bolder"> {{ row['Name'] }}</span></div>
                    <div><span class="text-secondary">{{getlocalized('ReceiptList', 'Gtin')}}:</span><span
                            class="fw-bolder"> {{ row['Gtin'] }}</span></div>
                    <div><span class="text-secondary">{{getlocalized('ReceiptList',
                            'SelectedProductTypeCode')}}:</span><span class="fw-bolder"> {{
                            row['SelectedProductTypeCode'] }}</span></div>
                    <div *ngIf="row['IsGenerate']" class="btn-group2 action-buttons"
                        style="background-color: #EDEDFF; margin: 0 -12px 0 -12px">
                        <div style="display: grid">
                            <div style="text-align: center">
                                <button id="Batch_Proccess_AddOrderDialogAction" [openDelay]="1500" [closeDelay]="400"
                                    ngbTooltip="{{getlocalized('common', 'AdditionalOrder')}}"
                                    (click)="addNewItems(row, contentLoads)" class="btn btn-lg btn-info mt-4"><i
                                        class="fa fa-plus"></i></button>
                            </div>
                            <span class="text-uppercase mb-3"
                                style="text-align: center; color: #838383">{{getlocalized('common',
                                'AdditionalOrder')}}</span>
                        </div>

                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Stats')}}" prop="Stats"
                cellClass="custom-row progressbar text-uppercase" [minWidth]="fieldSettings.FProgressbarWidth"
                [maxWidth]="fieldSettings.FProgressbarWidth" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body"
                        placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                        {{column.name}}
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
                    <!--{{ value }}-->
                    <div *ngFor="let stat of value" class="mt-2 mb-2" style="min-width: inherit">
                        <div
                            *ngIf="stat.All!=0 || (stat.Type == 'Fetched' && row['GroupType'] == 'Product' && stat.Value != 0)">



                            <!--{{stat.Name}} : {{stat.Value}} of {{stat.All}}-->
                            <!--{{stat.Type}} : {{stat.Value}} of {{stat.All}}-->
                            <span class="text-secondary">{{getlocalized('common', stat.Type)}}: </span>
                            <span class="main-text-color fw-bolder">{{stat.Value}} </span>
                            <span class="text-secondary">{{stat.Type == 'Fetched' ? getlocalized('common', 'surplus') :
                                getlocalized('common', 'of')}} </span>
                            <span class="main-text-color fw-bolder">{{stat.All}}</span>

                            <ngb-progressbar animated="true" height="25px" type="success" [showValue]="true"
                                [value]="countPercentByStat(stat)">
                                <!--{{stat.Type}} : {{stat.Value}} of {{stat.All}}-->
                            </ngb-progressbar>
                        </div>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{getlocalized('common', 'Printers')}}"
                [minWidth]="fieldSettings.FPrintersWidth" [maxWidth]="fieldSettings.FPrintersWidth"
                [canAutoResize]="true" cellClass="custom-row" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label ">
                        {{column.name}}
                    </div>
                    <div class="btn-group2">
                        <button id="Batch_Proccess_ReloadAction" [openDelay]="1500" [closeDelay]="400"
                            ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="load()"
                            class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div style="background: #C74F4F; color: #FFF; text-align: center; font-size: 18px; font-weight: 500; margin-left: -14px; margin-top: -1px !important; margin-bottom: 0px; "
                        class="alert alert-danger text-uppercase mb-2 mt-2" *ngIf="noCodesForPrinter(row)">
                        {{getlocalized('common', 'NoCodesForPrinter')}}</div>
                    <ngx-datatable
                        *ngIf="true && isReady && flowsPrinters!=undefined && flowsPrinters.length!=undefined && isFlowsPrinterEnabled(row)"
                        ngx-localizer class="bootstrap ngx-datatable printer-table mt-4 mb-2 me-3"
                        id="someTable{{row['Id']}}" [rows]="getFlowPrintersPerType(row)" [columnMode]="ColumnMode.force"
                        [headerHeight]="25" rowHeight="50px" [footerHeight]="10" [rowClass]="getFlowRowClass">
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Name')}}" prop="PrinterName"
                            cellClass="custom-row printerName custom-celltext"
                            [minWidth]="fieldSettings.FPrinterNameWidth" [maxWidth]="fieldSettings.FPrinterNameWidth"
                            [canAutoResize]="true" [resizeable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Template')}}" prop="Template"
                            headerClass="ps-1 pe-1" cellClass="custom-row custom-celltext"
                            [minWidth]="fieldSettings.FPrinterNameWidth" [maxWidth]="fieldSettings.FPrinterNameWidth"
                            [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Status')}}" prop="Status"
                            headerClass="ps-0 pe-0" cellClass="" [minWidth]="80" [maxWidth]="80" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <!--{{getlocalized('PrintStatusDictionary', value)}}-->
                                <div class="" ngbTooltip="{{getlocalized('PrintStatusDictionary', value)}}"
                                    triggers="click:blur">
                                    <span class="btn" [style.display]="value=='Started' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play fa-fade text-primary"
                                            style="--fa-animation-duration: 2s; --fa-fade-opacity: 0.6 "></i></span>
                                    <span class="btn" [style.display]="value=='Starting' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x animated faa-shake fa-play text-info"></i></span>
                                    <span class="btn" [style.display]="value=='Stopped' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-stop text-primary"></i></span>
                                    <span class="btn" [style.display]="value=='Stopping' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play faa-shake  animated faa-slow text-info"></i></span>
                                    <span class="btn" [style.display]="value=='Printing' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play faa-pulse animated faa-slow text-primary"></i></span>
                                    <span class="btn" [style.display]="value=='Unprinted' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-eject faa-pulse animated faa-slow text-danger"></i></span>
                                    <!--<span><i>{{getlocalized('PrintStatusDictionary', value)}}</i></span>-->
                                </div>


                            </ng-template>


                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn"
                            cellClass="central-btn" [minWidth]="140" [maxWidth]="140" [canAutoResize]="true"
                            [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    <span>
                                        {{column.name}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group2 action-buttons">
                                    <button id="Batch_Proccess_StartPrintAction" *ngIf="params.length==0"
                                        [disabled]="row.Status!='Stopped' || noCodesForPrinterButton(row.GroupType)"
                                        [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('common', 'StartPrint')}}" (click)="startPrint(row)"
                                        class="btn btn me-1  border-secondary"
                                        [ngClass]="{'btn-info': row.Status!='Started', 'btn-danger': row.Status=='Started'}"><i
                                            class="fa fa-print"></i></button>
                                    <button id="Batch_Proccess_StartPrintAction" *ngIf="params.length>0"
                                        [disabled]="row.Status!='Stopped' || noCodesForPrinterButton(row.GroupType)"
                                        [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('common', 'StartPrint')}}"
                                        (click)="addNewPrint(row, contentPrint)" class="btn btn me-1  border-secondary"
                                        [ngClass]="{'btn-info': row.Status!='Started', 'btn-danger': row.Status=='Started'}"><i
                                            class="fa fa-print"></i></button>
                                    <button id="Batch_Proccess_StopPrintAction" [disabled]="!(row.Status=='Started')"
                                        [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('common', 'StopPrint')}}" (click)="stopPrint(row)"
                                        class="btn btn me-1  border-secondary"
                                        [ngClass]="{'btn-info': row.Status!='Started', 'btn-danger': row.Status=='Started'}"><i
                                            class="fa fa-stop"></i></button>
                                    <button id="Batch_Proccess_MessagesAction" *ngIf="row.Messages.length>0"
                                        [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('PrinterList', 'Messages')}}"
                                        [ngbPopover]="contentMessages" closePopoverOnOutsideClick
                                        popoverClass="customPopover" triggers="manual"
                                        #contentMessagesPopover="ngbPopover"
                                        (click)="showPrinterMessages(contentMessagesPopover, row)"
                                        class="btn btn border-secondary"
                                        [ngClass]="getClassForMessagesBtn(row.Messages)">
                                        <i class="fa fa-exclamation-triangle"></i>
                                    </button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                    <ngx-datatable
                        *ngIf="false && isReady && packetsPrinters!=undefined && packetsPrinters.length!=undefined && isPacketPrinterEnabled(row)"
                        ngx-localizer class="bootstrap ngx-datatable printer-table mt-3 mb-2 me-3"
                        id="someTable{{row['Id']}}" [rows]="getPacketPrintersPerType(row)"
                        [columnMode]="ColumnMode.force" [headerHeight]="25" rowHeight="auto" [footerHeight]="10"
                        [rowClass]="getPacketRowClass">
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Name')}}" prop="PrinterName"
                            cellClass="custom-row printerName custom-celltext"
                            [minWidth]="fieldSettings.FPrinterNameWidth" [maxWidth]="fieldSettings.FPrinterNameWidth"
                            [canAutoResize]="true" [resizeable]="true">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Template')}}" prop="Template"
                            headerClass="ps-1 pe-1" cellClass="custom-row custom-celltext" [minWidth]="100"
                            [maxWidth]="100" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('PrinterList', 'Status')}}" prop="Status"
                            headerClass="ps-0 pe-0" cellClass="" [minWidth]="80" [maxWidth]="80" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <!--<span *ngIf="row.IsPreInitialize"> {{getlocalized('PrintStatusDictionary', value)}}</span>-->
                                <span *ngIf="row.IsPreInitialize"
                                    ngbTooltip="{{getlocalized('PrintStatusDictionary', value)}}" triggers="click:blur">
                                    <span class="btn" [style.display]="value=='Started' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play faa-flash animated faa-slow text-primary"></i></span>
                                    <span class="btn" [style.display]="value=='Starting' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x animated faa-shake fa-play text-info"></i></span>
                                    <span class="btn" [style.display]="value=='Stopped' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-stop text-primary"></i></span>
                                    <span class="btn" [style.display]="value=='Stopping' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play faa-shake  animated faa-slow text-info"></i></span>
                                    <span class="btn" [style.display]="value=='Printing' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-play faa-pulse animated faa-slow text-primary"></i></span>
                                    <span class="btn" [style.display]="value=='Unprinted' ? 'inherit' : 'none'"><i
                                            class="border-dark fa fa-2x fa-eject faa-pulse animated faa-slow text-danger"></i></span>
                                    <!--<span><i>{{getlocalized('PrintStatusDictionary', value)}}</i></span>-->
                                </span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn"
                            cellClass="central-btn" [minWidth]="140" [maxWidth]="140" [canAutoResize]="true"
                            [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    <span>
                                        {{column.name}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group2 action-buttons">
                                    <button id="Batch_Proccess_PreInitializeAction" *ngIf="row.IsPreInitialize"
                                        [disabled]="row.Status!='Stopped'" [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('common', 'PreInitialize')}}"
                                        (click)="startPacketPrint(row)" class="btn btn me-1  border-secondary"
                                        [ngClass]="{'btn-info': row.Status!='Started', 'btn-danger': row.Status=='Started'}"><i
                                            class="fa fa-magic"></i></button>
                                    <button id="Batch_Proccess_StopPrintAction" *ngIf="row.IsPreInitialize"
                                        [disabled]="!(row.Status=='Started')" [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('common', 'StopPrint')}}"
                                        (click)="stopPacketPrint(row)" class="btn btn me-1  border-secondary"
                                        [ngClass]="{'btn-info': row.Status!='Started', 'btn-danger': row.Status=='Started'}"><i
                                            class="fa fa-stop"></i></button>
                                    <button id="Batch_Proccess_MessagesAction" *ngIf="row.Messages.length>0"
                                        [openDelay]="1500" [closeDelay]="400"
                                        ngbTooltip="{{getlocalized('PrinterList', 'Messages')}}"
                                        [ngbPopover]="contentMessages" closePopoverOnOutsideClick
                                        popoverClass="customPopover" triggers="manual"
                                        #contentMessagesPopover="ngbPopover"
                                        (click)="showPrinterMessages(contentMessagesPopover, row)"
                                        class="btn btn border-secondary"
                                        [ngClass]="getClassForMessagesBtn(row.Messages)">
                                        <i class="fa fa-warning"></i>
                                    </button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>

                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

<ng-template #contentLoads let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                    <span class="header-badge"
                        style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common',
                        'AdditionOrder')}} {{getlocalized('common', 'for')}} {{currentProductType?.Name}}</span>
                </h4>
                <button id="Batch_Proccess_CloaseAddOrderDialogAction" type="button" class="btn btn-lg btn-info"
                    aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="mb-3 col-lg-12 col-xl-12">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text">{{getlocalized('common', 'Quantity')}}</span>
                            <input type="text" appOnlynumber class="form-control form-control-lg"
                                [(ngModel)]="currentProductTypeCount">
                        </div>
                    </div>
                </div>

                <div class="modal-footer p-0 border-0">
                    <div class="btn-group2 me-0">
                        <button type="button" class="btn btn-lg btn-info btn me-2 " aria-label="Close"
                            (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                        <button id="Batch_Proccess_AddOrderAction" [openDelay]="1500" [closeDelay]="400"
                            ngbTooltip="{{getlocalized('common', 'AdditionOrder')}}" (click)="additionalLoading()"
                            class="btn btn-lg btn-success"><i class="fa fa-save me-1 "></i>{{getlocalized('common',
                            'AdditionOrder')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #contentPrint let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">
                    <span class="header-badge"
                        style="white-space: normal; text-overflow: ellipsis; ">{{getlocalized('common',
                        'PrintingParameters')}}</span>
                </h4>
                <button type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <app-meta-component [metaType]="'printingFlow'" [id]="currentPrinter.Id" [isAutoReloadEnabled]="true"
                    (onUpdateData)="currentParam  = $event" (onUpdateDataObject)="params= $event"></app-meta-component>
                <div class="modal-footer">
                    <div class="btn-group2">
                        <button type="button" class="btn btn-info me-1 " aria-label="Close"
                            (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}"
                            (click)="saveFlatItem()" class="btn btn-success float-end"><i
                                class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
                        <!--<button [openDelay]="1500" [closeDelay]="400" ngbTooltip="Заказать" (click)="additionalLoading()" class="btn btn-sm btn-success"><i class="fa fa-save me-1 "></i>Заказать</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #contentMessages let-row="row">
    <div *ngIf="row.Messages!=undefined && row.Messages.length>0" class="list-group">
        <div *ngFor="let item of row.Messages">
            <div class="" [ngClass]="getClassForMessage(item.Type)">{{item.Text}}</div>
        </div>
    </div>
</ng-template>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [isDoubleConfirm]=true [typeClass]="confirmation.ClassType" [message]="confirmation.Message"
        [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>