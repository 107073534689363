<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled' && isLoadProductCodesEnabled" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header">
            <h3>
                <span class="header-badge text-uppercase">
                    {{getlocalized('common', 'LoadCodesFromFiles')}}
                </span>
            </h3>
        </div>
        <div class="card-body form-group mb-3 pb-0">       
            <angular-file-uploader class="custom-file-uploader p-0" style="margin:0!important"
                id="FileCodesUploadForm"
                [config]="uploadCodesConfig"
                (ApiResponse)="CodesUpload($event)">
            </angular-file-uploader>
        </div>
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled' && isUploadProductCodesEnabled" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header">
            <h3>
                <span class="header-badge text-uppercase">
                    {{getlocalized('common', 'UploadCodesToFiles')}}
                </span>
            </h3>
        </div>
        <div class="card-body form-group mb-3 pb-0">       
            <button id="UploadCodesToJsonFileButton" class="btn btn-lg btn-info action-buttons list-group-item list-group-item-action" (click)="saveCodesToJsonFile()">
                {{getlocalized('common', 'UploadCodesToJsonFile')}}
            </button> 
            <button id="UploadCodesToCsvFileButton" class="btn btn-lg btn-info action-buttons list-group-item list-group-item-action" (click)="saveCodesToCsvFile()">
                {{getlocalized('common', 'UploadCodesToCsvFile')}}
            </button> 
            <button id="UploadCodesToTxtFileButton" class="btn btn-lg btn-info action-buttons list-group-item list-group-item-action" (click)="saveCodesToTxtFile()">
                {{getlocalized('common', 'UploadCodesToTxtFile')}}
            </button> 
        </div>
    </div>
</div>
