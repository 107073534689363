
<div *ngIf="isAutoReloadEnabled" class="form-row">
    <div [ngSwitch]="item.discriminator" *ngFor="let item of params" class=" mb-3 col-lg-12 col-md-12">
        <!--INT-->
        <div *ngSwitchCase="'IntVariable'" class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <input [disabled]="item.IsReadOnly" *ngSwitchCase="'IntVariable'" appOnlynumber type="number"
                   class="form-control form-control-lg" [(ngModel)]="item.Value" (ngModelChange)="getMetadata($event)">
        </div>

        <!--BOOL-->
        <div *ngSwitchCase="'BoolVariable'" class="form-check form-control-lg form-switch">
            <input [disabled]="item.IsReadOnly" class="form-check-input big-switch" type="checkbox" [(ngModel)]="item.Value" (ngModelChange)="getMetadata($event)" id="{{item.Name}}">
            <label class="form-check-label" for="{{item.Name}}">{{item.Description}}</label>
        </div>

        <!--DATE-->
        <div *ngSwitchCase="'DateVariable'" class="">
            <div class="input-group input-group-lg">
                <span class="input-group-text">{{item.Description}}</span>

            <input [disabled]="item.IsReadOnly && isFieldDisabled(item)" class="form-control form-control-lg" placeholder="dd.mm.yyyy"
                   (ngModelChange)="getMetadata($event)"
                   name="dp5" [(ngModel)]="item.Value" ngbDatepicker #d5="ngbDatepicker">
                <button [disabled]="item.IsReadOnly && isFieldDisabled(item)" class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button">
                    <i class="fa fa-calendar"></i>
                </button>
            </div>
        </div>

        <!--STRING-->
        <div *ngSwitchDefault class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <input *ngSwitchDefault type="text" [disabled]="item.IsReadOnly" class="form-control form-control-lg" [(ngModel)]="item.Value" (ngModelChange)="getMetadata($event)">
        </div>
    </div>
</div>

<div *ngIf="!isAutoReloadEnabled" class="row">
    <div [ngSwitch]="item.discriminator" *ngFor="let item of params" class=" mb-3 pr3 col-lg-12 col-xl-12">
        <!--INT-->
        <div *ngSwitchCase="'IntVariable'" class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <input *ngSwitchCase="'IntVariable'" appOnlynumber type="number" class="form-control form-control-lg" (ngModelChange)="changeData($event)" [(ngModel)]="item.Value">
        </div>

        <!--DOUBLE-->
        <div *ngSwitchCase="'DoubleVariable'" class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <input *ngSwitchCase="'DoubleVariable'" type="number" class="form-control form-control-lg" (ngModelChange)="changeData($event)" [(ngModel)]="item.Value">
        </div>

        <!-- BOOL -->
        <div *ngSwitchCase="'BoolVariable'" class="form-check form-control-lg form-switch ms-1">
            <input class="form-check-input big-switch" type="checkbox" [(ngModel)]="item.Value" id="{{item.Name}}" (ngModelChange)="changeData($event)">
            <label class="form-check-label ms-3" for="{{item.Name}}">{{item.Description}}</label>
        </div>

        <!--DATE-->
        <div *ngSwitchCase="'DateVariable'" class="">
            <div class="input-group input-group-lg">
                <span class="input-group-text">{{item.Description}}</span>
                <input class="form-control form-control-lg" placeholder="dd.mm.yyyy" (ngModelChange)="changeData($event)"
                       name="dp5" [disabled]="false" [(ngModel)]="item.Value" ngbDatepicker #d5="ngbDatepicker">
                <button class="btn btn-outline-secondary calendar" (click)="d5.toggle()" type="button">
                    <i class="fa fa-calendar"></i>
                </button>
            </div>
        </div>

        <!--EnumVariable-->
        <div *ngSwitchCase="'EnumVariable'" class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <select *ngSwitchCase="'EnumVariable'" name="{{item.Name}}"
                    id="Receipt_Edit_Select_{{item.Name}}"
                    [(ngModel)]="item.Value"
                    (ngModelChange)="changeData($event)"
                    class="form-select form-control form-control-lg">
                <option *ngFor="let opt of item.Items" ng-selected="item.Value===opt.Name" [value]="opt.Name">{{ opt.Description}}</option>
            </select>
        </div>

        <!--STRING-->
        <div *ngSwitchDefault class="input-group input-group-lg">
            <span class="input-group-text">{{item.Description}}</span>
            <input *ngSwitchDefault type="text" class="form-control form-control-lg" [(ngModel)]="item.Value" (ngModelChange)="changeData($event)">
            <app-validation *ngIf="isValidationEnabled && item.Required" [val]="item.Value" [name]="'Fields.' + [item.Name]" [errors]="errors" [size]="'lg'"></app-validation>
        </div>
    </div>
</div>