<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-0">
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h4 ps-1 pe-1 text-uppercase">
                        {{getlocalized('common', 'ValidationLayer')}}{{codeLevel}}
                    </div>
                </div>
                <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
        </div>
        <div class="card-body form-group mb-2 p-2 pb-0">
            <div class="row">
                <div class="col-md-5 p-0">
                    <div class="container">                        
                        <div class="mb-3 border border-light rounded">
                            <div class="grid p-2 rounded border" style="border-width:4px!important;"
                                 [class.bg-secondary]="false"
                                 [class.border-success]="getBorder('success')"
                                 [class.border-danger]="getBorder('danger')"
                                 [class.border-dark]="getBorder('default')"
                                 [ngStyle]="{'grid-template-columns': 'repeat(' + viewModel.LayerColsCount + ', 1fr)', 'grid-template-rows': 'repeat('+ viewModel.LayerRowsCount + ', 1fr)'}">
                                <div *ngFor="let item of viewModel.Products"
                                     [class.bg-success]="item.IsOk"
                                     [class.bg-danger]="item.IsError"
                                     [class.bg-secondary]="item.IsEmpty"
                                     [class.active-cell]="item.IsActive">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7 ps-0">
                    <div class="card-body form-group p-0 pb-0">
                        <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerComplete'" class="alert alert-success text-center pb-3 pt-3 h3" role="alert">
                            {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                        </div>
                        <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerNotComplete'" class="alert alert-warning text-center pb-3 pt-3 h3" role="alert">
                            {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                        </div>
                        <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerError'" class="alert alert-danger text-center pb-3 pt-3 h3" role="alert">
                            {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                        </div>
                        <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='LayerEmpty'" class="alert alert-info text-center pb-3 pt-3 h3" role="alert">
                            {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                        </div>
                        <div *ngIf="this.viewModel!=undefined && this.viewModel.Status=='Unknown'" class="alert alert-danger text-center pb-3 pt-3 h3" role="alert">
                            {{getlocalized('SerializationLayerStatus', this.viewModel.Status)}}
                        </div>
                    </div>
                    <div class="input-group input-group-sm mb-2">
                        <button id="BranchSerialization_ClearLayerAction" class="btn btn-lg mt-1 btn-danger btn-block text-uppercase" (click)="clearLayer()">
                            {{getlocalized('common', 'ClearLayer')}}
                        </button> 
                    </div>
                    <div *ngIf="getCellError()" class="mt-1 p-2 alert-danger h3 rounded">
                        {{getCellError()}}
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</div>
