import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import { BoxPalletStateDto } from './aggregation-box-pallet.model';

@Injectable()
export class AspuAggregationBoxPalletService {
    path: string;

    constructor(
        private httpService: AspuHttpService) {
    }

    getStatus(): Observable<IResult<BoxPalletStateDto>> {
        return this.httpService.get<IResult<BoxPalletStateDto>>('AggrAlgorithms/Manual');
    }

    setCode(item: AspuCodeOnly) {
        return this.httpService.put<IResult<AspuSetEntityResult>>(JSON.stringify(item), 'AggrAlgorithms/Manual');
    }

    setBoxNotFull() {
      return this.httpService.put<IResult<any>>(null, 'AggrAlgorithms/Manual/boxNotFull');
    }

    setPalletNotFull() {
      return this.httpService.put<IResult<any>>(null, 'AggrAlgorithms/Manual/palletNotFull');
    }

    cleanBox() {
        return this.httpService.delete<IResult<any>>(null, 'AggrAlgorithms/Manual/clearBox');
    }

    cleanPallet() {
        return this.httpService.delete<IResult<any>>(null, 'AggrAlgorithms/Manual/clearPallet');
    }

    cleanAll() {
        return this.httpService.delete<IResult<any>>(null, 'AggrAlgorithms/Manual/clearAll');
    }

    cancel() {
      return this.httpService.put<IResult<any>>(null, 'AggrAlgorithms/Manual/cancelNotFull');
    }

    remove(item: AspuCodeOnly) {
      return this.httpService.post<IResult<boolean>>(JSON.stringify(item), 'AggrAlgorithms/Manual/remove');
    }
}
