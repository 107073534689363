import { Component, OnInit, Input, SimpleChanges, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuBatchProductType } from '../batch-receipt.model';
import { AspuBatchStart, AspuBatchStartFromBarcode, AspuKmRequest } from './batch-edit.model';
import { AspuBatchEditService } from './batch-edit.service';
import { IResult } from 'vekas_models/models/iresult';
import { LangService } from 'vekas_models/services/lang.service';
import { AspuBatchState } from '../batch-state.model';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuInitialFields, AspuMetaBase } from 'src/app/business/core/common/common.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter2, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from 'vekas_models/core/datepicker-adapter/datepicker-adapter';
import { AspuSettingsService } from '../../../../core/settings/settings.service';
import { AspuSettings } from '../../../../core/settings/settings.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AspuBatchService } from '../batch.service';
import { AspuCommonService } from '../../../core/common/common.service';
import { AspuReceiptService } from '../../receipt/receipt.service';

@Component({
    selector: 'app-batch-edit-component',
    templateUrl: './batch-edit.component.html',
    providers: [AspuBatchEditService, AspuIndicatorService, AspuSettingsService, AspuCommonService, AspuReceiptService,
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateAdapter, useClass: CustomAdapter2 },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class AspuBatchEditComponent implements OnInit {
    rows = new Array<AspuBatchProductType>();

    substatus: string;

    viewModel = new AspuBatchStart();

    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    performance = 0;
    settings = new AspuSettings();

    isApplyEnabled = true;

    @Input() productTypes: Array<AspuBatchProductType>;
    @Input() initialBatchNumber: string;
    @Input() receiptId: string;
    // @Input() Id

    modalReference: NgbModalRef;
    searchChangeObserver;

    constructor(private serv: AspuBatchEditService, private bserv: AspuCommonService,
        private rservice: AspuReceiptService,
        private lservice: LangService, private modalService: NgbModal,
        public toastService: ToastService, private indServ: AspuIndicatorService, private route: ActivatedRoute,
        private router: Router, private settingsService: AspuSettingsService
    ) {
        if (localStorage.getItem("token") == undefined) {
            console.log("BATCHPROCCESS -> localStorage.getItem('token') == undefined")
            this.router.navigate(['/home']);
        }

        //TODO: возможно стоит добавить отслеживание обновления индикаторов и делать редирект
        this.indServ.getIndicators().subscribe((data) => {
            if (data.Value.Batch == "Enabled") {
                this.router.navigate(['/aspu-batch-process']);
            }
            else {
                this.reload();
            }
        });

        this.getSettings();
    }

    private _doReload = this.doGetBatchStatuses.bind(this);
    private _doCheckState = this.doCheckStateStatuses.bind(this);
    private _doAction = this.doActionPrevent.bind(this);

    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    doAction(code: string) {
        console.log("doAction")
        console.log(code)
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;

            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }
        this.barcode = code;
        if (!this.hasBarcode())
            return;

        this.startBatchWithParameters(this.contentBatch)
    }

    @ViewChild('contentBatch', { static: true }) contentBatch?: TemplateRef<any>; //ElementRef<HTMLElement>;

    batchParams: Array<AspuMetaBase>;

    refreshRequesterType() {
        if (this.hasBarcode())
            return;

        let sources = this.productTypes.find(x => x.GroupType == 'Product').IdentificationCodesSources;
        let defaultSource = this.productTypes.find(x => x.GroupType == 'Product').RequesterType;
        console.log('defaultSource')
        console.log(defaultSource)
        if (defaultSource != undefined)
            this.viewModel.IdentificationCodesSource = defaultSource;

        if (defaultSource == undefined && sources.length == 1) {
            this.viewModel.IdentificationCodesSource = sources[0];
        }
    }

    ngOnInit() {
        //console.log('this.productTypes')
        //console.log(this.productTypes)

        this.refreshRequesterType();

        window.addEventListener("JobStateChanged", this._doReload, false);
        window.addEventListener("BatchStartJob", this._doCheckState, false);
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);

        let batchRawData = localStorage.getItem('plcBatchMeta');
        if (batchRawData != undefined) {
            this.batchParams = JSON.parse(batchRawData);
            console.log('this.batchParams')
            console.log(this.batchParams)
        }
    }

    ngOnDestroy() {
        window.removeEventListener('JobStateChanged', this._doReload);
        window.removeEventListener("BatchStartJob", this._doCheckState);
        window.removeEventListener("CodeReceived", this._doAction);
        window.removeEventListener("EnterCodeReceived", this._doAction);
    }

    doGetBatchStatuses() {
        this.loading = true;
        this.serv.getBatchStatuses().subscribe((data: IResult<AspuBatchState>) => {
            //this.substatus = data.Value.SubStepName;
            if (data.IsSuccess) {
                console.log(data.Value)
                if (data.Value.ProductionStartAllowed) {
                    this.router.navigate(['/aspu-batch-process']);
                }
                else {
                    this.isStarted = false;
                }
            }
            this.isReady = true;
            this.loading = false;
        })
    }

    isBatchStarted = false;

    doCheckStateStatuses(detail: any) {
        let data = detail.detail;
        if (data.IsSuccess) {
            if (data.Value.Active && this.isBatchStarted != true) {
                this.isBatchStarted = true;
            }
            else if (data.Value.Active != true && this.isBatchStarted) {
                this.isBatchStarted = false;
            }

            //console.log("data.Value.SubStepName")
            //console.log(data.Value.SubStepName)
            //console.log("this.substatus")
            //console.log(this.substatus)

            if (data.Value.SubStepName != undefined && data.Value.SubStepName != this.substatus) {
                this.toastService.getToast(data.Value.Name + ": " + data.Value.SubStepName, 'info');
                this.substatus = data.Value.SubStepName;
            }
            if (data.Value.Active == false) {
                this.router.navigate(['/aspu-batch-process']);
                this.doGetBatchStatuses();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            const cur = chng.currentValue;
            const prev = chng.previousValue;
            //console.log(propName)
            //console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
            if (propName == 'productTypes') {
                this.rows = cur;
                //console.log('ngOnChanges')
                //console.log(this.rows)
                this.rows.forEach((row: AspuBatchProductType) => {
                    row.SelectedProductTypeCode = row.ProductTypeCodes[0];
                });

                this.refreshRequesterType();

                this.isReady = true;
                this.loading = false;
            }

            this.viewModel.Name = this.initialBatchNumber;
            this.viewModel.ReceiptId = this.receiptId;
        }

        //console.log('this.rows');
        //console.log(this.rows);
    }

    private reload(): void {
        //this.serv.getReceipts().subscribe((data: IResult<any>) => {
        //        if (data.IsSuccess) {
        //            this.rows = data.Value;
        //            console.log(this.rows);
        //        }
        //    },
        //    error => console.error(error));
    }

    jobId: string;
    isStarted = false;

    startBatch() {
        console.log("startBatch");

        if (this.aspuBatchMetadata != undefined)
            this.viewModel.UserDefinedVars = this.aspuBatchMetadata;

        this.viewModel.ProductTypes = new Array<AspuKmRequest>();
        for (var i = 0; i < this.rows.length; i++) {
            let item = new AspuKmRequest();
            item.Count = this.rows[i].SelectedQuantityKm == undefined ? 0 : this.rows[i].SelectedQuantityKm;
            item.ErpId = this.rows[i].SelectedProductTypeCode;
            item.ProductTypeId = this.rows[i].Id;

            this.viewModel.ProductTypes.push(item);
        }
        this.isStarted = true;
        this.loading = true;
        this.serv.startBatch(this.viewModel).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                if (this.modalReference != undefined)
                    this.modalReference.close();

                localStorage.setItem('industry', data.Value.Industry)

                this.jobId = data.Value.JobId;
                console.log("JOBID: " + this.jobId);
                //this.loading = false;
                //this.router.navigate(['/aspu-batch-process']);
            }
            else {
                console.error(data.Messages);
                this.isStarted = false;
                this.loading = false;
            }
        },
            error => {
                console.error(error);
                this.isStarted = false;
                this.loading = false;
            }
        );
    }

    isBatchNumberAvailable() {
        console.log(localStorage.getItem('modulePath'));
        if (localStorage.getItem('modulePath') == '550-4')
            return false;
        return true;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    startBatchWithParameters(contentBatch: any) {
        console.log("contentBatch")
        console.log(contentBatch)
        if (this.batchParams.length == 0) {
            if (this.hasBarcode()) {
                this.startBatchFromBarcode();
                //else
                //    this.startBatch();
                return;
            }
            else if (this.getRequesterTypesEnabled().length <= 1) {
                this.startBatch();
                return;
            }
        }
        this.modalReference = this.modalService.open(contentBatch, { scrollable: false, windowClass: 'custom-modal-class', size: 'lg' });
    }

    aspuBatchMetadata: any;

    saveBatchParameters() {
        if (this.hasBarcode())
            this.startBatchFromBarcode();
        else
            this.startBatch();
    }

    barcode: string;
    hasBarcode() {
        return localStorage.getItem("StartBatchByKm") == 'true';
    }

    startBatchFromBarcode() {
        if (this.barcode != undefined) {
            this.loading = true;
            let batch = new AspuBatchStartFromBarcode(this.barcode);
            if (this.aspuBatchMetadata != undefined)
                batch.UserDefinedVars = this.aspuBatchMetadata;
            this.serv.startBatchFromBarcode(batch).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.loading = false;
                    //this.router.navigate(['/aspu-batch-process']);
                }
                this.barcode = undefined;
                this.loading = false;
            },
                error => console.error(error));
        }
    }

    confirmation = new ConfirmationModel();
    checkPerformance(contentBatch: any) {
        console.log(this.performance);
        console.log(this.rows.reduce((p, c) => p + (c.SelectedQuantityKm == undefined ? 0 : c.SelectedQuantityKm), 0));
        const selectedQuantity = this.rows.reduce((p, c) => p + (c.SelectedQuantityKm == undefined ? 0 : c.SelectedQuantityKm), 0);
        if (this.performance == 0 || selectedQuantity <= this.performance) {
            this.startBatchWithParameters(contentBatch);

            return;
        }

        this.confirmation.Parameter = contentBatch;
        this.confirmation.Counter++;

        this.confirmation.Message = LangService.getlocalized('BatchList', 'PerformanceMessage').replace('{0}', this.performance).replace('{1}', selectedQuantity) + '. ' + LangService.getlocalized('common', 'Sure');

        this.confirmation.ClassType = "warning";
    }

    getConfirmation(event) {
        console.log('CONFIRMATION')
        console.log(event)
        if (event) {
            this.startBatchWithParameters(this.confirmation.Parameter);
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    getRequesterTypesEnabled() {
        let sources = this.productTypes.find(x => x.GroupType == 'Product').IdentificationCodesSources
        if (sources.length == 0)
            sources.push(this.viewModel.IdentificationCodesSource)
        return sources;
    }

    getSettings() {
        this.settingsService.getSettings().subscribe((data: IResult<AspuSettings>) => {
            console.log('settings')
            console.log(data)
            if (data.IsSuccess) {
                this.settings = data.Value;
                this.performance = data.Value.Performance;
            }
        },
            error => console.error(error));
    }
}
