import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuReceipt } from './receipt-detail.model';
import { AspuReceiptService } from './receipt.service';
import { AspuProductType } from '../../common/receipt/receipt-detail.model';
import { AspuMetaBase } from '../../core/common/common.model';

@Component({
    selector: 'app-receipt-detail-component',
    templateUrl: './receipt-detail.component.html',
    providers: [AspuReceiptService]
})
export class AspuReceiptDetailComponent implements OnInit {
    viewModel: AspuReceipt = new AspuReceipt();
    rows = new Array<AspuProductType>();
    receiptParams: Array<AspuMetaBase>;
    loading = true;
    errors = new Array<any>(new Object());

    @Input() id: any;
    @Output() onChange = new EventEmitter();

    constructor(private serv: AspuReceiptService, private lservice: LangService, 
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        this.route.params.subscribe(params => {
            if (!params.id) {
                return;
            }
            this.id = params.id;
            this.loadItems();
        });
    }

    private _doReload = this.loadItems.bind(this);
    ngOnInit() {
        window.addEventListener("ReceiptsUpdated", this._doReload, false);

        let receiptRawData = localStorage.getItem('ReceiptsVariables');
        if (receiptRawData != undefined) {
            this.receiptParams = JSON.parse(receiptRawData);
        }
    }

    ngOnDestroy() {
        window.removeEventListener("ReceiptsUpdated", this._doReload);
    }

    //ngOnChanges(changes: SimpleChanges) {
    //    for (const propName in changes) {
    //        const chng = changes[propName];
    //        const cur = chng.currentValue;
    //        const prev = chng.previousValue;
    //        //console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    //        if (propName == 'id') {
    //            this.loadItems()
    //        }
    //    }
    //}

    loadItems() {
        this.loading = true;
        this.serv.getItemById(this.id).subscribe((data: IResult<AspuReceipt>) => {
            this.viewModel = data.Value;
            this.errors = data.ValidationMessages;
            this.rows = this.viewModel.ProductTypes.filter(x => x != undefined);
            this.loading = false;
            console.log(this.viewModel)
            this.loadImage();
        },
            error => console.error(error));
    }


    image: any;
    isImageExpanded = false;

    loadImage() {
        let id = this.viewModel.ProductTypes.find(x => x.GroupType == 'Product').Id;
        if (id != undefined) {
            this.serv.getImage(id).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.image = data.Value.Image;
                }
                else {
                }
            });
        }
    }

    getProfileNameById(id: string) {
        let profiles = this.rows.reduce((pn, u) => [...pn, ...u.Profiles], []);
        let name = profiles.filter(x => x.Id == id).map(x => x.Name)[0];
        return name;
    }

    updateViewModel($event) {
        console.log("event")
        console.log(event)
    }


    //form = new FormGroup({
    //    Name: new FormControl(''),
    //    Nomenclature: new FormControl(''),
    //    NomenclatureCode: new FormControl(''),
    //    StartupSkip: new FormControl(''),
    //});

    saveItem() {
        if (this.viewModel.Id != null) {
            this.loading = true;
            this.serv.updateItem(this.viewModel).subscribe(data => {
                if (data.IsSuccess) {
                    this.onChange.emit(undefined);
                    this.router.navigate(['/aspu-receipt-list']);
                } else {
                    this.errors = data.ValidationMessages;
                }
                this.loading = false;
            });
        }
    }

    editProfile(id: string): void {
        console.log(id);
        this.router.navigate(['../aspu-profile-edit/' + id + '/editCard'], { relativeTo: this.route });
    }
    

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
