import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuBoxEntity, AspuSetEntityResult } from './aggregation-box.model';

@Injectable()
export class AspuAggregationBoxService {
    path: string;

    constructor(
        private httpService: AspuHttpService) {
        let raw = JSON.parse(localStorage.getItem("AggrAlgorithms"));
        let alg = raw.filter(x => x.DisplayName === 'Агрегация короба').map(x => x.Type)[0];
        switch (alg) {
            case "Box4602":
                this.path = "Box4602";
                break;
            default:
                this.path = "Simple";
                break;
        }
    }

    getStatus(): Observable<IResult<AspuBoxEntity>> {
        return this.httpService.get<IResult<AspuBoxEntity>>('AggrAlgorithms/' + this.path);
    }

    setBoxCode(item: AspuCodeOnly) {
        return this.httpService.put<IResult<AspuSetEntityResult>>(JSON.stringify(item), 'AggrAlgorithms/' + this.path);
    }

    setBoxNotFull() {
        return this.httpService.put<IResult<boolean>>(null, 'AggrAlgorithms/'+ this.path+'/notFull');
    }

    cleanBox() {
        return this.httpService.delete<IResult<any>>(null, 'AggrAlgorithms/' + this.path);
    }

    removeCodeFromBox(item: AspuCodeOnly) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'AggrAlgorithms/'+ this.path+'/delete');
    }    

    removeCodeFromQueue(id: string) {
        return this.httpService.delete<IResult<any>>(id, 'AggrAlgorithms/' + this.path + '/queue');
    }

    plcQueueClear() {
        return this.httpService.post<IResult<any>>(null, 'AggrAlgorithms/'+ this.path+'/plc/queue/clear');
    } 
}