import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
//import { IResult } from '../../../../../../models/iresult';
import { AspuHttpService } from "../../../services/aspu.http.service";
import { AspuCodeOnly } from "../../code/code.model";
import { AspuDraft } from '../../draft/draft.model';
import { AspuReserveCodeRequest } from './code-reservation.model';

@Injectable()
export class AspuCodeReservationService {

    constructor(
        private httpService: AspuHttpService) { }

    findBatchesByCode(item: AspuCodeOnly): Observable<IResult<Array<AspuDraft>>> {
        return this.httpService.post<IResult<Array<AspuDraft>>>(JSON.stringify(item), 'AggrAlgorithms/CodesReservation/getBatchesByCode');
    }

    reserveCodes(item: AspuReserveCodeRequest): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'AggrAlgorithms/CodesReservation/reserveCodes');
    }
}