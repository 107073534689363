import { Component, HostListener } from "@angular/core";
import { ConfigurationService } from "./services/configuration.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent {
    title = "Автоматизированная Система Поштучного Учета";
    isNavExpand = true;

    public configuration: ConfigurationService;

    constructor() {
        this.configuration = ConfigurationService.getInstance();
        this.isNavExpand = localStorage.getItem("isNavExpand") == 'false' ? false : true;
        ConfigurationService.setIndustry();
    }

    ngOnInit() {
        window.addEventListener("ChangeNavSize", this._doChangeNavSize, false);
    }

    ngOnDestroy() {
        window.addEventListener("ChangeNavSize", this._doChangeNavSize);
    }

    private _doChangeNavSize = this.doChangeNavSize.bind(this);



    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        localStorage.setItem("isUserActive", 'true');
    }

    @HostListener('window:mousedown', ['$event'])
    handleMouseDown(event: KeyboardEvent) {
        localStorage.setItem("isUserActive", 'true');
    }

    doChangeNavSize(data: any) {
        this.isNavExpand = JSON.parse(data.detail);
    }

    getTheme() {
        return localStorage.getItem("userTheme");
    }
}
