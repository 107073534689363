import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AuthorizationService } from 'vekas_models/services/authorization.service';
import { LangService } from 'vekas_models/services/lang.service';
import { FileOperation } from '../../core/common/common.model';
import { AspuIndicator } from '../../core/indicator/indicator.model';
import { AspuIndicatorService } from '../../core/indicator/indicator.service';
import { saveAs } from 'file-saver';
import { AspuFileService } from './file.service';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-component',
  templateUrl: './file.component.html',
  providers: [AspuIndicatorService, AspuFileService]
})
export class AspuFileComponent implements OnInit {

  indicator = new AspuIndicator();
  algorithms: Array<FileOperation>;

  isLoadProductCodesEnabled=false;
  isUploadProductCodesEnabled=false;

  constructor( private lservice: LangService, 
    public modalService: NgbModal, 
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private toastService: ToastService,
    private router: Router,
    private indServ: AspuIndicatorService,
    private fileService: AspuFileService){ 
      this.loadItemsWithStateCheck();
      this.algorithms = JSON.parse(localStorage.getItem("FileOperations"));

      this.isLoadProductCodesEnabled = this.algorithms.find(x => x.Type === 'LoadProductCodes') != undefined;
      this.isUploadProductCodesEnabled = this.algorithms.find(x => x.Type === 'UploadProductCodes') != undefined;
    }

    ngOnInit() {      
      window.addEventListener("ShowPopupMessage", this._doShowPopup, false);
    }
    
  ngOnDestroy() {
      window.removeEventListener("ShowPopupMessage", this._doShowPopup);
  }

  getlocalized(type: string, name: string) {
    return LangService.getlocalized(type, name);
  }

  public loadItemsWithStateCheck() {
    this.indServ.getIndicators().subscribe((data) => {this.indicator = data.Value;});
  }

  hostUrl: any = (<any>window).aspuApiServiceHost;

  uploadCodesConfig = {
    multiple: true,
    formatsAllowed: ".csv, .json, .txt",
    maxSize: "20",
    uploadAPI:  {
      url:`${this.hostUrl}/api/files/LoadProductCodes/codes/`,
      method:"POST",
      headers: {
            "Authorization": this.authorizationService.GetToken() == undefined
                ? ""
                : "Bearer " + this.authorizationService.GetToken(),
            "Lang": LangService.currentLangName
         },
      responseType: 'application/json; charset=utf-8',
    },
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    fileNameIndex: false,
    replaceTexts: {
        selectFileBtn: this.getlocalized('common', 'SelectFiles'),
        resetBtn: this.getlocalized('common', 'ResetFiles'),
        uploadBtn: this.getlocalized('common', 'UploadFiles'),
        dragNDropBox: 'Drag N Drop',
        attachPinBtn: 'Attach Files...',
        afterUploadMsg_success: this.getlocalized('common', 'FilesUploadedSuccess'),
        afterUploadMsg_error: this.getlocalized('common', 'FilesUploadedFailed'),
        sizeLimit: this.getlocalized('common', 'SizeLimit'),
    }
  };

  CodesUpload(event): void {
    console.log(event.body);
    let json = JSON.parse(event.body.toString());
    console.log(json);
    json.Value.forEach((x: string) => { this.toastService.getToast( x, 'info') });
    json.Messages.forEach((x: string) => { this.toastService.getToast( x, 'error') });
  }

  saveCodesToJsonFile()
  {
    this.fileService.getCodesToJsonFile().subscribe((data: IResult<any>) =>
    {
      if (data.IsSuccess)
      {
        const blob = new Blob([data.Value], { type: 'text/plain' });
        saveAs(blob, 'codes.json', true);
      } else
      {
        console.log(data.Messages);
      }
    }, error => console.error(error));
  }

  saveCodesToCsvFile()
  {
    this.fileService.getCodesToCsvFile().subscribe((data: IResult<any>) =>
    {
      if (data.IsSuccess)
      {
        const blob = new Blob([data.Value], { type: 'text/plain' });
        saveAs(blob, 'codes.csv', true);
      } else
      {
        console.log(data.Messages);
      }
    }, error => console.error(error));
  }

  saveCodesToTxtFile()
  {
    this.fileService.getCodesToTxtFile().subscribe((data: IResult<any>) =>
    {
      if (data.IsSuccess)
      {
        const blob = new Blob([data.Value], { type: 'text/plain' });
        saveAs(blob, 'codes.txt', true);
      } else
      {
        console.log(data.Messages);
      }
    }, error => console.error(error));
  }

  
  modalReference: any;
  popupMessage: string;
  popupMessageStatus: boolean;

  private _doShowPopup = this.doShowPopup.bind(this);

  doShowPopup(data: any) {
      console.log(data);
      let result = data.detail;
      this.openPopup();
      this.popupMessage = result.topic;
      this.popupMessageStatus = result.data;
      setTimeout(() => { this.modalService.dismissAll() }, 1000);
  }

  @ViewChild('contentPopup', { static: true }) viewMePopup?: TemplateRef<any>; //ElementRef<HTMLElement>;
  openPopup() {
      this.modalReference = this.modalService.open(this.viewMePopup, { centered: true, scrollable: false, windowClass: 'custom-modal-class', size: 'xl' });
  }
}

