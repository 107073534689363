<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span id="codeInputSpan" style="display:none"></span>
            <span class="header-badge text-uppercase">{{getlocalized('StatusCodeList', 'StatusCodeChange')}}</span>
        </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
        <div *ngIf="successAlert" class="alert alert-success text-center text-uppercase pb-5 pt-5 display-4" role="alert">
            {{getlocalized('StatusCodeList', 'Success')}}
        </div>

        <div *ngIf="failAlert" class="alert alert-danger text-center text-uppercase pb-5 pt-5 display-4" role="alert">
            {{getlocalized('StatusCodeList', 'Error')}}
        </div>

        <div *ngIf="!failAlert && !successAlert" class="alert alert-warning text-uppercase text-center pb-5 pt-5 display-4" role="alert">
            {{getlocalized('StatusCodeList', 'ScanCode')}}
        </div>

        <div class="row align-items-center d-flex justify-content-center mb-2">
            <div class="col">
                <span class="btn-group2 btn-group-toggle btn-group-vertical" ngbRadioGroup name="radioBasic" [(ngModel)]="mode">
                    <label ngbButtonLabel class="btn-lg text-uppercase btn-agg mb-1 mt-1 rounded-bottom" [ngClass]="{'btn-success': mode=='validate', 'btn-info': mode!='validate'}">
                        <input id="CodeStatus_ValidationToggleAction" ngbButton type="radio" class="btn-check" value="validate">{{getlocalized('StatusCodeList', 'Validation')}}
                    </label>
                    <label ngbButtonLabel class="btn-lg text-uppercase btn-agg mb-1 mt-1 rounded-bottom rounded-top" [ngClass]="{'btn-danger': mode=='defect', 'btn-info': mode!='defect'}">
                        <input id="CodeStatus_DefectToggleAction" ngbButton type="radio" class="btn-check" value="defect">{{getlocalized('StatusCodeList', 'Defect')}}
                    </label>
                    <label ngbButtonLabel class="btn-lg text-uppercase btn-agg mb-1 mt-1 rounded-top" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                        <input id="CodeStatus_InfoToggleAction" ngbButton type="radio" class="btn-check" value="info">{{getlocalized('StatusCodeList', 'Information')}}
                    </label>
                </span>
            </div>
        </div>

        <div class="form-row mt-1">
            <div class=" mb-3 pe-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text text-uppercase">{{getlocalized('common', 'Code')}}</span>
                    <input id="codeInput" [disabled]="false" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="code">
                    <button id="codeButton"
                            *ngIf="true" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(code)" class="input-group-append btn btn- ms-3 btn-success">
                        <i class="fa fa-lg fa-arrow-circle-right"></i><!--{{getlocalized('common', 'Save')}}-->
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentCode!=undefined">
    <app-code-info-component [code]="currentCode" [counter]="counter"></app-code-info-component>
</div>
