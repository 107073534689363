import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuIndicator } from './indicator.model';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';

@Injectable()
export class AspuIndicatorService {

    constructor(
        private httpService: AspuHttpService) { }

    getIndicators(): Observable<IResult<AspuIndicator>> {
        return this.httpService.get<IResult<AspuIndicator>>('Management/indicators', null);
    }
}