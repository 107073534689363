<div class="btn-group2">
    <!--<button id="Receipt_Edit_BackAction" class="btn btn-lg btn-info me-1 " [routerLink]="['/aspu-receipt-list']"><i class="fa fa-reply-all me-1 "></i>{{getlocalized('common', 'ToReceipts')}}</button>
    <button id="Receipt_Edit_SaveAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()" class="btn btn-lg btn-success"><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>-->

    <button id="Receipt_Edit_BackAction" class="btn btn-info btn-icon me-1 " [routerLink]="['/aspu-receipt-list']" style="width: 186px">
        <span><i class="fa-solid fa-2x fa-pull-left fa-reply-all mt-0 me-0"></i></span>
        <span class="btn-icon-label mt-0" style="">{{getlocalized('common', 'ToReceipts')}}</span>
    </button>

    <button id="Receipt_Edit_SaveAction" [disabled]="false" (click)="saveItem()"
            class="btn btn-success btn-icon me-1" style="width: 166px;">
        <span><i class="fa-solid fa-2x fa-save fa-pull-left mt-0 me-0"></i></span>
        <span class="btn-icon-label mt-0">{{getlocalized('common', 'Save')}}</span>
    </button>

    <img *ngIf="image!=undefined" style="max-height: 64px !important; width: auto !important;" src="data:image/jpg;base64,{{image}}" class="img-thumbnail rounded mr-1" />
</div>

<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">
                {{getlocalized('common', 'Receipt')}} : {{viewModel.Name}}
            </span>
            <!--<span class="badge badge-danger mt-1" style="float: right">{{getlocalized('common', 'Edition')}}</span>-->
        </h3>
    </div>

    <div class="card-body form-group mb-0 pb-0">
        <div class="row ms-1 me-1">
            <div class="mb-3 pe-3 ps-0 col-lg-12 col-xl-12">
                <!--<div *ngIf="image!=undefined" class="photo mb-2">
                    <img src="data:image/jpg;base64,{{image}}" 
                         [ngClass]="{'custom-img-collapsed' : !isImageExpanded, 'custom-img-expanded' : isImageExpanded}" 
                         class="img-fluid rounded mx-auto d-block"
                         (click)="isImageExpanded=!isImageExpanded"/>
                </div>-->
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('ReceiptList', 'Name')}}</span>
                    <input id="Receipt_Edit_NameInput" type="text" class="form-control" [(ngModel)]="viewModel.Name">
                    <app-validation [val]="viewModel.Name" [name]="'Name'" [errors]="errors" [size]="'lg'"></app-validation>
                </div>
            </div>

            <app-meta-component class="col-12 p-0" *ngIf="viewModel.Id!=undefined" [metaType]="'receipt'"
                                [isValidationEnabled]="true"
                                [isAutoReloadEnabled]="false"
                                [errors]="errors"
                                [startValues]="viewModel.Fields"
                                (onUpdateData)="viewModel.Fields = $event;">
            </app-meta-component>
        </div>

        <app-receipt-product-list-component [productList]=rows [loading]=loading></app-receipt-product-list-component>

        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()" class="btn btn-lg btn-success mb-2 float-end"><i class="fa fa-save me-1"></i>{{getlocalized('common', 'Save')}}</button>
    </div>
</div>

