import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import { AspuLayerState } from './layer-serialization.model';

@Injectable()
export class AspuLayerSerializationService {
    url = "AggrAlgorithms/Layer";

    constructor(
        private httpService: AspuHttpService) {}

    getStatus(): Observable<IResult<AspuLayerState>> {
        return this.httpService.get<IResult<AspuLayerState>>(this.url);
    }

    clearLayer(){
      return this.httpService.delete<IResult<any>>(null, this.url);
    }

    pushCode(item: AspuCodeOnly):Observable<IResult<AspuSetEntityResult>>{
      return this.httpService.post<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url);
    }
}
