import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import {AspuBlockAggrState} from './aggregation-box-visual.model';

@Injectable()
export class AspuAggregationBoxVisualService {
    url = "AggrAlgorithms/LayerBox";

    constructor(
        private httpService: AspuHttpService) {}

    getStatus(): Observable<IResult<AspuBlockAggrState>> {
      return this.httpService.get<IResult<AspuBlockAggrState>>(this.url);
    }

    clearLayer(){
      return this.httpService.delete<IResult<any>>(null, this.url + '/layer');
    }

    clearAll(){
      return this.httpService.delete<IResult<any>>(null, this.url);
    }

    pushCode(item: AspuCodeOnly):Observable<IResult<AspuSetEntityResult>>{
      return this.httpService.put<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url);
    }
}
