<ul ngbNav #nav="ngbNav" [destroyOnHide]="true" class="nav-tabs">
    <li [ngbNavItem]="99" [destroyOnHide]="true" *ngIf="viewModel!=undefined">
        <a ngbNavLink class="h5">{{getlocalized('common', 'Plc')}}</a>
        <ng-template ngbNavContent>
          <div class="card mt-2">
            <div class="card-header">
              <h3>
                <span class="header-badge text-uppercase">{{getlocalized('common', 'Plc')}}</span>
              </h3>
            </div>
            <div class="card-body form-group mb-3 pb-0">

              <!--<div *ngIf="viewModel!=undefined" class="form-row">
                <div class="mb-0 pe-3 col-lg-6 col-md-12">
                  <div *ngFor="let val of viewModel" class="mt-2 mb-2" style="">
                    <div *ngIf="true" class="h3">
                      <span class="badge badge-secondary">
                        {{val.Description}}:
                        <i *ngIf="val.Value" class="fa fa-circle text-success"></i>
                        <i *ngIf="val.Value==false" class="fa fa-circle text-danger"></i>
                        <i *ngIf="val.Value==undefined" class="fa fa-circle text-dark"></i>
                      </span>
                    </div>

                  </div>
                </div>
              </div>-->

              <div *ngIf="viewModel!=undefined" class="form-row">
                <div class="mb-0 pe-3 col-xl-10 col-lg-12">
                  <table class="table table-striped">
                    <tbody>
                      <tr *ngFor="let val of viewModel">
                        <td class="text-uppercase" style="font-size: 24px; font-weight: 500;">{{val.Description}}</td>
                        <td>
                          <div *ngIf="val.Value" class="plc-indicator bg-success "></div>
                          <div *ngIf="val.Value==false" class="plc-indicator bg-danger "></div>
                          <div *ngIf="val.Value==undefined" class="plc-indicator bg-dark "></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
           </div>
        </ng-template>
    </li>

    <!--<li [ngbNavItem]="i" [destroyOnHide]="true" *ngFor="let config of configs; let i = index">
        <a ngbNavLink class="h5">{{config.Description}}</a>
        <ng-template ngbNavContent>
            <app-plc-custom-edit-component [viewModel]="config"></app-plc-custom-edit-component>
        </ng-template>
    </li>-->
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
  




