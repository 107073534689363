import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuBoxEntity, AspuBoxQueue, AspuEntity, AspuSetEntityResult} from './aggregation-box.model';
import { AspuAggregationBoxService } from './aggregation-box-list.service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuCode, AspuCodeOnly } from '../../code/code.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { AggrAlgorithm } from '../../../core/common/common.model';
import { ViewChild } from '@angular/core';
import { TemplateRef } from '@angular/core';

@Component({
    selector: 'app-aggregation-box-list-component',
    templateUrl: './aggregation-box-list.component.html',
    providers: [AspuAggregationBoxService, AspuIndicatorService],
  styles: ['.codeReadedSuccess { background-color: #A0FFA0; } .coreReadedFailed { background-color: #FF4040;}']
})
export class AspuAggregationBoxListComponent implements OnInit {
    viewModel = new AspuBoxEntity();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    CodeAddedSuccess = '';

    isHandleModeAvailable = false;
    isDetalized = false;

    mode = "aggregation"; //aggregation, remove, info
    isShowPlcQueueClearButton = false;

    currentProductCode: string;

    algorithms: Array<AggrAlgorithm>;

    constructor(private serv: AspuAggregationBoxService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {
        this.algorithms = JSON.parse(localStorage.getItem("AggrAlgorithms"));
        this.isHandleModeAvailable = this.algorithms.find(x => x.Type === 'Box4602')!=undefined;

        this.viewModel.Box = new AspuEntity();
        this.loadItemsWithStateCheck();

        window.addEventListener("BoxZoneStateUpdated", this._doReload, false);
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
        window.addEventListener("AspuIndicatorUpdated", this._doReload, false);
        window.addEventListener("AggrStateUpdated", this._doReload, false);
        window.addEventListener("ShowPopupMessage", this._doShowPopup, false);
    }
    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReloadEvent.bind(this);

    doActionPrevent(data: any) {
        console.log("CodeReceived - prevent")
        this.doAction(data.detail)
    }

    doReloadEvent() {
        this.loading = true;
        this.loadItemsWithStateCheck();
    }

    ngOnInit() { 
        this.InitMode();
    }

    InitMode() {
        var algoritms = localStorage.getItem("AggrAlgorithms");
        if (algoritms == undefined) return;

        var list = JSON.parse(algoritms) as Array<AggrAlgorithm>;
        if (list == undefined) return;

        var param = list.filter(x=>x.Type == "Box4602" || x.Type == "Simple").find(x=>x.IsShowPlcQueueClearButton != undefined);
        if (param == undefined) return;

        this.isShowPlcQueueClearButton = param.IsShowPlcQueueClearButton;
    }

    ngOnDestroy() {
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener('EnterCodeReceived', this._doAction);
        window.removeEventListener('BoxZoneStateUpdated', this._doReload);
        window.removeEventListener('AspuIndicatorUpdated', this._doReload);
        window.removeEventListener('AggrStateUpdated', this._doReload);
        window.removeEventListener("ShowPopupMessage", this._doShowPopup);
    }

    onSort(event) {  }

    public  loadItems() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<AspuBoxEntity>) => { 
            this.viewModel = data.Value;
            if (this.viewModel.Status == 'BoxUpdate')
                this.viewModel.Box.Code = undefined;
            this.isReady = true;
            this.loading = false;
        }, error => {
                console.error(error)
        });
    }

    indicator = new AspuIndicator();

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) =>
        {
            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled")
            {
                this.loadItems();
            }
            else
            {
                this.loading = false;
            }
        });
    }

    counter = 0;
    currentCode: AspuCode;

    editCode(row: AspuCode) {
        this.counter++;
        this.currentCode = row;
        this.currentProductCode = undefined;
    }

    isCodeReceivedPrevented = false;
    doAction(code: string) {
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;

            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

       // console.log("doAction");

        switch (this.mode) {
            case "aggregation":
                this.setBox(code.trim());
                break;
            case "remove":
                this.removeBox(code.trim());
                break;
            case "info":
                console.log('this.isCodeReceivedPrevented')
                console.log(this.isCodeReceivedPrevented)
                if (!this.isCodeReceivedPrevented) {
                    this.editCode(new AspuCode(code.trim()));
                }
                break;
            default: break;
        }
  }

    getCodeBgColor()
    {
      return this.CodeAddedSuccess; // ? 'codeReadedSuccess' : 'coreReadedFailed';
    }

    setTimetToCleanCodeAddedSuccess()
    {
      setTimeout(() => { this.CodeAddedSuccess = ''; }, 1000);

    }

    setBox(code: string) {
        this.serv.setBoxCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                let result = data.Value;
                if (this.currentProductCode == undefined) {
                    if (!result.IsOk) {
                        this.toastService.getToast(result.Message, 'error');
                        this.loading = false;

                        this.currentProductCode = undefined;
                        this.viewModel.Box.Code = undefined;
                        this.CodeAddedSuccess = 'coreReadedFailed';
                        this.setTimetToCleanCodeAddedSuccess();
                        return;
                    }
                    else {
                        this.toastService.getToast("Код добавлен!", 'info');
                        this.CodeAddedSuccess = 'codeReadedSuccess';
                    }
                    this.viewModel.Box.IsEmpty = result.IsEmpty;
                    this.viewModel.Box.IsError = result.IsError;
                    this.viewModel.Box.IsOk = result.IsOk;
                    if (result.IsOk) {
                        this.viewModel.Status = "ProductsEnter";
                        this.viewModel.IsClearAvailable = true;
                    }
                }
                else {
                    this.currentProductCode = undefined;
                  if (data.Value.IsOk)
                  {
                        this.CodeAddedSuccess = 'codeReadedSuccess';
                      this.toastService.getToast("Код добавлен!", 'info');
                    }
                    else
                    {
                        this.CodeAddedSuccess = 'coreReadedFailed';
                      this.toastService.getToast(data.Value.Message, 'error');
                    }
                }
                this.loadItems();
            }

            this.setTimetToCleanCodeAddedSuccess();
            this.loading = false;
        },
            error => console.error(error));
    }

    removeBox(code: string) { //TODO: removeBox & removeCodeFromBox do the same!!!
        this.loading = true;
        this.serv.removeCodeFromBox(new AspuCodeOnly(code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
              this.CodeAddedSuccess = 'codeReadedSuccess';
                this.toastService.getToast("Удаление кода выполнено!", 'warning');
                this.loadItems();
            }
            else {
                data.Messages.forEach((val: string) => {
                    this.CodeAddedSuccess = 'coreReadedFailed';
                    this.toastService.getToast(val, 'error');
                });
            }

            this.setTimetToCleanCodeAddedSuccess();
            this.currentProductCode = undefined;
            this.loading = false;
        },
            error => console.error(error));
    }
       
    removeItems() {
        this.loading = true;
        this.serv.cleanBox().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }

            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    closeNotFull() {
        this.actionRow(undefined, "closeNotFull");        
    }

    plcQueueClear() {
        this.loading = true;
        this.serv.plcQueueClear().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            this.isReady = true;
        },
        error => console.error(error)); 
        this.loading = false;
    }

    removeCodeFromBox(code: string) {
        this.loading = true;
        this.serv.removeCodeFromBox(new AspuCodeOnly(code)).subscribe((data: IResult<boolean>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {

        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning"
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'closeNotFull') {
                this.loading = true;
                this.serv.setBoxNotFull().subscribe((data: IResult<boolean>) => {
                    if (data.IsSuccess) {
                        this.loadItems();
                    }
                    this.loading = false;
                },
                    error => console.error(error));
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    getRealCount() {
        if (this.viewModel != undefined && this.viewModel.Products!=undefined)
            return this.viewModel.Products.filter(x => x.IsOk == true).length;
        return '-'
    }

    hasParrentInfo() {
        return this.algorithms.some(x => x.ShowParentInfo && x.Type=='Simple');

        //localStorage.getItem("ReceiptsVariables", JSON.stringify(this.algorithms.ReceiptsVariables));
    }

    removeFromQueue(id: string) {
        this.loading = true;
        this.serv.removeCodeFromQueue(id).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    isInvalidBoxCode() {

        return this.viewModel != undefined && this.viewModel.IsQueueEnabled && this.viewModel.Queue.length > 0 && !this.viewModel.Queue[0].IsOk
    }

    getRowClass = (row: AspuEntity) => {
        return {
            'bg-warning': row.IsError
            //'bg-dark text-danger': row.Type == "Critical" && row.ConfirmStatus === "NotConfirmed",
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    modalReference: any;
    popupMessage: string;
    popupMessageStatus: boolean;

    private _doShowPopup = this.doShowPopup.bind(this);

    doShowPopup(data: any) {
        console.log(data);
        let result = data.detail;
        this.openPopup();
        this.popupMessage = result.topic;
        this.popupMessageStatus = result.data;
        setTimeout(() => { this.modalService.dismissAll() }, 1000);
    }

    @ViewChild('contentPopup', { static: true }) viewMePopup?: TemplateRef<any>; //ElementRef<HTMLElement>;
    openPopup() {
        this.modalReference = this.modalService.open(this.viewMePopup, { centered: true, scrollable: false, windowClass: 'custom-modal-class', size: 'xl' });
    }
}
