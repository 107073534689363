import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuDriver, AspuPrinter, AspuPrinterId } from './printer.model';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
import { Page } from 'vekas_models/models/page';

@Injectable()
export class AspuPrinterService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(): Observable<IResult<Array<AspuPrinter>>> {
        return this.httpService.get<IResult<Array<AspuPrinter>>>('Printers', null);
    }

    getItemsV2(filter: FiltersContainer): Observable<IResult<Page<AspuPrinter>>> {
        return this.httpService.post<IResult<Page<AspuPrinter>>>(filter, 'printers/list');
    }

    getDrivers(): Observable<IResult<Array<AspuDriver>>> {
        return this.httpService.get<IResult<Array<AspuDriver>>>('Printers/drivers', null);
    }

    createItem(item: AspuPrinter): Observable<IResult<AspuPrinter>> {
        return this.httpService.put<IResult<AspuPrinter>>(JSON.stringify(item), 'Printers');
    }

    updateItem(item: AspuPrinter): Observable<IResult<AspuPrinter>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Printers/' + item.Id);
    }

    deleteItem(id: string): Observable<IResult<any>> {
        return this.httpService.delete<IResult<any>>(null, 'Printers/' + id);
    }
    
    refreshTemplates(item: AspuPrinterId): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Printers/templates');
    }
}