import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuHttpService } from '../../services/aspu.http.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { DebugAggregationsInfo, DebugCreateCommonAggregation, DebugCurrentCodesAvailabilityOperations } from './debug.model';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';

@Injectable()
export class DebugService {

    constructor(
        private httpService: AspuHttpService) { }

    //general
    backup(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/general/backup');
    }

    clearBatch(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/general/ClearBatch');
    }

    //common
    getAggregations(): Observable<IResult<DebugAggregationsInfo>> {
        return this.httpService.get<IResult<DebugAggregationsInfo>>('debug/common/CreateAggregations', null);
    }

    createAggregations(item: DebugCreateCommonAggregation): Observable<IResult<number>> {
        return this.httpService.post<IResult<number>>(JSON.stringify(item), 'debug/common/CreateAggregations');
    }

    //current
    getCurrent(): Observable<IResult<DebugCurrentCodesAvailabilityOperations>> {
        return this.httpService.get<IResult<DebugCurrentCodesAvailabilityOperations>>('debug/current', null);
    }

    setPrintNew(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/SetPrintNew');
    }

    setPrintInProgress(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/SetPrintInProgress');
    }

    setPrintFinished(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/SetPrintFinished');
    }

    setDefect(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/SetDefect');
    }

    unsetDefect(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/UnsetDefect');
    }

    setValidated(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/SetValidated');
    }

    unsetValidated(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/UnsetValidated');
    }

    createAggr(genCodes: boolean): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/CreateAggr?genCodes=' + genCodes);
    }

    deleteAggr(): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(null, 'debug/current/DeleteAggr');
    }

    //current
    getCode(isBox: boolean): Observable<IResult<any>> {

        return isBox
            ? this.httpService.get<IResult<any>>('debug', 'code?GroupType=Box&Role=Parent&PrintingStatus=Printed')
            : this.httpService.get<IResult<any>>('debug', 'code?GroupType=Product&Role=Child&PrintingStatus=Printed')
    }
}