import { AspuEntity } from "../box/aggregation-box.model";

export class PackZoneStateDto {
    ModalStatus: string; // None,    StorageError,    PackCreating
    Storage: Array<AspuEntity>;
    Pack: Array<AspuEntity>;
    ErrorCode: AspuEntity;
    Capacity: number;
    IsReplaceModeOn:boolean;
    PageLength: number;
}


export class PackZoneClearRequest {
    Mode: number; //  Pack,    All

    constructor(mode: number) {
        this.Mode = mode;
    }
}
