<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="btn-group2">
        <button id="AggBox_CloseAction" [disabled]="isCloseBoxActive || viewModel.Pack == undefined || viewModel.Pack.length < 1 || viewModel.ModalStatus == 'PackIsFull'" class="btn btn-lg btn-info me-1" (click)="checkBoxBeforClose()"><i class="fa fa-lg fa-times me-1"></i>{{getlocalized('common', 'CloseBox')}}</button>
        <button id="AggBox_CancelAction" [disabled]="!isCloseBoxActive || viewModel.Pack == undefined || viewModel.Pack.length < 1 || viewModel.ModalStatus == 'PackIsFull'" class="btn btn-lg btn-info me-1" (click)="cancelCloseBox()"><i class="fa fa-lg fa-times me-1"></i>{{getlocalized('common', 'Cancel')}}</button>
        <button id="AggBox_ClearAction"    [disabled]="viewModel.Pack == undefined || viewModel.Pack.length < 1" class="btn btn-lg btn-danger me-1" (click)="clearBox()"><i class="fa fa-lg fa-times me-1"></i>{{getlocalized('common', 'ClearBox')}}</button>
        <button id="AggBox_ClearAllAction" [disabled]="(viewModel.Pack == undefined || viewModel.Pack.length < 1) && (viewModel.Storage == undefined || viewModel.Storage.length < 1)" class="btn btn-lg btn-danger" (click)="clearAllBoxs()"><i class="fa fa-lg fa-trash me-1"></i>{{getlocalized('common', 'ClearAll')}}</button>
    </div>

    <div class="card mt-2">
        <div class="card-header">
            <h3>
                <span id="codeInputSpan" style="display:none"></span>
                <span class="header-badge2 h3 text-uppercase">{{getlocalized('common', 'AggregationBox')}}</span>
                <span *ngIf="loading" class="ms-3"><app-spinner></app-spinner></span>
                <span class="btn-group2 btn-group-toggle float-end" ngbRadioGroup name="radioBasic" [(ngModel)]="mode">
                    <label ngbButtonLabel class="btn-lg me-1" [ngClass]="{'btn-success': mode=='queue', 'btn-info': mode!='queue'}">
                        <input id="AggBox_QueueToggleAction" class="btn-check" ngbButton type="radio" [value]="checkFullBox('queue')">{{getlocalized('AggregationList', 'Queue')}}
                    </label>
                    <label ngbButtonLabel class="btn btn-lg me-1 " [ngClass]="{'btn-success': mode=='aggregation', 'btn-info': mode!='aggregation'}">
                        <input id="AggBox_AggregationToggleAction" class="btn-check" ngbButton type="radio" [value]="checkFullBox('aggregation')">{{getlocalized('AggregationList', 'Aggregation')}}
                    </label>
                    <label ngbButtonLabel class="btn btn-lg me-1 " [ngClass]="{'btn-secondary': mode=='replace', 'btn-info': mode!='replace'}">
                        <input id="AggBox_ReplaceToggleAction" class="btn-check" ngbButton type="radio" value="replace">{{getlocalized('AggregationList', 'Replace')}}
                    </label>
                    <label ngbButtonLabel class="btn btn-lg me-1 " [ngClass]="{'btn-danger': mode=='remove', 'btn-info': mode!='remove'}">
                        <input id="AggBox_RemoveToggleAction" class="btn-check" ngbButton type="radio" value="remove">{{getlocalized('AggregationList', 'Removing')}}
                    </label>
                    <label ngbButtonLabel class="btn btn-lg" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                        <input id="AggBox_InfoToggleAction" class="btn-check" ngbButton type="radio" value="info">{{getlocalized('AggregationList', 'Information')}}
                    </label>
                </span>
            </h3>
        </div>
        <div class="card-body form-group mb-3 pb-0">

            <div *ngIf="mode=='info'" class="form-row">
                <div class="input-group input-group-sm mb-3 pr-3 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'Code')}}</span>
                        <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentInfoCode">
                        <button id="codeButton" *ngIf="currentInfoCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(currentInfoCode)" class="input-group-append btn btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right me-1"></i>{{getlocalized('common', 'Save')}}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="mode!='info' && (!isBoxScanModeActive || mode=='remove' || mode=='replace' || mode =='queue')" class="form-row">
                <div class="input-group input-group-sm mb-3 pr-3 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'ProductCode')}}</span>
                        <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentProductCode">
                        <button id="codeButton" *ngIf="currentProductCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(currentProductCode)" class="input-group-append btn btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right me-1"></i>{{getlocalized('common', 'Save')}}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isBoxScanModeActive && mode=='aggregation'" class="form-row">
                <div class="input-group input-group-sm mb-3 pr-3 col-lg-12 col-md-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase">{{getlocalized('AggregationList', 'BoxCode')}}</span>
                        <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentBoxCode">
                        <button id="codeButton" *ngIf="currentBoxCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(currentBoxCode)" class="input-group-append  btn btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right me-1"></i>{{getlocalized('common', 'Save')}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="mb-3 pe-3 col-lg-6 col-md-12">
                    <h3>
                        <span class="badge bg-success text-uppercase">{{getlocalized('AggregationList', 'Queue')}}</span>
                        <span *ngIf="loading" class="ms-3"><app-spinner></app-spinner></span>
                        <span class="badge bg-primary float-end" style="margin-top:0px;">{{getlocalized('Common', 'Count')}}: {{viewModelQueuePage.TotalElements}}</span>
                    </h3>
                    <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable double-table"
                                   id="someTable"
                                   [rows]="viewModelQueuePage.Items"
                                   [loadingIndicator]=""
								   [externalPaging]="true"
								   [externalSorting]="true"
								   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   rowHeight="auto"
                                   (sort)="onSort($event)"
                                   [rowClass]="getRowClass"
                                   [reorderable]="reorderable"
                                   [count]="viewModelQueuePage.TotalElements"
                                   [limit]="viewModelQueuePage.Size"
                                   [offset]="viewModelQueuePage.PageNumber"
                                   (page)="onQueuePage($event)"> 
                                   [externalPaging]="true">
                        <ngx-datatable-column name="{{getlocalized('AggregationList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    <span (click)="sort()">
                                        {{column.name}}
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <code>{{ value }}</code>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    <span>
                                        {{column.name}}
                                    </span>
                                </div>
                                <div class="btn-group2">
                                    <button id="AggBox_UpdateQueueAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadQueueItems()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group2 action-buttons">
                                    <button id="AggBox_RemoveQueueProductAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Remove Product" class="btn btn-sm btn-danger" (click)="removeProduct(row['Code'])"><i class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>

                <div class="mb-3 pe-3 col-lg-6 col-md-12">
                    <h3>
                        <span class="badge bg-success text-uppercase">{{getlocalized('AggregationList', 'FormedBox')}} </span>
                        <span *ngIf="loading" class="ms-3"><app-spinner></app-spinner></span>
                        <span class="badge bg-primary float-end" style="margin-top:0px;">{{getlocalized('Common', 'Count')}}: {{viewModelPackPage.TotalElements}} {{getlocalized('Common', 'of')}} {{viewModel?.Capacity}}</span>
                    </h3>
                    <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable double-table"
                                   id="someTable"
                                   [rows]="viewModelPackPage.Items"
                                   [loadingIndicator]=""
								   [externalPaging]="true"
								   [externalSorting]="true"
                                   [columnMode]="ColumnMode.force"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   rowHeight="auto"
                                   (sort)="onSort($event)"
                                   [rowClass]="getRowClass"
                                   [reorderable]="reorderable"
                                   [count]="viewModelPackPage.TotalElements"
                                   [limit]="viewModelPackPage.Size"
                                   [offset]="viewModelPackPage.PageNumber"
                                   (page)="onPackPage($event)">
                        <ngx-datatable-column name="{{getlocalized('AggregationList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label header-label-nofilter">
                                    {{column.name}}
                                </div>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <code>{{ value }}</code>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label">
                                    {{column.name}}
                                </div>
                                <div class="btn-group2">
                                    <button id="AggBox_UpdateAggProductAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadPackItems()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group2 action-buttons">
                                    <button id="AggBox_RemoveAggProductAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Remove Product" class="btn btn-sm btn-outline-danger" (click)="removeProductFromBox(row['Code'])"><i class="fa fa-trash"></i></button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="currentCode!=undefined">
    <app-code-edit-component [code]="currentCode" [counter]="counter" [isActionAvailable]="true"></app-code-edit-component>
</div>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>
