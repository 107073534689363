import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuProfile } from './profile.model';
import { AspuProfileService } from './profile.service';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';

@Component({
    selector: 'app-profile-list-component',
    templateUrl: './profile-list.component.html',
    providers: [AspuProfileService]
})
export class AspuProfileListComponent implements OnInit {
    rows = new Array<AspuProfile>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    constructor(private serv: AspuProfileService, private lservice: LangService, public modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.loadItems();
    }

    ngOnInit() { }

    onSort(event) {    }

    public  loadItems() {
        this.loading = true;

            this.serv.getItems().subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.rows = data.Value;
                } else {
                  //  console.log(data);
                }
                    this.isReady = true;
                    this.loading = false;
            }, error => console.error(error));

    }

    confirmation = new ConfirmationModel();
    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.deleteItem(this.confirmation.Parameter);
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning"
    }

    // добавление заказов
    addItem(): void {
        this.router.navigate(['../aspu-profile-edit/new'], { relativeTo: this.route });
    }

    //aspu-profile-edit/07418a31-ccdd-434b-636e-08d8660412be/editCard

    // редактирование заказов
    editItem(id: string): void {
        this.router.navigate(['../aspu-profile-edit/' + id + '/editCard'], { relativeTo: this.route });
    }

    // удаление заказа
    deleteItem(id: string) {
        this.loading = true;
        this.serv.deleteItem(id).subscribe(data => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            this.loading = false;
        });
    }

    onActivate(event) {
        if (event.type === 'dblclick') {
            this.editItem(event.row.Id);
        }
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }   
}
