import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuPlcService } from './plc.service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuPlcConfigPage } from '../../core/common/common.model';

@Component({
    selector: 'app-plc-custom-edit-component',
    templateUrl: './plc-edit.component.html',
    providers: [AspuPlcService]
})
export class AspuCustomPlcEditComponent implements OnInit {
    //@Input() groupType: any;
    @Input() viewModel: AspuPlcConfigPage;
    isFlat: boolean;

    constructor(private serv: AspuPlcService, private lservice: LangService, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }       
        this.isFlat = undefined;
    }

    ngOnInit() {
        
        this.loadItems();
    }

    loadItems() {
       // console.log("this.viewModel")
      //  console.log(this.viewModel)
        this.serv.getPlcSettings(this.viewModel.Name).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                console.log(data.Value);
                this.getIndicator(data.Value);
                //this.viewModel.Children = data.Value;
            }
        },
            error => console.error(error));
    }

    getIndicator(val: any) {
        for (var key in val) {
            if (val.hasOwnProperty(key)) {
                let node = this.viewModel.Children.find(x => x.Name == key)
                let nodeIndex = this.viewModel.Children.indexOf(node);                    

                //console.log(node.Children==undefined)
                if (node.Children != undefined) {
                  //  console.log('Complex model')
                    this.isFlat = false;
                    node.Children.forEach(function (item, i, arr) {
                        let some = val[key]
                        for (var key2 in some) {
                            if (some.hasOwnProperty(key2)) {
                                let child = node.Children.find(x => x.Name == key2);
                                child.Value = some[key2];
                                let index = node.Children.indexOf(child);
                                node.Children[index] = child;
                            }
                        }
                    })
                    this.viewModel.Children[nodeIndex] = node;
                }
                else {
                  //  console.log('Flat model')
                    this.isFlat = true;
                    node.Value = val[key];
                }
                
            }
        }
        //console.log("RealViewModel");
        //console.log(this.viewModel);
    }

    saveComplexItem() {
      //  console.log(this.viewModel.Children);
        var obj = {};
        let val = this.viewModel.Children;
        for (var key in val) {
            if (val.hasOwnProperty(key)) {
                //console.log(key + " -> " + val[key]);
                let some = val[key]
                for (var key2 in some) {
                    if (some.hasOwnProperty(key2)) {
                        if (key2 == 'Name') {
                  //          console.log(key2 + " ---> " + some[key2]);
                            obj[some[key2]] = {};
                            let children = some['Children'];
                            if (children != undefined) {
                                children.forEach(function (item, i, arr) {
                                    for (var key3 in item) {
                                        if (item.hasOwnProperty(key3)) {
                                            if (key3 == 'Name') {
                                                //console.log(key3 + " ------> " + item[key3] + ': ' + item['Value']);
                                                var s = {};
                                                s[item[key3]] = item['Value'];
                                                obj[some[key2]] = { ...obj[some[key2]], ...s}
                                            }
                                        }
                                    }
                                })
                            }
                            else {
                             //   console.log('Children IS undefined')

                            }
                        }
                    }
                }
            }
        }
        this.saveItem(obj);
    }

    saveFlatItem() {
        //  console.log(this.viewModel.Children);
        var obj = {};
        let val = this.viewModel.Children;
        for (var key in val) {
            if (val.hasOwnProperty(key)) {
                //console.log(key + " -> " + val[key]);
                let some = val[key]
                for (var key2 in some) {
                    if (some.hasOwnProperty(key2)) {
                        if (key2 == 'Name') {
                            //console.log(key2 + " ---> " + some[key2]);
                            obj[some[key2]] = some['Value'];
                        }
                    }
                }
            }
        }
       // console.log(obj);
        this.saveItem(obj);
    }

    saveItem(obj: any) {
        this.serv.setPlcSettings(obj, this.viewModel.Name).subscribe(data => {
                if (data.IsSuccess) {
                    if (data.Messages != undefined) {
                        this.toastService.getToast(data.Messages[0], 'warning');
                    } else {
                        this.toastService.getToast("Сохранено успешно!", 'warning');
                    }
                }
            },
            error => {
                this.loadItems();
            });
    }

    //getFields(val: any) {
    //    for (var key in val) {
    //        if (val.hasOwnProperty(key)) {
    //            //let node = this.viewModel.Children.find(x => x.Name == key)
    //            //let nodeIndex = this.viewModel.Children.indexOf(node);
    //            console.log(key + " ---> " + val[key]);
    //        }
    //    }
    //}

   redirect() {
        //this.router.navigate(['/module-460_1/aspu-plc-list']);
    }


    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    setId(id: string) {
        //console.error(id);
        var elem = document.getElementById(id);
        if (elem == null) {
            return id;
        }

        var pos = id.lastIndexOf("_");
       // console.error(pos);
        if (pos < 0)
        {
            return this.setId(id + "_1");
        }
        var lastIndex = +id.substring(pos + 1, id.length);
        if (isNaN(lastIndex))
        {
            return this.setId(id + "_1");
        }
       // console.error(lastIndex);
        return this.setId(id.substring(0, pos + 1) + (++lastIndex));
    }
}
