<div class="btn-group2">
    <!--<button id="Profile_Edit_BackAction" class="btn btn-lg btn-info me-1 " [routerLink]="['/aspu-profile-list']"><i class="fa fa-reply-all me-1 "></i>{{getlocalized('common', 'ToProfiles')}}</button>
    <button id="Profile_Edit_SaveAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveProfile()" class="btn btn-lg btn-success me-1 "><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}</button>
    <button id="Profile_Edit_SaveAndCloseAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save&Close')}}" (click)="saveProfile(true);" class="btn btn-lg btn-success me-1 "><i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save&Close')}}</button>-->

    <button id="Printer_Edit_BackAction" class="btn btn-info btn-icon me-1 " [routerLink]="['/aspu-profile-list']" style="width: 186px">
        <span><i class="fa-solid fa-2x fa-pull-left fa-reply-all mt-0 me-0"></i></span>
        <span class="btn-icon-label mt-0" style="">{{getlocalized('common', 'ToProfiles')}}</span>
    </button>

    <button id="Profile_Edit_SaveAction" [disabled]="false" (click)="saveProfile()"
            class="btn btn-success btn-icon me-1 " style="width: 166px;">
        <span><i class="fa-solid fa-2x fa-save fa-pull-left mt-0 me-0"></i></span>
        <span class="btn-icon-label mt-0">{{getlocalized('common', 'Save')}}</span>
    </button>

    <button id="Profile_Edit_SaveAndCloseAction" class="btn btn-success btn-icon me-1 " (click)="saveProfile(true);" style="width: 176px">
        <span><i class="fa-solid fa-2x fa-pull-left fa-save mt-2 me-0"></i></span>
        <span class="btn-icon-label" style="">{{getlocalized('common', 'Save&Close')}}</span>
    </button>
</div>

<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">
                {{getlocalized('common', 'Profile')}}:
                <span *ngIf="isNew()">{{getlocalized('common', 'NewM')}}</span>
                <span *ngIf="!isNew()">{{viewModel.Name}}</span>
            </span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            <!--<span class="badge badge-danger mt-1" style="float: right">{{getlocalized('common', 'Edition')}}</span>-->
        </h3>
    </div>
    <div class="card-body form-group mb-0 pb-0">
        <div class="row">
            <div class="mb-3 pe-3 col-xl-6 col-xxl-6">
                <div class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{getlocalized('ProfileList', 'Name')}}</span>
                    <input id="Profile_Edit_NameInput" type="text" name="Name" class="form-control" [(ngModel)]="viewModel.Name">
                    <app-validation [val]="viewModel.Name" [name]="'Name'" [errors]="errors" [size]="'lg'"></app-validation>
                </div>
            </div>
            <div class="mb-3 pe-3 col-xl-6 col-xxl-6">
                <div class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{getlocalized('ProfileList', 'GroupType')}}</span>
                    <select name="GroupType"
                            id="Profile_Edit_GroupTypeSelect"
                            [disabled]="viewModel.Id!=undefined"
                            [(ngModel)]="viewModel.GroupType"
                            class="form-select form-control form-control-lg">
                        <option *ngFor="let groupType of groupTypes" [value]="groupType">{{getlocalized('ProductGroupTypeDictionary', groupType)}}</option>
                    </select>
                    <app-validation [val]="viewModel.GroupType" [name]="'GroupType'" [errors]="errors" [size]="'lg'"></app-validation>
                </div>
            </div>

            <div class="mb-3 pe-3 col-xl-6 col-xxl-6">
                <div class="form-check form-control-lg form-switch">
                    <input id="Profile_Edit_IsDefault" class="form-check-input big-switch" type="checkbox" [(ngModel)]="viewModel.IsDefault">
                    <label class="form-check-label ms-3" for="Profile_Edit_IsDefault">{{getlocalized('ProfileList', 'IsDefault')}}</label>
                </div>
            </div>

            <div class="mb-3 pe-3 col-xl-6 col-xxl-6">
                <div class="form-check form-control-lg form-switch">
                    <input id="Profile_Edit_AutoRequestCheckBox" class="form-check-input big-switch" type="checkbox" [(ngModel)]="viewModel.AutoRequestEnabled">
                    <label class="form-check-label ms-3" for="Profile_Edit_AutoRequestCheckBox">{{getlocalized('ProfileList', 'AutoRequestEnabled')}}</label>
                </div>
            </div>

            <div class="mb-3 pe-3 col-xl-6 col-xxl-6" [hidden]='!viewModel.AutoRequestEnabled'>
                <div class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{getlocalized('ProfileList', 'AutoRequestMin')}}</span>
                    <input id="Profile_Edit_AutoRequestMinInput" type="number" name="AutoRequestMin" class="form-control" [(ngModel)]="viewModel.AutoRequestMin">
                    <!--<app-validation [val]="viewModel.AutoRequestMin" [name]="'AutoRequestMin'" [errors]="errors"></app-validation>-->
                    <app-validation [val]="viewModel.AutoRequestMin" [name]="'AutoRequestMin'" [errors]="errors" [size]="'lg'"></app-validation>
                </div>
            </div>
            <div class="mb-3 pe-3 col-xl-6 col-xxl-6" [hidden]='!viewModel.AutoRequestEnabled'>
                <div class="input-group input-group-lg">
                    <span class="input-group-text label-edit-width">{{getlocalized('ProfileList', 'AutoRequestMax')}}</span>
                    <input id="Profile_Edit_AutoRequestMaxInput" type="number" name="AutoRequestMax" class="form-control" [(ngModel)]="viewModel.AutoRequestMax">
                    <app-validation [val]="viewModel.AutoRequestMax" [name]="'AutoRequestMax'" [errors]="errors" [size]="'lg'"></app-validation>
                </div>

                <!--<app-validation [val]="viewModel.AutoRequestMax" [name]="'AutoRequestMax'" [errors]="errors"></app-validation>-->
            </div>
        </div>
    </div>
</div>

<div class="mt-2 mb-2" *ngIf="viewModel!=undefined && viewModel.Id!=undefined">
    <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
        <li [ngbNavItem]="1">
            <a ngbNavLink class="h5">{{getlocalized('common', 'BufferGroupPrintContours')}}</a>
            <ng-template ngbNavContent>
                <app-buffer-edit-component [profileId]="viewModel.Id" [groupType]="viewModel.GroupType" [bufferType]="'PrintingFlow'"></app-buffer-edit-component>
            </ng-template>
        </li>

        <li [ngbNavItem]="2">
            <a ngbNavLink class="h5">{{getlocalized('common', 'BufferSinglePrintContours')}}</a>
            <ng-template ngbNavContent>
                <app-buffer-edit-component [profileId]="viewModel.Id" [groupType]="viewModel.GroupType" [bufferType]="'PacketPrintingFlow'"></app-buffer-edit-component>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
