import { Directive, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";

@Directive({ selector: '[ngx-resize-watcher]' })
export class NgxResizeWatcherDirective implements AfterContentChecked {

    constructor(private table: DatatableComponent, public ref: ChangeDetectorRef) {
    }

    private latestWidth=0;

    ngOnInit() {
        window.addEventListener("ChangeNavSize", this._doChangeSize, false);
    }

    ngOnDestroy() {
        window.removeEventListener("ChangeNavSize", this._doChangeSize);
    }

    private _doChangeSize = this.doChangeSize.bind(this);

    doChangeSize(data: any) {
        //console.log('doChangeSize')
        //console.log(data.detail);
        //console.log('this.table')
        //console.log(this.table.element.style.display)

        this.table.element.style.display = 'none';

        setTimeout(() => {
            this.table.recalculate();
            this.table.recalculateColumns();
            window.dispatchEvent(new Event('resize'));

            this.table.recalculate();
            this.table.recalculateColumns();
            this.table.element.style.display = '';

                //this.table._innerWidth = 'auto';
        /*this.table.element.style.width = 'auto'*/
                //this.ref.detectChanges();
        /*this.table['cd'].markForCheck();*/
                //document.body.style.width = 'auto';

           }, 0);
    }

    ngAfterContentChecked() {
        if (this.table && this.table.element.clientWidth !== this.latestWidth) {
            this.latestWidth = this.table.element.clientWidth;
            //console.log('ngAfterContentChecked');
            //setTimeout(() => {

                this.table.recalculate();
                this.table.recalculateColumns();
            window.dispatchEvent(new Event('resize'));

            this.table.recalculate();
            this.table.recalculateColumns();
        }
    }

}
