//export class AspuPrintingFlow  {
//    Id: string;
//    PrinterId: string;
//    PrinterName: string;
//    Template: string;
//}

export class AspuProfile {
    Id: string;
    Name: string;
    GroupType: string;
    IsDefault: boolean;
    AutoRequestEnabled: boolean;
    AutoRequestMin: number;
    AutoRequestMax: number;
   // PrintingFlows: Array<AspuPrintingFlow>;
}

