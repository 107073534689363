import { AspuBatchProductType } from "../batch/batch-receipt.model";

export class  AspuReceiptInfo {
        Id: string;
        Name: string;
        HasBlock: boolean;
        HasBox: boolean;
        HasPallet: boolean;
    IsValid: boolean;
    ProductTypes: Array<AspuBatchProductType>
    }
//448
//460_1
//460_2
//460_4
//460_4_1
//460_5
//550_4
//550_5
//550_6
//550_6.1
//550_6.2
//550_6.3
//550_6.4
//550_7
//664
//692_2_0
//692_2_2
//742
//768_2
//768_4
//789
//799

