import { Injectable } from '@angular/core';
import { AspuErrorMessage, AspuMessage } from "./message.model";
import { Observable } from 'rxjs';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';

import { AspuHttpService } from '../../services/aspu.http.service';
import { AspuMessageFilterContainer } from '../../core/models/messageFilter';


@Injectable()
export class MessageService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(filter: AspuMessageFilterContainer): Observable<IResult<AspuMessage>> {
        return this.httpService.post<IResult<AspuMessage>>(filter, 'Messages');
    }

    getItemsV2(filter: FiltersContainer): Observable<IResult<Page<AspuMessage>>> {
        return this.httpService.post<IResult<Page<AspuMessage>>>(filter, 'Messages/v2');
    }

  getErrors(filter: FiltersContainer): Observable<IResult<Array<AspuErrorMessage>>> {
        //return this.httpService.post<IResult<Page<AspuMessage>>>(filter, 'Messages/v2');
        return this.httpService.post<IResult<Array<AspuErrorMessage>>>(filter, 'Messages/errorMessages');
    }

    getUnconfirmed(): Observable<IResult<any>> {
        return this.httpService.get<IResult<any>>('Messages/unconfirmed', null);
    }

    confirmById(id: string): Observable<IResult<any>> {
        return this.httpService.post<IResult<AspuMessage>>(null, 'Messages/confirm/' + id);
  }

  confirmByType(plcMessageId: number): Observable<IResult<any>> {
    return this.httpService.post<IResult<any>>(null, 'Messages/confirm_by_type/' + plcMessageId);
  }

    confirmAll(): Observable<IResult<any>> {
        return this.httpService.post<IResult<AspuMessage>>(null, 'Messages/confirm_all');
    }
}
