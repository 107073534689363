<nav class="navbar navbar-expand-lg fixed-top page-header-component ps-0" style="background-color: #ffffff">
  <div id="logo-container">
    <div style="height:55px!important; display:flex">
      <a class="navbar-brand m-0 mx-auto" [routerLink]="['/aspu-message-list']" [routerLinkActive]="['active']"
         [routerLinkActiveOptions]="{ exact: true }">
        <img class="mx-auto d-block" src="../images/VekasLogoWhite.png" style="max-height:55px;" />
        <!--{{getlocalized('common', 'MenuServerName')}}-->
      </a>
    </div>
    <div style="height:50px!important; ">
        <div style="position:absolute; top:53px; left:205px" [class.invisible]="!authorizationService.IsAuthorized()">
            <div *ngIf="isNavExpand" class="btn btn-lg text-success" (click)="changeNavbarSize()"><i class="fa fa-outdent"></i></div>
            <div *ngIf="!isNavExpand" class="btn btn-lg btn-outline-info text-success border-success" (click)="changeNavbarSize()"><i class="fa fa-indent"></i></div>
        </div>
        <table class="table table-borderless" style="height: 50px !important">
            <tr>
                <td class="p-0 text-center text-capitalize fw-bolder" style="color: aliceblue">клиент АСПУ
                </td>
            </tr>
            <tr>
                <td class="p-0 text-center" style="color: aliceblue">
                    <span style="display:inline">
                        <span>{{this.aspuInfo?.AspuVersion}}</span>
                        <!--<span>{{this.aspuInfo?.AspuModule}}</span>-->
                        <span><a class="nav-item nav-link text-light" style="display:inherit" href="{{this.aspuInfo?.OmsLineUrl}}" target="_blank">{{this.aspuInfo?.AspuModule}}</a></span>
                    </span>
                </td>
            </tr>

        </table>

        <!--<table class="table table-borderless" style="height: 50px !important">
      <tr>
        <td class="p-0" bgcolor="#FBFFF">Ячейка 3</td>
      </tr>
      <tr>
        <td class="p-0" rowspan="2" bgcolor="#FBF0DB">Ячейка 1</td>
        <td class="p-0" bgcolor="#FBF011">Ячейка 2</td>
      </tr>

    </table>-->
    </div>
  </div>
  <!--<div>
    <small class="badge bg-primary text-light d-block mb-1">{{this.aspuInfo?.AspuVersion}}</small>
    <small class="badge bg-info text-light d-block">{{this.aspuInfo?.AspuModule}}</small>
  </div>
  <a class="nav-item nav-link text-light" href="{{this.aspuInfo?.OmsLineUrl}}" target="_blank">
    <u>{{this.aspuInfo?.OmsLineName}}</u>
  </a>-->
  <!--<div >
    <div *ngIf="isNavExpand" class="btn btn-dark" (click)="changeNavbarSize()"><i class="fa fa-bars"></i></div>
    <div *ngIf="!isNavExpand" class="btn btn-outline-dark" (click)="changeNavbarSize()"><i class="fa fa-bars"></i></div>
  </div>-->


  <div class="collapse navbar-collapse" id="headerNavBar">
    <ul class="nav justify-content-center ms-auto">
      <!--<li class="nav-item">
    <div *ngIf="isNavExpand" class="btn btn-dark" (click)="changeNavbarSize()"><i class="fa fa-bars"></i></div>
    <div *ngIf="!isNavExpand" class="btn btn-outline-dark" (click)="changeNavbarSize()"><i class="fa fa-bars"></i></div>
  </li>-->
      <li class="nav-item" *ngIf="authorizationService.IsAuthorized()">
        <app-indicator-component></app-indicator-component>
      </li>
      <!--<li class="nav-item" *ngIf="authorizationService.IsAuthorized()"><div class="btn btn-sm btn-danger"><i class="fa fa-cog"></i></div></li>-->
      <li style="display: flex"  *ngIf="authorizationService.IsAuthorized()">
        <app-messages [isLarge]="true" style="margin:auto!important"></app-messages>
      </li>
      <!--<li><app-settings></app-settings></li>-->
      <li class="nav-item" *ngIf="authorizationService.IsAuthorized() && (isAdmin() || hasAccess())" style="display: flex">
        <!--<a *ngIf="!isBatch" class="nav-link a-click" [routerLink]="['/aspu-settings']" title="{{getlocalized('common', 'Settings')}}"><i class="fa fa-cog"></i></a>-->
        <!--<a *ngIf="isBatch" class="nav-link text-secondary" title="{{getlocalized('common', 'Settings')}}"><i class="fa fa-cog"></i></a>-->
        <a style="margin:auto!important" class="nav-link a-click violet-text faa-parent animated-hover ps-1 pe-1" [routerLink]="['/aspu-settings']"
           title="{{getlocalized('common', 'Settings')}}"><i class="fa faa-spin fa-cog fa-3x"></i></a>
      </li>
      <!--<li class="nav-item" *ngIf="authorizationService.IsAuthorized()"><a  class="nav-link" href="#" title="{{getlocalized('common', 'MenuUserSettings')}}"><i class="fa fa-user"></i></a></li>-->
    <li class="nav-item" *ngIf="authorizationService.IsAuthorized()" style="display: flex" triggers="click:blur" ngbTooltip="{{authorizationService.GetUserName()}}">
      <!--<span class="nav-link usernameinfo" [ngStyle]="{'width':'{{getLoginWidth()}}'}">{{authorizationService.GetUserName()}}</span>-->
      <!--<span class="nav-link usernameinfo" [style.width]="getLoginWidth()" [ngStyle]="{'width':'{{getLoginWidth()}}'}">{{authorizationService.GetUserName()}}</span>-->
        <span class="nav-link usernameinfo text-uppercase" #widthTarget [style.width]="getLoginWidth()">{{authorizationService.GetUserName()}}</span>
      <!--<span class="nav-link usernameinfo text-uppercase" #widthTarget [ngStyle]="{'width':'{{getLoginWidth()}}'}">{{authorizationService.GetUserName()}}</span>-->

      <!--[ngStyle]="{'width':'{{getLoginWidth()}}'}"-->
      <!--[style.width]="getLoginWidth()"-->

    </li>
      <li class="nav-item" *ngIf="authorizationService.IsAuthorized()" style="display: flex">
        <a style="margin:auto!important" class="nav-link ps-2 pe-2" href="#"
           (click)="onLogout()" title="{{getlocalized('common', 'MenuLogOut')}}">
          <i class="fa fa-user-times fa-3x violet-text"></i>
        </a>
      </li>
      <li class="nav-item" *ngIf="!authorizationService.IsAuthorized()" style="display: flex">
        <a class="nav-link ps-2 pe-2" style="margin:auto!important"
           [routerLink]="['/login']" href="#" title="{{getlocalized('common', 'MenuLogOn')}}">
          <i class="fa fa-user-plus fa-3x violet-text"></i>
        </a>
      </li>
      <li class="nav-item me-3" style="display: flex">
        <app-lang [url]="'test'" class="hide-dropdown" style="margin:auto!important"></app-lang>
      </li>
    </ul>
  </div>
</nav>
