import { Component } from '@angular/core';
import { AuthorizationService } from 'vekas_models/services/authorization.service';
import { LangService } from 'vekas_models/services/lang.service';
import { LoginService } from '../core/login/login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
    constructor(public authorizationService: AuthorizationService, private loginService: LoginService) {
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
