import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuSettingsService } from '../settings.service';
import { AspuWeightConfig, AspuWeightDriver } from '../settings.model';

@Component({
    selector: 'app-weight-config-component',
    templateUrl: './weight-config.component.html',
    providers: [AspuSettingsService]
})
export class AspuWeightConfigComponent implements OnInit {
    @Input() items: Array<AspuWeightConfig>
    
    drivers: Array<AspuWeightDriver>;
    devicesName:Array<string>;

    isReady = true;
    loading = false;
    ColumnMode = ColumnMode;
    reorderable = true;
    id: string;

    @Output() onUpdateData = new EventEmitter<Array<AspuWeightConfig>>();

    constructor(public modalService: NgbModal, private router: Router, private serv: AspuSettingsService) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.loadDrivers();
    }

    ngOnInit() {}   

    saveProfile() {
        this.onUpdateData.emit(this.items);
        this.currentId = undefined;
        this.editState = false;
    }

    loadDrivers() {
        this.serv.getWeightDrivers().subscribe(data => {
            if (data.IsSuccess) {
                this.drivers = data.Value;
                this.devicesName = this.drivers.map(x=>x.Name);
            }
        });
    }


    editState: boolean = false;
    viewModel = new AspuWeightConfig;
    viewModelOrigin: AspuWeightConfig;

    saveItem(terminal: AspuWeightConfig) {
        this.viewModel = terminal;
        this.viewModelOrigin = JSON.parse(JSON.stringify(terminal)); 
        if (this.viewModel.Id == undefined) {
            this.currentId = undefined;
            this.editState = false;
        }
        else {
            this.currentId = undefined;
            this.editState = false;
        }
        this.saveProfile();
    }

    cancel(row: AspuWeightConfig) {
        if (this.currentId != undefined) {
            this.currentId = undefined;
        } else {
            this.items = this.items.filter(x => x.Id!=undefined);
        }
        this.editState = false;
    }

    addItem() {
        if (this.editState) return;        

        this.editState = true;
        let config = new AspuWeightConfig();
        config.Id = undefined;

        let rows = [...this.items];
        rows.splice(0, 0, config);
        this.items = [...rows];
        this.viewModel = config;
        this.editItem(config);
    }

    confirmation = new ConfirmationModel();

    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType = "warning"
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.items = this.items.filter(x => x.Id != this.confirmation.Parameter)
                this.saveProfile();
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    currentId: string;

    editItem(config: AspuWeightConfig) {
        this.currentId = config.Id;
        this.viewModelOrigin = JSON.parse(JSON.stringify(config));
        this.viewModel = config;
    }

    isEdit(id: string) {
        return (id == this.currentId);
    }

    isStreakMWeightTerminal() {
        return this.items?.filter(x => x.discriminator == 'StreakMWeightingConfig').length > 0;
    }

    isWeightBarcode() {
        return this.items?.filter(x => x.discriminator == 'BarcodeWeightingConfig').length > 0;
    }

    driverNameChanged(event) {
        const val = event.target.value;
        let config = this.drivers.filter(x => x.Name == val)[0];
        this.viewModel.DriverId = config.Id;
        this.viewModel.discriminator = config.Config.discriminator;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
