export class AspuProductItem {
    IsError: boolean;
    IsEmpty: boolean;
    IsOk: boolean;
    IsNoRead: boolean;
    Code: string;
    IsActive: boolean;
    Message: string;
    constructor() { }
}

export class AspuBranchState {
    Products: AspuProductItem[];
    Capacity: number;
    
    constructor() {
        this.Products = new Array<AspuProductItem>();
    }
}
