import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuErrorMessage, AspuMessage } from './message.model';
import { MessageService } from './message.service';
import { AspuMessageFilter, AspuMessageFilterContainer } from '../../core/models/messageFilter';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
/*import { DatePickerRangeInfo } from 'vekas_models/models/datepicker.model';*/
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
/*import { DatepickerRangeComponent } from 'vekas_models/core/datepicker-range/datepicker-range.component';*/


@Component({
    selector: 'app-error-message-list-component',
    templateUrl: './error-message-list.component.html',
    providers: [MessageService]
})
export class AspuErrorMessageListComponent implements OnInit {
    //rows = new Array<AspuMessage>();
    rows = new Array<AspuErrorMessage>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();

    //@ViewChild(DatepickerRangeComponent, {static: true})
    //datepicker: DatepickerRangeComponent;

    messageTypes = [undefined, "Trace", "Debug", "Info", "Warning", "Error", "Critical"];

    constructor(private serv: MessageService, private lservice: LangService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        //this.gridSort.dir = "desc";
        //this.gridSort.field = "Time";
        this.loadItemsV2();

        //this.datepicker.result.TimeTo = this.datepicker.getStringDate(this.datepicker.toDate);
        //this.datepicker.result.TimeFrom = this.datepicker.getStringDate(this.datepicker.fromDate);
        //this.datepicker.onChange.emit(this.datepicker.result);
    }

    updateFilter(event) {
        this.loadItemsV2();
    }

    //onPage(event) {
    //    this.page.PageNumber = event.offset;
    //    this.loadItemsV2();
    //}

    //setPage(event) {
    //    const val = event.newValue;
    //    this.page.PageNumber = 0;
    //    this.page.Size = val;

    //    if (!this.searchChangeObserver) {
    //        Observable.create(observer => {
    //                this.searchChangeObserver = observer;
    //            }).pipe(debounceTime(300))
    //            .pipe(distinctUntilChanged())
    //            .subscribe(() => {
    //                this.loadItemsV2();
    //            });
    //    }
    //    this.searchChangeObserver.next(event);
    //}

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort = new GridSort();
        this.gridSort.field = sort.prop;
        this.gridSort.dir = sort.dir;
        this.loadItemsV2();
    }

    public loadItemsV2() {
        this.loading = true;
        this.serv.getErrors(this.getFilter()).subscribe((data: IResult<Array<AspuErrorMessage>>) => {
            if (data.IsSuccess) {
                this.rows = data.Value;
                console.log(this.rows)
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
      }
        return filter;
    }

  sendConfirmationByType(plcMessageId: number) {
        this.loading = true;
    this.serv.confirmByType(plcMessageId).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.loadItemsV2();
                } 
                this.loading = false;
            }, error => console.error(error));
    }

    cleanFilter(field: string) {
        if (field != undefined) {
            this.gridFilters = this.gridFilters.filter(x => x.field != field);
        } else {
            this.gridFilters = new Array<GridFilter>();
        }
    }

    public resetChild: Object;

    resetFilter() {
        let some = JSON.parse(JSON.stringify(this.gridFilters));
        this.resetChild = some;
        this.cleanFilter("Time");
        this.loadItemsV2();
    }

    getRowClass = (row: AspuErrorMessage) => {
        return {
            //'bg-danger': row.Type == "Error",
            //'bg-dark text-danger': row.Type == "Critical" && row.ConfirmStatus === "NotConfirmed",
            //'bg-warning': row.Type == "Warning",
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
