export class AspuCodeOnly {
    Code: string;
    ShowParentInfo: boolean;

    constructor(code: string, showParentInfo = false) {
        this.Code = code;
        this.ShowParentInfo = showParentInfo;
    }
}

export class AspuWeightOnly {
    Weight: string;

    constructor(weight: string) {
        this.Weight = weight;
    }
}

export class AspuServiceCodeOnly {
    ServiceCode: string;

    constructor(serviceCode: string) {
        this.ServiceCode = serviceCode;
    }
}

export class AspuCode extends AspuCodeOnly {
    //Code: string;
    GroupType: string;
    PrintingStatus: string;
    EntityStatus: string;
    OmsUploaded: boolean; //undefined если неизвестно
    Validated: boolean;
    Weight: number;
    ServiceCode: string;
}

export class AspuCodeParentChild {
    ChildCode: string;
    ParentCode: string;

    constructor(parent: string, child: string) {
        this.ParentCode = parent;
        this.ChildCode = child;
    }
}

export class AspuCodeFilter {
    GroupType: string;
    PrintingStatus: string; //Unprinted,    Printing,    Printed
    EntityStatus: string; // None, //КМ без привязки     Defect,     Entity //агрегирован
    OmsUploaded: boolean; //
    Count: boolean; 
}