<ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="true" class="nav-tabs">
  <li [ngbNavItem]="i" [destroyOnHide]="true" *ngFor="let config of configs; let i = index">
    <a ngbNavLink class="h5">{{config.Description}}</a>
    <ng-template ngbNavContent>
      <app-plc-custom-edit-component [viewModel]="config"></app-plc-custom-edit-component>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>





