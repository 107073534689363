import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuProfile } from './profile.model';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';

@Injectable()
export class AspuProfileService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(): Observable<IResult<Array<AspuProfile>>> {
        return this.httpService.get<IResult<Array<AspuProfile>>>('Receipts/profiles'); 
    }

    getItemById(id: string): Observable<IResult<AspuProfile>> {
        return this.httpService.get<IResult<Array<AspuProfile>>>('Receipts/profiles', id);
    }

    createItem(item: AspuProfile): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Receipts/profiles');
    }

    updateItem(item: AspuProfile) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Receipts/profiles/' + item.Id);
    }
    deleteItem(id: string) {
        return this.httpService.delete(id, "Receipts/profiles");
    }

    //getBuffers() {
    //    return this.httpService.get<IResult<Array<AspuBuffer>>>('Printers/buffer');
    //}

}