import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from "../../../../../../models/iresult";
import { IResult } from 'vekas_models/models/iresult';
import { AspuBoxEntity, AspuEntity, AspuSetEntityResult } from '../box/aggregation-box.model';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuCode, AspuCodeOnly } from '../../code/code.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { AggrAlgorithm, AspuMetaBase } from 'src/app/business/core/common/common.model';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilters } from 'vekas_models/models/filtersContainer';
import { AspuAggregationBoxQueueService } from './aggregation-box-queue.service';
import { PackZoneClearRequest, PackZoneStateDto } from './aggregation-box-queue.model';

@Component({
    selector: 'app-aggregation-box-queue-list-component',
    templateUrl: './aggregation-box-queue-list.component.html',
    providers: [AspuAggregationBoxQueueService, AspuIndicatorService]
})
export class AspuAggregationBoxQueueListComponent implements OnInit {
    viewModel = new PackZoneStateDto();
    viewModelQueuePage = new Page<AspuEntity>();
    viewModelPackPage = new Page<AspuEntity>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    currentInfoCode: string;
    currentProductCode: string;
    currentBoxCode: string;
    currentQueueProductCode: string;
    mode = "queue"; //aggregation, remove, info, replace
    parentBindPolicy = "Scan"; //Scan, ConfirmUI, Auto

    defaultMode = 'aggregation';
    defaultParentBindPolicy = 'Scan';

    isBoxScanModeActive = false;
    isCloseBoxActive = false;

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReloadEvent.bind(this);
    private _doTryReload = this.doTryReload.bind(this);

    constructor(private serv: AspuAggregationBoxQueueService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {
        this.loadItemsWithStateCheck();
    }

    ngOnInit() {
        this.preventDoubleCheck();
        window.addEventListener('CodeReceived', this._doAction, false);
        window.addEventListener('EnterCodeReceived', this._doAction, false);
        window.addEventListener('CodesModified', this._doTryReload, false);
        window.addEventListener('PackZoneStateUpdated', this._doReload, false);
        window.addEventListener('AspuIndicatorUpdated', this._doReload, false);
        this.InitMode();
        this.viewModelQueuePage.Size = this.viewModelPackPage.Size = 10;
        this.viewModelQueuePage.PageNumber = this.viewModelPackPage.PageNumber = 0;
    }

    ngOnDestroy() {
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener('EnterCodeReceived', this._doAction);
        window.removeEventListener('CodesModified', this._doTryReload);
        window.removeEventListener('PackZoneStateUpdated', this._doReload);
        window.removeEventListener('AspuIndicatorUpdated', this._doReload);
        clearInterval(this.timerId);
    }

    //prevent block
    eventCounter = 0;
    timerId: any;

    doTryReload() {
        this.eventCounter++;
    }

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.eventCounter > 0) {
                this.eventCounter = 0;
                this.loadItemsWithStateCheck();
            }
        }, 5000);
    }
    //prevent block end

    InitMode() {
        var items = localStorage.getItem("AggrAlgorithms");
        if (items == undefined) return;

        var algoritms = JSON.parse(items) as Array<AggrAlgorithm>;
        if (algoritms == undefined) return;

        var list = algoritms.filter(x => x.Type == "BoxQueue")[0];
        this.mode = list?.Mode.toLowerCase() ?? this.defaultMode;
        this.parentBindPolicy = list?.ParentBindPolicy ?? this.defaultParentBindPolicy;
    }

    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    doReloadEvent() {
        this.loading = true;
        this.loadItemsWithStateCheck();
    }

    onSort(event) { }

    onQueuePage(event) {
        this.viewModelQueuePage.PageNumber = event.offset;
        this.loadQueueItems();
    }

    onPackPage(event) {
        this.viewModelPackPage.PageNumber = event.offset;
        this.loadPackItems();
    }

    getQueueFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.take = this.viewModelQueuePage.Size;
        filter.skip = (this.viewModelQueuePage.PageNumber) * this.viewModelQueuePage.Size;
        return filter;
    }

    getPackFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.take = this.viewModelPackPage.Size;
        filter.skip = (this.viewModelPackPage.PageNumber) * this.viewModelPackPage.Size;
        return filter;
    }

    public loadQueueItems() {
        this.loading = true;
        this.serv.getBoxQueue(this.getQueueFilter()).subscribe((data: IResult<Page<AspuEntity>>) => {
            this.viewModelQueuePage = data.Value;
            console.log('loadQueueItems')
            console.log(this.viewModelQueuePage)
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    public loadPackItems() {
        this.loading = true;
        this.serv.getBoxPack(this.getPackFilter()).subscribe((data: IResult<Page<AspuEntity>>) => {
            this.viewModelPackPage = data.Value;
            console.log('loadPackItems')
            console.log(this.viewModelPackPage)
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    public getState() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<PackZoneStateDto>) => {
            this.viewModel = data.Value;
            this.viewModel.Pack = this.viewModelPackPage.Items;
            this.viewModel.Storage = this.viewModelQueuePage.Items; 
            if (this.viewModel.PageLength != undefined && this.viewModel.PageLength > 0)
                this.viewModelQueuePage.Size = this.viewModelPackPage.Size = this.viewModel.PageLength;
            this.isReady = true;
            this.loading = false;

            if (this.viewModel.IsReplaceModeOn) {
                this.mode = "replace";
            }
            else {
                if (this.mode == "replace") {
                    this.mode = this.defaultMode;
                }
            }
            if (this.viewModel.ModalStatus == "PackIsFull") {
                if (this.mode == 'queue') {
                    return;
                }
                if (this.parentBindPolicy == 'Scan') {
                    this.isBoxScanModeActive = true;
                }
                else {
                    this.actionRow(undefined, "closeBox")
                }
            };
            if (this.viewModel.ModalStatus == "PackIsEmpty") {
                this.isBoxScanModeActive = false;
            }
        }, error => console.error(error));
    }

    public loadItems() {
        this.loadPackItems();
        this.loadQueueItems();
        this.getState();
    }

    indicator = new AspuIndicator();

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) =>
        {
            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled")
            {
                this.loadItems();
                //this.loadAllItems()
            }
            else
            {
                this.loading = false;
            }
        });
    }

    counter = 0;
    currentCode: AspuCode;

    editCode(row: AspuCode) {
        this.counter++;
        this.currentCode = row;
        this.currentProductCode = '';
        this.currentInfoCode = '';
    }

    doAction(code: string) {
        console.log("doAction")
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        if (this.isBoxScanModeActive && this.mode == 'aggregation') {
            this.currentBoxCode = code;
            this.closeBox();
            return;
        }

        switch (this.mode) {
            case "aggregation":
                this.addProduct(code.trim());
                break;
            case "queue":
                this.saveToQueue(code.trim());
                break;
            case "remove":
                this.removeProduct(code.trim());
                break;
            case "replace":
                this.replaceProduct(code.trim());
                break;
            case "info":
                this.editCode(new AspuCode(code.trim()));
                break;
            default: break;
        }
    }

    addProduct(code: string) {
        console.log('addProduct')
        this.loading = true;
        this.serv.setProductCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                let result = data.Value;
                if (!result.IsOk) {
                    this.toastService.getToast(result.Message, 'error');
                }
                else {
                    this.toastService.getToast("Продукт успешно добавлен в короб", 'info');
                }
            }
            
            this.loadItems();
            //this.loadAllItems()
            this.isReady = true;
            this.loading = false;
            this.currentProductCode = this.currentBoxCode = undefined;            
        },
            error => console.error(error));
    }

    removeProduct(code: string) {
        this.loading = true;

        this.serv.removeProductCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {

            if (data.IsSuccess) {
                this.currentProductCode = '';
                this.toastService.getToast("Продукт удален из короба", 'warning');

                if (this.viewModelPackPage.PageNumber != 0 && this.viewModelPackPage.Items.length == 1)
                  this.viewModelPackPage.PageNumber--;

                if (this.viewModelQueuePage.PageNumber != 0 && this.viewModelQueuePage.Items.length == 1)
                  this.viewModelQueuePage.PageNumber--;
            }
            else {
                this.toastService.getToast(data.Messages[0], 'error');
            }
            //this.loadAllItems()
            this.loadItems();
            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    replaceProduct(code: string) {
        this.loading = true;
        this.serv.replaceProductCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                this.currentProductCode = '';
                this.toastService.getToast("Продукт заменен в коробе. Ошибки в очереди отсутствуют", 'info');

                if (this.viewModelPackPage.PageNumber != 0 && this.viewModelPackPage.Items.length == 1)
                  this.viewModelPackPage.PageNumber--;

                if (this.viewModelQueuePage.PageNumber != 0 && this.viewModelQueuePage.Items.length == 1)
                  this.viewModelQueuePage.PageNumber--;
            }            
            this.loadItems();
            this.isReady = true;
            this.loading = false;
        },
        error => console.error(error));
    }

    removeProductFromBox(code: string) {
        this.isBoxScanModeActive = false;
        this.removeProduct(code);
    }    

    saveToQueue(code: string) {
        console.log('saveToQueue')
        this.serv.setProductCodeToQueue(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {                
                let result = data.Value;
                if (result != undefined && !result.IsOk) {
                    this.toastService.getToast(result.Message, 'error');
                }
            }
            //this.loadAllItems()
            this.loadItems();
            this.isReady = true;
            this.loading = false;
            this.currentProductCode = this.currentBoxCode = undefined;
        },
            error => console.error(error));
    }

    clearBox() {
        this.loading = true;
        this.isBoxScanModeActive = false;
        this.serv.clearBox(new PackZoneClearRequest(0)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                //this.loadAllItems()
                this.loadItems();
            }

            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    clearAllBoxes() {
        this.loading = true;
        this.isBoxScanModeActive = false;
        this.serv.clearBox(new PackZoneClearRequest(1)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                //this.loadAllItems()
                this.loadItems();
            }

            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    checkBoxBeforClose() {
        this.isCloseBoxActive = true;
        if (this.mode != 'queue' && this.mode != 'aggregation'){
            this.mode = this.defaultMode;
        }
        if (this.viewModel.Storage != undefined && this.viewModel.Storage.length > 0) {
            this.serv.moveStorageToPack().subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    //this.loadAllItems()
                    this.loadItems();
                }
            });
        }
        else {
            this.actionRow(undefined, "closeBox")
        }
    }

    cancelCloseBox() {
        if (this.mode != 'queue' && this.mode != 'aggregation'){
            this.mode = this.defaultMode;
        }
        this.isCloseBoxActive = false;
        this.isBoxScanModeActive = false;
    }

    closeBox(keepScanBoxModeActive = false) {
        this.loading = true;
        this.serv.closeBox(this.isBoxScanModeActive ? new AspuCodeOnly(this.currentBoxCode) : null).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                if (this.currentBoxCode!=undefined && this.currentBoxCode!="")
                {
                    this.toastService.getToast("Код короба добавлен!", 'info');
                    this.toastService.getToast("Короб сформирован", 'info'); 
                    this.isCloseBoxActive = false; 
                }
                this.currentBoxCode = undefined;                
                this.isBoxScanModeActive = keepScanBoxModeActive;
                //this.loadAllItems()
                this.loadItems();
            }
            else {
                this.currentProductCode = this.currentBoxCode = undefined;
            }

            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    confirmation = new ConfirmationModel();
    
    actionRow(id: string, action: string) {
        if (action == "closeBox") {
          if (this.viewModel.Capacity > this.viewModelPackPage.TotalElements) {
              this.confirmation.Message = 'Подтвердите закрытие неполного короба из ' + this.viewModelPackPage.TotalElements + ' продуктов';
                this.confirmation.Action = action;
                this.confirmation.Parameter = id;
                this.confirmation.Counter++;
                this.confirmation.ClassType = "warning"
            } 
            else {
                this.closeBox();
            }
        }       
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'closeBox') {
                if (this.parentBindPolicy == 'Scan') {
                    this.isBoxScanModeActive = true;
                    this.closeBox(true);
                }
                else {
                    this.closeBox();
                }
            }
        }
        else {
            this.isCloseBoxActive = false;
            this.isBoxScanModeActive = false;
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    getRowClass = (row: AspuEntity) => {
        return {
            //'bg-danger': row.Type == "Error",
            'bg-dark text-danger': !row.IsOk
            //'bg-warning': row.Type == "Warning",
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    checkFullBox(mode: string) {
        if (this.isCloseBoxActive) {
            this.isBoxScanModeActive = true;
        }
        else if (mode == 'queue') {
            this.isBoxScanModeActive = false;
        }
        else if (mode == 'aggregation') {
            if (this.viewModelPackPage.TotalElements >= this.viewModel.Capacity) {
                this.isBoxScanModeActive = true;
            }
            else {
                this.isBoxScanModeActive = false;
            }
        }

        return mode;
    }
}
