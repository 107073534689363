<ng-template #contentLoads let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12 p-0">
            <div class="modal-header" style="word-break: break-all; ">
                <h4 class="modal-title" id="modal-basic-title" style="max-width: 90%;">
                    <span class="badge bg-secondary p-2">
                        {{getlocalized('common', 'Code')}}:
                        <span class="badge bg-info" style="font-size: 1.1em; white-space: normal; max-width: 90%; text-align:left">{{code}}</span>
                    </span>
                </h4>
                <button id="Code_Edit_CloseAction" type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
            <div class="modal-body pb-0 pt-0">
                <div class="card mt-2">
                    <div class="card-header">
                        <h3>
                            <span class="badge-secondary">{{getlocalized('Common', 'Code')}}</span>
                        </h3>
                    </div>
                    <div class="card-body form-group mb-0 pb-0">
                        <div class="form-row">
                            <div class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('CodeList', 'EntityStatus')}}</span>
                                <span *ngIf="getEntityStatus(viewModel) == 'FullEntity'" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="getEntityStatus(viewModel) == 'Defect'" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input disabled type="text" class="form-control border-start-0" [ngClass]="{'border-success': getEntityStatus(viewModel) == 'FullEntity', 'border-danger': getEntityStatus(viewModel) == 'Defect'}" value="{{getlocalized('AggregationStatusDictionary', getEntityStatus(viewModel))}}">
                            </div>

                            <div class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('CodeList', 'ValidationStatus')}}</span>
                                <span *ngIf="viewModel?.IsValidated" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="!viewModel?.IsValidated" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input *ngIf="viewModel?.IsValidated" disabled type="text" class="form-control border-start-0 border-success" value="{{getlocalized('common', 'IsValidated')}}">
                                <input *ngIf="!viewModel?.IsValidated" disabled type="text" class="form-control border-start-0 border-danger" value="{{getlocalized('common', 'NotValidated')}}">
                            </div>

                            <div class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('Common', 'Status')}}</span>
                                <input disabled type="text" class="form-control" value="{{getlocalized('StorageStatusDictionary',viewModel?.Status)}}">
                            </div>

                            <div *ngIf="viewModel?.PrintedOn != undefined" class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('CodeInfo', 'PrintedOn')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.PrintedOn | date: 'dd.MM.yyyy HH:mm'}}">
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="viewModel?.BatchNumber != undefined || viewModel?.StartDate != undefined || viewModel?.FinalizationDate != undefined || viewModel?.ProductLineName != undefined" class="card mt-2">
                    <div class="card-header">
                        <h3>
                            <span class="badge-secondary">{{getlocalized('Common', 'Batch')}}</span>
                        </h3>
                    </div>
                    <div class="card-body form-group mb-0 pb-0">
                        <div class="form-row">
                            <div *ngIf="viewModel?.BatchNumber != undefined" class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('Common', 'Batch')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.BatchNumber}}">
                            </div>

                            <div *ngIf="viewModel?.StartDate != undefined" class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('CodeInfo', 'BatchStartDate')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.StartDate | date: 'dd.MM.yyyy HH:mm'}}">
                            </div>

                            <div *ngIf="viewModel?.FinalizationDate != undefined" class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('CodeInfo', 'BatchFinalizationDate')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.FinalizationDate | date: 'dd.MM.yyyy HH:mm'}}">
                            </div>

                            <div *ngIf="viewModel?.ProductLineName != undefined" class="input-group mb-3 pe-3 col-lg-6 col-md-12">
                                <span class="input-group-text">{{getlocalized('Common', 'ProductLine')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.ProductLineName}}">
                            </div>
                        </div>
                    </div>
                </div>
    
                <div *ngIf="viewModel?.ParentCode != undefined" class="card mt-2">
                    <div class="card-header">
                        <h3>
                            <span class="badge-secondary">
                                {{getlocalized('CodeList', 'Parent')}}
                                <span class="btn btn-sm btn-info" (click)="redirectToCode(viewModel?.ParentCode)">
                                    <i class="fa fa-sign-in-alt me-1 "></i>{{viewModel?.ParentCode}}
                                </span>
                            </span>
                        </h3>
                    </div>
                </div>
    
                <div *ngIf="viewModel?.ChildrenCodes != undefined && viewModel?.ChildrenCodes.length > 0" class="card mt-2">
                    <div class="card-header">
                        <h3>
                            <span class="badge-secondary">{{getlocalized('CodeList', 'Children')}}</span>
                        </h3>
                    </div>
                    <div class="card-body form-group mb-0 pb-0">
                        <div class="form-row">
                            <ul>
                                <li class="mb-1" *ngFor="let code of viewModel?.ChildrenCodes; index as i; first as isFirst">
                                    {{i+1}} :  <code style="font-size: 120%">{{code}}</code>
                                    <button id="Code_Edit_CopyToClickboardAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Копировать в буфер обмена" (click)="copyToClipboard(code)" class="btn btn-sm btn-info ms-2 me-1 "><i class="fa fa-copy"></i></button>
                                    <button id="Code_Edit_GoToCode" [openDelay]="1500" [closeDelay]="400" (click)="redirectToCode(code)" class="btn btn-sm btn-info me-1 "><i class="fa fa-sign-out-alt"></i></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="btn-group2">
                        <button type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            {{getlocalized('common', 'Close')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
