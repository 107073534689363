<ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <li [ngbNavItem]="1" [disabled]="isBatch">
        <a ngbNavLink class="h5">{{getlocalized('common', 'Settings')}}</a>
        <ng-template ngbNavContent>
            <button id="saveSettingsBtn" [openDelay]="1500" [closeDelay]="400"
                ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem()"
                class="btn btn-lg btn-success mb-2 mt-2"><i class="fa fa-save me-1"></i>{{getlocalized('common',
                'Save')}}</button>
            <div class="card mb-2">
                <div class="card-header">
                    <h3>
                        <span class="header-badge text-uppercase">
                            {{getlocalized('common', 'Settings')}}
                        </span>
                        <!--<span class="badge badge-danger" style="float: right">{{getlocalized('common', 'Edition')}}</span>-->
                    </h3>
                </div>
                <div class="card-body form-group pt-1 mb-0 pb-0">
                    <h3><span class="header-badge text-uppercase">{{getlocalized('SettingsList',
                            'CommonSettings')}}</span></h3>
                    <div class="form-row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'ProductionLineId')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="viewModel.ProductionLineId" />
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'OmsLineName')}}</span>
                                <input type="text" disabled class="form-control" [(ngModel)]="viewModel.OmsLineName">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'ServerNumber')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="viewModel.ServerNumber">
                                <app-validation [val]="viewModel.ServerNumber" [name]="'ServerNumber'" [errors]="errors"
                                    [size]="'lg'"></app-validation>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'LineNumber')}}</span>
                                <input type="text" disabled class="form-control" [(ngModel)]="viewModel.LineNumber">
                                <app-validation [val]="viewModel.LineNumber" [name]="'LineNumber'" [errors]="errors"
                                    [size]="'lg'"></app-validation>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.ReasignCodesFromOms" id="check_ReasignCodesFromOms">
                                <label class="form-check-label ms-3"
                                    for="check_ReasignCodesFromOms">{{getlocalized('SettingsList',
                                    'ReasignCodesFromOms')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.DefectDuplicateOnValidation"
                                    id="check_DefectDuplicateOnValidation">
                                <label class="form-check-label ms-3"
                                    for="check_DefectDuplicateOnValidation">{{getlocalized('SettingsList',
                                    'DefectDuplicateOnValidation')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.DefectPrintedAndNotValidated"
                                    id="check_DefectPrintedAndNotValidated">
                                <label class="form-check-label ms-3"
                                    for="check_DefectPrintedAndNotValidated">{{getlocalized('SettingsList',
                                    'DefectPrintedAndNotValidated')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.EnablePrintingWhenProductionDisabled"
                                    id="check_EnablePrintingWhenProductionDisabled">
                                <label class="form-check-label ms-3"
                                    for="check_EnablePrintingWhenProductionDisabled">{{getlocalized('SettingsList',
                                    'EnablePrintingWhenProductionDisabled')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.IgnoreBackupCreating" id="check_IgnoreBackupCreating">
                                <label class="form-check-label ms-3"
                                    for="check_IgnoreBackupCreating">{{getlocalized('SettingsList',
                                    'IgnoreBackupCreating')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.UseOnlyDraftBatchEnable" id="check_UseOnlyDraftBatchEnable">
                                <label class="form-check-label ms-3"
                                    for="check_UseOnlyDraftBatchEnable">{{getlocalized('SettingsList',
                                    'UseOnlyDraftBatchEnable')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.DraftProductionDatePlanEnable"
                                    id="check_DraftProductionDatePlanEnable">
                                <label class="form-check-label ms-3"
                                    for="check_DraftProductionDatePlanEnable">{{getlocalized('SettingsList',
                                    'DraftProductionDatePlanEnable')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.IsHideBatchStopBtn" id="check_IsHideBatchStopBtn">
                                <label class="form-check-label ms-3"
                                    for="check_IsHideBatchStopBtn">{{getlocalized('SettingsList',
                                    'IsHideBatchStopBtn')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.CheckProductInStorageEnable"
                                    id="check_CheckProductInStorageEnable">
                                <label class="form-check-label ms-3"
                                    for="check_CheckProductInStorageEnable">{{getlocalized('SettingsList',
                                    'CheckProductInStorageEnable')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.ProductWeightValidationEnable"
                                    id="check_ProductWeightValidationEnable">
                                <label class="form-check-label ms-3"
                                    for="check_ProductWeightValidationEnable">{{getlocalized('SettingsList',
                                    'ProductWeightValidationEnable')}}</label>
                            </div>
                        </div>

                      <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.AllowValidatePrintedOnFinalize"
                                    id="check_AllowValidatePrintedOnFinalize">
                                <label class="form-check-label ms-3"
                                    for="check_AllowValidatePrintedOnFinalize">{{getlocalized('SettingsList',
                                    'AllowValidatePrintedOnFinalize')}}</label>
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'StoreBackupDays')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="viewModel.StoreBackupDays">
                                <app-validation [val]="viewModel.StoreBackupDays" [name]="'StoreBackupDays'"
                                    [errors]="errors" [size]="'lg'"></app-validation>
                            </div>
                        </div>

                        <app-meta-component class="col-12 pe-3" [metaType]="'appconfig'" [isValidationEnabled]="true"
                            [isAutoReloadEnabled]="false" [startValues]="viewModel.Fields" [errors]="errors"
                            (onUpdateData)="viewModel.Fields = $event">
                        </app-meta-component>


                    </div>
                    <h3><span class="header-badge text-uppercase">{{getlocalized('SettingsList',
                            'AuthorizationSettings')}}</span></h3>
                    <div class="form-row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'AspuUserName')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="viewModel.AspuUserName">
                            </div>
                        </div>
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'AspuPassword')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="viewModel.AspuPassword">
                            </div>
                        </div>
                    </div>

                    <h3><span class="header-badge text-uppercase">{{getlocalized('SettingsList',
                            'PartialReportUpload')}}</span></h3>
                    <div class="form-row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="form-check form-control-lg form-switch">
                                <input class="form-check-input big-switch" type="checkbox"
                                    [(ngModel)]="viewModel.UsePartialReportsUploading" id="defaultCheck1">
                                <label class="form-check-label ms-3" for="defaultCheck1">{{getlocalized('SettingsList',
                                    'UsePartialReportsUploading')}}</label>
                            </div>
                        </div>
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10"></div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'PartialReportsSecondsValue')}}</span>
                                <input [disabled]="!viewModel.UsePartialReportsUploading" type="number"
                                    class="form-control" [(ngModel)]="viewModel.PartialReportsSecondsValue">
                            </div>
                        </div>
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-10">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'PartialReportsCapacityValue')}}</span>
                                <input [disabled]="!viewModel.UsePartialReportsUploading" type="number"
                                    class="form-control" [(ngModel)]="viewModel.PartialReportsCapacityValue">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>

    <li [ngbNavItem]="2">
        <a ngbNavLink class="h5">{{getlocalized('common', 'Cameras')}}</a>
        <ng-template ngbNavContent>
            <app-camera-config-component *ngIf="config.CameraConfig!=undefined"
                (onUpdateData)="updateCameraData($event)" [items]="config.CameraConfig"></app-camera-config-component>
        </ng-template>
    </li>

    <li [ngbNavItem]="3">
        <a ngbNavLink class="h5">{{getlocalized('SettingsList', 'PlcSettings')}}</a>
        <ng-template ngbNavContent>
            <button id="saveSettingsBtn2" [openDelay]="1500" [closeDelay]="400"
                ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="updateAspuConfig()"
                class="btn btn btn-success mb-2 mt-2"><i class="fa fa-lg fa-save me-2"></i>{{getlocalized('common',
                'Save')}}</button>
            <div class="card mb-2">
                <div class="card-header">
                    <h3>
                        <span class="header-badge text-uppercase">
                            {{getlocalized('SettingsList', 'PlcSettings')}}
                        </span>
                        <span class="badge badge-danger" style="float: right">{{getlocalized('common',
                            'Edition')}}</span>
                    </h3>
                </div>

                <div class="card-body form-group pt-3 mb-0 pb-0">
                    <div class="form-row">
                        <div class="mb-3 pe-3 col-xl-8 col-lg-12">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text label-edit-width">{{getlocalized('SettingsList',
                                    'PlcId')}}</span>
                                <input type="text" class="form-control" [(ngModel)]="config.PlcId" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>

    <li [ngbNavItem]="4">
        <a ngbNavLink class="h5">{{getlocalized('common', 'UserSettings')}}</a>
        <ng-template ngbNavContent>
            <div class="card-body form-group pt-3 mb-0 pb-0">
                <div class="row">
                    <div class="mb-3 pe-3 col-xl-8 col-lg-12">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text label-edit-width text-uppercase">{{getlocalized('common',
                                'Theme')}}</span>
                            <select id="Theme_Edit_ThemeSelect" [(ngModel)]="currentUserTheme"
                                (change)="changeTheme($event)" class="form-select form-control form-control-lg ">
                                <option ng-selected="currentUserTheme===theme" *ngFor="let theme of userThemes"
                                    [value]="theme">{{theme}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>

    <li [ngbNavItem]="5">
        <a ngbNavLink class="h5">Configuration.json</a>
        <ng-template ngbNavContent>
            <!-- <app-configurationJson-component></app-configurationJson-component> -->
            <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink class="h5">{{getlocalized('common', 'Form Settings')}}</a>
                    <ng-template ngbNavContent>
                        <app-configurationJson-component></app-configurationJson-component>
                    </ng-template>

                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink class="h5">{{getlocalized('common', 'JSON Settings')}}</a>
                    <ng-template ngbNavContent>
                        <config-json-editor></config-json-editor>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </ng-template>
    </li>

    <li [ngbNavItem]="6">
        <a ngbNavLink class="h5">{{getlocalized('WeightList', 'WeightSettings')}}</a>
        <ng-template ngbNavContent>
            <app-weight-config-component *ngIf="config.WeightConfig!=undefined"
                (onUpdateData)="updateWeightData($event)" [items]="config.WeightConfig"></app-weight-config-component>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
