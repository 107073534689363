//export class AspuReportStatus {
//    IsNotInitialized: boolean;
//    IsWorkingNow: boolean;
//    IsFail: boolean;
//    Items: Array<AspuReportItem>;

//    constructor() {
//        this.Items = new Array<AspuReportItem>();
//    }
//}


export class DebugAggregationsInfo {
    public GroupTypes: Array<string>;
}

export class DebugCreateCommonAggregation {
    GroupType: string;
    AllowGenerateCodes: boolean;
    Count: number;

    constructor() {
        this.AllowGenerateCodes = true;
        this.Count = 1;
    }
}

export class DebugCurrentCodesAvailabilityOperations{
    public SetPrintNew: boolean;
    public SetPrintInProgress: boolean;
    public SetPrintFinished: boolean;
    public SetDefect: boolean;
    public UnsetDefect: boolean;
    public CreateAggr: boolean;
    public DeleteAggr: boolean;
}

