export class AspuProductItem {
    IsError: boolean;
    IsEmpty: boolean;
    IsOk: boolean;
    IsNoRead: boolean;

    IsActive: boolean;

    constructor() { }
}

export class AspuBlockLayer {
    Items: AspuProductItem[];
    IsActive: boolean;
    CellError: string;

    constructor() {
        this.Items = new Array<AspuProductItem>();
    }
}

export class AspuBlockAggrState {
    Status: string;

    Layers: AspuBlockLayer[];
    LayerRowCount: number;
    LayerColsCount: number;

    AllowClearLayer: boolean;
    AllowClearAll: boolean;

    constructor() {
        this.Layers = new Array<AspuBlockLayer>();
    }
}
