<div class="form-group pb-0 pe-2" style="">
    <ngx-datatable ngx-resize-watcher ngx-localizer class="bootstrap ngx-datatable"
                   id="someTable"
                   [rows]="rows"
                   [loadingIndicator]=""
                   [columnMode]="ColumnMode.force"
                   [headerHeight]="50"
                   [footerHeight]="50"
                   [scrollbarH]="true"
                   rowHeight="auto"
                   [rowClass]="getRowClass"
                   [reorderable]="reorderable">
        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Name')}}" prop="Name" [sortable]="true" cellClass="custom-row text-uppercase" headerClass="hide-icon" [minWidth]="150" [maxWidth]="500" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{ value }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'GTIN')}}" prop="Gtin" [sortable]="true" cellClass="custom-row" headerClass="hide-icon" [minWidth]="170" [maxWidth]="170" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{ value }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'GroupType')}}" prop="GroupType" [sortable]="true" headerClass="hide-icon" cellClass="custom-row text-uppercase" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{getlocalized('ProductGroupTypeDictionary', value)}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'QuantityKm')}}" prop="QuantityKm" [sortable]="true" headerClass="hide-icon" [minWidth]="100" cellClass="custom-row" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'RequesterType')}}" prop="RequesterType" [sortable]="true" headerClass="hide-icon" cellClass="custom-row text-uppercase" [minWidth]="150" [maxWidth]="350" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'ExpirationDate')}}" prop="ExpirationDate" [sortable]="true" headerClass="hide-icon" cellClass="custom-row" [minWidth]="150" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                <!--{{value}}-->
                {{getExpirationTimeNormalized(value)}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'CountInGroup')}}" prop="GroupCapacity" [sortable]="true" headerClass="hide-icon" cellClass="custom-row" [minWidth]="150" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('ReceiptList', 'Profile')}}" prop="ProfileId" [sortable]="true" headerClass="hide-icon" cellClass="custom-row" [minWidth]="150" [resizeable]="false">
            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                <div class="header-label header-label-nofilter">
                    <span (click)="sort()">
                        {{column.name}}
                    </span>
                </div>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <!--{{value}}-->
                <select name="profileId"
                        id="ReceiptProduct_List_ProfileIdSelect"
                        (click)="getProfiles()"
                        [(ngModel)]="row['ProfileId']"
                        class="form-select form-control form-control-lg">
                    <option *ngIf="row['ProfileId']==undefined"></option>
                    <option *ngFor="let profile of getProfilesByType(row['GroupType'])" ng-selected="profile.Id==row['ProfileId']" [ngValue]="profile.Id">{{ profile.Name }}</option>
                </select>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>
