import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuReceiptService } from './receipt.service';
import { AspuReceiptInfo } from './receipt-info.model';
//import { AspuReceipt } from './receipt-detail/receipt.model';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-receipt-list-component',
    templateUrl: './receipt-list.component.html',
    providers: [AspuReceiptService]
})
export class AspuReceiptListComponent implements OnInit {
    //rows = new Array<AspuReceiptInfo>();
    page = new Page<AspuReceiptInfo>(); //todo
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    searchChangeObserver;
    selectedReceiptId: string;
    selectedReceiptIsValid: boolean;

    constructor(private serv: AspuReceiptService, private lservice: LangService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.page.Size = window["pageSize"];
        this.page.PageNumber = 0;
        this.loadItems();
    }

    ngOnInit() {
        window.addEventListener("ReceiptsUpdated", this._doReload, false);
    }
    ngOnDestroy() {
        window.removeEventListener('ReceiptsUpdated', this._doReload);
    }

    private _doReload = this.doReloadEvent.bind(this);
    doReloadEvent() {
        this.loadItems();
    }

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadItems();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort = new GridSort();
        this.gridSort.dir = sort.dir;
        this.gridSort.field = sort.prop;
        this.loadItems();
    }

    updateFilter(event) {
        this.page.PageNumber = 0;
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
                //  .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    this.loadItems();
                });
        }
        this.searchChangeObserver.next(event);
    }


    public loadItems() {
        this.loading = true;
        this.serv.getItemsV2(this.getFilter()).subscribe((data: IResult<Page<any>>) => {
            if (data.IsSuccess) {
                this.page= data.Value;
                this.isReady = true;
            } else {
                console.log(data);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        return filter;
    }


    editItem(id: string): void {
            this.router.navigate(['../aspu-receipt-detail/' + id + '/editCard'], { relativeTo: this.route });
    }

    getRowClass = (row: AspuReceiptInfo) => {
        return {
            'bg-danger': !row.IsValid,
            //'bg-dark text-danger': row.Type == "Critical" && row.ConfirmStatus === "NotConfirmed",
            //'bg-warning': row.Type == "Warning",
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus === "Confirmed",
        };
    }

    onActivate(event) {
        if (event.type == 'click') {
            this.selectedReceiptId = event.row.Id;
            this.selectedReceiptIsValid = event.row.IsValid;
        }

        if (event.type === 'dblclick') {
            this.editItem(event.row.Id);
        }
    }

    public resetChild: Object;

    resetFilter() {
        this.resetChild = JSON.parse(JSON.stringify(this.gridFilters));
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
