export class AspuStat {
    Value: number;
    All: number;
    Type: string;
}

export class AspuProcessProductType {

    CountInGroup: number
    GroupType: string;
    Gtin: string;
    HasCountLimit: boolean;
    Id: string;
    IsExistsNotPrinted: boolean;
    IsGenerate: boolean;
    Name: string;
    ProductTypeCodes: string[];
    QuantityKm: number;
    SelectedProductTypeCode: string;
    Stats: Array<AspuStat>;
}

export class AspuBatchProcessState {
    IsBatch: boolean;
    IsProduction: boolean;
    BatchName: string;
    ReceiptName: string;
    OmsBatchUrl: string;
    StartDate: string;
    ProductionDate: string;
   // VetCert: string;
    ProductTypes: AspuProcessProductType[];
}

export class AspuPrintingState {
    Flows:Array<AspuFlowsPrintingState>;
    Packets:Array<AspuPacketsPrintingState>;
}

export class AspuFlowsPrintingState {
    Id: number;
    GroupType: string;
    Status: string;
    PrinterName: string;
    Template: string;
    Messages: AspuPrintingMessage[];
}

export class AspuPacketsPrintingState {
    Id: number;
    GroupType: string;
    Status: string;
    PrinterName: string;
    Template: string;
    Messages: AspuPrintingMessage[];
    IsPreInitialize:boolean;
}

export class AspuPrintingMessage{
    Text: string;
    Type: string;
}

export class PrintingAction {
    Id: number;
    UserDefinedVars: any;

    constructor(id: number) {
        this.Id = id;
        this.UserDefinedVars = {};
    }
}

export class StopBatchDto{
    IsValidatePrinted: boolean;
    IsSuspend: boolean;
}

export class BatchIdDto {
    Id: string;

    constructor(id: string) {
        this.Id = id;
    }
}

export class JobIdDto {
    JobId: string;
    Data: any;

    constructor(jobId: string) {
        this.JobId = jobId;
    }
}

export class FieldWidth {
    FProductWidth: number;
    FProgressbarWidth: number;
    FPrintersWidth: number;
    FPrinterNameWidth: number;
}