import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuPrinterService } from './printer.service';
import { AspuPrinter } from './printer.model';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
//import { IResult } from '../../../../models/iresult';

@Component({
    selector: 'app-printer-list-component',
    templateUrl: './printer-list.component.html',
    providers: [AspuPrinterService]
})
export class AspuPrinterListComponent implements OnInit {
    rows = new Array<AspuPrinter>();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    constructor(private serv: AspuPrinterService, private lservice: LangService, public modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.loadItems();
    }

    ngOnInit() { }

    onSort(event) {
    }

    public  loadItems() {
        this.loading = true;

            this.serv.getItems().subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.rows = data.Value;
                    this.isReady = true;
                } else {
                    console.log(data);
                }
                    this.loading = false;
            }, error => console.error(error));
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {
            this.confirmation.Action = action;
            this.confirmation.Parameter = id;
            this.confirmation.Counter++;
        this.confirmation.Message = LangService.getlocalized('common', 'Sure');
        this.confirmation.ClassType="warning"
    }

    getConfirmation(event) {
        if (event) {
            if (this.confirmation.Action == 'delete') {
                this.deleteItem(this.confirmation.Parameter);
            }
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    editItem(id: string): void {
        this.router.navigate(['../aspu-printer-edit/' + id + '/editCard'], { relativeTo: this.route });
    }

    deleteItem(id: string) {
        this.loading = true;
        this.serv.deleteItem(id).subscribe(data => {
            if (data.IsSuccess) {
                this.loadItems();
            }
            this.loading = false;
        });
    }

    addItem(): void {
        this.router.navigate(['../aspu-printer-edit/new'], { relativeTo: this.route });
    }

    onActivate(event) {
        if (event.type == 'dblclick') {
            this.editItem(event.row.Id);
        }
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    } 
   
    
}

