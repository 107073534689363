import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuProfile } from './profile.model';
import { AspuProfileService } from './profile.service';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'vekas_models/core/toaster/toast-service';

@Component({
    selector: 'app-profile-edit-component',
    templateUrl: './profile-edit.component.html',
    providers: [AspuProfileService]
})
export class AspuProfileEditComponent implements OnInit {
    viewModel: AspuProfile = new AspuProfile();
    
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    id: string;

    groupTypes: string[];

    constructor(private serv: AspuProfileService, private lservice: LangService, public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }

        if (localStorage.getItem("token") != undefined) {
            this.groupTypes = JSON.parse(localStorage.getItem('GroupTypes'));
        }
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (!params.id) {
                this.loading = false;
                this.viewModel.GroupType = this.groupTypes[0];
                this.viewModel.Id = params.id;
                return;
            }
            this.id = params.id;
            this.loadItems();
        });
     }

    loadItems() {
        this.loading = true;
        this.serv.getItemById(this.id).subscribe((data: IResult<AspuProfile>) => {
            this.viewModel = data.Value;
            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    errors: any;
    saveProfile(redirect = false) {
        if (this.viewModel.Id == null) {
            this.serv.createItem(this.viewModel).subscribe(data => {
                if(data.IsSuccess)
                {
                    this.toastService.getToast("Профиль успешно создан!", 'info');
                    if (redirect) this.redirect();
                    else
                        this.redirectToThis(data.Value);
                } else {
                    this.errors = data.ValidationMessages
                }
            },
                error => {
                    console.error(error);
                    this.loadItems()
                });
        }
        else {
            this.serv.updateItem(this.viewModel).subscribe(data => {
                if (data.IsSuccess) {
                    this.toastService.getToast("Профиль успешно обновлен!", 'info');
                    if (redirect) {
                        this.redirect();
                    }
                    else {
                        this.loadItems();
                    }
                }
                else {
                    this.errors = data.ValidationMessages
                }
            },
        error => {
            console.error(error);
            this.loadItems()
        });
        }
    }

    redirect() {
        this.router.navigate(['/aspu-profile-list']);
    }

    redirectToThis(id: string) {
        console.log('redirect: ' + id)
        this.router.navigate(['/aspu-profile-edit/' + id + '/editCard']);
        //this.loadItems();
    }

    isNew() {
        return this.viewModel == null ? false : this.viewModel.Id == null;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
