
<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>

<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-0">
        <!--<div class="card-header">
            <span id="codeInputSpan" style="display:none"></span>
            <div class="display-4">
                {{getlocalized('LayerBoxStatus', this.viewModel.Status)}}
            </div>
        </div>-->
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h4 ps-1 pe-1 text-uppercase">
                        {{getlocalized('common', 'AggregationBox')}}
                    </div>
                    <!--<span class="badge badge-info" *ngIf="viewModel.Products!=undefined">{{getRealCount()}}</span>-->
                </div>
                <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
            <div class="btn-group2 d-flex justify-content-end">
                <button id="LayerAggBox_ClearLayerAction" [disabled]="!viewModel.AllowClearLayer || this.viewModel.IsAggregate" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Clear current layer" (click)="clearLayer()" class="btn btn-sm btn-danger me-1 text-uppercase">
                    <i class="fa fa-lg fa-times me-1 "></i>{{getlocalized('common', 'ClearLayer')}}
                </button>
                <button id="LayerAggBox_ClearAllAction" [disabled]="!viewModel.AllowClearAll" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Clear all layer" (click)="clearAll()" class="btn btn-sm btn-danger me-1 text-uppercase">
                    <i class="fa fa-lg fa-times me-1 "></i>{{getlocalized('common', 'ClearAll')}}
                </button>
            </div>
        </div>
        <div class="card-body form-group mb-3 p-2 pb-0">
            <div class="row">
                <div class="col-6 p-0">
                    <div class="container">
                        <div *ngFor="let layer of viewModel.Layers; let i = index" id="{{ 'object-' + i }}" class="mb-1 border border-light rounded">
                            <div class="grid p-2 rounded border" style="border-width:4px!important;"
                                 [class.bg-secondary]="layer.IsActive"
                                 [class.border-success]="getBorder(layer, 'success')"
                                 [class.border-danger]="getBorder(layer, 'danger')"
                                 [class.border-dark]="getBorder(layer, 'default')"
                                 [ngStyle]="{'grid-template-columns': 'repeat(' + viewModel.LayerColsCount + ', 1fr)', 'grid-template-rows': 'repeat('+ viewModel.LayerRowsCount + ', 1fr)'}">
                                <div *ngFor="let item of layer.Items"
                                     [class.bg-success]="item.IsOk"
                                     [class.bg-danger]="item.IsError && !item.IsNoRead"
                                     [class.bg-warning]="item.IsNoRead"
                                     [class.active-cell]="item.IsActive">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 ps-0">
                    <div class="mb-3" *ngIf="this.viewModel.IsAggregate">
                        <ngb-accordion #acc="ngbAccordion">
                            <ngb-panel>
                                <ng-template ngbPanelHeader>
                                    <button ngbPanelToggle class="accordion-button">{{getlocalized('common', 'AggregateActions')}}</button>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="list-group">
                                        <button type="button" id="LayerAggBox_AddChildren" [disabled]="this.viewModel.IsFullAggregate" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Add children" class="p-3 list-group-item list-group-item-action" (click)="setAction('Add')">
                                            <i class="fa fa-lg fa-plus me-1"></i>{{getlocalized('common', 'AddChildren')}}
                                        </button>
                                        <button type="button" id="LayerAggBox_DeleteChildren" [disabled]="this.viewModel.Count < 1" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Delete children" class="p-3 list-group-item list-group-item-action" (click)="setAction('Remove')">
                                            <i class="fa fa-lg fa-times me-1"></i>{{getlocalized('common', 'DeleteChildren')}}
                                        </button>
                                        <button type="button" id="LayerAggBox_ReplaceChildren" [disabled]="this.viewModel.Count < 1" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Replace children" class="p-3 list-group-item list-group-item-action" (click)="setAction('ReplaceChild')">
                                            <i class="fa fa-lg fa-exchange me-1"></i>{{getlocalized('common', 'ReplaceChildren')}}
                                        </button>
                                        <button type="button" id="LayerAggBox_ReplaceCode" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Replace code" class="p-3 list-group-item list-group-item-action" (click)="setAction('ReplaceCode')">
                                            <i class="fa fa-lg fa-exchange me-1"></i>{{getlocalized('common', 'ReplaceCode')}}
                                        </button>
                                        <button type="button" id="LayerAggBox_DestroyAggregate" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Destroy aggregate" class="p-3 list-group-item list-group-item-action" (click)="destroyBox()">
                                            <i class="fa fa-lg fa-arrows-alt me-1"></i>{{getlocalized('common', 'DestroyAggregate')}}
                                        </button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                    <div class="alert alert-info">
                        <div *ngIf="this.viewModel.IsAggregate && this.viewModel.BoxCode != undefined" class="text-center pb-2 pt-2 h3 text-uppercase" role="alert">
                            {{this.viewModel.BoxCode.Code}}
                        </div>
                        <div class="mb-2 text-center">
                            <span class="" style="font-size: 125px;">
                                {{viewModel.Count}}
                            </span>
                            <span class="display-4 text-uppercase" >
                                {{getlocalized('common', 'of')}} 
                                <span class="" style="font-size: 125px;">
                                    {{viewModel.Capacity}}
                                </span>
                            </span>
                        </div>
                        <div *ngIf="true" class="text-center pb-3 pt-2 h3 text-uppercase" role="alert">
                            {{getlocalized('LayerBoxStatus', this.viewModel.Status)}}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div *ngIf="!this.viewModel.IsAggregate || this.viewModel.IsTemporaryAggregate" class="input-group input-group-lg text-uppercase">
                            <span class="input-group-text">{{getlocalized('AggregationList', 'Code')}}</span>
                            <input id="codeInput" placeholder="Введите код" type="text" class="form-control" [(ngModel)]="currentCode">
                            <button id="codeButton" *ngIf="currentCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doAction(currentCode)" class="input-group-append ms-3  btn btn btn-success">
                                <i class="fa fa-2x fa-arrow-circle-right"></i><!--{{getlocalized('common', 'Save')}}-->
                            </button>
                        </div>
                    </div>
                    <div *ngIf="getCellError()" class="mt-1 p-2 alert-danger h3 rounded">
                        {{getCellError()}}
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentPopup let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12 p-0">
            <div *ngIf="popupMessageStatus" class="alert alert-success text-center display-4 m-0" style="word-wrap: break-word" role="alert">
                {{popupMessage}}
            </div>
            <div *ngIf="!popupMessageStatus" class="alert alert-danger text-center display-4 m-0" style="word-wrap:break-word" role="alert">
                {{popupMessage}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contentSecondModal let-c="close" let-modal dismissed="dismiss()">
    <div class="modal-header pb-0">
        <h4 class="modal-title">
            <span class="badge bg-secondary">
                {{getlocalized('common', currentAction)}}
            </span>
        </h4>
        <button type="button" class="close p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div *ngIf="currentAction!='ReplaceChild'" class="form-row">
            <div class="mb-3 pe-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Code')}}</span>
                <input id="" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="actionCode">
                <button id="codeButton" *ngIf="actionCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(actionCode); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
                </button>
                </div>
            </div>
        </div>

        <div *ngIf="currentAction=='ReplaceChild'" class="form-row">
            <div class="mb-3 pe-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Remove')}}</span>
                    <input id="" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="replaceItem.Code">
                </div>
            </div>
            <div class="mb-3 pe-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Add')}}</span>
                        <input id="" [disabled]="replaceItem.Code==undefined" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="replaceItem.NewCode">
                        <button id="codeButton" [disabled]="replaceItem.Code==undefined" *ngIf="replaceItem.NewCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(undefined); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
                </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="modal.dismiss('Cross click')">{{getlocalized('common', 'Close')}}</button>
    </div>
</ng-template>
