<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'BatchHistory')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
      <ngx-datatable ngx-resize-watcher *ngIf="isReady"
                     ngx-localizer class="bootstrap ngx-datatable"
                     id="someTable"
                     [rows]="page.Items"
                     [loadingIndicator]=""
                     [scrollbarH]="true"
                     [columnMode]="ColumnMode.force"
                     [count]="page.TotalElements"
                     [limit]="page.Size"
                     [offset]="page.PageNumber"
                     (page)="onPage($event)"
                     (onSort)="onSort($event)"
                     [headerHeight]="50"
                     [footerHeight]="50"
                     rowHeight="auto"
                     [reorderable]="reorderable"
                     [externalPaging]="true">
        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'Name')}}" prop="Name" cellClass="custom-row text-uppercase" [minWidth]="150" [canAutoResize]="true" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              <span (click)="sort()">
                {{column.name}}
              </span>
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Name'" [fieldType]="'string'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [placement]="'right'"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'Receipt')}}" prop="Receipt" cellClass="custom-row text-uppercase" [minWidth]="250" [maxWidth]="500" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Receipt'" [fieldType]="'string'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'ProductGtin')}}" prop="ProductGtin" cellClass="custom-row" [minWidth]="170" [maxWidth]="500" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'ProductGtin'" [fieldType]="'string'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'StartDate')}}" prop="StartDate" cellClass="custom-row" [minWidth]="150" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'StartDate'" [fieldType]="'date'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value | date: 'dd.MM.yyyy HH:mm:ss'}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'StopDate')}}" prop="StopDate" cellClass="custom-row" [minWidth]="150" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'StopDate'" [fieldType]="'date'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{ value | date: 'dd.MM.yyyy HH:mm:ss'}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column *ngIf="isWeightAvailable()" name="{{getlocalized('CodeList', 'Weight')}}" prop="Weight" cellClass="custom-row" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
            <div class="header-label" ngbTooltip="{{getlocalized('CodeList', 'Weight')}}">
              <span (click)="sort()">
                {{column.name}}
              </span>
            </div>
            <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Weight'" [fieldType]="'number'"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)">
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('BatchHistoryList', 'StopReason')}}" prop="StopReason" cellClass="custom-row" [minWidth]="170" [maxWidth]="500" [resizeable]="false" headerClass="hide-icon">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              {{column.name}}
            </div>
            <complex-filter-component [gridSort]="gridSort"
                                      [viewModel]="gridFilters"
                                      [fieldName]="'StopReason'"
                                      [fieldType]="'list'"
                                      [fieldValuesLocaleKey]="'BatchHistoryList'"
                                      [fieldValues]="stopReasonList"
                                      (onClick)="gridFilters = $event"
                                      (onClose)="updateFilter($event)"
                                      [reset]="resetChild"
                                      (onSort)="onSort($event)"
                                      [isLarge]=true>
            </complex-filter-component>
          </ng-template>
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{getlocalized('BatchHistoryList', value)}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="170" [maxWidth]="170" [canAutoResize]="true" [resizeable]="false">
          <ng-template let-column="column" ngx-datatable-header-template>
            <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
              <span>
                {{column.name}}
              </span>
            </div>
            <div class="btn-group2">
              <button id="BatchHistory_List_UpdateAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItemsV2()" class="btn btn-sm btn-info me-3"><i class="fa fa-redo"></i></button>
              <button id="BatchHistory_List_ResetFilter" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
            </div>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div class="btn-group2 action-buttons">
              <button id="BatchHistory_List_EditAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Counters')}}" (click)="editItem(row['Id'])" class="btn btn btn-info me-2"><i class="fa fa-dice-d6"></i></button>
              <a *ngIf="row['OmsUrl']" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Открыть в ОМС" href="{{row['OmsUrl']}}" target="_blank" class="btn btn btn-info me-2"><i class="fa fa-external-link-alt"></i></a>
              <a *ngIf="row['ArchivePath']" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Открыть архив партии" href="{{row['ArchivePath']}}" target="_blank" class="btn btn btn-info"><i class="fa fa-folder"></i></a>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
</div>
