import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpXhrBackend } from "@angular/common/http";
import { SignalRService } from "./signalr.service";
import { IResult } from "vekas_models/models/iresult";
import { LangService } from "vekas_models/services/lang.service";

export class ConfigurationModel
{
    public WebApiHost: string;
    public AspuHost: string;
    public PageSize: number;
    public DefaultLanguage: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService
{
  private static instance: ConfigurationService;
  private static http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  public Configuration: ConfigurationModel;
  public isLoaded: boolean = false;

  private constructor()
  {
  }

  public static getInstance(): ConfigurationService
  {
    if (!ConfigurationService.instance)
    {

        ConfigurationService.instance = new ConfigurationService();

      this.http.get<IResult<any>>("/UI/settings", this.getCommonOptions()).toPromise()
        .then(
          (data: any) =>
          {
                console.log("/UI/settings");
                console.log(data);
            if (data.IsSuccess)
            {
              ConfigurationService.instance.Configuration = data.Value;
                if (ConfigurationService.instance.Configuration != null && ConfigurationService.instance.Configuration.WebApiHost != null) {
                  (<any>window).apiServiceHost = ConfigurationService.instance.Configuration.WebApiHost;
               //   SignalRService.Connect((<any>window).apiServiceHost);
                }

                if (ConfigurationService.instance.Configuration != null && ConfigurationService.instance.Configuration.AspuHost != null) {
                    (<any>window).aspuApiServiceHost = ConfigurationService.instance.Configuration.AspuHost;
              }
            }
            else
            {
              console.log("Load Configuration Error");
            }
          }
        )
        .catch(
          error =>
          {
            console.error(error)
          })
        .finally(
          () =>
          {

            SignalRService.getInstance();
            LangService.getInstance("Aspu", ConfigurationService.instance.Configuration?.DefaultLanguage);

            ConfigurationService.instance.isLoaded = true;
          }
        );
    }

    return ConfigurationService.instance;
  }

  public static setIndustry() {
    this.http.get<IResult<string>>((<any>window).aspuApiServiceHost + "/api/Management/Industry", this.getCommonOptions()).toPromise()
      .then(
        (data: any) => {
          if (data.IsSuccess) {
            localStorage.setItem('industry', data.Value);
          }
          else {
            console.log("Load Industry Error");
          }
        }
      )
      .catch( error => { console.error(error) })
      .finally( () => {} );
  }


  private static getCommonOptions(): any
  {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
  }
}
