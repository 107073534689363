import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../services/aspu.http.service';
import { AspuCountersDto, AspuCounterRequestObj } from './counters.model';

@Injectable()
export class AspuCountersService {

    constructor(private httpService: AspuHttpService) {}    

    getCounters(id: string): Observable<IResult<AspuCountersDto>> {
        return this.httpService.get<IResult<AspuCountersDto>>('Codes/counters/get', id);
    } 

    setCounters(item: AspuCounterRequestObj): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/counters/set');
    }
    
    clearCounters(item: AspuCounterRequestObj): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/counters/clear');
    }
}
