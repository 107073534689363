import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuReportStatus } from './report.model';
import { AspuHttpService } from '../../services/aspu.http.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';

@Injectable()
export class AspuReportService {

    constructor(
        private httpService: AspuHttpService) { }

    getReports() {
        return this.httpService.get<IResult<AspuReportStatus>>('Management/reports');
    }

    reloadReports() {
        return this.httpService.post<IResult<any>>(null, 'Management/reports');
    }
}