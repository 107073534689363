<div class="card mt-2" style="min-width:1300px;">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'Codes')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        </h3>
    </div>


    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
        <ngb-panel id="panel1">
            <!--<ng-template ngbPanelTitle>
              <div class="d-flex align-items-center justify-content-between">
                {{getlocalized('common', 'Filters')}}
                <i class="fa fa-filter"></i>
              </div>
            </ng-template>-->
            <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between accordion-button">
                    <button ngbPanelToggle class="btn container-fluid text-start ps-0">
                        <span class="text-uppercase me-2 " style="font-size: 20px; font-weight: 500">{{getlocalized('common', 'Filters')}}</span>
                        <!--<i class="fa fa-lg fa-filter" style="color: #3F3F9B"></i>-->

                        <span class="fa-layers fa-2x fa-fw" style="color: #3F3F9B">
                            <i class="fas fa-filter"></i>
                            <i *ngIf="filterContainer?.filter?.filters?.length>0" class="fa-inverse fas fa-circle text-success" data-fa-transform="shrink-10 up-6 right-6"></i>
                        </span>

                        <!--<span *ngIf="!acc.isExpanded('panel1')" class="float-end text-dark"><i class="fa fa-2x fa-chevron-down"></i></span>
                        <span *ngIf="acc.isExpanded('panel1')" class="float-end text-dark"><i class="fa fa-2x fa-chevron-up"></i></span>-->

                    </button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div *ngIf="true" class="form-row2" style="width:1240px">
                    <div class="input-group2 input-group-sm2 mb-3 pe-3 code-width code-filter-block">
                        <span class="btn-group2 btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn code-filter-header code-width pt-3 pb-3">{{getlocalized('CodeList', 'GroupType')}}</label>
                            <label *ngFor="let type of productTypes" class="btn-check" ngbButtonLabel class="btn btn-lg btn-info custom-btn-var code-width">
                                <input ngbButton type="radio" class="btn-check" id="ProductType.GroupType" value="{{type}}">{{getlocalized('ProductGroupTypeDictionary', type)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group2 input-group-sm2 mb-3 pe-3 code-width code-filter-block">
                        <span class="btn-group2 btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn code-filter-header code-width pt-3 pb-3">{{getlocalized('CodeList', 'PrintingStatus')}}</label>
                            <label [id]="PrintingStatus" *ngFor="let status of printerStatuses" ngbButtonLabel class="btn btn-lg btn-info custom-btn-var code-width">
                                <input ngbButton type="radio" class="btn-check" id="PrintingStatus" value="{{status}}">{{getlocalized('PrintStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group2 input-group-sm mb-3 pe-3 code-width code-filter-block">
                        <span class="btn-group2 btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn code-filter-header code-width pt-3 pb-3" style="">{{getlocalized('CodeList', 'EntityStatus')}}</label>
                            <label *ngFor="let status of entityStatuses" ngbButtonLabel class="btn btn-lg btn-info custom-btn-var code-width">
                                <input ngbButton type="radio" class="btn-check" id="EntityStatus" value="{{status}}">{{getlocalized('AggregationStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group2 input-group-sm mb-3 pe-3 code-width code-filter-block">
                        <span class="btn-group2 btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn code-filter-header code-width pt-3 pb-3" style="">{{getlocalized('CodeList', 'OmsUploadedStatus')}}</label>
                            <label *ngFor="let status of omsUploadedStatuses" ngbButtonLabel class="btn btn-lg btn-info custom-btn-var code-width">
                                <input ngbButton type="radio" class="btn-check" id="OmsUploaded" value="{{getUploadStatusByText(status)}}">{{getlocalized('OmsUploadStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group2 input-group-sm mb-3 pe-3 code-width code-filter-block">
                        <span class="btn-group2 btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn code-filter-header code-width pt-3 pb-3" style="">{{getlocalized('CodeList', 'ValidationStatus')}}</label>
                            <label *ngFor="let status of validateStatuses" ngbButtonLabel class="btn btn-lg btn-info custom-btn-var code-width">
                                <input ngbButton type="radio" class="btn-check" id="Validated" value="{{getValidateStatusByText(status)}}">{{getlocalized('ValidateStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>
                </div>

                <div *ngIf="false" class="form-row">
                    <div class="input-group input-group-lg mb-3 pe-3">
                        <span class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn btn-lg custom-btn-prepend2" style="padding-left: 5px; padding-right: 5px ">{{getlocalized('CodeList', 'GroupType')}}</label>
                            <!--<label *ngFor="let type of productTypes; let i = index" ngbButtonLabel [ngStyle]="{'width': i==0 ? '64px!important' : 'inherit'}" class="btn-lg btn-info custom-btn-var">-->
                            <label *ngFor="let type of productTypes; let i = index" ngbButtonLabel [style.width]="i==0 ? '64px!important' : '130px!important'" class="btn-lg btn-info custom-btn-var">
                                <!--<input ngbButton type="radio" id="ProductType.GroupType" [ngStyle]="{'width': i==0 ? '64px!important' : 'inherit'}" value="{{type}}">{{getlocalized('ProductGroupTypeDictionary', type)}}-->
                                <input ngbButton type="radio" class="btn-check" id="ProductType.GroupType" value="{{type}}">{{getlocalized('ProductGroupTypeDictionary', type)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group input-group-lg mb-3 pe-3">
                        <span class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn btn-lg custom-btn-prepend2" style="padding-left: 0px; padding-right: 0px ">{{getlocalized('CodeList', 'PrintingStatus')}}</label>
                            <label [id]="PrintingStatus" *ngFor="let status of printerStatuses; let i = index" ngbButtonLabel [style.width]="i==0 ? '64px!important' : '130px!important'" class="btn-lg btn-info custom-btn-var">
                                <input ngbButton type="radio" class="btn-check" id="PrintingStatus" value="{{status}}">{{getlocalized('PrintStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group input-group-lg mb-3 pe-3">

                        <span class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn btn-lg custom-btn-prepend2" style="padding-left: 0px; padding-right: 0px ">{{getlocalized('CodeList', 'EntityStatus')}}</label>
                            <label *ngFor="let status of entityStatuses; let i = index" ngbButtonLabel [style.width]="i==0 ? '64px!important' : '130px!important'" class="btn-lg btn-info custom-btn-var">
                                <input ngbButton type="radio" class="btn-check" id="EntityStatus" value="{{status}}">{{getlocalized('AggregationStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group input-group-lg mb-3 pe-3">
                        <span class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn btn-lg custom-btn-prepend2" style="padding: 0px; ">{{getlocalized('CodeList', 'OmsUploadedStatus')}}</label>
                            <label *ngFor="let status of omsUploadedStatuses; let i = index" ngbButtonLabel [style.width]="i==0 ? '64px!important' : '130px!important'" class="btn-lg btn-info custom-btn-var">
                                <input ngbButton type="radio" class="btn-check" id="OmsUploaded" value="{{getUploadStatusByText(status)}}">{{getlocalized('OmsUploadStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                    <div class="input-group input-group-lg mb-3 pe-3">
                        <span class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" (change)="updateFilter($event)">
                            <label class="btn btn-lg custom-btn-prepend2" style="padding-left: 0px; padding-right:0px">{{getlocalized('CodeList', 'ValidationStatus')}}</label>
                            <label *ngFor="let status of validateStatuses; let i = index" ngbButtonLabel [style.width]="i==0 ? '64px!important' : '130px!important'" class="btn-lg btn-info custom-btn-var">
                                <input ngbButton type="radio" class="btn-check" id="Validated" value="{{getValidateStatusByText(status)}}">{{getlocalized('ValidateStatusDictionary', status)}}
                            </label>
                        </span>
                    </div>

                </div>

                <div class="mb-3 ps-0 pe-0 col-lg-12 col-xl-12">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text">{{getlocalized('MessageList', 'Quantity')}}</span>
                        <input disabled="disabled" type="text" class="form-control" [(ngModel)]="page.Size">
                        <mv-slider [min]="50" [max]="200"
                                   [enabled]="true"
                                   [value]="page.Size"
                                   [tooltip]="'always'"
                                   [ticks]="[50, 100, 150, 200]"
                                   [ticksLabels]="['50', '100', '150', '200']"
                                   [ticksTooltip]="false"
                                   [ticksSnapBounds]="50"
                                   (change)="setPage($event)"
                                   class="ms-4 me-2 ">
                        </mv-slider>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <div class="card-body form-group mb-3 pb-0">
        <div class="mb-3 ps-0 pe-0 pe-0 col-lg-6 col-xl-6">
            <div class="input-group input-group-lg ">
                <span class="input-group-text">{{getlocalized('AggregationList', 'Code')}}</span>
                <input id="codeInput" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="customCode">
                <button id="codeButton"
                        [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="editCodeFromCode()" class="input-group-append2 btn btn-lg btn-success">
                    <i class="fa fa-search me-1 "></i>
                </button>
            </div>

            <div *ngIf="isCodeWeightAvailable()" class="input-group input-group-lg mb-3 pr-0 col-lg-6 col-md-6 mt-2">
                <div class="input-group-prepend input-group-lg">
                    <span class="input-group-text">{{getlocalized('CodeList', 'Weight')}}</span>
                </div>
                <input disabled type="text" class="form-control" [(ngModel)]="codesWeigth">                
            </div>
        </div>

        <div>
            <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                           id="codeTable"
                           [rows]="page.Items"
                           [loadingIndicator]=""
                           [externalPaging]="true"
                           [externalSorting]="true"
                           [columnMode]="ColumnMode.force"
                           [headerHeight]="50"
                           [scrollbarH]="true"
                           [footerHeight]="50"
                           rowHeight="auto"
                           [count]="page.TotalElements"
                           [limit]="page.Size"
                           [offset]="page.PageNumber"
                           (page)="onPage($event)"
                           (sort)="onSort($event)"
                           [rowClass]="getRowClass"
                           [reorderable]="reorderable"
                           (activate)="onActivate($event)">
                <ngx-datatable-column name="{{getlocalized('CodeList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true"  [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                                {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <code style="word-wrap:inherit">{{ value }}</code>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{getlocalized('CodeList', 'GroupType')}}" prop="GroupType" cellClass="custom-row" [minWidth]="100" [maxWidth]="150" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{getlocalized('ProductGroupTypeDictionary', value)}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('CodeList', 'PrintingStatus')}}" prop="PrintingStatus" cellClass="custom-row" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{getlocalized('PrintStatusDictionary', value)}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('CodeList', 'OmsUploadedStatus')}}" prop="OmsUploaded" cellClass="" [minWidth]="100" [maxWidth]="140" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <div *ngIf="value"><i class="fa fa-circle-check text-success"></i></div>

                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('CodeList', 'EntityStatus')}}" prop="EntityStatus" cellClass="custom-row" [minWidth]="120" [maxWidth]="140" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{getlocalized('AggregationStatusDictionary', value)}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('CodeList', 'ValidationStatus')}}" prop="Validated" cellClass="" [minWidth]="120" [maxWidth]="140" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <span *ngIf="value"><i class="text-light fa-regular fa fa-2x fa-circle-check"></i></span>
                        <span *ngIf="!value"><i class="text-danger fa fa-2x fa-times"></i></span>

                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isWeightAvailable()" name="{{getlocalized('CodeList', 'Weight')}}" prop="Weight" cellClass="" [minWidth]="120" [maxWidth]="130" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isServiceCodeAvailable()" name="{{getlocalized('CodeList', 'ServiceCode')}}" prop="ServiceCode" cellClass="" [minWidth]="120" [maxWidth]="190" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{value}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="150" [maxWidth]="150" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip"  tooltipClass="customTooltip" triggers="click:blur">
                                {{column.name}}
                        </div>
                        <div class="btn-group2">
                            <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItemsV2()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                        </div>
                    </ng-template>
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <div class="btn-group2 action-buttons">
                            <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="Копировать в буфер обмена" (click)="copyToClipboard(row['Code'])" class="btn btn btn-info me-2 "><i class="fa fa-copy"></i></button>
                            <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editCode(row)" class="btn btn btn-info"><i class="fa fa-edit"></i></button>
                            <!--<button *ngIf="row['GroupType']!='Pallet'" ngbTooltip="Переместить в агрегат" (click)="aggregate(row)" class="btn btn-sm btn-info"><i class="fa fa-arrow-circle-o-up"></i></button>-->
                        </div>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>

<div *ngIf="currentCode!=undefined">
    <app-code-edit-component (onChange)="isCodeReceivedPrevented = $event" [code]="currentCode" [counter]="counter"></app-code-edit-component>
</div>
