import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuLayer63313VisualService } from './layer63313-visual.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuCodeOnly } from '../../code/code.model';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import { AspuLayerState } from './layer63313-visual.model';

@Component({
    selector: 'app-layer63313-visual.component',
    templateUrl: './layer63313-visual.component.html',
    styleUrls: ['./layer63313-visual.scss'],
    providers: [AspuLayer63313VisualService, AspuIndicatorService]
})
export class AspuLayer63313VisualComponent implements OnInit {
    viewModel = new AspuLayerState();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    currentCode: string;

    isHandleModeAvailable = false;
    isDetalized = false;

    currentProductCode: string;

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReload.bind(this);

    constructor(private serv: AspuLayer63313VisualService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {

        this.loadItemsWithStateCheck();
    }

    ngOnInit() {
        window.addEventListener("AggrStateUpdated", this._doReload, false);
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
    }

    ngOnDestroy() {
        window.removeEventListener("AggrStateUpdated", this._doReload);
        window.removeEventListener("CodeReceived", this._doAction);
        window.removeEventListener("EnterCodeReceived", this._doAction);
    }
    
    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    doAction(code: string) {
        console.log("doAction")
        this.codeLevel = '+';
        if (code == null) {
            this.codeLevel = '++';
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;

            if (code == null) {
                this.codeLevel = '+++';
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        this.serv.pushCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                let result = data.Value;
                if (!result.IsOk) {
                    this.toastService.getToast(result.Message, 'error');
                }
                else {
                    this.toastService.getToast(data.Value.Message, 'info');
                }
            }

            this.isReady = true;
            this.loading = false;
            this.currentCode = undefined;
        },
            error => console.error(error));

    }

    doReload(event) {
        if (event.detail == "LayerSerialization") {
            this.loadItems();
        }
    }

    clearLayer() {
        console.log("Clear layer");
        this.currentCode="";
        this.serv.clearLayer().subscribe((data: IResult<any>) => {
            this.isReady = true;
            this.loading = false;
        },
        error => console.error(error));
    }

    public loadItems() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<AspuLayerState>) => {
            console.log(data.Value);
            data.Value.Products.reverse();
            this.viewModel = data.Value;
            this.isReady = true;
            this.loading = false;
        });
    }

    indicator = new AspuIndicator();

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) => {
            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled") {
                this.loadItems();
            }
            else {
                this.loading = false;
            }
        });
    }

    getBorder(color: string) {
        let hasSuccess = this.viewModel.Products.filter(x => x.IsOk).length == this.viewModel.Products.length;
        let hasError = this.viewModel.Products.filter(x => x.IsError).length != 0;
        switch (color) {
            case 'success':
                return hasSuccess;
            case 'danger':
                return hasError;
            default:
                return !(hasError || hasSuccess);
        }
    }

    getCellError() {
        for (var i = 0; i < this.viewModel.Products.length; i++) {
            let layer = this.viewModel.Products[i];
            if (layer.Message != undefined)
                return layer.Message;
        }
        return undefined;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
