
<div *ngIf="indicator.Production!='Enabled'" class="p-0 m-0">
    <div class="alert alert-secondary text-center pb-5 pt-5 display-4" role="alert">
        {{getlocalized('common', 'ProductionOn')}}
    </div>
</div>


<div *ngIf="indicator.Production=='Enabled'" class="p-0 m-0">
    <div class="card mt-2">
        <div class="card-header d-flex justify-content-between">
            <div id="codeInputSpan" style="display:none"></div>
            <div>
                <div class="header-badge" style="margin-top: 10px; display: inline-block">
                    <div class="h4 ps-1  pe-1 text-uppercase">
                        {{getlocalized('common', 'AggregationBox')}}
                    </div>
                    <!--<span class="badge badge-info" *ngIf="viewModel.Products!=undefined">{{getRealCount()}}</span>-->
                </div>
                <span *ngIf="loading" class="ms-3" style="position: relative; top: -5px;"><app-spinner [mode]="'burst'"></app-spinner></span>
            </div>
            <div class="btn-group2 d-flex justify-content-end">
                <button *ngIf="!isDetalized" class="btn btn-sm btn-secondary me-1  text-uppercase" (click)="isDetalized=true">
                    <i class="fa fa-lg fa-toggle-off me-1 "></i>{{getlocalized('common', 'Details')}}
                </button>
                <button *ngIf="isDetalized" class="btn btn-sm btn-success me-1  text-uppercase" (click)="isDetalized=false">
                    <i class="fa fa-lg fa-toggle-on me-1 "></i>{{getlocalized('common', 'Details')}}
                </button>
                <button id="AggBox_RemoveAction" [disabled]="!viewModel.IsClearAvailable" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Remove" (click)="removeItems()" class="btn btn-sm btn-danger me-1  text-uppercase">
                    <i class="fa fa-lg fa-times me-1 "></i>{{getlocalized('common', 'ClearBox')}}
                </button>
                <button id="AggBox_CloseNotFullAction" [disabled]="!viewModel.IsAllowCloseNotFull" class="btn btn-sm btn-info me-1  text-uppercase" (click)="closeNotFull()">
                    <i class="fa fa-lg fa-times me-1 "></i>{{getlocalized('common', 'CloseNotFull')}}
                </button>
                <button *ngIf="isShowPlcQueueClearButton" id="AggBox_PlcQueueClearAction" class="btn btn-sm btn-danger me-1  text-uppercase" (click)="plcQueueClear()">
                    <i class="fa fa-lg fa-times me-1 "></i>{{getlocalized('common', 'PlcQueueClear')}}
                </button>
            </div>
        </div>
        <div class="card-body form-group mb-3 p-2 pb-0 {{getCodeBgColor()}}">
            <div *ngIf="viewModel!=undefined && viewModel.IsWaitingWeighting" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanWeightingWaiting')}}
            </div>

            <div *ngIf="!isInvalidBoxCode() && !viewModel.IsWaitingWeighting && viewModel!=undefined && viewModel.Status=='ProductsEnter'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanProductCode')}}
                <span class="badge badge-warning" *ngIf="isHandleModeAvailable">{{getlocalized('StatusCodeList', 'ManualAssembly')}}</span>
            </div>

            <div *ngIf="!isInvalidBoxCode() && viewModel!=undefined && viewModel.Status=='ContentEnter'" class="alert alert-info text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanContentCode')}}
            </div>

            <div *ngIf="isInvalidBoxCode()" class="alert alert-danger text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('common', 'InvalidBoxCode')}}
            </div>

            <div *ngIf="!isInvalidBoxCode() && viewModel!=undefined && (viewModel.Status=='BoxEnter' || viewModel.Status=='BoxUpdate')" class="alert alert-warning text-uppercase text-center pb-5 pt-5 display-4" role="alert">
                {{getlocalized('StatusCodeList', 'ScanBoxCode')}}
            </div>

            <div *ngIf="!isDetalized" class="m-0">
                <div class="row align-items-center d-flex justify-content-center">
                    <div class="col">
                        <span class="btn-group2 btn-group-toggle btn-group-vertical" ngbRadioGroup name="radioBasic" [(ngModel)]="mode">
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg text-uppercase" [ngClass]="{'btn-success': mode=='aggregation', 'btn-info': mode!='aggregation'}">
                                <input id="AggBox_AggregationToggleAction" ngbButton class="btn-check" type="radio" value="aggregation">{{getlocalized('AggregationList', 'Aggregation')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 rounded-bottom btn-agg text-uppercase rounded-top" [ngClass]="{'btn-danger': mode=='remove', 'btn-info': mode!='remove'}">
                                <input id="AggBox_RemovingToggleAction" ngbButton class="btn-check" type="radio" value="remove">{{getlocalized('AggregationList', 'Removing')}}
                            </label>
                            <label ngbButtonLabel class="btn-lg mb-3 mt-1 text-uppercase rounded-top btn-agg" [ngClass]="{'btn-warning': mode=='info', 'btn-info': mode!='info'}">
                                <input id="AggBox_InfoToggleAction" ngbButton class="btn-check" type="radio" value="info">{{getlocalized('AggregationList', 'Information')}}
                            </label>
                        </span>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <div>
                            <span class="" style="font-size:170px; ">
                                {{getRealCount()}}
                            </span>
                        </div>
                    </div>
                    <div class="col align-self-start d-flex justify-content-center">
                        <div>
                            <span class="display-4 text-uppercase" style="">
                                {{getlocalized('common', 'of')}} <span class="display-2">{{viewModel.Capacity}}</span>
                            </span>
                        </div>
                    </div>
                    <div *ngIf="viewModel.IsQueueEnabled" class="col align-self-start d-flex justify-content-center">
                        <div>
                            <div class="fw-bold text-info text-uppercase">{{getlocalized('AggregationList', 'Queue')}}:</div>
                            <div *ngFor="let item of viewModel.Queue">
                                <div [ngClass]="{'text-success': item.IsOk==true, 'text-danger': item.IsOk!=true}" class="mb-1">
                                    {{item.Name}}
                                    <div class="btn btn-sm btn-danger" (click)="removeFromQueue(item.Id)"><i class="fa fa-trash"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase label-edit-width-190">{{getlocalized('AggregationList', 'BoxCode')}}</span>
                        <input id="{{viewModel.Status=='BoxEnter' || viewModel.Status=='BoxUpdate'? 'codeInput' : '' }}" [disabled]="!(viewModel.Status=='BoxEnter' || viewModel.Status=='BoxUpdate')" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="viewModel.Box.Code">
                        <button id="codeButton"
                                *ngIf="viewModel.Status=='BoxEnter' || viewModel.Status=='BoxUpdate'" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Apply')}}" (click)="doAction(viewModel.Box.Code)" class="input-group-append ms-3 btn btn btn-success text-uppercase">
                            <i class="fa fa-lg fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>

                <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text text-uppercase label-edit-width-190">{{getlocalized('AggregationList', 'ProductCode')}}</span>
                        <!--<input id="{{viewModel.Status=='ProductsEnter'? 'codeInput' : ''}}" [disabled]="viewModel.Status!='ProductsEnter'" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentProductCode">-->
                        <input id="{{viewModel.Status=='ProductsEnter'? 'codeInput' : ''}}" [disabled]="!(viewModel.Status=='ProductsEnter' || viewModel.Status=='ContentEnter')" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentProductCode">
                        <button id="codeButton"
                                *ngIf="(viewModel.Status=='ProductsEnter' || viewModel.Status=='ContentEnter')" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Apply')}}" (click)="doAction(currentProductCode)" class="ms-3 text-uppercase btn btn btn-success">
                            <i class="fa fa-lg fa-arrow-circle-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <ngx-datatable ngx-resize-watcher *ngIf="isReady && isDetalized" ngx-localizer class="bootstrap ngx-datatable"
                           id="someTable"
                           [rows]="viewModel.Products"
                           [loadingIndicator]=""
                           [columnMode]="ColumnMode.force"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           rowHeight="auto"
                           (sort)="onSort($event)"
                           [rowClass]="getRowClass"
                           [reorderable]="reorderable">
                <ngx-datatable-column name="{{getlocalized('AggregationList', 'Code')}}" prop="Code" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <code>{{ value }}</code>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="{{getlocalized('AggregationList', 'Message')}}" prop="Message" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" [canAutoResize]="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label header-label-nofilter" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                {{column.name}}
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>

<div *ngIf="currentCode!=undefined">
    <app-code-edit-component (onChange)="isCodeReceivedPrevented = $event" [showParentInfo]="hasParrentInfo()" [code]="currentCode" [counter]="counter" [isActionAvailable]="true"></app-code-edit-component>
</div>

<div *ngIf="confirmation.Message!=undefined">
    <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
</div>

<ng-template #contentPopup let-modal>
    <div class="modal-header p-0">
        <div class="col-lg-12 p-0">
            <div *ngIf="popupMessageStatus" class="alert alert-success text-center display-4 m-0" style="word-wrap: break-word" role="alert">
                {{popupMessage}}
            </div>
            <div *ngIf="!popupMessageStatus" class="alert alert-danger text-center display-4 m-0" style="word-wrap:break-word" role="alert">
                {{popupMessage}}
            </div>
        </div>
    </div>
</ng-template>
