import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginModel } from './login.model';
import { map } from 'rxjs/operators';
import { IResult } from "vekas_models/models/iresult";
import { AuthorizationService } from "vekas_models/services/authorization.service"
import { AspuHttpService } from '../../business/services/aspu.http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService
{
    constructor(private httpService: AspuHttpService, private authorizationService: AuthorizationService)
  {
  }

  public Login(item: LoginModel): Observable<any>
  {
     console.log("Login");
      return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Users/auth_login')
      .pipe(map(user =>
      {
        if (user)
        {
          console.log("Login service");
            console.log(user);
           // localStorage.setItem("aggrAlgorithms", user.AggrAlgorithms);
            this.authorizationService.SetUserInfo(user.Value.UserName, user.Value.Token, user.Value.TokenExpirationDateTime, Date.now(), user.Value.TokenLifeTime);
          this.getUseUIAccess();
        }
        return user.Value;
      }));
    }


  public GetRoles(item: string): Observable<any> {
      return this.httpService.get<IResult<any>>('oms/userrole/getRolesByUser', item)
          .pipe(map(roles => {
              if (roles.IsSuccess) {
                  let isAdmin = roles.Value.RoleShortNames.filter(u => u === "Администратор" || u ==="Сверхпользователь").length > 0;
                  localStorage.setItem("isUserAdmin", isAdmin.toString());
              }
              return roles.Value;
          }));
  }

  public getUseUIAccess()
  {
    this.httpService.get<IResult<any>>('oms/appaccess/aspuUserUIAccess')
      .subscribe(data =>
      {
        this.authorizationService.SetUseUIAccess(JSON.stringify(data.Value));
      });
  }

  public Logout()
  {
      console.log("LogOut Service happened");
      this.authorizationService.RemoveUserInfo();
      localStorage.removeItem("isUserAdmin");
  }
}
