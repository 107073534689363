<!--<ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <li [ngbNavItem]="1" >
        <a ngbNavLink class="h5">{{getlocalized('common', 'Messages')}}</a>
        <ng-template ngbNavContent>-->
            <div class="card mt-2">
                <div class="card-header">
                    <h3>
                        <span class="header-badge text-uppercase">{{getlocalized('common', 'Messages')}}</span>
                        <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
                    </h3>
                </div>
                <div class="card-body form-group mb-3 pb-0">
                    <!--<div class="form-row">-->
                    <!--<div class="input-group input-group-sm mb-3 pe-3 col-lg-12 col-md-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{getlocalized('MessageList', 'Dates')}}</span>
                        </div>
                        <datepicker-range #datepicker (onChange)="setFilterDates($event)"></datepicker-range>
                    </div>-->
                    <!-- <div class="input-group input-group-sm mb-3 pe-3 col-lg-6 col-md-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{getlocalized('MessageList', 'Type')}}</span>
                        </div>
                        <select name="Type"
                                id="Type"
                                (change)="updateFilter($event)"
                                class="form-control form-control-sm ">
                            <option *ngFor="let type of messageTypes" [value]="type">{{type}}</option>
                        </select>
                    </div> -->

                    <div class="mb-3 ps-0 pe-0 col-lg-12 col-xl-12">
                        <div class="input-group input-group-lg">
                            <span class="input-group-text text-uppercase">{{getlocalized('MessageList', 'Quantity')}}</span>
                            <input disabled="disabled" type="text" class="form-control" [(ngModel)]="page.Size">
                            <mv-slider [min]="50" [max]="200"
                                       [enabled]="true"
                                       [value]="page.Size"
                                       [tooltip]="'always'"
                                       [ticks]="[50, 100, 150, 200]"
                                       [ticksLabels]="['50', '100', '150', '200']"
                                       [ticksTooltip]="false"
                                       [ticksSnapBounds]="50"
                                       (change)="setPage($event)"
                                       class="ms-4 me-2">
                            </mv-slider>
                        </div>
                    </div>


                    <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                                   #someTable
                                   [rows]="page.Items"
                                   [loadingIndicator]=""
                                   [externalPaging]="true"
                                   [externalSorting]="true"
                                   [columnMode]="ColumnMode.force"
                                   [count]="page.TotalElements"
                                   [limit]="page.Size"
                                   [offset]="page.PageNumber"
                                   (page)="onPage($event)"
                                   (sort)="onSort($event)"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   rowHeight="auto"
                                   [rowClass]="getRowClass"
                                   [reorderable]="reorderable">
                        <ngx-datatable-column name="{{getlocalized('MessageList', 'Time')}}" prop="Time" cellClass="custom-row" headerClass="hide-icon" [minWidth]="120" [maxWidth]="180" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Time'" [fieldType]="'date'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [placement]="'right'"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value | date: 'dd.MM.yyyy HH:mm'}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="{{getlocalized('MessageList', 'Type')}}" prop="Type" cellClass="custom-row text-uppercase" headerClass="hide-icon" [minWidth]="100" [maxWidth]="110" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Type'" [fieldType]="'list'"
                                                          [fieldValues]="messageTypes"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [placement]="'right'"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{getlocalized('MessageList', 'Priority')}}" prop="Priority" cellClass="custom-row" headerClass="ps-0 pe-0 hide-icon" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Priority'" [fieldType]="'number'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column *ngIf="isBranchAvailable()" name="{{getlocalized('MessageList', 'Branch')}}" prop="Branch" cellClass="custom-row" headerClass="ps-0 pe-0 hide-icon" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Branch'" [fieldType]="'number'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column *ngIf="isObjectIdAvailable()" name="{{getlocalized('MessageList', 'ObjectId')}}" prop="ObjectId" cellClass="custom-row" headerClass="ps-0 pe-0 hide-icon" [minWidth]="110" [maxWidth]="120" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'ObjectId'" [fieldType]="'number'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{getlocalized('MessageList', 'Text')}}" prop="Text" cellClass="custom-row" headerClass="hide-icon" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <complex-filter-component [gridSort]="gridSort" [viewModel]="gridFilters" [fieldName]="'Text'" [fieldType]="'string'"
                                                          (onClick)="gridFilters = $event"
                                                          (onClose)="updateFilter($event)"
                                                          [reset]="resetChild"
                                                          (onSort)="onSort($event)"
                                                          [isLarge]=true>
                                </complex-filter-component>
                            </ng-template>
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn hide-icon" cellClass="central-btn" [minWidth]="130" [maxWidth]="140" [canAutoResize]="true" [resizeable]="false">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="header-label" ngbTooltip="{{column.name}}" container="body" placement="bottom" data-bs-toggle="tooltip" tooltipClass="customTooltip" triggers="click:blur">
                                    {{column.name}}
                                </div>
                                <div class="btn-group2">
                                    <button id="Message_List_Update" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItemsV2()" class="btn btn-sm btn-info me-1 "><i class="fa fa-redo"></i></button>
                                    <button id="Message_List_ConfirmAll" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('MessageList', 'ConfirmAll')}}" (click)="sendConfirmation()" class="btn btn-sm btn-info me-1 "><i class="fa fa-paper-plane"></i></button>
                                    <button id="Message_List_ResetFilter" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reset')}}" (click)="resetFilter()" class="btn btn-sm btn-danger"><i class="fa fa-filter-circle-xmark"></i></button>
                                </div>
                            </ng-template>
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                <div class="btn-group2 action-buttons">
                                    <button id="Message_List_Confirm" *ngIf="row['Priority']>0 && row['ConfirmStatus']!='Confirmed'" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('MessageList', 'Confirm')}}" (click)="sendConfirmation(row)" class="btn btn-sm btn-info"><i class="fa fa-paper-plane"></i></button>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        <!--</ng-template>-->
    <!--</li>
    <li [ngbNavItem]="2">
      <a ngbNavLink class="h5">{{getlocalized('common', 'ErrorMessages')}}</a>
        <ng-template ngbNavContent>
            <app-error-message-list-component></app-error-message-list-component>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>-->
