export class AspuProductItem {
    IsError: boolean;
    IsEmpty: boolean;
    IsOk: boolean;
    IsNoRead: boolean;
    Code: string;
    IsActive: boolean;
    Message: string;
    constructor() { }
}

export class AspuLayerState {
    Products: AspuProductItem[];
    Capacity: number;
    LayerRowsCount: number;
    LayerColsCount: number;
    IsClearAvailable: boolean;
    Status: string;
    
    constructor() {
        this.Products = new Array<AspuProductItem>();
    }
}
