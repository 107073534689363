import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../business/services/aspu.http.service';
import { AspuConfigSettings, AspuConfigurationJson, AspuSettings, AspuWeightDriver } from './settings.model';

@Injectable()
export class AspuSettingsService {

    constructor(
        private httpService: AspuHttpService) { }

        public getSettings(): Observable<IResult<AspuSettings>> {
        return this.httpService.get<IResult<AspuSettings>>('Management/AppConfig', null);
    }

    public setSettings(item: AspuSettings) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Management/AppConfig');
    }

    public getConfig() {
        return this.httpService.get<IResult<AspuConfigSettings>>('Management/ClientConfig', null);
    }

    public setConfig(item: AspuConfigSettings) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Management/ClientConfig');
    }

    public setUserTheme(theme: string) {
        return this.httpService.get<IResult<any>>('oms/setTheme', theme);
    }

    getConfigurationJson(): Observable<IResult<AspuConfigurationJson>> {
        return this.httpService.get<IResult<AspuConfigurationJson>>('management/GetConfiguration');
    }
    
    setConfigurationJson(item: AspuConfigurationJson) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'management/SetConfiguration');
    }

    getWeightDrivers(): Observable<IResult<Array<AspuWeightDriver>>> {
        return this.httpService.get<IResult<Array<AspuWeightDriver>>>('Weighting/drivers', null);
    }
}