export class AspuReportStatus {
    IsNotInitialized: boolean;
    IsWorkingNow: boolean;
    IsFail: boolean;
    Items: Array<AspuReportItem>;

    constructor() {
        this.Items = new Array<AspuReportItem>();
    }
}


export class AspuReportItem {
    Status: string; //None,Ignore,Ok,Error,
    Name: string; // Printed,    Defected,    Unused,    Aggregated,    Finalize
    Errors: string[];
}