 <div class="card mt-2">
        <div class="card-header">
            <h3>
                <span class="header-badge text-uppercase">
                    {{getlocalized('WeightList', 'WeightSettings')}}
                </span>
                <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
            </h3>
        </div>
        <div class="card-body mb-3 pb-0">
            <ngx-datatable *ngIf="isReady" ngx-resize-watcher  ngx-localizer class="bootstrap ngx-datatable"
                           id="someTable"
                           [rows]="items"
                           [loadingIndicator]=""
                           [columnMode]="ColumnMode.force"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           rowHeight="auto"
                           [reorderable]="reorderable">

                <ngx-datatable-column name="{{getlocalized('WeightList', 'DriverName')}}" prop="DriverName" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div class="header-label header-label-nofilter">
                                <span (click)="sort()">
                                    {{column.name}}
                                </span>
                            </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])" class="text-uppercase">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <select name="driverNameId"
                                            id="driverNameId"
                                            class="form-select form-control form-control-lg text-uppercase"
                                            (change)="driverNameChanged($event)"
                                            [(ngModel)]="viewModel.DriverName">
                                        <option *ngFor="let dest of devicesName" [value]="dest">{{getlocalized('WeightNameDictionary', dest)}}</option>
                                    </select>
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{getlocalized('WeightNameDictionary', value)}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('WeightList', 'IsEnable')}}" prop="Enable" [sortable]="false" cellClass="" canAutoResize="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div class="input-group-prepend">
                                    <input class="big-checkbox" type="checkbox" [(ngModel)]="viewModel.Enable" >
                                </div>
                            </div>
                            <div *ngSwitchDefault>
                                <i class="text-success fa fa-2x fa-check" *ngIf="value"></i>
                                <i class="text-danger fa fa-2x fa-times" *ngIf="!value"></i>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('WeightList', 'MinValue')}}" prop="MinValue" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.MinValue">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{getlocalized('WeightList', 'MaxValue')}}" prop="MaxValue" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.MaxValue">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isStreakMWeightTerminal()" name="{{getlocalized('WeightList', 'Host')}}" prop="Host" [sortable]="true" cellClass="custom-row" canAutoResize="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div class="header-label header-label-nofilter">
                                <span (click)="sort()">
                                    {{column.name}}
                                </span>
                            </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                    <input type="text" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Host">
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isStreakMWeightTerminal()" name="{{getlocalized('WeightList', 'Port')}}" prop="Port" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Port">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isStreakMWeightTerminal()" name="{{getlocalized('WeightList', 'Password')}}" prop="Password" [sortable]="true" cellClass="custom-row" canAutoResize="true" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                            <div class="header-label header-label-nofilter">
                                <span (click)="sort()">
                                    {{column.name}}
                                </span>
                            </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                    <input type="text" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Password">
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isStreakMWeightTerminal()" name="{{getlocalized('WeightList', 'Pause')}}" prop="Pause" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Pause">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isStreakMWeightTerminal()" name="{{getlocalized('WeightList', 'Timeout')}}" prop="Timeout" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Timeout">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column *ngIf="isWeightBarcode()" name="{{getlocalized('WeightList', 'Length')}}" prop="Length" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Length">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column> 

                <ngx-datatable-column *ngIf="isWeightBarcode()" name="{{getlocalized('WeightList', 'Skip')}}" prop="Skip" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Skip">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column> 

                <ngx-datatable-column *ngIf="isWeightBarcode()" name="{{getlocalized('WeightList', 'Take')}}" prop="Take" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.Take">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column> 

                <ngx-datatable-column *ngIf="isWeightBarcode()" name="{{getlocalized('WeightList', 'DecimalPlaces')}}" prop="DecimalPlaces" [sortable]="true" cellClass="custom-row" [minWidth]="200" [maxWidth]="250" canAutoResize="true" [resizeable]="true">
                    <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div *ngIf="true" class="">
                                    <input type="number" class="form-control form-control-lg" style="text-align:center;" [(ngModel)]="viewModel.DecimalPlaces">
                                </div>
                            </div>
                            <div *ngSwitchDefault>{{value}}</div>
                        </div>
                    </ng-template>
                </ngx-datatable-column> 

                <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="140" [maxWidth]="140" [resizeable]="false">
                    <ng-template let-column="column" ngx-datatable-header-template>
                        <div class="header-label">
                            <span>
                                {{column.name}}
                            </span>
                        </div>
                        <div class="btn-group2">
                            <button id="createCameraBtn" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Create')}}" (click)="addItem()" class="btn btn-sm btn-info"><i class="fa fa-plus"></i></button>
                        </div>
                    </ng-template>
                    <ng-template let-value="value" ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                        <div [ngSwitch]="isEdit(row['Id'])">
                            <div *ngSwitchCase="true">
                                <div class="btn-group2 action-buttons ">
                                    <button id="saveCameraBtn" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Save')}}" (click)="saveItem(row)" class="btn btn btn-info me-2"><i class="fa fa-save"></i></button>
                                    <button id="cancelCameraBtn" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Cancel')}}" (click)="cancel(row)" class="btn btn btn-danger"><i class="fa fa-ban"></i></button>
                                </div>
                            </div>
                            <div *ngSwitchDefault>
                                <div class="btn-group2 action-buttons">
                                    <button id="editCameraBtn" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" (click)="editItem(row)" class="btn btn btn-info me-2"><i class="fa fa-edit"></i></button>
                                    <button id="removeCameraBtn" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn btn-danger"><i class="fa fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngx-datatable-column> 
            </ngx-datatable>
        </div>
    </div>

    <div *ngIf="confirmation.Message!=undefined">
        <confirmation-component [typeClass]="confirmation.ClassType" [message]="confirmation.Message" [counter]="confirmation.Counter" (onClick)="getConfirmation($event)" [isLarge]=true></confirmation-component>
    </div>
