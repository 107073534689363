<ng-template #contentLoads let-modal>
<div class="modal-header p-0">
    <div class="col-lg-12 p-0">
        <div class="modal-header" style="word-break: break-all; ">
            <h4 class="modal-title" id="modal-basic-title" style="max-width: 90%;" >
                <span class="badge bg-secondary p-2">{{getlocalized('CodeList', 'Code')}}:
                    <span class="badge bg-info" style="font-size: 1.1em; white-space: normal; max-width: 90%; text-align:left">{{viewModel?.Current?.Code}}</span>
                </span>
            </h4>
            <button id="Code_Edit_CloseAction" type="button" class="btn btn-info btn-lg" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="modal-body pb-0 pt-0">
            <div *ngIf="isActionAvailable()" class="card mt-2">
                <div class="card-header">
                    <h3>
                        <span class="badge bg-secondary">{{getlocalized('common', 'Actions')}}</span>
                    </h3>
                </div>
                <div class="card-body form-group mb-0 pb-0">
                    <div class="row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-12">
                            <div class="input-group2">
                                <div class="input-group input-group-lg input-group-lg">
                                    <button id="Code_Edit_SetDefectAction" *ngIf="viewModel?.AvailableOperations.Defect" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'SetDeffect')}}" (click)="setDefect()" class="btn btn-sm btn-danger me-1 "><i class="fa fa-toggle-off me-1 "></i>{{getlocalized('common', 'SetDeffect')}}</button>
                                    <button id="Code_Edit_UnsetDefectAction" *ngIf="viewModel?.AvailableOperations.Undefect" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'ResetDeffect')}}" (click)="removeDefect()" class="btn btn-sm btn-warning me-1 "><i class="fa fa-toggle-on me-1 "></i>{{getlocalized('common', 'ResetDeffect')}}</button>

                                    <button id="Code_Edit_SetValidateAction" *ngIf="viewModel?.AvailableOperations.Validate" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'SetValidate')}}" (click)="setValidate()" class="btn btn-sm btn-info me-1 "><i class="fa fa-toggle-off me-1 "></i>{{getlocalized('common', 'SetValidate')}}</button>

                                    <button id="Code_Edit_AddAction" *ngIf="viewModel?.Current.GroupType!='Product' && viewModel?.Current?.GroupCapacity > viewModel?.Children?.Codes?.length && viewModel?.Children?.Codes?.length > 0" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'AddIncludes')}}" (click)="addItemDialog()" class="btn btn-sm btn-success me-1 "><i class="fa fa-plus me-1 "></i>{{getlocalized('common', 'AddIncludes')}}</button>
                                    <button id="Code_Edit_RemoveAction" *ngIf="viewModel?.Current.GroupType!='Product' && viewModel?.Children?.Codes?.length > 0" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'RemoveIncludes')}}" (click)="removeItemDialog()" class="btn btn-sm btn-danger me-1 "><i class="fa fa-trash me-1 "></i>{{getlocalized('common', 'RemoveIncludes')}}</button>

                                    <button id="Code_Edit_DestroyAction" *ngIf="viewModel?.AvailableOperations.Destroy" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Disband')}}" (click)="disbandStorage()" class="btn btn-sm btn-danger me-1 "><i class="fa fa-expand-arrows-alt me-1 "></i>{{getlocalized('common', 'Disband')}}</button>
                                    <button id="Code_Edit_IndividualOmsReportingReloadAction" *ngIf="viewModel?.AvailableOperations.IndividualOmsReportingReload" [openDelay]="1500" [closeDelay]="400" (click)="sendToOms()" ngbTooltip="{{getlocalized('common', 'ReloadToOms')}}" class="btn btn-sm btn-warning me-1 "><i class="fa fa-send me-1 "></i>{{getlocalized('common', 'ReloadToOms')}}</button>

                                    <button id="Code_Edit_ReplaceCodeAction" *ngIf="viewModel?.AvailableOperations.ReplaceCode" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'ReplaceCode')}}"  (click)="replaceItemDialog()"     class="btn btn-sm btn-success me-1 "><i class="fa fa-exchange-alt me-1 "></i>{{getlocalized('common', 'ReplaceCode')}}</button>
                                    <button id="Code_Edit_ChangeIncludes"  *ngIf="viewModel?.AvailableOperations.ReplaceChild"  [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'ReplaceChild')}}" (click)="replaceItemDialog(true)" class="btn btn-sm btn-primary me-1 "><i class="fa fa-exchange-alt me-1 "></i>{{getlocalized('common', 'ReplaceChild')}}</button>
                                    
                                    <button id="Code_Edit_CheckServiceCode" *ngIf="viewModel?.Current?.ServiceCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'CheckServiceCode')}}" (click)="checkServiceCodeDialog()" class="btn btn-sm btn-primary me-1 "><i class="fa-solid fa-check me-1"></i>{{getlocalized('common', 'CheckServiceCode')}}</button>  
                                    <button id="Code_Edit_ChangeServiceCode" *ngIf="viewModel?.Current?.ServiceCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'ChangeServiceCode')}}" (click)="changeServiceCodeDialog()" class="btn btn-sm btn-primary me-1 "><i class="fa fa-exchange-alt me-1 "></i>{{getlocalized('common', 'ChangeServiceCode')}}</button>  
                                </div>
                                <input *ngIf="newCodeToggle" type="text" class="form-control form-control-sm" [(ngModel)]="newCodeValue">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-header">
                    <h3>
                        <span class="badge bg-secondary">{{getlocalized('CodeList', 'Current')}}</span>
                    </h3>
                </div>
                <div class="card-body form-group mb-0 pb-0">
                    <div class="row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'GroupType')}}</span>
                                <input disabled type="text" class="form-control" value="{{getlocalized('ProductGroupTypeDictionary', viewModel?.Current?.GroupType)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'PrintingStatus')}}</span>
                                <span *ngIf="viewModel?.Current?.PrintingStatus == 'Printed'" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="viewModel?.Current?.PrintingStatus != 'Printed'" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input disabled type="text" class="form-control border-start-0" [ngClass]="{'border-success': viewModel?.Current?.PrintingStatus == 'Printed', 'border-danger': viewModel?.Current?.PrintingStatus != 'Printed'}" value="{{getlocalized('PrintStatusDictionary', viewModel?.Current?.PrintingStatus)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'EntityStatus')}}</span>
                                <span *ngIf="this.viewModel?.Current?.EntityStatus == 'FullEntity'" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="this.viewModel?.Current?.EntityStatus == 'Defect'" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input disabled type="text" class="form-control border-start-0" [ngClass]="{'border-success': this.viewModel?.Current?.EntityStatus == 'FullEntity', 'border-danger': this.viewModel?.Current?.EntityStatus == 'Defect'}" value="{{getlocalized('AggregationStatusDictionary', viewModel?.Current?.EntityStatus)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                          <div class="input-group input-group-lg">
                              <span class="input-group-text">{{getlocalized('CodeList', 'OmsUploadedStatus')}}</span>
                              <span *ngIf="viewModel?.Current?.OmsUploaded" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                              <span *ngIf="!viewModel?.Current?.OmsUploaded" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                              <input *ngIf="viewModel?.Current?.OmsUploaded" disabled type="text" class="form-control border-start-0 border-success" value="{{getlocalized('common', 'IsOmsUploaded')}}">
                              <input *ngIf="!viewModel?.Current?.OmsUploaded" disabled type="text" class="form-control border-start-0 border-danger" value="{{getlocalized('common', 'NotOmsUploaded')}}">
                          </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'ValidationStatus')}}</span>
                                <span *ngIf="viewModel?.Current?.Validated" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="!viewModel?.Current?.Validated" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input *ngIf="viewModel?.Current?.Validated" disabled type="text" class="form-control border-start-0 border-success" value="{{getlocalized('common', 'IsValidated')}}">
                                <input *ngIf="!viewModel?.Current?.Validated" disabled type="text" class="form-control border-start-0 border-danger" value="{{getlocalized('common', 'NotValidated')}}">
                            </div>
                        </div>

                        <div *ngIf="viewModel?.Current?.Weight!=undefined" class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'Weight')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.Current?.Weight}}">
                            </div>
                        </div>

                        <div *ngIf="viewModel?.Current?.ServiceCode!=undefined" class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'ServiceCode')}}</span>
                                <input disabled type="text" class="form-control" value="{{viewModel?.Current?.ServiceCode}}">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="viewModel?.Parent!=undefined" class="card mt-2">
                <div class="card-header">
                    <h3>
                        <span class="badge bg-secondary">
                            {{getlocalized('CodeList', 'Parent')}}
                            <span class="btn btn-sm btn-info" style="font-size: 1em;" (click)="redirectToCode(viewModel?.Parent?.Code)">
                                <i class="fa fa-sign-in-alt me-1"></i>{{viewModel?.Parent?.Code}}
                            </span>
                        </span>
                    </h3>
                </div>
                <div class="card-body form-group mb-0 pb-0">
                    <div class="row">
                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'GroupType')}}</span>
                                <input disabled type="text" class="form-control" value="{{getlocalized('ProductGroupTypeDictionary', viewModel?.Parent?.GroupType)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'PrintingStatus')}}</span>
                                <span *ngIf="viewModel?.Parent?.PrintingStatus == 'Printed'" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="viewModel?.Parent?.PrintingStatus != 'Printed'" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input disabled type="text" class="form-control border-start-0" [ngClass]="{'border-success': viewModel?.Parent?.PrintingStatus == 'Printed', 'border-danger': viewModel?.Parent?.PrintingStatus != 'Printed'}" value="{{getlocalized('PrintStatusDictionary', viewModel?.Parent?.PrintingStatus)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'EntityStatus')}}</span>
                                <span *ngIf="this.viewModel?.Parent?.EntityStatus == 'FullEntity'" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <input disabled type="text" class="form-control border-start-0" [ngClass]="{'border-success': this.viewModel?.Parent?.EntityStatus == 'FullEntity'}" value="{{getlocalized('AggregationStatusDictionary', viewModel?.Parent?.EntityStatus)}}">
                            </div>
                        </div>

                        <div class="mb-3 pe-3 col-xl-12 col-xxl-6">
                            <div class="input-group input-group-lg">
                                <span class="input-group-text">{{getlocalized('CodeList', 'OmsUploadedStatus')}}</span>
                                <span *ngIf="viewModel?.Parent?.OmsUploaded" class="input-group-text border-success border-end-0 border-right-0"><i class="fa fa-check text-success"></i></span>
                                <span *ngIf="!viewModel?.Parent?.OmsUploaded" class="input-group-text border-danger border-end-0 border-right-0"><i class="fa fa-ban text-danger"></i></span>
                                <input *ngIf="viewModel?.Parent?.OmsUploaded" disabled type="text" class="form-control border-start-0 border-success" value="{{getlocalized('common', 'IsOmsUploaded')}}">
                                <input *ngIf="!viewModel?.Parent?.OmsUploaded" disabled type="text" class="form-control border-start-0 border-danger" value="{{getlocalized('common', 'NotOmsUploaded')}}">
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div *ngIf="viewModel?.Children!=undefined && viewModel?.Children?.Codes.length>0" class="card mt-2">
                <div class="card-header">
                    <h3>
                        <span class="badge bg-secondary">{{getlocalized('CodeList', 'Children')}}</span>
                        <span class="badge bg-info float-end">{{viewModel?.Children?.Codes.length}}/{{viewModel?.Current?.GroupCapacity}}</span>
                    </h3>
                </div>
                <div class="card-body form-group mb-0 pb-0">
                    <div class="form-row">
                        <ul>
                            <li class="mb-1" *ngFor="let code of viewModel?.Children?.Codes; index as i; first as isFirst">
                                <!--{{i}}/{{users.length}}. {{user}} <span *ngIf="isFirst">default</span>-->
                                {{i+1}} :  <code style="font-size: 120%">{{code}}</code>
                                <button id="Code_Edit_CopyToClickboardAction" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Копировать в буфер обмена" (click)="copyToClipboard(code)" class="btn btn btn-info ms-2 me-1 "><i class="fa fa-copy"></i></button>
                                <button id="Code_Edit_GoToCode" [openDelay]="1500" [closeDelay]="400" (click)="redirectToCode(code)" class="btn btn btn-info me-1 "><i class="fa fa-sign-out-alt"></i></button>
                                <button id="Code_Edit_RemoveIncludes" *ngIf="isActionAvailable()" [openDelay]="1500" [closeDelay]="400" (click)="removeItem(code)" class="btn btn btn-danger me-1 "><i class="fa fa-trash-alt"></i></button>                                
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group2">
                    <button type="button" class="btn btn-info btn-lg" style="width:120px" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        {{getlocalized('common', 'Close')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

</ng-template>


<ng-template #contentSecondModal let-c="close" let-modal dismissed="dismiss()">
    <div class="modal-header">
        <h4 class="modal-title"><span class="badge bg-secondary">{{getlocalized('common', currentMode)}}</span></h4>

        <button id="Code_Edit_CloseAction" type="button" class="btn btn-info" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div class="modal-body pb-0">
        <div *ngIf="currentMode!='ReplaceCode' && currentMode!='ReplaceChild' && currentMode!='CheckServiceCode'" class="form-row">
            <div class="mb-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Code')}}</span>
                <input id="" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="currentCode">
                <button id="codeButton"
                        *ngIf="currentCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(currentCode); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
                </button>
                </div>
            </div>
        </div>

        <div *ngIf="currentMode=='CheckServiceCode'" class="form-row">
            <div class="mb-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('CodeList', 'ServiceCode')}}</span>
                <input id="" placeholder="Service code" type="text" class="form-control" [(ngModel)]="currentCode">
                <button id="codeButton"
                        *ngIf="currentCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(currentCode); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Check')}}
                </button>
                </div>
            </div>
        </div>

        <div *ngIf="currentMode=='ReplaceCode' || currentMode=='ReplaceChild'" class="form-row">
            <div class="mb-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Remove')}}</span>
                <input id="" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="replaceItem.Code">
                </div>
                <!--<button id="codeButton"
                        *ngIf="replaceItem.Code!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(replaceItem.Code); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
                </button>-->
            </div>

            <div class="mb-3 col-lg-12 col-md-12">
                <div class="input-group input-group-lg">
                    <span class="input-group-text">{{getlocalized('common', 'Add')}}</span>
                <input id="" [disabled]="replaceItem.Code==undefined" placeholder="Type new code" type="text" class="form-control" [(ngModel)]="replaceItem.NewCode">
                <button id="codeButton" [disabled]="replaceItem.Code==undefined"
                        *ngIf="replaceItem.NewCode!=undefined" [openDelay]="1500" [closeDelay]="400" ngbTooltip="Apply" (click)="doSmth(undefined); modal.dismiss('')" class="input-group-append btn btn-sm btn-success">
                    <i class="fa fa-save me-1 "></i>{{getlocalized('common', 'Save')}}
                </button>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="modal.dismiss('Cross click')">{{getlocalized('common', 'Close')}}</button>
    </div>
</ng-template>
