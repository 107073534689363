import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuBatchProcessService } from './batch-process.service';
import { AspuBatchProcessState, AspuProcessProductType, AspuStat, PrintingAction, StopBatchDto, BatchIdDto, AspuPrintingMessage, AspuPrintingState, AspuFlowsPrintingState, AspuPacketsPrintingState, FieldWidth } from './batch-process.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AspuBatchStart, AspuKmRequest, AspuAdditionLoading } from '../batch-edit/batch-edit.model';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { AspuBatchState } from '../batch-state.model';
import { AspuJob } from '../../../core/models/aspu.job.model';
import { stat } from 'fs';
import { AspuInitialFields, AspuMetaBase } from '../../../core/common/common.model';
import { CustomAdapter2, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from 'vekas_models/core/datepicker-adapter/datepicker-adapter';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuSettingsService } from '../../../../core/settings/settings.service';
import { AspuSettings } from '../../../../core/settings/settings.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuJobService } from 'src/app/business/services/aspu.job.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AspuCommonService } from '../../../core/common/common.service';
import { Observable } from 'rxjs';
import { AspuReceiptService } from '../../receipt/receipt.service';

@Component({
    selector: 'app-batch-process-component',
    templateUrl: './batch-process.component.html',
    providers: [AspuBatchProcessService, AspuIndicatorService, AspuJobService, AspuCommonService, AspuSettingsService, AspuReceiptService,
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateAdapter, useClass: CustomAdapter2 },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]

})
export class AspuBatchProcessComponent implements OnInit {
    viewModel =new  AspuBatchProcessState();
    rows = new Array<AspuProcessProductType>();

    flowsPrinters: Array<AspuFlowsPrintingState>;
    packetsPrinters: Array<AspuPacketsPrintingState>;
    searchChangeObserver;

    substatus: string;

    isReady = false;
    loading = true;
    isPrinting = false;
    allowValidatePrintedOnFinalize = false;
    ColumnMode = ColumnMode;
    ColumnMode2 = ColumnMode;
    reorderable = true;
    modalReference: NgbModalRef;
    omsBatchUrl: string;

    constructor(
        private serv: AspuBatchProcessService,
        private jservice: AspuJobService,
        private lservice: LangService,
        private cserv: AspuSettingsService,
        private modalService: NgbModal,
        public toastService: ToastService,
        private indServ: AspuIndicatorService,
        private dserv: AspuCommonService,
        private rservice: AspuReceiptService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.omsBatchUrl = '#';
        if (localStorage.getItem("token") == undefined) {
           // console.log("BATCHPROCCESS -> localStorage.getItem('token') == undefined")
            this.router.navigate(['/home']);
        }

        this.indServ.getIndicators().subscribe((data) => {
            if (data.Value.Batch == "Disabled") {
                console.log('redirect to /aspu-batch-receipt')
                this.router.navigate(['/aspu-batch-receipt']);
            }
            else {
                this.load();
            }
        });

        this.allowValidatePrintedOnFinalize = localStorage.getItem("allowValidatePrintedOnFinalize") == "true";
    }

    params: Array<AspuMetaBase>;
    currentWidth = 0;
    fieldSettings = new FieldWidth();

    ngOnInit() {
        window.addEventListener("ChangeNavSize", this._doSetCustomWidth, false);
        let raw = localStorage.getItem('plcPrintingMeta');
        if (raw != undefined) {
            this.params = JSON.parse(raw);
            console.log('InitializePARAMS')
            console.log(this.params)
        }
        this.preventDoubleCheck();
        window.addEventListener("PrintingUpdated", this._doPrinterUpdate, false);
        window.addEventListener("CodesModified", this._doTryReload, false);
        window.addEventListener("ProductionButtonOff", this._doDisabledProductionButton, false);
        window.addEventListener("BatchStateUpdated", this._doCheckBatchStarted, false);
        window.addEventListener("CountersUpdated", this._doCheckCounters, false);

        this.setCustomWidth();
    }

    setCustomWidth() {
        this.fieldSettings.FPrintersWidth = 476;
        this.fieldSettings.FPrinterNameWidth = 125; //100 80 140
        let isExpand = localStorage.getItem("isNavExpand") == 'false' ? false : true;
        let availableWidth = window.innerWidth - (isExpand ? 252 : 85)
        if (isExpand) {
            this.fieldSettings.FProductWidth = 300;
            this.fieldSettings.FProgressbarWidth = 250;
            this.fieldSettings.FPrintersWidth = availableWidth - this.fieldSettings.FProductWidth - this.fieldSettings.FProgressbarWidth - 30;
            let printerNameFiledWidth = this.fieldSettings.FPrintersWidth - 100 - 80 - 140 - 60
            this.fieldSettings.FPrinterNameWidth = ((printerNameFiledWidth > 200 ? printerNameFiledWidth : 150) + 100 )/2 ;
        }
        else {
            let availablePerField = availableWidth > 1200 ? 300 : (availableWidth - 625) / 2 ;

            this.fieldSettings.FProductWidth = availablePerField; //availableWidth > 1000 ? (availableWidth-625)/2 : 200;
            this.fieldSettings.FProgressbarWidth = availablePerField; //availableWidth > 1000 ? (availableWidth - 625) / 2 : 220;
            this.fieldSettings.FPrintersWidth = availableWidth - this.fieldSettings.FProductWidth - this.fieldSettings.FProgressbarWidth - 30;
            this.fieldSettings.FPrinterNameWidth = (this.fieldSettings.FPrintersWidth  - 80 - 140 - 60) / 2;
        }
        console.log(window.innerWidth)
        console.log(availableWidth)
        this.currentWidth = (isExpand ? 1400 : window.innerWidth - 625) / 2;
        
    }

    private _doSetCustomWidth = this.setCustomWidth.bind(this);
    private _doTryReload = this.doTryReload.bind(this);
    private _doCheckCounters = this.doCheckCounters.bind(this);
    private _doPrinterUpdate = this.getPrinters.bind(this); //!
    private _checkBatchFinalizeJob = this.checkBatchFinalizeJob.bind(this);
    private _doCheckBatchStarted = this.checkBatchStarted.bind(this);
    private _doDisabledProductionButton = this.disabledProductionButton.bind(this);


    doTryReload() {
        this.counter++;
    }

    doCheckCounters() {
        this.statCounter++;
    }

    checkBatchFinalizeJob() {
        this.jservice.getJobStatus("BatchFinalizeJob").subscribe(
            (data: IResult<AspuJob>) => {
                if (data.IsSuccess && data.Value.Failed == true) {
                    window.removeEventListener("JobStateChanged", this._checkBatchFinalizeJob);
                }
                if (data.IsSuccess && data.Value.Active == false) {
                    window.removeEventListener("JobStateChanged", this._checkBatchFinalizeJob);
                    console.log('checkBatchFinalizeJob, redirect to /aspu-batch-receipt')
                    this.router.navigate(['/aspu-batch-receipt']);
                }
            },
            (error) => {
                console.error(error);
            });
    }

    checkBatchStarted(data: any) {
        let payload = data.detail;
        if (!payload.topic) {
            //реальный редирект происходит здесь
            console.log('checkBatchStarted, redirect to /aspu-batch-receipt')
            this.router.navigate(['/aspu-batch-receipt'], { relativeTo: this.route });
        }
    }

    disabledProductionButton(data: any) {
        console.log('------------');
        console.log('disabledProductionButton');
        console.log('------------');
        this.viewModel.IsProduction = false;
    }

    ngOnDestroy() {
        window.removeEventListener("PrintingUpdated", this._doPrinterUpdate);
        window.removeEventListener("CodesModified", this._doTryReload); 
        window.removeEventListener("ProductionButtonOff", this._doTryReload); 
        window.removeEventListener("JobStateChanged", this._checkBatchFinalizeJob);
        window.removeEventListener("BatchStateUpdated", this._doCheckBatchStarted);
        window.removeEventListener("CountersUpdated", this._doCheckCounters);

        window.removeEventListener("ChangeNavSize", this._doSetCustomWidth);
        clearInterval(this.timerId);
    }

    private load() {
        this.reload();
        this.getPrinters();
        this.getSettings();
    }

    isHideBatchStopBtn = true;
  //  settings: AspuSettings
    getSettings() {
        console.log('StartGETSETTINGS')

        this.cserv.getSettings().subscribe((data: IResult<AspuSettings>) => {
                console.log(data)
            if (data.IsSuccess) {
                this.isHideBatchStopBtn = data.Value.IsHideBatchStopBtn;
                this.allowValidatePrintedOnFinalize = this.allowValidatePrintedOnFinalize || data.Value.AllowValidatePrintedOnFinalize;
            }
        });
    }

    counter = 0;
    statCounter = 0;
    timerId : any;

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.counter > 0) {
                this.counter = 0;
                this.reload()
            }

            if (this.statCounter > 0) {
                console.log('statCounter')
                console.log(this.statCounter)
                this.statCounter = 0;
                this.reload()
            }
        }, 5000);
    }

    private reload(): void {
        this.loading = true;

        this.serv.getBatchProcessState().subscribe((data: IResult<AspuBatchProcessState>) => {
            if (data.IsSuccess) {
                this.omsBatchUrl = data.Value.OmsBatchUrl;
                this.viewModel = data.Value;
                this.rows = this.viewModel.ProductTypes;
                this.loadImage();
            }

            this.isReady = true;
            this.loading = false;
            },
            error => console.error(error));
    }

    private additionalLoading() {
        this.loading = true;

        let val = new AspuAdditionLoading(); // AspuBatchStart();
        val.CodesInfo = new Array<AspuKmRequest>();

        this.rows.forEach((item: AspuProcessProductType) => {
            //item.Printers = this.printers.filter(x => x.GroupType == item.GroupType);
            let newItem = new AspuKmRequest();
            if (this.currentProductType.Id == item.Id) {
                newItem.Count = this.currentProductTypeCount;
            }
            else {
                newItem.Count = 0;
            }
           // newItem.ErpId = item.SelectedProductTypeCode;
            newItem.ProductTypeId = item.Id;
            val.CodesInfo.push(newItem);           
        });


        this.modalReference.close();  
        this.serv.additionOrder(val).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.load();
            }

            this.currentProductType = undefined;
            this.currentProductTypeCount = 0;

          //  this.loading = false;
        },
            error => console.error(error));
    }

    getPrinters() {
        this.loading = true;
        this.serv.getPrintersState().subscribe((data: IResult<AspuPrintingState>) => {
            if (data.IsSuccess) {
                this.flowsPrinters = data.Value.Flows;
                this.packetsPrinters = data.Value.Packets;
                this.isPrinting = this.flowsPrinters.filter(x => x.Status != "Stopped").length > 0;
                if (this.currentAspuProcessProductType!=undefined) {
                    this.editItem(this.currentAspuProcessProductType, undefined);
                }
            }            
        },
        error => console.error(error));
        this.loading = false;
    }

    productionOn() {
        this.loading = true;
        this.serv.startProduction().subscribe((data: any) => {
            if (data.IsSuccess) {
                this.viewModel.IsProduction = true;
            }

            this.loading = false;
        },
            error => console.error(error));
    }

    productionOff() {
        this.loading = true;
        this.serv.stopProduction().subscribe((data: any) => {
            if (data.IsSuccess) {
                this.viewModel.IsProduction = false;
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    updateKmCounts(){
        this.loading = true;
        this.serv.updateKmCounts().subscribe((data: any) => {
            if (data.IsSuccess) {
                this.reload();
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    jobId: string;
    stopBatch(isValidatePrinted: boolean = false, isSuspend: boolean = false) {
        this.viewModel.IsBatch = false;
        this.loading = true;
        let stopBatchData: StopBatchDto =
        {
            IsValidatePrinted: isValidatePrinted,
            IsSuspend: isSuspend
        };
        this.serv.stopBatch(stopBatchData)
            .subscribe((data: any) => {
            if (data.IsSuccess) {
                this.jobId = data.Value.JobId;
                window.addEventListener("JobStateChanged", this._checkBatchFinalizeJob, false);
            }
            else {
                this.viewModel.IsBatch = true;
                this.loading = false;
            }
        },
            error => console.error(error));
    }

    suspendBatch() {
        this.stopBatch(false, true);
    }

    availablePrinters: Array<AspuFlowsPrintingState>;
    printerStatuses = ["Stopped", "Starting", "Started", "Stopping"];
    currentAspuProcessProductType: AspuProcessProductType;
   // currentContentChange: any;

    editItem(row: AspuProcessProductType, contentChange: any) {

        this.currentAspuProcessProductType = row;
        this.availablePrinters = this.flowsPrinters.filter(x => x.GroupType == row.GroupType);
        if(contentChange!=undefined)
            this.openModal(contentChange);
    }

    getFlowPrintersPerType(row: AspuProcessProductType) {
        return this.flowsPrinters.filter(x => x.GroupType == row.GroupType);
    }

    getPacketPrintersPerType(row: AspuProcessProductType) {
        return this.packetsPrinters.filter(x => x.GroupType == row.GroupType);
    }

    currentProductType: AspuProcessProductType;
    currentProductTypeCount: number;

    addNewItems(row: AspuProcessProductType, contentLoads: any) {
        this.currentProductType = row; 
        this.openModal(contentLoads, true);
    }

    currentPrinter: AspuFlowsPrintingState;
    currentParam: any;

    addNewPrint(row: AspuFlowsPrintingState, contentLoads: any) {
        this.currentPrinter = row;
        this.openModal(contentLoads, true);
    }

    showPrinterMessages(popover, row: AspuFlowsPrintingState) {
        if (popover.isOpen()) {
            popover.close();
        } else {
           // console.log(row)
            popover.open({ row });
        }
    }

    saveFlatItem() {
        console.log(this.params);
        console.log(this.currentParam);
        this.startPrint(this.currentPrinter);
    }

    openModal(content, isLarge = false) {
        this.modalReference = this.modalService.open(content, { scrollable: false, windowClass: 'custom-modal-class', size: isLarge ? 'lg' : 'xs' });

    }

    startPrint(item: AspuFlowsPrintingState) {
        console.log("startPrint");
        if (this.modalReference!=undefined)
                this.modalReference.close(); 

        this.loading = true;
        let state = new PrintingAction(item.Id);

        if (this.currentParam != undefined)
            state.UserDefinedVars = this.currentParam;

        this.serv.startPrint(state).subscribe(
            (data: any) => {
                if (data.IsSuccess) {
                    //this.viewModel.IsProduction = false;
                }
                this.loading = false;
            },
            error => console.error(error));
    }

    stopPrint(item: AspuFlowsPrintingState) {
        console.log("stopPrint");
        if(this.modalReference!=undefined)
        this.modalReference.close(); 

        this.loading = true;
        this.serv.stopPrint(item.Id).subscribe((data: any) => {
            if (data.IsSuccess) {
                //this.viewModel.IsProduction = false;
            }
            this.loading = false;
        },
            error => console.error(error));
    }

    /*currentPrintingFlow: string;*/
    startPacketPrint(item: AspuPacketsPrintingState) {
        console.log("startPacketPrint");
        if(this.modalReference!=undefined)
            this.modalReference.close(); 

        this.loading = true;
        
        this.serv.startPacketPrint(item.Id).subscribe((data: any) => {
            if (data.IsSuccess) {
                this.viewModel.IsProduction = false;
            }
        },
        error => console.error(error));
        this.loading = false;
    }

    stopPacketPrint(item: AspuPacketsPrintingState) {
        console.log("stopPacketPrint");
        if(this.modalReference!=undefined)
            this.modalReference.close(); 

        this.loading = true;
        this.serv.stopPacketPrint(item.Id).subscribe((data: any) => {
            if (data.IsSuccess) {
                this.viewModel.IsProduction = false;
            }
        },
        error => console.error(error));
        this.loading = false;
    }

    isFetched(stats: Array<AspuStat>) {
        let fetched = stats.find(x => x.Type === 'Fetched');
        return fetched != undefined
    }

    isCodeEnable(stats: Array<AspuStat>) {
       // console.log(stats);
        let fetched = stats.find(x => x.Type === 'Printed');
        if (fetched == undefined) return false;
        
        return fetched.All > fetched.Value;
    }

    countPercent(val: any, all: number) {
        if (all == 0)
            return 0;
        let realVal = (+val) / all * 100;
        //if (realVal < 1 && realVal>0)
            //realVal = 40;

        return Math.round(realVal);
    }

    countPercentByStat(stat: AspuStat) {
      if (stat.Type == 'Fetched') {
        let realVal = (+stat.Value) / (+stat.All + stat.Value) * 100;
        return Math.round(realVal);
      }

      if (stat.All == 0)
        return 0;
      let realVal = (+stat.Value) / stat.All * 100;
      return Math.round(realVal);
    }

    confirmation = new ConfirmationModel();
    actionRow(id: string, action: string) {
        this.confirmation.Action = action;
        this.confirmation.Parameter = id;
        this.confirmation.Counter++;
        switch (this.confirmation.Action) {
            case 'suspend':
                this.confirmation.Message = LangService.getlocalized('common', 'Suspend') + '? ' + LangService.getlocalized('common', 'Sure');
                break;
            case 'stopBatch':
                this.confirmation.Message = LangService.getlocalized('common', 'SimpleBatchFinish') + '? ' + LangService.getlocalized('common', 'Sure');
                break;
            case 'stopBatchWithParameter':
                this.confirmation.Message = LangService.getlocalized('common', 'ValidatePrintedBatchFinish') + '? ' + LangService.getlocalized('common', 'Sure');
                break;
            default:
                this.confirmation.Message = LangService.getlocalized('common', 'Sure');
                break;
        }
        this.confirmation.ClassType = "warning";
    }

    getConfirmation(event) {
     //   console.log('CONFIRMATION')
    //    console.log(event)
        if (event) {
            if (this.confirmation.Action == 'stopBatch') {
                this.stopBatch();
                //console.log('this.stopBatch();')
            }
            else if (this.confirmation.Action == 'stopBatchWithParameter') {
                this.stopBatch(true);
                //console.log('stopBatchWithParameter')

            }
            else if (this.confirmation.Action == 'suspend') {
                this.suspendBatch();
                //console.log('suspend')
            }
            
        }
        this.modalService.dismissAll();
        this.confirmation.Clear();
    }

    getFlowRowClass = (row: AspuFlowsPrintingState) => {
        return {
            'bg-warning': row.Status == "Stopped",
            'bg-info': row.Status == "Starting" || row.Status == "Stopping",
            'bg-success': row.Status == "Started",
        };
    }

    getPacketRowClass = (row: AspuFlowsPrintingState) => {
        return {
            'bg-light': row.Status == "Stopped",
            'bg-info': row.Status == "Starting" || row.Status == "Stopping",
            'bg-secondary': row.Status == "Started"
        };
    }

    isFlowsPrinterEnabled (row: AspuProcessProductType) {
        return this.flowsPrinters.filter(x => x.GroupType == row.GroupType).length > 0;
    }

    isPacketPrinterEnabled (row: AspuProcessProductType) {
        return this.packetsPrinters.filter(x => x.GroupType == row.GroupType).length > 0;
    }

    //onActivate(event) {
    //    if (event.type == 'click') {
    //        this.selectedReceipt = event.row;
    //        //this.selectedReceiptId = event.row.Id;
    //        //this.selectedReceiptIsValid = event.row.IsValid;
    //    }
    //}

    getClassForMessage(type: string) {
        switch (type) {
            case "Info":
                return 'text-success';
            case "Warning":
                return 'text-warning';
            case "Error":
                return 'text-danger';
            default:
                return '';
        }    }

    getClassForMessagesBtn(messages: AspuPrintingMessage[]) {
        if (messages.find(x => x.Type == 'Error'))
            return 'btn-danger';
        else if(messages.find(x => x.Type == 'Warning'))
            return 'btn-warning';

        return 'btn-success';
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }

    noCodesForPrinter(row: AspuProcessProductType) {
        return row['IsExistsNotPrinted']==false && this.isFetched(row['Stats'])
    }

    noCodesForPrinterButton(groupType: string) {
        let rows = this.rows.filter(x => x.GroupType == groupType);
        if (rows != undefined && rows.length > 0) {
            return this.noCodesForPrinter(rows[0]);
        }

        return false;
    }

    image: any;
    isImageExpanded = false;

    loadImage() {
        console.log('this.rows')
        console.log(this.rows)
        let id = this.rows.find(x => x.GroupType == 'Product').Id;
        if (id != undefined) {
            this.rservice.getImage(id).subscribe((data: IResult<any>) => {
                if (data.IsSuccess) {
                    this.image = data.Value.Image;
                }
                else {
                }
            });
        }
  }
}
