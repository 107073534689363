import { stat } from "fs";

export class AspuEntity {
    Id: number;
    Code: string;
    IsError: boolean;
    IsEmpty: boolean;
    IsNoRead: boolean;
    IsOk: boolean;
    Status: string;
    Message: string;
    Capacity: number;
}

export class AspuBoxEntity {
    Box: AspuEntity;
    Products: Array<AspuEntity>; //any[];
    Status: string; //BoxEnter,    ProductsEnter
    IsClearAvailable: boolean;
    IsAllowCloseNotFull: boolean;
    Capacity: number;
    Queue: Array<AspuBoxQueue>;
    IsQueueEnabled: boolean;
    IsWaitingWeighting: boolean;
}

export class AspuSetEntityResult {
    Message: string;
    IsOk: boolean;
    IsError: boolean;
    IsEmpty: boolean;
    IsBoxCreated: boolean;
}

export class AspuBoxQueue {
    Id: number;
    Name: string;
    IsOk: boolean;

    constructor(id: number, name: string, status: boolean) {
        this.Id = id;
        this.Name = name;
        this.IsOk = status;
    }
}


//"Queue": [{ "Id": 2, "Name": "Короб 2", "IsOk": false }, { "Id": 3, "Name": "Короб 3", "IsOk": false }]
