export class AspuCounterRequestObj {
    public Id: string;
    public Name: number;
}

export class AspuCountersDto {
    public BatchId: any;
    public Branches: Array<BranchDto>;
}

export class BranchDto {
    public Name: number;
    public IsTotal:boolean;
    public Values: Array<CounterDto>;
}

export class CounterDto {
    public Id: string;
    public Value: number;
    public Description: boolean;
}
