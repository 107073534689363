import { Component, OnInit } from '@angular/core';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { AspuCode } from "../code/code.model";
import { AspuCodeStatusService } from "./code-status.service";
import { AspuCodeStatus } from "./code-status.model";

@Component({
    selector: 'app-code-status-component',
    templateUrl: './code-status.component.html',
    providers: [AspuCodeStatusService]
})
export class AspuCodeStatusComponent implements OnInit {
    mode = "validate"; //validate, defect, info

    constructor(private serv: AspuCodeStatusService,
        public toastService: ToastService
    ) {
        this.loadItems();
    }

    ngOnInit() {
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
    }

    ngOnDestroy() {
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener('EnterCodeReceived', this._doAction);
    }

    private _doAction = this.doActionPrevent.bind(this);

    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    onSort(event) {    }

    public  loadItems() {}
    code: string;

    successAlert = false;
    failAlert = false;

    doAction(code: string) {
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;

            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        switch (this.mode) {
            case "validate":
                this.changeStatus(new AspuCodeStatus(code.trim(), 'validate'));
                break;
            case "defect":
                this.changeStatus(new AspuCodeStatus(code.trim(), 'defect'));
                break;
            case "info":
                this.showInfo(code.trim());
            default: break;
        }
        this.code = undefined;
    }

    counter = 0;
    currentCode: String;

    showInfo(code: String) {
        this.counter++;
        this.currentCode = code;
    }

    changeStatus(codeStatus: AspuCodeStatus) {
        this.serv.changeStatus(codeStatus).subscribe((data: IResult<any>) => {
            console.log(data);
            if (data.IsSuccess) {
                this.toastService.getToast("Статус кода изменен", 'info');
                this.successAlert = true;
                setTimeout(() => { this.successAlert = false}, 2000);
            }
            else {
                this.failAlert = true;
                setTimeout(() => { this.failAlert = false }, 2000);
            }
            },
            error => {
                console.error(error)
            });
    }
      
    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
