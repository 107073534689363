//export class AspuPrintingFlow  {
//    Id: string;
//    PrinterId: string;
//    PrinterName: string;
//    Template: string;
//}

export class AspuBatch {
    Id: string;
    BatchNumber: string;
    //VeterinaryCertificate: string;
    //InternalNumberERP: string;
    //Status: string;
    //CreatedBy: string;
    //CreatedOn: string;
    //UpdatedBy: string;
    //UpdatedOn: string;
}


export class AspuReserveCodeRequest {
    BatchIds: Array<string>;
}
