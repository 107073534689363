import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from "../../../core/indicator/indicator.service";
import { AspuCode, AspuCodeOnly, AspuServiceCodeOnly, AspuWeightOnly } from "../../code/code.model";
import { AspuSerializationService } from './serialization.service';
import { Howl } from 'howler';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { Page } from 'vekas_models/models/page';
import { FiltersContainer, GridFilter, GridFilters, GridSort } from 'vekas_models/models/filtersContainer';
import { AspuCodeService } from '../../code/code.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AggrAlgorithm } from 'src/app/business/core/common/common.model';

@Component({
    selector: 'app-serialization-component',
    templateUrl: './serialization.component.html',
    providers: [AspuIndicatorService, AspuSerializationService, AspuCodeService],
    styles: ['.codeReadedSuccess { background-color: #A0FFA0; } .codeReadedWarning { background-color: #ffc107; } .coreReadedFailed { background-color: #FF4040;}']
})
export class AspuSerializationComponent implements OnInit {
  //  viewModel = new AspuBoxEntity5504();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    isDetalized = false;
    CodeAddedSuccess = '';

    page = new Page<AspuCode>();
    searchChangeObserver;
    gridSort = new GridSort();
    gridFilters = new Array<GridFilter>();

    soundScan: Howl;
    soundOk: Howl;
    soundError: Howl;
    indicator = new AspuIndicator();
    mode = "aggregation"; //weight, aggregation, remove, info, servicecode
    productTypes = ["Product", "Block", "Box", "Pallet"];
    printerStatuses = ["Unprinted", "Printing", "Printed"];
    entityStatuses = ["None", "Defect", "PartialEntity", "FullEntity"];  //None - КМ без привязки// Entity - агрегирован

    currentAlgorithm: AggrAlgorithm;
    currentProductCode: string;

    constructor(private serv: AspuSerializationService, private cserv: AspuCodeService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {
        //this.viewModel.Box = new AspuEntity5504();
        this.page.Size = 50; // window["pageSize"];
        this.page.PageNumber = 0;
        this.gridSort.dir = "desc";
        this.gridSort.field = "PrintedDate";

        this.loadItemsWithStateCheck();        
      //  this.loadCodes();

        this.soundScan = new Howl({
            src: ['sound/scanit.wav'],

            onend: function () {
                console.log('SoundScan Finished!');
            },
            onloaderror: function () {
                console.log('SoundScan Error!');
            },
        });

        this.soundOk = new Howl({
            src: ['sound/success.wav'],
            onend: function () {
                console.log('soundOk Finished!');
            },
            onloaderror: function () {
                console.log('soundOk Error!');
            },
        });

        this.soundError = new Howl({
            src: ['sound/error.wav'],
            onend: function () {
                console.log('soundError Finished!');
            },
            onloaderror: function () {
                console.log('soundError Error!');
            },
        });

       // this.soundScan.play();
    }

    private _doAction = this.doActionPrevent.bind(this);
    private _doActionWithDelay = this.doActionPreventWithDelay.bind(this);

    private _doReload = this.doReloadEvent.bind(this);
    private _doTryReload = this.doTryReload.bind(this);
    //private _doCodesReload = this.loadCodes.bind(this);

    doActionPrevent(data: any) {
        console.log("CodeReceived - prevent")
        this.doAction(data.detail)
    }

    doActionPreventWithDelay(data: any) {
        console.log("doActionPreventWithDelay")
        this.doAction(data.detail)
        setTimeout(() => {
            this.loadCodes();
        }, 100);
    }

    doReloadEvent() {
        this.loading = true;
        this.loadItemsWithStateCheck();
        this.loadCodes();
    }

    ngOnInit() {
        this.preventDoubleCheck();
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doActionWithDelay, false);
        window.addEventListener("AspuIndicatorUpdated", this._doReload, false);
        window.addEventListener("CodesModified", this._doTryReload, false);
        window.addEventListener("ShowPopupMessage", this._doShowPopup, false);

        this.gridFilters.push(this.getGridFilter("Validated", "true", "eq", true));

        //if (this.indicator.Production == "Enabled")
        //        this.soundScan.play();
        this.InitMode();
        this.getAdditionalModesEnable();
    }

    InitMode() {
        var algoritms = localStorage.getItem("AggrAlgorithms");
        if (algoritms == undefined) return;

        var list = JSON.parse(algoritms) as Array<AggrAlgorithm>;
        if (list == undefined) return;

        var param = list.filter(x=>x.Type == "Serialization").find(x=>x.Mode != undefined && x.IsShowPlcQueueClearButton != undefined);
        if (param == undefined) return;

        this.currentAlgorithm = param;
        this.mode = param.Mode.toLowerCase();
        if (param.IsShowOnlyValidateProduct)
        {
            this.gridFilters.push(this.getGridFilter("EntityStatus", "Defect", "neq", true));
            this.gridFilters.push(this.getGridFilter("ProductType.GroupType", "Product", "eq", true));
        }
    }

    ngOnDestroy() {
        this.mode = "aggregation";
        this.toastService.clear(); 
        window.removeEventListener('CodeReceived', this._doAction);
        window.removeEventListener("EnterCodeReceived", this._doActionWithDelay);
        window.removeEventListener('AspuIndicatorUpdated', this._doReload);
        window.removeEventListener("CodesModified", this._doTryReload);
        window.removeEventListener("ShowPopupMessage", this._doShowPopup);
        clearInterval(this.timerId);
    }

    eventCounter = 0;
    timerId: any;

    doTryReload() {
        //console.log('doTryReload: ' + this.eventCounter);
        this.eventCounter++;
    }

    preventDoubleCheck() {
        this.timerId = setInterval(() => {
            if (this.eventCounter > 0) {
                this.eventCounter = 0;
                this.loadCodes();
            }
        }, 5000);
    }

    //public loadItems() {
    //    this.loading = false;
    //}

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) =>
        {
            console.log(this.indicator?.Production)
            console.log(data.Value.Production)
            if (this.indicator?.Production != data.Value.Production && data.Value.Production == "Enabled") {
                console.log('soundScan.play')
                this.soundScan.play();

            }

            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled")
            {
                this.loadCodes();
            }
            else
            {
                this.loading = false;
            }
        });
    }

    counter = 0;
    currentCode: AspuCode;
    code: string;

    editCode(row: AspuCode) {
        //console.log("row");
        //console.log(row);
        this.counter++;
        this.currentCode = row;

        this.currentProductCode = undefined;
    }

    doAction(code: string) {
            if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        switch (this.mode) {
            case "aggregation":
                this.serialize(code.trim());
                this.code = undefined;
                break;
            case "remove":
                this.weight = undefined;
                this.weightKg = undefined;
                this.serviceCode = undefined;
                this.remove(code.trim());
                this.code = undefined;
                break;
            case "info":
                this.weight = undefined;
                this.weightKg = undefined;
                this.serviceCode = undefined;
                this.editCode(new AspuCode(code.trim()));
                this.code = undefined;
                break;
            case "weight":
                this.serviceCode = undefined;
                this.weight = code.trim();
                this.doWeight(this.weight);
                this.code = undefined;
                break;
            case "servicecode":                
                this.serviceCode = code.trim();
                this.doServiceCode(this.serviceCode);
                this.code = undefined;
                break;
            default: break;
        }
    }

    remove(code: string) {
        this.loading = true;
        this.serv.delete(new AspuCodeOnly(code)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                //this.loadItems();
                //this.getToast("Код забракован", 'info');
                this.loadCodes();
                this.toastService.getToast("Код забракован!", 'warning');
                this.soundOk.play();
                this.CodeAddedSuccess = 'codeDefectSuccess';
                //this.successAlert = true;
               // setTimeout(() => { this.successAlert = false }, 2000);
            }
            else {
                this.toastService.getToast("Ошибка при забраковке кода!", 'error');
                this.soundError.play();
                this.CodeAddedSuccess = 'coreDefectFailed';

               // this.failAlert = true;
               // setTimeout(() => { this.failAlert= false }, 2000);
            }
            this.isReady = true;
            this.loading = false;
            this.setTimetToCleanCodeAddedSuccess();
        },
            error => console.error(error));
    }

    //successAlert = false;
    //failAlert = false;


    serialize(code: string) {
        this.loading = true;

        var data = new AspuCode(code);
        if (this.isWeightEnable && this.weightKg != undefined)
            data.Weight = this.weightKg;

        if (this.isServiceCodeEnable && this.serviceCode != undefined)
            data.ServiceCode = this.serviceCode;

        this.serv.serialize(data).subscribe((data: IResult<any>) => {
            //console.log(data);
            if (data.IsSuccess) {
                this.ShowBlackSquare(100, 500);
                this.soundOk.play();
                this.CodeAddedSuccess = 'codeValidateSuccess';
                this.toastService.getToast("Код валидирован!", 'info');
                this.loadCodes();
                setTimeout(() => { this.getNextMode();}, 1500);                
                //this.getToast("Код валидирован", 'info');
                //this.successAlert = true;
                //setTimeout(() => { this.successAlert = false}, 2000);
            } else {
                this.soundError.play();
                this.CodeAddedSuccess = 'coreValidateFailed';
                this.toastService.getToast("Ошибка при валидировании кода!", 'error');
                /*this.failAlert = true;*/
                /*setTimeout(() => { this.failAlert = false }, 2000);*/
            }

            this.isReady = true;
            this.loading = false;
            this.setTimetToCleanCodeAddedSuccess();
        },
        error => {
            console.error(error)
        });
    }

    public loadCodes() {
        //if (!this.isDetalized)
        //    return;

        this.loading = true;
        this.cserv.getItemsV2(this.getFilter()).subscribe((data: IResult<Page<AspuCode>>) => {
            if (data.IsSuccess) {
                this.page = data.Value;
            }
            this.isReady = true;
            this.loading = false;
        }, error => console.error(error));
    }

    getFilter() {
        let filter = new FiltersContainer();
        filter.includes = [];
        filter.includes.push("ProductType");
        filter.filter = new GridFilters();
        filter.filter.logic = "and";
        filter.filter.filters = this.gridFilters.filter(x => (x.value != undefined && x.operator != undefined) || (x.filters != undefined && x.filters.length > 0));

        filter.take = this.page.Size;
        filter.skip = (this.page.PageNumber) * this.page.Size;
        filter.sort = [];
        if (this.gridSort.field != null) {
            filter.sort.push(this.gridSort);
        }
        return filter;
    }

    updateFilter(event) {
        this.page.PageNumber = 0;
        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(500)) // wait some ms after the last event before emitting last event
                // .pipe(distinctUntilChanged()) // only emit if value is different from previous value
                .subscribe(() => {
                    this.loadCodes();
                });
        }
        this.searchChangeObserver.next(event);
    }

    onPage(event) {
        this.page.PageNumber = event.offset;
        this.loadCodes();
    }

    setPage(event) {
        const val = event.newValue;
        this.page.PageNumber = 0;
        this.page.Size = val;

        if (!this.searchChangeObserver) {
            Observable.create(observer => {
                this.searchChangeObserver = observer;
            }).pipe(debounceTime(300))
                .pipe(distinctUntilChanged())
                .subscribe(() => {
                    this.loadCodes();
                });
        }
        this.searchChangeObserver.next(event);

        //const val = event.target.value;

        //this.loadItemsV2();
    }

    onSort(event) {
        const sort = event.sorts[0];
        this.gridSort.field = sort.prop;
        this.gridSort.dir = sort.dir;
        this.loadCodes();
    }

    public resetChild: Object;

    resetFilter() {
        this.resetChild  = JSON.parse(JSON.stringify(this.gridFilters));
    }

    getGridFilter(field: string, val: any, operator: string, isHidden = false) {
        let gridFilter = new GridFilter();
        gridFilter.field = field;
        gridFilter.value = val;
        gridFilter.operator = operator;
        gridFilter.isHidden = isHidden
        return gridFilter;
    }


    getOperationStatus() {
        return this.CodeAddedSuccess;
    }

    getCodeBgColor() {
        switch (this.CodeAddedSuccess) {
            case 'codeValidateSuccess' :
            case 'codeDefectSuccess' : 
            case 'weightReadedSuccess' :
            case 'serviceCodeReadedSuccess' : {
                return 'codeReadedSuccess';
            }    
            case 'coreValidateFailed' :
            case 'coreDefectFailed' :
            case 'weightReadedFailed' : 
            case 'serviceCodeReadedFailed' : {
                return 'coreReadedFailed';
            }           
            case undefined :
            case '' : {
                    return '';
            }
            default : {            
                return '';
            }
        }
    }




    setTimetToCleanCodeAddedSuccess() {
        setTimeout(() => { this.CodeAddedSuccess = ''; }, 1000);
    }

    setStatus(status: string) {
        this.CodeAddedSuccess = status;
        this.setTimetToCleanCodeAddedSuccess();
    }

    getRowClass = (row: AspuCode) => {
        return {
            //'bg-danger': row.Type == "Error",
            'bg-dark text-danger': row.EntityStatus == 'Defect',
            //'bg-warning': row.Type == "Warning",
            'bg-custom-validated': row.Validated && row.EntityStatus != 'Defect',
            'bg-custom-success': row.EntityStatus == 'FullEntity' && row.OmsUploaded == undefined,
            'bg-custom-accepted': row.OmsUploaded,
            'bg-custom-danger': row.EntityStatus == 'FullEntity' && row.OmsUploaded == false,
            //'bg-info': row.Type == "Critical" && row.ConfirmStatus==="Confirmed",
        };
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name, localStorage.getItem('industry'));
    }

    plcQueueClear() {
        this.loading = true;
        this.serv.plcQueueClear().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
              //  this.loadItems();
            }
            this.isReady = true;
        },
        error => console.error(error));
        this.loading = false;
    }

    timer1:any;
    timer2:any;
    isSquareVisible = false;

    ShowBlackSquare(delay: number, time: number)
    {
        if (this.currentAlgorithm==undefined || !this.currentAlgorithm.IsShowBlackSquare) return;

        clearTimeout(this.timer1);
        clearTimeout(this.timer2);
        if (!this.isSquareVisible) {
            this.isSquareVisible=true;
            this.timer2 = setTimeout(() => { this.isSquareVisible=false; }, time);
        } else {
            this.isSquareVisible=false;
            this.timer1 = setTimeout(() => { this.isSquareVisible=true; }, delay);
            this.timer2 = setTimeout(() => { this.isSquareVisible=false; }, time);
        }
    }

    modalReference: any;
    popupMessage: string;
    popupMessageStatus: boolean;

    private _doShowPopup = this.doShowPopup.bind(this);

    doShowPopup(data: any) {
        console.log(data);
        let result = data.detail;
        this.openPopup();
        this.popupMessage = result.topic;
        this.popupMessageStatus = result.data;
        setTimeout(() => { this.modalService.dismissAll() }, 1000);
    }

    @ViewChild('contentPopup', { static: true }) viewMePopup?: TemplateRef<any>; //ElementRef<HTMLElement>;
    openPopup() {
        this.modalReference = this.modalService.open(this.viewMePopup, { centered: true, scrollable: false, windowClass: 'custom-modal-class', size: 'xl' });
    }

    
    isWeightEnable = false;
    isWeightBarcodeEnable = false;
    isWeightTerminalEnable = false;
    isServiceCodeEnable = false;

    public getAdditionalModesEnable() {
        this.getWeightEnable();
        this.getServiceCodeEnable();
    }

    private getNextMode() {
        this.weight = undefined;
        this.weightKg = undefined;
        this.serviceCode = undefined;
        if (this.isWeightEnable) {
            if (this.mode == "weight") return;

            console.log("set mode weight");
            this.mode = "weight";
            this.doWeight(this.weight);
            return;
        }
        if (this.isServiceCodeEnable) {
            if (this.mode == "servicecode") return;

            console.log("set mode service code");
            this.mode = "servicecode";
            return;
        }
    }

    private getWeightEnable() {
        this.isWeightEnable = false;
        this.serv.getWeightEnable().subscribe((data: IResult<number>) => {
            console.log("get weight enable");
            console.log(data.Value);
            if (data.IsSuccess) {
                this.isWeightEnable = data.Value != 0;
                this.isWeightBarcodeEnable = data.Value == 1;
                this.isWeightTerminalEnable = data.Value == 2;
                this.getNextMode();
            }
        });
    }

    private getServiceCodeEnable() {
        this.isServiceCodeEnable = false;
        this.serv.getServiceCodeEnable().subscribe((data: IResult<boolean>) => {
            console.log("get service code enable");
            console.log(data.Value);
            if (data.IsSuccess) {
                this.isServiceCodeEnable = data.Value;
                this.getNextMode();
            }
        });
    }

    weight: string;
    weightKg: number;
    weightCounter = 0;

    doWeight(value:string) {
        if (this.mode != "weight" || !this.isWeightEnable || (this.isWeightBarcodeEnable && value == undefined)) {
            this.weightCounter = 0;
            return;
        }
        
        if (this.weightCounter > 100) {
            this.weightCounter = 0;

            this.soundError.play();
            this.CodeAddedSuccess = 'weightReadedFailed'; 
            this.toastService.clear();
            this.toastService.getToast("Превышено допустимое количество запросов к весовому терминалу", 'error');
            return;
        }
        
        this.loading = true;
        this.serv.weight(new AspuWeightOnly(value)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.weightKg = data.Value;
                this.soundOk.play();
                this.setStatus('weightReadedSuccess');
                this.toastService.clear();
                this.toastService.getToast("Вес получен " + this.weightKg + " грамм!", 'info');
                this.mode = this.isServiceCodeEnable == true ? "servicecode" : "aggregation";
                this.weightCounter = 0;
            } else {
                this.weightKg = undefined;
                this.weightCounter ++;
                if (this.isWeightTerminalEnable) {
                    if (this.weight == undefined || this.weight == '') {
                        setTimeout(() => {this.doWeight(value);}, 250);
                    } else {
                        this.toastService.clear();
                    }
                } else {
                    this.soundError.play();
                    this.setStatus('weightReadedFailed');
                }
            }
            this.isReady = true;
        },
        error => console.error(error));
        this.loading = false;
    }

    serviceCode: string;

    doServiceCode(serviceCode:string) {
        if (!this.isServiceCodeEnable || this.mode!="servicecode") return;

        this.loading = true;
        if (serviceCode == undefined) serviceCode = "";       
        this.serv.serviceCode(new AspuServiceCodeOnly(serviceCode)).subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.soundOk.play();
                this.CodeAddedSuccess = 'serviceCodeReadedSuccess';
                this.toastService.getToast(data.Value, 'info');
                this.mode = "aggregation";
            } else {
                this.serviceCode = undefined;
                this.soundError.play();
                this.CodeAddedSuccess = 'serviceCodeReadedFailed';
            }
            this.isReady = true;
            this.setTimetToCleanCodeAddedSuccess();
        },
        error => console.error(error));
        this.loading = false;
    }

    isWeightAvailable() {
      return this.page?.Items?.filter(x => x.Weight != undefined).length > 0
    }

    isServiceCodeAvailable() {
      return this.page?.Items?.filter(x => x.ServiceCode != undefined).length > 0
    }

    modeChanged(event:any) {
        this.toastService.clear();
        this.doWeight(undefined);
        this.CodeAddedSuccess = '';
    }
}
