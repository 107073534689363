import { Component, Input } from "@angular/core";
import { LangService } from 'vekas_models/services/lang.service';
import { AspuDiagnosticInfo } from "../../business/core/common/common.model";

@Component({
  selector: "page-footer",
  templateUrl: "page-footer.component.html",
})

export class PageFooterComponent
{
  isNavExpand = true;
  aspuInfo: AspuDiagnosticInfo;

    constructor(private lservice: LangService) {
        this.isNavExpand = localStorage.getItem("isNavExpand") == 'false' ? false : true;
    }

    ngOnInit() {
      window.addEventListener("ChangeNavSize", this._doChangeNavSize, false);
      window.addEventListener("AspuDiagnosticEvent", this._doAspuDiagnosticUpdated, false);

    }

    ngOnDestroy() {
      window.removeEventListener("ChangeNavSize", this._doChangeNavSize);
      window.removeEventListener("AspuDiagnosticEvent", this._doAspuDiagnosticUpdated);
    }

    private _doChangeNavSize = this.doChangeNavSize.bind(this);
  private _doAspuDiagnosticUpdated = this.doAspuDiagnosticUpdated.bind(this);

    doChangeNavSize(data: any) {
        this.isNavExpand = JSON.parse(data.detail);
  }

  doAspuDiagnosticUpdated(data: any) {
    console.log(data);
    this.aspuInfo = data.detail;
  }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
