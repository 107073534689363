import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { LangService } from 'vekas_models/services/lang.service';
import { IResult } from 'vekas_models/models/iresult';
import { AspuAggregationBoxVisualService } from './aggregation-box-visual.service';
import { MessageCenterService } from 'vekas_models/core/message-center/message-center.service';
import { ToastService } from 'vekas_models/core/toaster/toast-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AspuIndicatorService } from '../../../core/indicator/indicator.service';
import { AspuCode, AspuCodeOnly } from '../../code/code.model';
import { ConfirmationModel } from 'vekas_models/core/confirm/confirmation.model';
import { AspuIndicator } from '../../../core/indicator/indicator.model';
import { AspuBoxEntity, AspuSetEntityResult } from '../box/aggregation-box.model';
import { AspuBlockAggrState, AspuBlockLayer, AspuProductItem } from './aggregation-box-visual.model';

@Component({
    selector: 'app-aggregation-box-visual-component',
    templateUrl: './aggregation-box-visual.component.html',
    styleUrls: ['./aggregation-box-visual.scss'],
    providers: [AspuAggregationBoxVisualService, AspuIndicatorService]
})
export class AspuAggregationBoxVisualComponent implements OnInit {
    viewModel = new AspuBlockAggrState();
    isReady = true;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;
    codeLevel = '';
    currentCode: string;

    isHandleModeAvailable = false;
    isDetalized = false;

    currentProductCode: string;

    private _doAction = this.doActionPrevent.bind(this);
    private _doReload = this.doReload.bind(this);

    constructor(private serv: AspuAggregationBoxVisualService, private lservice: LangService,
        public modalService: NgbModal, public toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private indServ: AspuIndicatorService
    ) {

        this.loadItemsWithStateCheck();
    }

    ngOnInit() {
        window.addEventListener("AggrStateUpdated", this._doReload, false);
        window.addEventListener("CodeReceived", this._doAction, false);
        window.addEventListener("EnterCodeReceived", this._doAction, false);
    }

    ngOnDestroy() {
        window.removeEventListener("AggrStateUpdated", this._doReload);
        window.removeEventListener("CodeReceived", this._doAction);
        window.removeEventListener("EnterCodeReceived", this._doAction);
    }
    
    doActionPrevent(data: any) {
        this.doAction(data.detail)
    }

    doAction(code: string) {
        console.log("doAction")
        if (code == null) {
            var elem = document.getElementById('codeInput') as HTMLInputElement;
            code = elem.value;
            if (code == null) {
                var elem1 = document.getElementById('codeInputSpan');
                code = elem1.innerHTML;
            }
        }

        this.serv.pushCode(new AspuCodeOnly(code)).subscribe((data: IResult<AspuSetEntityResult>) => {
            if (data.IsSuccess) {
                let result = data.Value;
                if (!result.IsOk) {
                    this.toastService.getToast(result.Message, 'error');
                }
                else {
                    this.toastService.getToast(data.Value.Message, 'info');
                }
            }

            this.isReady = true;
            this.loading = false;
            this.currentCode = undefined;
        },
            error => console.error(error));

    }

    doReload(event) {
        if (event.detail == "LayerBox") {
            this.loadItems();
        }
    }

    clearLayer(){
        console.log("Clear layer"); 
        this.serv.clearLayer().subscribe((data: IResult<any>) => {
            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    clearAll(){
        console.log("Clear layer"); 
        this.serv.clearAll().subscribe((data: IResult<any>) => {
            this.isReady = true;
            this.loading = false;
        },
            error => console.error(error));
    }

    public loadItems() {
        this.loading = true;
        this.serv.getStatus().subscribe((data: IResult<AspuBlockAggrState>) => {
            console.log(data.Value);
            data.Value.Layers.reverse();
            this.viewModel = data.Value;
            this.isReady = true;
            this.loading = false;
        });
    }

    indicator = new AspuIndicator();

    public loadItemsWithStateCheck() {
        this.indServ.getIndicators().subscribe((data) => {
            this.indicator = data.Value;
            if (data.IsSuccess && data.Value.Production == "Enabled") {
                this.loadItems();
            }
            else {
                this.loading = false;
            }
        });
    }

    getBorder(layer: AspuBlockLayer, color: string) {
        let hasSuccess = layer.Items.filter(x => x.IsOk).length == layer.Items.length;
        let hasError = layer.Items.filter(x => x.IsError).length != 0;
        switch (color) {
            case 'success':
                return hasSuccess;
            case 'danger':
                return hasError;
            default:
                return !(hasError || hasSuccess);
        }
    }

    getCellError() {
        for (var i = 0; i < this.viewModel.Layers.length; i++) {
            let layer = this.viewModel.Layers[i];
            if (layer.CellError != undefined)
                return layer.CellError;
        }
        return undefined;
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}