import { Component, OnInit, Injectable } from '@angular/core';
import { LoginModel } from './login.model';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { LangService } from "vekas_models/services/lang.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit
{
  loginModel: LoginModel = new LoginModel();
  loginError: string = "";

    constructor(private loginService: LoginService, private lservice: LangService, private router: Router) 
  {
        this.loginService.Logout();
  }

  ngOnInit()  {  }

  onLoginClick(event)
  {
    this.loginService.Login(this.loginModel).subscribe(
        (response) => {
            console.log(response);
            window["modulePath"] = response.ModulePath;
            localStorage.setItem("modulePath", response.ModulePath);
            localStorage.setItem("userTheme", response.Theme == undefined ? 'light-theme' : response.Theme);
            let c_event = new CustomEvent<any>("Login");
            window.dispatchEvent(c_event);
            //console.log("window['modulePath']");
            //console.log(window['modulePath']);
            if (response.DefaultRedirectUrl != undefined) {
                window["lastUrl"] = response.DefaultRedirectUrl
                localStorage.setItem("lastUrl", response.DefaultRedirectUrl);
            }

            if (response.UserName != undefined) {
                this.loginService.GetRoles(response.Id).subscribe(
                    (response) => {
                        //console.log(response);
                    });
            }

            this.router.navigateByUrl(window["lastUrl"]);
      },
      (error) => {
        //console.log(error);
          console.log('onLoginClick error');
          this.loginError = this.getlocalized('common', 'MessageLoginFail');
      }
    );
    }

  checkRolesForUser() {

  }

  getlocalized(type: string, name: string) {
      return LangService.getlocalized(type, name);
  }
}
