import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AspuReportService } from "./report.service";
import { AspuReportItem, AspuReportStatus } from './report.model';
import { LangService } from 'vekas_models/services/lang.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';

@Component({
    selector: 'app-aspu-report-list-component',
    templateUrl: './report-list.component.html',
    providers: [AspuReportService]
})
export class AspuReportListComponent implements OnInit {
    //rows = new Array<AspuReportItemStatus>();
    viewModel = new AspuReportStatus();
    isReady = false;
    loading = true;
    ColumnMode = ColumnMode;
    reorderable = true;

    constructor(private serv: AspuReportService, private lservice: LangService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        if (localStorage.getItem("token") == undefined) {
            this.router.navigate(['/home']);
        }
        this.loadItems();
    }

    ngOnInit() { }

    onSort(event) {
    }

    private loadItems() {
        this.loading = true;

        this.serv.getReports().subscribe((data: IResult<AspuReportStatus>) => {
            if (data.IsSuccess) {
                this.viewModel = data.Value;
                    //console.log(some);
                    this.isReady = true;
                } else {
                    console.log(data);
                }
                    this.loading = false;
            }, error => console.error(error));

    }

    private reloadReports() {
        this.serv.reloadReports().subscribe((data: IResult<any>) => {
            if (data.IsSuccess) {
                this.loadItems();
                let some = data.Value;
                console.log(some);
                this.isReady = true;
            } else {
                console.log(data);
            }
            this.loading = false;
        }, error => console.error(error));
    }

    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
