import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from "../../../services/aspu.http.service";
import { AspuCodeOnly } from "../../code/code.model";
import { AspuBranchState } from './branch-serialization.model';

@Injectable()
export class AspuBranchSerializationService {

    constructor(
        private httpService: AspuHttpService) { }

    serialize(item: AspuCodeOnly) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'AggrAlgorithms/BranchSerialization');
    }

    delete(item: AspuCodeOnly) {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'AggrAlgorithms/BranchSerialization/delete');
    }

    plcQueueClear() {
        return this.httpService.post<IResult<any>>(null, 'AggrAlgorithms/BranchSerialization/plc/queue/clear');
    }

    getStatus(): Observable<IResult<AspuBranchState>> {
        return this.httpService.get<IResult<AspuBranchState>>('AggrAlgorithms/BranchSerialization');
    }

    clearLayer(){
        return this.httpService.delete<IResult<any>>(null, 'AggrAlgorithms/BranchSerialization');
      }
}
