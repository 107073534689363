import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResult } from 'vekas_models/models/iresult';
import { AspuHttpService } from '../../../services/aspu.http.service';
import { AspuCodeReplace } from '../../code/code-edit/code-edit.model';
import { AspuCodeOnly, AspuCodeParentChild } from '../../code/code.model';
import { AspuSetEntityResult } from '../box/aggregation-box.model';
import { AspuBoxAggrState } from './aggregation-box-layer-timestamp.model';

@Injectable()
export class AspuAggrBoxLayerTimestampService {
    url = "AggrAlgorithms/LayerBoxTimestamp";

    constructor(private httpService: AspuHttpService) {}

    getStatus(): Observable<IResult<AspuBoxAggrState>> {
        return this.httpService.get<IResult<AspuBoxAggrState>>(this.url+ '/layer');
    }

    clearLayer(){
        return this.httpService.delete<IResult<any>>(null, this.url + '/layer');
    }

    clearAll(){
        return this.httpService.delete<IResult<any>>(null, this.url + '/alllayer');
    }

    pushCode(item: AspuCodeOnly):Observable<IResult<AspuSetEntityResult>>{
        return this.httpService.put<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url + '/layer');
    }

    updateStateByBoxCode(item: AspuCodeOnly):Observable<IResult<AspuSetEntityResult>>{
        return this.httpService.post<IResult<AspuSetEntityResult>>(JSON.stringify(item), this.url + '/box');
    }
    
    addToAggregate(item: AspuCodeParentChild): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/bind');
    }
    
    removeFromAggregate(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/unbind');
    }

    replaceCodeAggregate(item: AspuCodeReplace): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/replace/code');
    }

    replaceChildAggregate(item: AspuCodeReplace): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/replace/child');
    }    

    destroyAggregate(item: AspuCodeOnly): Observable<IResult<any>> {
        return this.httpService.post<IResult<any>>(JSON.stringify(item), 'Codes/destroy');
    }
}
