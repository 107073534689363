import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { Router } from '@angular/router';
import { AuthorizationService } from "vekas_models/services/authorization.service";
import { MessageCenterService } from "vekas_models/core/message-center/message-center.service";
import { LangService } from 'vekas_models/services/lang.service';
import { LoginService } from "../login/login.service";
import { AspuHttpService } from "../../business/services/aspu.http.service";
import { AspuCommonService } from "src/app/business/core/common/common.service";
import { IResult } from "vekas_models/models/iresult";
import { AspuDiagnosticInfo } from "src/app/business/core/common/common.model";

@Component({
    selector: "page-header",
    templateUrl: "./page-header.component.html",
    styleUrls: ["./page-header.component.css"],
    providers: [AspuHttpService, AspuCommonService]
})

export class PageHeaderComponent {
    messageCenterService = MessageCenterService.getInstance();
  langService: LangService;
  aspuInfo = new AspuDiagnosticInfo();
    isNavExpand: boolean;
    constructor(public authorizationService: AuthorizationService, public serv: AspuHttpService,
        private loginService: LoginService,
        private lservice: LangService,
        private cservice: AspuCommonService,
        private router: Router
    ) {
        this.isNavExpand = localStorage.getItem("isNavExpand") == 'false' ? false : true;
        if (!this.authorizationService.IsAuthorized()) {
            this.isNavExpand = true;
            this.changeNavbarSize();
        }
    }


  public innerWidth: any;

  ngOnInit() {
    this.innerWidth = window.innerWidth;
        //this.checkToken();
        this.getDiagnostic();
        window.addEventListener("AppConfigChanged", this._getDiagnostic, false);
    window.addEventListener("ExpandBarEvent", this._setExpand, false);
    }

    ngOnDestroy() {
        clearInterval(this.timerId);
      window.removeEventListener('AppConfigChanged', this._getDiagnostic);
      window.addEventListener("ExpandBarEvent", this._setExpand);
    }

    private _getDiagnostic = this.getDiagnostic.bind(this);
    private _setExpand = this.setExpand.bind(this);

    getDiagnostic(){
        this.cservice.getDiagnosticInfo().subscribe((data: IResult<AspuDiagnosticInfo>) => {
            if (data.IsSuccess) {
              this.aspuInfo = data.Value;
              let c_event = new CustomEvent<any>('AspuDiagnosticEvent', { detail: this.aspuInfo});
              window.dispatchEvent(c_event);
            }
        },
            error => console.error(error));
  }

  setExpand() {
    this.changeNavbarSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

    getLoginWidth() {
        var availableSpace: number = Math.floor(this.innerWidth - 949);
        let username = this.authorizationService.GetUserName();
        var koef = 0;
        if (username.length < 12)
            koef = 22;
        else if (username.length >= 12 && username.length < 24)
            koef = 44;
        else  
            koef = 88;

        var loginWidth: number = Math.floor(this.measureText(username)) + koef;
        let result = loginWidth > availableSpace ? availableSpace + 'px' : loginWidth + 'px'
        return result;
  }

  measureText(str, fontSize = 18) {
    const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625]
    const avg = 0.5279276315789471
    return str
      .split('')
      .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .reduce((cur, acc) => acc + cur) * fontSize
  }

  @ViewChild('widthTarget')
  widthTarget: ElementRef;

  ngAfterViewInit() { //view children must be accessed at this hook or later
    console.log(this.widthTarget.nativeElement.width);
  }

  displayTextWidth() {
    //const canvas = document.getElementById('canvas');
    //const ctx = canvas.getContext('2d');

    //let text = ctx.measureText('Hello world');
    //console.log(text.width);  // 56;



//    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
//    let context = canvas.getContext("2d");
  //  let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
  //let context = canvas.getContext("2d");
  //context.font = font;
  //let metrics = context.measureText(text);
  //return metrics.width;
}
    //ngOnDestroy() {
    //    window.removeEventListener('AspuIndicatorUpdated', this._doAction);
    //}

    //private _doAction = this.doActionPrevent.bind(this);

    //doActionPrevent(data: any) {
    //    let indicator = data.detail;
    //    this.isBatch = (indicator.Batch === 'Enabled');
    //}

    onLogout() {
        console.log("LogOut happened")
        this.loginService.Logout();
        this.messageCenterService.removeAll();
        // this.langService.setDefaultLang();
        //TODO: home
        this.router.navigateByUrl("/home");
    }

    isAdmin() {
        let val = localStorage.getItem("isUserAdmin");
        if (val != undefined && val == "true")
            return true;
        return false;
    }

    hasAccess()
    {
        let userUIAccess = JSON.parse(this.authorizationService.GetUserUIAccess());
        if (userUIAccess == null) return false;
        let isMenuItemExist = userUIAccess.includes('ASPU_UI!!Settings');
        return isMenuItemExist;
    }

    counter = 0;
    timerId: any;

    //checkToken() {
    //    this.timerId = setInterval(() => {
    //        let now = Date.now();
    //        let exp = this.authorizationService.GetTokenExpirationDateTime();

    //        if (exp != null && ((exp.getTime() - now) < 1000 * 60 * 2 || now > exp.getTime())) {
    //            /*this.serv.refreshToken(this.loginService);*/
    //            this.serv.refreshToken();
    //        } 
    //    }, 60000);
    //}

    changeNavbarSize() {
        this.isNavExpand = !this.isNavExpand;
        /*console.log('changeNavbarSize')*/
        localStorage.setItem("isNavExpand", this.isNavExpand + '');
        let c_event = new CustomEvent<any>("ChangeNavSize", { detail: localStorage.getItem("isNavExpand") });
        window.dispatchEvent(c_event);
    }


    getlocalized(type: string, name: string) {
        return LangService.getlocalized(type, name);
    }
}
