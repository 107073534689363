 export class AspuSettings {
    ProductionLineId: string;
    AspuUserName: string;
    AspuPassword: string;
    LineNumber: string;
    ServerNumber:number;
    OmsLineName: string;
    StoreBackupDays: number;
    ReasignCodesFromOms: boolean;
    DefectDuplicateOnValidation: boolean;
    DefectPrintedAndNotValidated: boolean;
    EnablePrintingWhenProductionDisabled: boolean;
    DraftProductionDatePlanEnable: boolean;
    UseOnlyDraftBatchEnable: boolean;
    UsePartialReportsUploading: boolean;
    CheckProductInStorageEnable: boolean;
    ProductWeightValidationEnable:boolean;
    IgnoreBackupCreating: boolean;
    PartialReportsSecondsValue: number;
    PartialReportsCapacityValue: number;
    PlcId: number;
    Performance: number;
    IsHideBatchStopBtn: boolean;
    Fields: any;
    AllowValidatePrintedOnFinalize: boolean;

    constructor() {
        this.Fields = new Object();
    }        
}

export class AspuCameraConfig {
    Host: string;
    Port: number;
    Id: number;
    Destination: string;
}

export class AspuWeightConfig {
    discriminator: string;
    Id: string;
    Enable: boolean;
    DriverId: string;
    DriverName: string;
    MinValue: number;
    MaxValue:number;
    Host: string;
    Port: number;
    Password: string;
    Timeout: number;
    Pause: number;
    Length: number;
    Skip: number;
    Take: number;
    DecimalPlaces: number;
}

export class AspuWeightDriver {
    Id: string;
    Name: string;
    Discriminator: string;
    Config: AspuWeightConfig;
  }

export class AspuConfigSettings{
    PlcId: number;
    CameraConfig: Array<AspuCameraConfig>;
    WeightConfig: Array<AspuWeightConfig>;
}

export class AspuConfigurationJson {
    Id: string;
    Data: string;
}
