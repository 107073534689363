import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AspuReceiptInfo } from './receipt-info.model';
import { AspuHttpService } from '../../services/aspu.http.service';
//import { IResult } from '../../../../models/iresult';
import { IResult } from 'vekas_models/models/iresult';
import { FiltersContainer } from 'vekas_models/models/filtersContainer';
import { Page } from 'vekas_models/models/page';
import { AspuReceipt } from './receipt-detail.model';

@Injectable()
export class AspuReceiptService {

    constructor(
        private httpService: AspuHttpService) { }

    getItems(): Observable<IResult<Array<AspuReceiptInfo>>> {
        return this.httpService.get<IResult<Array<AspuReceiptInfo>>>('Receipts', null);
    }

    getItemsV2(filter: FiltersContainer): Observable<IResult<Page<AspuReceiptInfo>>> {
        return this.httpService.post<IResult<Page<AspuReceiptInfo>>>(filter, 'Receipts/batch');
    }

    getItemById(id: string): Observable<IResult<AspuReceipt>> {
        return this.httpService.get<IResult<Array<AspuReceipt>>>('Receipts', id);
    }

    updateItem(item: AspuReceipt) {
        return this.httpService.post<IResult<AspuReceipt>>(JSON.stringify(item), 'Receipts/' + item.Id);
    }

    getImage(id: string) {
        return this.httpService.get<IResult<any>>("oms/producttype/getPhoto", id);
    }
}