export class AspuProductTypeCode {
    ProductTypeId: string;
    Code: string;
 }

export class AspuKmRequest {
    ProductTypeId: string;
    ErpId: string;
    Count: number;
}

export class AspuBatchStart {
    ReceiptId: string;
    Name: string;
    //VeterinaryCertificate: string;
    ProductTypes: Array<AspuKmRequest> 
    UserDefinedVars: any;
    IdentificationCodesSource: string;

    constructor() {
        this.UserDefinedVars = {};
    }
}

export class AspuBatchStartFromBarcode {
    KmCode: string;
    UserDefinedVars: any;

    constructor(code: string) {
        this.KmCode = code;
        this.UserDefinedVars = {};
    }
}

export class AspuAdditionLoading {
    CodesInfo: Array<AspuKmRequest>
}

//export class AspuJobAnswer {
//    Id: string;
//    Name: string;
//    SubStepId: string;
//    SubStepName: string;
//    Active: boolean;
//    Failed: boolean;
//    Error: string;
//}