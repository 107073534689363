import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PageHeaderComponent } from "./page-header/page-header.component";
import { PageFooterComponent } from "./page-footer/page-footer.component";
import { MessageCenter } from "vekas_models/core/message-center/message-center.component";
import { NgbAlertModule, NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LangComponent } from "vekas_models/core/lang/lang.component";
import { AspuIndicatorComponent } from "../business/core/indicator/indicator.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
        RouterModule,
        NgbAlertModule,
        //NgbTooltip,
        NgbModule,
        FormsModule
  ],
  declarations: [
    PageHeaderComponent,
      PageFooterComponent,
      MessageCenter,
      AspuIndicatorComponent,
      LangComponent,
  ],
  providers: [],
  exports: [
    PageHeaderComponent,
      PageFooterComponent,
      AspuIndicatorComponent,
  ]
})

export class PageModule { }
