<div class="card mt-2">
    <div class="card-header">
        <h3>
            <span class="header-badge text-uppercase">{{getlocalized('common', 'Reports')}}</span>
            <span *ngIf="loading" class="ms-3"><app-spinner [mode]="'burst'"></app-spinner></span>
        </h3>
    </div>
    <div class="card-body form-group mb-3 pb-0">
        <ngx-datatable ngx-resize-watcher *ngIf="isReady" ngx-localizer class="bootstrap ngx-datatable"
                       id="someTable"
                       [rows]="viewModel.Items"
                       [loadingIndicator]=""
                       [columnMode]="ColumnMode.force"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       rowHeight="auto"
                       [reorderable]="reorderable">
            <ngx-datatable-column name="{{getlocalized('ReportList', 'Name')}}" prop="Name" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{getlocalized('ReportTypeDictionary', value)}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReportList', 'Status')}}" prop="Status" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                    <div>
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{getlocalized('ReportStatusDictionary', value)}}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('ReportList', 'Errors')}}" prop="Errors" cellClass="custom-row" [canAutoResize]="true" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn" let-sortDir="sortDir">
                        <div class="header-label header-label-nofilter">
                            <span (click)="sort()">
                                {{column.name}}
                            </span>
                        </div>
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="{{getlocalized('common', 'Actions')}}" headerClass="central-btn" cellClass="central-btn" [minWidth]="120" [maxWidth]="130" [resizeable]="false">
                <ng-template let-column="column" ngx-datatable-header-template>
                    <div class="header-label">
                        <span>
                            {{column.name}}
                        </span>
                    </div>
                    <div class="btn-group2">
                        <button *ngIf="viewModel.Items.length>0" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Reload')}}" (click)="reloadReports()" class="btn btn-sm btn-danger me-1 "><i class="fa fa-redo"></i></button>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Update')}}" (click)="loadItems()" class="btn btn-sm btn-info"><i class="fa fa-redo"></i></button>
                    </div>
                </ng-template>
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <!--<div class="btn-group action-buttons">
                        <a routerLink="/product-type/{{row['Id']}}/editCard" [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Edit')}}" class="btn btn-sm btn-info"><i class="fa fa-edit"></i></a>
                        <button [openDelay]="1500" [closeDelay]="400" ngbTooltip="{{getlocalized('common', 'Remove')}}" (click)="actionRow(row['Id'], 'delete')" class="btn btn-sm btn-danger"><i class="fa fa-trash-alt"></i></button>
                    </div>-->
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
